// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { put, call, takeLatest } from 'redux-saga/effects';

import { takeAuth } from './common.saga';

import Api from 'api';
import Actions from 'actions';

export function* settingsSaga() {
    yield takeLatest(Actions.projects.segmentTypesFetch, segmentTypesFetch);

    yield takeLatest(Actions.projects.createSegmentType, createSegmentType);

    yield takeLatest(Actions.projects.deleteSegmentType, deleteSegmentType);
}

function* segmentTypesFetch({ payload }) {
    try {
        const auth = yield takeAuth();

        yield put(Actions.projects.segmentTypesStart());
        const segmentTypes = yield call(
            Api.projects.getProjectSegmentTypes,
            auth,
            payload.offset,
            payload.limit
        );
        yield put(
            Actions.projects.segmentTypesSuccess(
                segmentTypes.count,
                segmentTypes.data
            )
        );
    } catch (error) {
        yield put(Actions.projects.segmentTypesFailure());
    } finally {
        yield put(Actions.projects.segmentTypesStop());
    }
}

function* createSegmentType({ payload }) {
    try {
        const auth = yield takeAuth();

        yield put(Actions.projects.segmentTypesStart());
        yield call(Api.projects.createProjectSegmentType, auth, payload);
        yield put(Actions.projects.createSegmentTypeSuccess());
    } catch (error) {
        yield put(Actions.projects.createSegmentTypeFailure());
    } finally {
        yield put(Actions.projects.segmentTypesStop());
    }
}

function* deleteSegmentType({ payload }) {
    try {
        const auth = yield takeAuth();

        yield call(Api.projects.deleteProjectSegmentType, auth, payload);
        yield put(Actions.projects.deleteSegmentTypeSuccess());
    } catch (error) {
        yield put(Actions.projects.deleteSegmentTypeFailure());
    } finally {
    }
}
