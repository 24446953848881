// Copyright © 2020 Embrox Solutions
// Copyright © 2020 Genconstrux GmbH

import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';

import { MapView } from 'components/map/MapView';
import { EntitiesMenu } from 'components/editor/EntitiesMenu';
import { ToolMenu } from 'components/editor/ToolMenu';
import { PropertiesDialog } from 'components/editor/PropertiesDialog';
import { StatusBar } from 'components/editor/StatusBar';
import { CreateSegmentDialog } from 'components/editor/CreateSegmentDialog';

const MemoMapView = React.memo(MapView);

const Editor = ({ className, editor }) => {
    return (
        <Container fluid className={`${className || ''} p-0`}>
            <PropertiesDialog
                onInitialize={editor.onPropertiesDialogInitialized}
                onSave={editor.onPropertiesDialogSaved}
            />
            <CreateSegmentDialog
                onInitialize={editor.onCreateSegmentDialogInitialized}
                onSave={editor.onCreateSegmentDialogSaved}
            />

            <MemoMapView
                className={`position-fixed p-0 m-0 w-100 h-100`}
                width='100%'
                height='100%'
                style={{
                    zIndex: 0,
                }}
                draw={editor.draw}
                initialCenter={editor.startPoint}
                onLoad={editor.onLoad}
            />

            <StatusBar
                className='position-fixed p-0'
                style={{
                    right: 10,
                    bottom: 0,
                    fontSize: '12pt',
                }}
                onInitialize={editor.onStatusBarInitialized}
            />

            <Row className='w-100 justify-content-between align-items-start p-4'>
                <ToolMenu
                    modes={editor.toolModes}
                    actions={editor.toolActions}
                    onInitialize={editor.onToolMenuInitialized}
                    onModeSelect={editor.onToolModeSelected}
                    onActionCall={editor.onToolActionCalled}
                />

              
           
                            <EntitiesMenu
                                className='position-fixed mr-2'
                                styles={{
                                    right: 0,
                                }}
                                onInitialize={editor.onEntitiesMenuInitialized}
                                onClick={editor.onEntitiesMenuClicked}
                            />
            </Row>
        </Container>
    );
};

export { Editor };
