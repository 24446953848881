// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

function NoAuthRoute({ component: Component, ...rest }) {
    const isLogged = useSelector(state => state.account.user.isLogged);

    return (
        <Route
            {...rest}
            render={props =>
                !isLogged ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{ pathname: '/', state: { from: props.location } }}
                    />
                )
            }
        />
    );
}

export { NoAuthRoute };
