// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { groupSegmentFeatures, ungroupSegmentFeatures } from './editor.group';

const POINTS_EPS = 0.0001;

const arePointsEquals = (a, b) => {
    return (
        POINTS_EPS > Math.abs(a[0] - b[0]) && POINTS_EPS > Math.abs(a[1] - b[1])
    );
};

const isSegmentFeture = (feature) =>
    feature &&
    feature.geometry &&
    ('LineString' === feature.geometry.type ||
        'MultiLineString' === feature.geometry.type);

const isAreaFeture = (feature) =>
    feature && feature.geometry && 'Polygon' === feature.geometry.type;

const getDefaultSegmentType = (instance) =>
    instance.segmentTypes.byId[instance.segmentTypes.allIds[0]];

const getDefaultAreaType = (instance) =>
    instance.areaTypes.byId[instance.areaTypes.allIds[0]];

const createEditorController = (instance) => {
    const createSegment = (feature) => {
        instance.draw.setFeatureProperty(feature.id, 'segment', {
            drawId: feature.id,
            id: null,
            points: feature.geometry.coordinates.map(() => ({
                elevation: 0.0,
                notes: '',
            })),
            isChanged: true,
            images: [],
            documents: [],
            segmentType: getDefaultSegmentType(instance),
        });

        instance.segments.push({
            drawId: feature.id,
            featureType: feature.geometry.type,
            id: null,
        });
    };

    const createArea = (feature) => {
        instance.draw.setFeatureProperty(feature.id, 'area', {
            drawId: feature.id,
            id: null,
            isChanged: true,
            images: [],
            documents: [],
            areaType: getDefaultAreaType(instance),
        });

        instance.areas.push({
            drawId: feature.id,
            featureType: feature.geometry.type,
            id: null,
        });
    };

    const createMapListener = (map, draw) => {
        map.on('mousemove', (e) => {
            instance.statusBar.setCoordinates(e.lngLat.lng, e.lngLat.lat);
        });

        map.on('draw.create', (e) => {
            for (let feature of e.features) {
                switch (feature.geometry.type) {
                    case 'LineString':
                        createSegment(feature);
                        break;
                    case 'Polygon':
                        createArea(feature);
                        break;
                    default:
                        break;
                }
            }

            instance.openPropertiesDialogRequested = true;
            instance.toolMenu.changeMode('cursor');
            instance.entitiesMenu.setSegments(instance.segments);
            instance.entitiesMenu.setAreas(instance.areas);
        });

        map.on('draw.selectionchange', (e) => {
            if (!e.features) {
                return;
            }

            if (instance.openPropertiesDialogRequested) {
                instance.openPropertiesDialog();
            }

            if (0 === e.features.length) {
                instance.entitiesMenu.select('');
            }

            for (let feature of e.features) {
                if (!feature.properties.segment) {
                    continue;
                }

                const featureSegment = feature.properties.segment;

                instance.entitiesMenu.select(featureSegment.drawId);
            }
        });

        map.on('draw.update', (e) => {
            switch (e.action) {
                case 'change_coordinates':
                    e.features.filter(isSegmentFeture).forEach((f) => {
                        const segmentProps = f.properties.segment;

                        switch (f.geometry.type) {
                            case 'LineString':
                                for (
                                    let i = 0;
                                    i <
                                    f.geometry.coordinates.length -
                                        segmentProps.points.length;
                                    ++i
                                ) {
                                    segmentProps.points.push({
                                        elevation: 0.0,
                                        notes: '',
                                    });
                                }

                                segmentProps.points.length =
                                    f.geometry.coordinates.length;

                                break;
                            case 'MultiLineString':
                                for (let i in f.geometry.coordinates) {
                                    for (
                                        let j = 0;
                                        j <
                                        f.geometry.coordinates[i].length -
                                            segmentProps.points[i].length;
                                        ++j
                                    ) {
                                        segmentProps.points[i].push({
                                            elevation: 0.0,
                                            notes: '',
                                        });
                                    }

                                    segmentProps.points[i].length =
                                        f.geometry.coordinates[i].length;
                                }

                                break;
                            default:
                                break;
                        }

                        segmentProps.isChanged = true;
                        instance.draw.setFeatureProperty(
                            f.id,
                            'segment',
                            segmentProps
                        );
                    });
                    e.features.filter(isAreaFeture).forEach((f) => {
                        const areaProps = f.properties.area;

                        areaProps.isChanged = true;

                        instance.draw.setFeatureProperty(
                            f.id,
                            'area',
                            areaProps
                        );
                    });
                    break;

                default:
                    break;
            }
        });
    };

    const savePointsProperties = (data) => {
        const pointsFeatureCollection = instance.draw.getSelectedPoints();
        const featureCollection = instance.draw.getSelected();
        if (0 < pointsFeatureCollection.features.length) {
            for (let feature of featureCollection.features) {
                const segmentProps = feature.properties.segment;
                const areaProps = feature.properties.area;

                switch (feature.geometry.type) {
                    case 'LineString':
                        for (let i in feature.geometry.coordinates) {
                            for (let pointFeature of pointsFeatureCollection.features) {
                                const point = pointFeature.geometry.coordinates;
                                const segmentPoint =
                                    feature.geometry.coordinates[i];

                                if (arePointsEquals(point, segmentPoint)) {
                                    segmentProps.points[i].elevation =
                                        data.elevation;
                                    feature.geometry.coordinates[i] = [
                                        data.longitude,
                                        data.latitude,
                                    ];
                                    segmentProps.isChanged = true;
                                    break;
                                }
                            }
                        }

                        break;
                    case 'MultiLineString':
                        for (let i in feature.geometry.coordinates) {
                            const coords = feature.geometry.coordinates[i];
                            for (let j in coords) {
                                for (let pointFeature of pointsFeatureCollection.features) {
                                    const point =
                                        pointFeature.geometry.coordinates;
                                    const segmentPoint = coords[j];
                                    if (arePointsEquals(point, segmentPoint)) {
                                        segmentProps.points[i][j].elevation =
                                            data.elevation;
                                        coords[j] = [
                                            data.longitude,
                                            data.latitude,
                                        ];
                                        segmentProps.isChanged = true;
                                        break;
                                    }
                                }
                            }
                        }
                        break;
                    case 'Polygon':
                        for (let j in feature.geometry.coordinates) {
                            for (let k in feature.geometry.coordinates[j]) {
                                for (let pointFeature of pointsFeatureCollection.features) {
                                    const point =
                                        pointFeature.geometry.coordinates;
                                    const areaPoint =
                                        feature.geometry.coordinates[j][k];
                                    if (arePointsEquals(point, areaPoint)) {
                                        feature.geometry.coordinates[j][k] = [
                                            data.longitude,
                                            data.latitude,
                                        ];
                                        areaProps.isChanged = true;
                                    }
                                }
                            }
                        }

                        break;
                    default:
                        break;
                }

                if (segmentProps) {
                    const segmentData = instance.segments.find(
                        (i) => i.drawId === segmentProps.drawId
                    );

                    instance.draw.delete(feature.id);

                    segmentProps.drawId = instance.draw.add({
                        type: 'Feature',
                        properties: {},
                        geometry: feature.geometry,
                    })[0];

                    if (segmentData) {
                        segmentData.drawId = segmentProps.drawId;
                        instance.segments = instance.segments.concat([]);
                    }

                    instance.draw.setFeatureProperty(
                        segmentProps.drawId,
                        'segment',
                        segmentProps
                    );
                } else if (areaProps) {
                    const areaData = instance.areas.find(
                        (i) => i.drawId === areaProps.drawId
                    );

                    instance.draw.delete(feature.id);

                    areaProps.drawId = instance.draw.add({
                        type: 'Feature',
                        properties: {},
                        geometry: feature.geometry,
                    })[0];

                    if (areaData) {
                        areaData.drawId = areaProps.drawId;
                        instance.areas = instance.areas.concat([]);
                    }

                    instance.draw.setFeatureProperty(
                        areaProps.drawId,
                        'area',
                        areaProps
                    );
                }
            }
        }
    };

    const saveSegmentProperties = (data) => {
        const segmentsFeatureCollection = instance.draw.getSelected();
        for (let segmentFeature of segmentsFeatureCollection.features) {
            const segmentProps = segmentFeature.properties.segment;
            if (segmentProps) {
                segmentProps.id = data.id;
                segmentProps.title = data.title;
                segmentProps.link = data.link;
                segmentProps.description = data.description;
                segmentProps.segmentType =
                    instance.segmentTypes.byId[data.segmentTypeId];
                segmentProps.images = segmentProps.images.filter(
                    (image) =>
                        undefined ===
                        data.changedImages.find(
                            (i) => i.id === image.id && i.isDeleted
                        )
                );
                segmentProps.images = segmentProps.images.concat(
                    data.changedImages
                        .filter((i) => i.isUpload)
                        .map((file) => ({ ...file, isLocked: true }))
                );

                segmentProps.documents = segmentProps.documents.filter(
                    (image) =>
                        undefined ===
                        data.changedDocuments.find(
                            (i) => i.id === image.id && i.isDeleted
                        )
                );

                segmentProps.documents = segmentProps.documents.concat(
                    data.changedDocuments
                        .filter((i) => i.isUpload)
                        .map((file) => ({ ...file, isLocked: true }))
                );

                segmentProps.isChanged = true;

                instance.draw.setFeatureProperty(
                    segmentProps.drawId,
                    'segment',
                    segmentProps
                );
            }
        }
    };

    const saveAreaProperties = (data) => {
        const areasFeatureCollection = instance.draw.getSelected();
        for (let segmentFeature of areasFeatureCollection.features) {
            const areaProps = segmentFeature.properties.area;
            if (areaProps) {
                areaProps.id = data.id;
                areaProps.title = data.title;
                areaProps.link = data.link;
                areaProps.description = data.description;
                areaProps.areaType = instance.areaTypes.byId[data.areaTypeId];
                areaProps.images = areaProps.images.filter(
                    (image) =>
                        undefined ===
                        data.changedImages.find(
                            (i) => i.id === image.id && i.isDeleted
                        )
                );
                areaProps.images = areaProps.images.concat(
                    data.changedImages
                        .filter((i) => i.isUpload)
                        .map((file) => ({ ...file, isLocked: true }))
                );

                areaProps.documents = areaProps.documents.filter(
                    (image) =>
                        undefined ===
                        data.changedDocuments.find(
                            (i) => i.id === image.id && i.isDeleted
                        )
                );

                areaProps.documents = areaProps.documents.concat(
                    data.changedDocuments
                        .filter((i) => i.isUpload)
                        .map((file) => ({ ...file, isLocked: true }))
                );

                areaProps.isChanged = true;

                instance.draw.setFeatureProperty(
                    areaProps.drawId,
                    'area',
                    areaProps
                );
            }
        }
    };

    return {
        onEntitiesMenuInitialized: () => {},

        createSegmentFromPoints: (points) => {
            if (1 >= points.length) {
                return;
            }

            const filteredPoints = [points[0]];

            for (let i = 1; i < points.length; ++i) {
                if (!arePointsEquals(points[i - 1], points[i])) {
                    filteredPoints.push(points[i]);
                }
            }

            if (1 >= filteredPoints.length) {
                return;
            }

            const feature = {
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: filteredPoints,
                },
            };
            feature.properties = {};
            const id = instance.draw.add(feature)[0];

            feature.id = id;
            createSegment(feature);

            instance.toolMenu.changeMode('cursor');
            instance.entitiesMenu.setSegments(instance.segments);
            instance.entitiesMenu.setAreas(instance.areas);
        },

        getSegments: () => {
            if (!instance.map || !instance.draw) {
                return [];
            }

            const segmentFeatures = instance.draw
                .getAll()
                .features.filter((feature) => !!feature.properties.segment)
                .map((feature) => {
                    switch (feature.geometry.type) {
                        case 'LineString':
                            return {
                                type: 'Feature',
                                properties: {
                                    ...feature.properties,
                                    segment: {
                                        ...feature.properties.segment,
                                        points: [
                                            feature.properties.segment.points,
                                        ],
                                    },
                                },
                                geometry: {
                                    type: 'MultiLineString',
                                    coordinates: [feature.geometry.coordinates],
                                },
                            };
                        default:
                            return feature;
                    }
                })
                .map((feature) => ({
                    feature: feature,
                    id: feature.properties.segment.id,
                    isUpload: true,
                    isDelete: false,
                    isChanged: feature.properties.segment.isChanged,
                }));

            return segmentFeatures.concat(
                instance.project.segmentIds
                    .filter(
                        (id) =>
                            0 > segmentFeatures.findIndex((f) => f.id === id)
                    )
                    .map((id) => ({
                        feature: null,
                        id: id,
                        isUpload: false,
                        isDelete: true,
                        isChanged: true,
                    }))
            );
        },

        getAreas: () => {
            if (!instance.map || !instance.draw) {
                return [];
            }

            const collection = instance.draw.getAll();
            const areaFeatures = collection.features
                .filter(
                    (feature) =>
                        !!feature.properties.area &&
                        'Polygon' === feature.geometry.type
                )

                .map((feature) => ({
                    feature: feature,
                    id: feature.properties.area.id,
                    isUpload: true,
                    isDelete: false,
                    isChanged: feature.properties.area.isChanged,
                }));

            return areaFeatures.concat(
                instance.project.areaIds
                    .filter((id) => !areaFeatures.find((f) => f.id === id))
                    .map((id) => ({
                        feature: null,
                        id: id,
                        isUpload: false,
                        isDelete: true,
                        isChanged: true,
                    }))
            );
        },

        openPropertiesDialog: () => {
            instance.openPropertiesDialogRequested = false;
            const pointsFeatureCollection = instance.draw.getSelectedPoints();
            const featureCollection = instance.draw.getSelected();

            if (0 < pointsFeatureCollection.features.length) {
                for (let i in pointsFeatureCollection.features) {
                    const pointFeature = pointsFeatureCollection.features[i];
                    for (let feature of featureCollection.features) {
                        const point = pointFeature.geometry.coordinates;

                        switch (feature.geometry.type) {
                            case 'LineString':
                                for (let j in feature.geometry.coordinates) {
                                    const segmentPoint =
                                        feature.geometry.coordinates[j];

                                    if (arePointsEquals(point, segmentPoint)) {
                                        return instance.propertiesDialog.openForPoints(
                                            {
                                                elevation:
                                                    j <
                                                    feature.properties.segment
                                                        .points.length
                                                        ? feature.properties
                                                              .segment.points[j]
                                                              .elevation
                                                        : 0.0,
                                                lng: segmentPoint[0],
                                                lat: segmentPoint[1],
                                            }
                                        );
                                    }
                                }

                                break;
                            case 'MultiLineString':
                                for (let j in feature.geometry.coordinates) {
                                    const coordinates =
                                        feature.geometry.coordinates[j];
                                    for (let k in coordinates) {
                                        const segmentPoint = coordinates[k];

                                        if (
                                            arePointsEquals(point, segmentPoint)
                                        ) {
                                            return instance.propertiesDialog.openForPoints(
                                                {
                                                    elevation:
                                                        j <
                                                        feature.properties
                                                            .segment.points
                                                            .length
                                                            ? feature.properties
                                                                  .segment
                                                                  .points[j][k]
                                                                  .elevation
                                                            : 0.0,
                                                    lng: segmentPoint[0],
                                                    lat: segmentPoint[1],
                                                }
                                            );
                                        }
                                    }
                                }

                                break;

                            case 'Polygon':
                                for (let j in feature.geometry.coordinates) {
                                    for (let k in feature.geometry.coordinates[
                                        j
                                    ]) {
                                        const areaPoint =
                                            feature.geometry.coordinates[j][k];
                                        if (arePointsEquals(point, areaPoint)) {
                                            return instance.propertiesDialog.openForPoints(
                                                {
                                                    elevationDisabled: true,
                                                    elevation: -1.0,
                                                    lng: areaPoint[0],
                                                    lat: areaPoint[1],
                                                }
                                            );
                                        }
                                    }
                                }

                                break;
                            default:
                                break;
                        }
                    }
                }

                return instance.propertiesDialog.openForPoints();
            }

            if (0 < featureCollection.features.length) {
                const segmentProps =
                    featureCollection.features[0].properties.segment;

                if (segmentProps) {
                    return instance.propertiesDialog.openForSegments({
                        id: segmentProps.id,
                        projectId: instance.project.id,
                        link: segmentProps.link,
                        cadLayer: segmentProps.layer,
                        title: segmentProps.title,
                        description: segmentProps.description,
                        segmentType: segmentProps.segmentType,
                        images: segmentProps.images,
                        documents: segmentProps.documents,
                        geoJson: featureCollection.features[0],
                    });
                } else {
                    const areaProps =
                        featureCollection.features[0].properties.area;

                    return instance.propertiesDialog.openForAreas({
                        id: areaProps.id,
                        projectId: instance.project.id,
                        link: areaProps.link,
                        title: areaProps.title,
                        description: areaProps.description,
                        areaType: areaProps.areaType,
                        images: areaProps.images,
                        documents: areaProps.documents,
                        geoJson: featureCollection.features[0],
                    });
                }
            }
        },

        onPropertiesDialogSaved: (data) => {
            switch (data.type) {
                case 'points':
                    return savePointsProperties(data);
                case 'areas':
                    return saveAreaProperties(data);
                case 'segments':
                    return saveSegmentProperties(data);
                default:
                    break;
            }
        },
        trashFeatures: () => {
            if (0 < instance.draw.getSelectedPoints().features.length) {
                instance.draw.trash();
                return;
            }

            const ids = instance.draw.getSelected().features.map((f) => f.id);
            if (0 >= ids.length) {
                return;
            }

            instance.draw.delete(ids);

            instance.segments = instance.segments.filter(
                (segment) => !ids.includes(segment.drawId)
            );

            instance.areas = instance.areas.filter(
                (area) => !ids.includes(area.drawId)
            );

            instance.entitiesMenu.setSegments(instance.segments);
            instance.entitiesMenu.setAreas(instance.areas);
        },
        groupFeatures: () => {
            const [segmentFeature, toRemove] = groupSegmentFeatures(
                instance.draw.getSelected().features
            );

            if (!segmentFeature) {
                return;
            }

            instance.draw.delete(Array.from(toRemove));

            const drawId = instance.draw.add(segmentFeature)[0];
            instance.segments.push({
                drawId: drawId,
                id: segmentFeature.properties.segment.id,
                featureType: segmentFeature.geometry.type,
            });

            instance.segments = instance.segments.filter(
                (segment) => !toRemove.has(segment.drawId)
            );

            instance.entitiesMenu.setSegments(instance.segments);
            instance.entitiesMenu.select(drawId);
            instance.toolMenu.changeMode('cursor');
            instance.draw.changeMode('simple_select', {
                featureIds: [drawId],
            });
        },

        ungroupFeatures: () => {
            const [segmentFeatures, toRemove] = ungroupSegmentFeatures(
                instance.draw.getSelected().features
            );

            if (!segmentFeatures || 0 === segmentFeatures.length) {
                return;
            }

            instance.draw.delete(Array.from(toRemove));

            const drawIds = segmentFeatures.map(
                (feature) => instance.draw.add(feature)[0]
            );
            for (let i in drawIds) {
                instance.segments.push({
                    drawId: drawIds[i],
                    id: segmentFeatures[i].properties.segment.id,
                });
            }

            instance.segments = instance.segments.filter(
                (segment) => !toRemove.has(segment.drawId)
            );

            instance.entitiesMenu.setSegments(instance.segments);
            instance.entitiesMenu.select(drawIds[0]);
            instance.toolMenu.changeMode('cursor');
            instance.draw.changeMode('simple_select', {
                featureIds: drawIds,
            });
        },

        onMapLoaded: (map, draw) => {
            createMapListener(map, draw);

            if (!instance.project) {
                return;
            }
            instance.segments = [];
            instance.areas = [];

            if (instance.project) {
                // Synchronize initial segments
                for (let segment of instance.project.segments) {
                    const geoJson = segment.geoJson;

                    const drawId = draw.add(geoJson)[0];

                    const segmentData = {
                        ...geoJson.properties.segment,
                        id: segment.id,
                        drawId: drawId,
                    };

                    instance.draw.setFeatureProperty(
                        drawId,
                        'segment',
                        segmentData
                    );

                    instance.segments.push(segmentData);
                }

                // Synchronize initial areas
                for (let area of instance.project.areas) {
                    const geoJson = area.geoJson;

                    const drawId = draw.add(geoJson)[0];

                    const areaData = {
                        ...geoJson.properties.area,
                        id: area.id,
                        drawId: drawId,
                    };

                    instance.draw.setFeatureProperty(drawId, 'area', areaData);

                    instance.areas.push(areaData);
                }
            }

            instance.entitiesMenu.setSegments(instance.segments);
            instance.entitiesMenu.setAreas(instance.areas);
        },
    };
};

export { createEditorController };
