// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';
import { Container, Row, Spinner } from 'react-bootstrap';


const Splash = () => (
    <Container fluid>
        <Row className='justify-content-center mt-4'>
            <Spinner animation='border' />
        </Row>
    </Container>
);

export { Splash };
