// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { put, call, takeLatest } from 'redux-saga/effects';

import { takeAuth } from './common.saga';

import Api from 'api';
import Actions from 'actions';

const ACTION_TYPE_ENDS = '/search:request';

export function* mapSaga() {
    yield takeLatest(
        (action) => action.type.endsWith(ACTION_TYPE_ENDS),
        search
    );
    yield takeLatest(Actions.projects.placeSearch, searchPlace);
}

function* search({ type, payload }) {
    const baseType =
        type.length <= ACTION_TYPE_ENDS.length
            ? ''
            : type.substring(0, type.length - ACTION_TYPE_ENDS.length);

    try {
        const result = yield call(
            Api.map.getGeoPlace,
            payload.query,
            payload.limit
        );

        yield put({
            type: `${baseType}/search:success`,
            payload: {
                data: result,
                count: result.length,
            },
        });
    } catch (error) {
        console.log(error);
        yield put({ type: `${baseType}/search:failure` });
    } finally {
    }
}

function* searchPlace({ type, payload }) {
    try {
        const auth = yield takeAuth();
        if (payload.query === '' || !payload.query) {
            const result = yield call(Api.projects.getProjects, auth, 0, 50);
            yield put(Actions.projects.placeSearchSuccess({}, result.data));

            return;
        }

        const result = yield call(
            Api.map.getGeoPlace,
            payload.query,
            payload.limit
        );

        if (0 >= result.length) {
            yield put(Actions.projects.placeSearchFailure());
            return;
        }

        const place = result[0];

        const projects = yield call(Api.projects.getProjectsInBounds, auth, {
            sw: {
                latitude: place.bbox[1],
                longitude: place.bbox[0],
            },
            ne: {
                latitude: place.bbox[3],
                longitude: place.bbox[2],
            },
        });

        yield put(Actions.projects.placeSearchSuccess(place.center, projects));
    } catch (error) {
        console.log(error);
        yield put(Actions.projects.placeSearchFailure(error));
    } finally {
    }
}
