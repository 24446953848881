// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { BasePage } from 'components/pages/BasePage';
import { Button, Card, CardDeck, Row } from 'react-bootstrap';
import { LinkButton } from 'components/common/LinkButton';
import { useTranslation } from 'react-i18next';

import LogoImage from 'assets/gendigitlogo.png';
import { useSelector } from 'react-redux';

const HomePage = () => {
    const { t } = useTranslation();
    const user = useSelector((state) => state.account.user);
    return (
        <BasePage className='HomePage p-4'>
            <Row>
                <h2>Home</h2>
            </Row>
            <Row className='mt-4'>
                {user.features.canCreateProject && (
                    <LinkButton to='/projects/create' variant='secondary'>
                        {t('home-create-project-title')}
                    </LinkButton>
                )}
                <LinkButton className='ml-2' to='/projects' variant='secondary'>
                    {t('home-projects-title')}
                </LinkButton>
            </Row>
            <Row className='p-4 justify-content-center'>
                <CardDeck>
                    <Card className='p-2'>
                        <Card.Body>
                            <Row className='justify-content-center p-2'>
                                <img
                                    src={LogoImage}
                                    width='250px'
                                    height='150px'
                                    />
                            </Row>
                            <Row className='justify-content-center mt-2'>
                                <Card.Title>
                                    <h3>{t('home-map-title')}</h3>
                                </Card.Title>
                            </Row>
                            <Row className='justify-content-center'>
                                <Card.Text>
                                    {t('home-map-description')}
                                </Card.Text>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <Row className='justify-content-center'>
                                <LinkButton variant='primary' to='/map'>
                                    {t('home-map-btn')}
                                </LinkButton>
                            </Row>
                        </Card.Footer>
                    </Card>
                </CardDeck>
            </Row>
        </BasePage>
    );
};

export { HomePage };
