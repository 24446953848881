// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { combineReducers } from '@reduxjs/toolkit';

import Actions from 'actions';

import { addToPageReducer } from './page.reducer';
import { makeTableReducer } from './table.reducer';
import { makeFetchReducer } from './fetch.reducer';
import { makeListReducer } from './list.reducer';
import { makeModelReducer } from './model.reducer';

export default combineReducers({
    table: makeTableReducer(Actions.users.TABLE_ACTION, {
        page: {
            [`${Actions.users.CREATE_ACTION}:success`]: addToPageReducer,
        },
    }),
    permissions: makeFetchReducer(
        Actions.users.PERMISSIONS_ACTION,
        makeListReducer
    ),
    create: makeFetchReducer(Actions.users.CREATE_ACTION, makeModelReducer),
    block: makeFetchReducer(Actions.users.BLOCK_ACTION, makeModelReducer),
});
