// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Form, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { ProjectWizardStep } from 'components/wizard/ProjectWizardStep';

import Actions from 'actions';

import { PageLayout } from 'components/common/PageLayout';
import { PageSection } from 'components/common/PageSection';

import { useListReducer } from 'helpers/hooks';
import { useForm } from 'react-hook-form';
import { push } from 'connected-react-router';
import { PageSectionHeader } from 'components/common/PageSectionHeader';
import { useTranslation } from 'react-i18next';

const LayersProjectWizardStep = ({ className }) => {
    const dispatch = useDispatch();

    const projectInfo = useSelector(
        (state) => state.projects.wizard.view.data.start
    );

    if (projectInfo.isImporting) {
        dispatch(push(`/projects/${projectInfo.id}`));
    }

    const projectlayers = projectInfo.layers;

    if (0 >= projectlayers.length) {
        dispatch(Actions.projects.wizardRequestViewEdit(projectInfo.id));
    }

    return (
        <ProjectWizardStep className={`${className || ''} container p-4 `}>
            <PageLayout>
                <LayersTable
                    layers={projectlayers}
                    projectId={projectInfo.id}
                />
            </PageLayout>
        </ProjectWizardStep>
    );
};

const TableRow = ({ layer, segmentTypes, register, errors }) => {
    const { t } = useTranslation();
    return (
        <Row className='mt-4 border-bottom'>
            <Col className='p-1 mr-1 text-wrap overflow-auto' sm={5}>
                {layer.name}
            </Col>
            <Col className='p-1 mr-1 align-self-center' sm={4}>
                <Form.Label>
                    {t('project-wizard-layers-segment-type')}
                </Form.Label>
                <Form.Control
                    as='select'
                    name={`type-${layer.id}`}
                    ref={register({ required: false })}
                    isInvalid={errors[`type-${layer.id}`]}
                >
                    {segmentTypes.map((p) => (
                        <option key={p.id} value={p.id}>
                            {p.name}
                        </option>
                    ))}
                </Form.Control>
            </Col>
            <Col className='align-self-center' sm={1}>
                <Form.Check
                    type='checkbox'
                    name={`enabled-${layer.id}`}
                    label={t('project-wizard-layers-enabled')}
                    ref={register({ required: false })}
                />
            </Col>
        </Row>
    );
};

const LayersTable = ({ layers, projectId }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const segmentTypesList = useListReducer((state) => state.app.segmentTypes);

    const sortedLayers = layers
        .map((a) => a)
        .sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });

    const { register, handleSubmit, errors } = useForm({
        defaultValues: layers.reduce((result, layer) => {
            result[`type-${layer.id}`] =
                layer.userProjectSegmentTypeId ||
                layer.projectSegmentTypeId ||
                null;
            result[`enabled-${layer.id}`] =
                layer.enabled &&
                result[`type-${layer.id}`] &&
                '' !== result[`type-${layer.id}`];
            return result;
        }, {}),
    });

    const onSubmit = (data) => {
        const changedLayers = [];
        for (let layer of layers) {
            const dataEnabled = data[`enabled-${layer.id}`];
            const dataType = data[`type-${layer.id}`] || null;

            if (
                dataEnabled !== layer.enabled ||
                (layer.userProjectSegmentTypeId &&
                    dataType !== layer.userProjectSegmentTypeId) ||
                dataType !== layer.userProjectSegmentTypeId
            ) {
                changedLayers.push({
                    id: layer.id,
                    enabled: dataEnabled,
                    userProjectSegmentTypeId: dataType,
                });
            }
        }

        dispatch(
            Actions.projects.wizardUploadLayers.execute(
                projectId,
                changedLayers
            )
        );
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <PageSection>
                <PageSectionHeader title={t('project-wizard-layers-title')}>
                    <Button type='submit'>
                        {t('project-wizard-layers-configure-btn')}
                    </Button>
                </PageSectionHeader>
                <Row>
                    <Col sm={12}>
                        {sortedLayers.map((layer) => (
                            <TableRow
                                key={layer.id}
                                layer={layer}
                                segmentTypes={segmentTypesList}
                                register={register}
                                errors={errors}
                            />
                        ))}
                    </Col>
                </Row>
            </PageSection>
        </Form>
    );
};

export { LayersProjectWizardStep };
