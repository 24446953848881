// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

const persistStorage = {
    load: key => {
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch (error) {
            console.log('PersistStorage error', error);
            return undefined;
        }
    },

    store: (key, item) => {
        try {
            localStorage.setItem(key, JSON.stringify(item));
        } catch (error) {
            console.log('PersistStorage error', error);
        }
    },
    remove: key => {
        try {
            localStorage.removeItem(key);
        } catch (error) {
            console.log('PersistStorage error', error);
        }
    },
};

export { persistStorage };
