// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { makeRequestActions } from './common';
import { makeAlertActions } from './alert.actions';
import { makeProcessActions } from './process.actions';

export const LOGIN_ACTION = 'account/login';
export const SAVE_MAP_ACTION = 'account/user-map';
export const REFRESH_ACTION = 'account/refresh';
export const LOGOUT_ACTION = 'account/logout';
export const REGISTER_ACTION = 'account/register';
export const RESTORE_PASSWORD_ACTION = 'account/restorePassword';

export const [logout] = makeRequestActions(LOGOUT_ACTION);

export const [login, loginSuccess, loginFailure] = makeRequestActions(
    LOGIN_ACTION,
    {
        request: (username, password) => ({ payload: { username, password } }),
        success: (user) => ({ payload: user }),
    }
);

export const [saveMap, saveMapSuccess] = makeRequestActions(SAVE_MAP_ACTION, {
    request: (map) => ({ payload: map }),
    success: (map) => ({ payload: map }),
});

export const [refresh, refreshSuccess, refreshFailure] = makeRequestActions(
    REFRESH_ACTION,
    {
        request: (token) => ({ payload: token }),
        success: (user) => ({ payload: user }),
    }
);

export const [loginAlertShow, loginAlertHide] = makeAlertActions(LOGIN_ACTION);
export const [loginProcessStart, loginProcessStop] = makeProcessActions(
    LOGIN_ACTION
);

export const [register, registerSuccess, registerFailure] = makeRequestActions(
    REGISTER_ACTION,
    {
        request: (user) => ({ payload: user }),
    }
);

export const [registerAlertShow, registerAlertHide] = makeAlertActions(
    REGISTER_ACTION
);
export const [registerProcessStart, registerProcessStop] = makeProcessActions(
    REGISTER_ACTION
);

export const [
    restorePassword,
    restorePasswordSuccess,
    restorePasswordFailure,
] = makeRequestActions(RESTORE_PASSWORD_ACTION, {
    request: (email) => ({ payload: { email } }),
    success: (data) => ({ payload: data }),
});

export const [
    restorePasswordAlertShow,
    restorePasswordAlertHide,
] = makeAlertActions(RESTORE_PASSWORD_ACTION);
