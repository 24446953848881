// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import {
    getJsonRequest,
    postJsonRequest,
    deleteRequest,
    putRequest,
    postRequest,
    withAuth,
    withOffsetLimit,
} from './common';

export const createForumTopic = (auth, projectId, topic) =>
    postJsonRequest(
        `/project-chat/projects/${projectId}/topics`,
        withAuth(auth, {
            body: JSON.stringify({
                name: topic.name,
            }),
        })
    );

export const createForumTopicMessage = (auth, projectId, topicId, message) =>
    postJsonRequest(
        `/project-chat/projects/${projectId}/topics/${topicId}/messages`,
        withAuth(auth, {
            body: JSON.stringify({
                message: message,
            }),
        })
    );

export const createForumTopicMessageFile = (auth, projectId, topicId, file) => {
    const formData = new FormData();

    formData.append('file', file);
    return postRequest(
        `/project-chat/projects/${projectId}/topics/${topicId}/messages/files`,
        withAuth(auth, {
            body: formData,
        })
    );
};

export const deleteForumTopicMessage = (auth, projectId, topicId, messageId) =>
    deleteRequest(
        `/project-chat/projects/${projectId}/topics/${topicId}/messages/${messageId}`,
        withAuth(auth)
    );

export const deleteForumTopic = (auth, projectId, topicId) =>
    deleteRequest(
        `/project-chat/projects/${projectId}/topics/${topicId}`,
        withAuth(auth)
    );

export const getForumTopics = (auth, projectId) =>
    getJsonRequest(
        `/project-chat/projects/${projectId}/topics`,
        withAuth(auth)
    );

export const getForumTopicMessages = (
    auth,
    projectId,
    topicId,
    offset = 0,
    limit = 50
) =>
    getJsonRequest(
        withOffsetLimit(
            `/project-chat/projects/${projectId}/topics/${topicId}/messages`,
            offset,
            limit
        ),
        withAuth(auth)
    );
