// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';
import { extend } from 'react-three-fiber';

import * as THREE from 'three';

import { Sky } from 'three/examples/jsm/objects/Sky';

extend({ Sky });

const SkyMesh = ({ scale }) => {
    return (
        <mesh visible scale={scale}>
            <boxGeometry attach='geometry' args={[1, 1, 1]} />
            <shaderMaterial
                attach='material'
                args={[
                    {
                        fragmentShader: Sky.SkyShader.fragmentShader,
                        vertexShader: Sky.SkyShader.vertexShader,
                        uniforms: THREE.UniformsUtils.clone(
                            Sky.SkyShader.uniforms
                        ),
                        side: THREE.BackSide,
                        depthWrite: false,
                    },
                ]}
                uniforms-sunPosition={{
                    value: new THREE.Vector3(30, 3000, 30),
                }}
            />
        </mesh>
    );
};

export { SkyMesh };
