// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { BasePage } from 'components/pages/BasePage';
import { PageLayout } from 'components/common/PageLayout';
import { PageSectionAddItem } from 'components/common/PageSectionAddItem';
import { PageHeader } from 'components/common/PageHeader';
import { PageSectionPagination } from 'components/common/PageSectionPagination';
import { CreateRequestDialog } from 'components/dialogs/CreateRequestDialog';

import { RequestsTable } from 'components/tables/RequestsTable';

import Actions from 'actions';
import { useTranslation } from 'react-i18next';

const RequestsPage = () => {
    const { t } = useTranslation();
    return (
        <BasePage>
            <PageLayout>
                <PageHeader title={t('requests-title')}>
                    <PageSectionAddItem
                        variant='outline-primary'
                        size={'lg'}
                        dialog={CreateRequestDialog}
                    >
                        {t('requests-create-btn')}
                    </PageSectionAddItem>
                </PageHeader>

                <PageSectionPagination
                    fetchAction={Actions.requests.tableFetch}
                    table={RequestsTable}
                    scope={['requests', 'table']}
                />
            </PageLayout>
        </BasePage>
    );
};

export { RequestsPage };
