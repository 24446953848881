// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useEffect, useState } from 'react';

const FilePicker = ({
    className,
    label = 'Choose file...',
    initialFile,
    onFileChange = () => {},
    accept = '',
    clearFile = false,
}) => {
    const [file, setFile] = useState(initialFile);

    const onFileChanged = (e) => {
        if (!e || !e.target || !e.target.files || 0 === e.target.files.length) {
            return;
        }
        const file = e.target.files[0];
        setFile(file);
        onFileChange(file);
    };

    useEffect(() => {
        if (clearFile) {
            setFile(null);
        }
    }, [clearFile]);
    return (
        <div className={`${className || ''} custom-file`}>
            <input
                type='file'
                className='custom-file-input'
                onChange={onFileChanged}
                accept={accept}
            />
            <label className='custom-file-label'>
                {(file && file.name) || label}
            </label>
        </div>
    );
};

export { FilePicker };
