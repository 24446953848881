// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import {
    getJsonRequest,
    postJsonRequest,
    deleteRequest,
    putRequest,
    withAuth,
} from './common';

export const createJoinRequest = (auth, projectId, joinRequest) =>
    postJsonRequest(
        `/projects/${projectId}/join-requests`,
        withAuth(auth, {
            body: JSON.stringify({
                description: joinRequest.description,
            }),
        })
    );

export const approveJoinRequest = (auth, projectId, joinId) =>
    putRequest(
        `/projects/${projectId}/join-requests/${joinId}/approve`,
        withAuth(auth, {})
    );

export const declineJoinRequest = (auth, projectId, joinId) =>
    deleteRequest(
        `/projects/${projectId}/join-requests/${joinId}/decline`,
        withAuth(auth, {})
    );

export const cancelJoinRequest = (auth, projectId, joinId) =>
    deleteRequest(
        `/projects/${projectId}/join-requests/${joinId}/cancel`,
        withAuth(auth, {})
    );

export const getJoinRequests = (auth) =>
    getJsonRequest(`/projects/join-requests`, withAuth(auth));

export const getJoinRequestByProject = (auth, projectId) =>
    getJsonRequest(`/projects/${projectId}/join-requests`, withAuth(auth));
