// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { makeRequestActions } from './common';

export const makePageActions = (scope, prepare) =>
    makeRequestActions(`${scope}/page`, {
        request:
            (prepare && prepare.request) ||
            ((offset, limit) => ({
                payload: { offset, limit },
            })),
        success:
            (prepare && prepare.success) ||
            ((count, data) => ({
                payload: { count, data },
            })),
    });
