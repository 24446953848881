// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { combineReducers, createReducer } from '@reduxjs/toolkit';

import Actions from 'actions';

import { makeAlertReducer } from './alert.reducer';
import { makePageReducer, removeFromPageReducer } from './page.reducer';
import { makeProcessReducer } from './process.reducer';
import { makeSearchReducer } from './search.reducer';
import {
    makeListReducer,
    addToListReducer,
    clearListReducer,
    initListReducer,
    updateToListReducer,
} from './list.reducer';
import { makeFetchReducer } from './fetch.reducer';
import { makeModelReducer } from './model.reducer';

const investmentListReducer = {
    [`${Actions.investments.APPROVE_INVEST_ACTION}:success`]: (
        state,
        { payload }
    ) =>
        updateToListReducer(state, payload.investmentId, {
            status: 'APPROVED',
        }),
    [`${Actions.investments.DECLINE_INVEST_ACTION}:success`]: (
        state,
        { payload }
    ) =>
        updateToListReducer(state, payload.investmentId, {
            status: 'DECLINED',
        }),
    [`${Actions.investments.CANCEL_INVEST_ACTION}:success`]: (
        state,
        { payload }
    ) =>
        updateToListReducer(state, payload.investmentId, {
            status: 'CANCELED',
        }),
};

export default combineReducers({
    phase: combineReducers({
        edit: makeFetchReducer(Actions.investments.EDIT_PHASE_ACTION, (scope) =>
            makeModelReducer(scope, null)
        ),
    }),
    overview: makeFetchReducer(
        Actions.investments.INVESTMENTS_OVERVIEW_ACTION,
        (scope) => makeModelReducer(scope, null)
    ),
    investNow: makeFetchReducer(
        Actions.investments.INVEST_NOW_ACTION,
        (scope) => makeModelReducer(scope, null)
    ),
    approveInvest: makeFetchReducer(
        Actions.investments.APPROVE_INVEST_ACTION,
        (scope) => makeModelReducer(scope, null)
    ),
    declineInvest: makeFetchReducer(
        Actions.investments.DECLINE_INVEST_ACTION,
        (scope) => makeModelReducer(scope, null)
    ),
    cancelInvest: makeFetchReducer(
        Actions.investments.CANCEL_INVEST_ACTION,
        (scope) => makeModelReducer(scope, null)
    ),
    list: makeFetchReducer(
        Actions.investments.INVESTMENTS_LIST_ACTION,
        (scope) => makeListReducer(scope, investmentListReducer)
    ),
});
