// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { getJsonRequest, deleteRequest } from './common';
import { makeFileDownloadLink } from './file.api';


export const getImageInfo = (id, init) => getJsonRequest(
    `/images/${id}/info`,
    init,
    r => {
        r.downloadLink = makeFileDownloadLink(r.fileId);
        return r;
    }
);



export const deleteImage = (id, init) => deleteRequest(
    `/image/${id}`,
    init
);

