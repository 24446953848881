// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Form } from 'react-bootstrap';

import { BaseDialog } from 'components/dialogs/BaseDialog';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import Actions from 'actions';
import { useTranslation } from 'react-i18next';

const ProjectVisibilityDialog = ({
    project,
    visibility,
    show,
    onHide,
    onClose,
    onAccept,
}) => {
    const { register, handleSubmit } = useForm({
        defaultValues: {
            visibility: visibility,
        },
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const onSubmit = (data) => {
        dispatch(
            Actions.projects.changeVisibility(
                project.id,
                data.visibility,
                project.name
            )
        );
    };

    return (
        <BaseDialog
            title={t('project-visibility-title')}
            onSubmit={handleSubmit(onSubmit)}
            show={show}
            onHide={onHide}
            onClose={onClose}
            onAccept={onAccept}
            finished={true}
        >
            <Form.Group>
                <Form.Label>{t('project-visibility-visibility')}</Form.Label>
                <Form.Control
                    as='select'
                    defaultValue='PRIVATE'
                    name='visibility'
                    ref={register}
                >
                    <option value='PUBLIC'>
                        {t('project-visibility-public')}
                    </option>
                    <option value='PRIVATE'>
                        {t('project-visibility-private')}
                    </option>
                </Form.Control>
            </Form.Group>
        </BaseDialog>
    );
};

export { ProjectVisibilityDialog };
