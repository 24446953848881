// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { put, call, takeLatest, select, delay, all } from 'redux-saga/effects';

import { push as routerPush, push } from 'connected-react-router';

import { takeAuth, loadAllPages } from './common.saga';

import Api from 'api';
import Actions from 'actions';

export function* projectsSaga() {
    yield takeLatest(Actions.projects.edit.execute, editProject);
    yield takeLatest(Actions.projects.mapSearch, mapSearch);
    yield takeLatest(Actions.projects.tableFetch, tableFetch);
    yield takeLatest(Actions.projects.segmentsTableFetch, segmentsTableFetch);
    yield takeLatest(Actions.projects.modelFetch, modelFetch);
    yield takeLatest(Actions.projects.deleteProject, deleteProject);
    yield takeLatest(Actions.projects.wizardCreate, wizardCreate);
    yield takeLatest(Actions.projects.wizardUpdateInfo, wizardUpdateInfo);
    yield takeLatest(Actions.projects.wizardEditorUpload, wizardEditorUpload);
    yield takeLatest(Actions.projects.ownListFetch, ownListFetch);
    yield takeLatest(Actions.projects.changeVisibility, changeVisibility);
    yield takeLatest(Actions.projects.infoModel.fetch, infoModelFetch);
    yield takeLatest(Actions.projects.mapProjects.fetch, mapProjectsSearch);
    yield takeLatest(Actions.projects.wizardRequestViewEdit, wizardRequestEdit);
    yield takeLatest(
        Actions.projects.wizardEditSegment.execute,
        wizardEditSegment
    );
    yield takeLatest(Actions.projects.wizardEditArea.execute, wizardEditArea);
    yield takeLatest(
        Actions.projects.wizardRequestViewLayers,
        wizardRequestLayers
    );

    yield takeLatest(
        Actions.projects.wizardUploadLayers.fetch,
        wizardLayersUpload
    );

    yield takeLatest(
        Actions.projects.wizardImportStatus.fetch,
        wizardImportStatus
    );
}

function* editProject({ payload }) {
    try {
        yield put(Actions.projects.edit.start());

        const auth = yield takeAuth();

        yield call(
            Api.projects.editProject,
            auth,
            payload.projectId,
            payload.project
        );

        yield put(
            Actions.projects.edit.success({
                id: payload.projectId,
                ...payload.project,
            })
        );
    } catch (error) {
        yield put(Actions.projects.edit.failure(error));
    } finally {
        yield put(Actions.projects.edit.stop());
    }
}

function* mapSearch({ payload }) {
    try {
        const auth = yield takeAuth();

        const projects = yield call(
            Api.projects.getProjectsInBounds,
            auth,
            payload.bounds
        );

        // NOTE: For testing, delete later
        // const projects = (yield loadAllPages(Api.projects.getProjects, auth))
        //     .data;

        for (let project of projects) {
            const existProject = payload.projects.find(
                (p) => p.id === project.id
            );

            if (existProject) {
                project.segments = existProject.segments;
                project.areas = existProject.areas;
            } else {
                project.segments = yield fetchProjectSegments(auth, project.id);
                project.areas = yield fetchProjectAreas(auth, project.id);
            }
        }

        yield put(Actions.projects.mapSearchSuccess(projects));
    } catch (error) {
        yield put(Actions.projects.mapSearchFailure(error));
    } finally {
    }
}

function* wizardEditSegment({ payload }) {
    try {
        yield put(Actions.projects.wizardEditSegment.start());
        const auth = yield takeAuth();

        const segmentId = yield uploadSegment(
            auth,
            payload.projectId,
            payload.id,
            payload.segment
        );
        yield put(
            Actions.projects.wizardEditSegment.success(
                payload.projectId,
                segmentId
            )
        );
    } catch (error) {
        yield put(Actions.projects.wizardEditSegment.failure(error));
    } finally {
        yield put(Actions.projects.wizardEditSegment.stop());
    }
}

function* wizardEditArea({ payload }) {
    try {
        yield put(Actions.projects.wizardEditArea.start());
        const auth = yield takeAuth();

        const areaId = yield uploadArea(
            auth,
            payload.projectId,
            payload.id,
            payload.area
        );
        yield put(
            Actions.projects.wizardEditArea.success(payload.projectId, areaId)
        );
    } catch (error) {
        yield put(Actions.projects.wizardEditArea.failure(error));
    } finally {
        yield put(Actions.projects.wizardEditArea.stop());
    }
}

function* mapProjectsSearch({ payload }) {
    try {
        const auth = yield takeAuth();

        yield put(Actions.projects.mapProjects.start());

        const cache = yield select((state) => state.projects.mapProjects.model);

        const projectIds = (yield loadAllPages(
            Api.projects.getProjectIdsInBounds,
            auth,
            payload.bounds
        )).data;

        const segmentTypes = yield select((state) => state.app.segmentTypes);
        const areaTypes = yield select((state) => state.app.areaTypes);

        for (let i in projectIds) {
            const projectId = projectIds[i];
            let project = cache.projects.byId[projectId];

            if (!project) {
                project = yield call(Api.projects.getProject, auth, projectId);

                yield put(
                    Actions.projects.mapProjects.projectsLoaded([project])
                );
            }

            const areas = (yield call(
                Api.projects.getProjectAreasInBounds,
                auth,
                projectId,
                payload.bounds,
                project.areaIds.filter((id) =>
                    cache.areas.allIds.find((i) => i === id)
                )
            )).map((area) => correctAreaModel(area, projectId, areaTypes));

            yield put(Actions.projects.mapProjects.areasLoaded(areas));

            const segments = (yield call(
                Api.projects.getProjectSegmentsInBounds,
                auth,
                projectId,
                payload.bounds,
                project.segmentIds.filter((id) =>
                    cache.segments.allIds.find((i) => i === id)
                )
            )).map((segment) =>
                correctSegmentModel(segment, projectId, segmentTypes)
            );

            yield put(Actions.projects.mapProjects.segmentsLoaded(segments));

            yield put(
                Actions.projects.mapProjects.progress(i, projectIds.length)
            );
        }

        yield put(Actions.projects.mapProjects.success());
    } catch (error) {
        console.log(error);
        yield put(Actions.projects.mapProjects.failure(error));
    } finally {
        yield put(Actions.projects.mapProjects.stop());
    }
}

function correctSegmentModel(segment, id, segmentTypes = []) {
    let points =
        (segment.geoJson.properties.segment &&
            segment.geoJson.properties.segment.points) ||
        segment.geoJson.properties.points ||
        [];

    switch (segment.geoJson.geometry.type) {
        case 'LineString':
            break;
        case 'MultiLineString':
            if (
                Array.isArray(points) &&
                0 < points.length &&
                !Array.isArray(points[0])
            ) {
                points = [points];
            }
            break;
        default:
            break;
    }

    return {
        ...segment,
        layerId:
            segment.layerId ||
            (segment.geoJson &&
                segment.geoJson.properties &&
                segment.geoJson.properties.projectLayerId) ||
            null,
        geoJson: {
            ...segment.geoJson,
            properties: {
                ...segment.geoJson.properties,
                segment: {
                    ...segment.geoJson.properties.segment,
                    id: segment.id,
                    projectId: id,
                    link: segment.link,
                    points: points,
                    documents: segment.documents,
                    images: segment.images,
                    layer:
                        (segment.geoJson.properties.segment &&
                            segment.geoJson.properties.segment.layer) ||
                        segment.geoJson.properties.layer ||
                        'No layer',
                    description: segment.description,
                    title: segment.title,
                    segmentType: segmentTypes.byId[segment.typeId],
                },
            },
        },
    };
}

function correctAreaModel(area, projectId, areaTypes) {
    return {
        ...area,
        geoJson: {
            ...area.geoJson,
            properties: {
                ...area.geoJson.properties,
                area: {
                    ...area.geoJson.properties.area,
                    id: area.id,
                    projectId: projectId,
                    link: area.link,
                    documents: area.documents,
                    images: area.images,
                    description: area.description,
                    title: area.title,
                    areaType: areaTypes.byId[area.typeId],
                },
            },
        },
    };
}

function* fetchProjectSegments(auth, id) {
    let segments = (yield loadAllPages(
        Api.projects.getProjectSegments,
        auth,
        id
    )).data;

    for (let segment of segments) {
        segment.documents = [];
        segment.images = [];

        for (let documentId of segment.documentIds) {
            segment.documents.push(
                yield call(
                    Api.projects.getProjectSegmentDocument,
                    auth,
                    id,
                    segment.id,
                    documentId
                )
            );
        }

        for (let imageId of segment.coverIds) {
            segment.images.push(
                yield call(
                    Api.projects.getProjectSegmentImage,
                    auth,
                    id,
                    segment.id,
                    imageId
                )
            );
        }
    }

    const segmentTypes = yield select((state) => state.app.segmentTypes);

    segments = segments.map((segment) =>
        correctSegmentModel(segment, id, segmentTypes)
    );

    return segments;
}

function* fetchProjectAreas(auth, id) {
    let areas = (yield loadAllPages(Api.projects.getProjectAreas, auth, id))
        .data;

    for (let area of areas) {
        area.documents = [];
        area.images = [];

        for (let documentId of area.documentIds) {
            area.documents.push(
                yield call(
                    Api.projects.getProjectAreaDocument,
                    auth,
                    id,
                    area.id,
                    documentId
                )
            );
        }

        for (let imageId of area.coverIds) {
            area.images.push(
                yield call(
                    Api.projects.getProjectAreaImage,
                    auth,
                    id,
                    area.id,
                    imageId
                )
            );
        }
    }

    const areaTypes = yield select((state) => state.app.areaTypes);

    areas = areas.map((area) => correctAreaModel(area, id, areaTypes));

    return areas;
}

function* fetchProjectInfo(auth, id) {
    const model = yield call(Api.projects.getProject, auth, id);

    model.documents = [];
    model.images = [];

    model.isImporting = false;
    model.isFailed = false;

    const isAdmin = auth.permissions.includes('ADMIN');
    const canEdit = isAdmin || auth.id === model.ownerId;
    const isJoined = model.participants.includes(auth.id);
    const canJoin = !isJoined && !canEdit;
    const canForum = isJoined || canEdit;
    const canEditPhases = isJoined || canEdit;
    const canInvest = auth.permissions.includes('INVESTOR');

    model.userFeatures = {
        canEdit,
        canJoin,
        canForum,
        canInvest,
        canEditPhases,
        isAdmin,
        isJoined,
    };

    for (let cadFile of model.cadFiles) {
        if ('COMPLETED' !== cadFile.status) {
            model.isImporting = true;
        }
        if ('FAILED' === cadFile.status) {
            model.isFailed = true;
            break;
        }
    }

    for (let documentId of model.documentIds) {
        model.documents.push(
            yield call(Api.projects.getProjectDocument, auth, id, documentId)
        );
    }

    for (let imageId of model.coverIds) {
        model.images.push(
            yield call(Api.projects.getProjectImage, auth, id, imageId)
        );
    }

    return model;
}

function* fetchProjectModel(auth, id) {
    const model = yield fetchProjectInfo(auth, id);

    model.segments = (yield fetchProjectSegments(auth, id)).filter(
        (segment) => {
            const layer = model.layers.find(
                (layer) => layer.id === segment.layerId
            );
            return !layer || layer.enabled;
        }
    );

    model.segmentIds = model.segments.map((s) => s.id);

    model.areas = yield fetchProjectAreas(auth, id);

    return model;
}

function* infoModelFetch({ payload }) {
    try {
        const auth = yield takeAuth();

        yield put(Actions.projects.infoModel.start());

        const model = yield fetchProjectInfo(auth, payload.id);

        yield put(Actions.projects.infoModel.success(model));
    } catch (error) {
        yield put(
            Actions.projects.infoModel.show(
                'Projects fetch failed',
                error.message
            )
        );

        yield put(Actions.projects.infoModel.failure(error));
    } finally {
        yield put(Actions.projects.infoModel.stop());
    }
}

function* modelFetch({ payload }) {
    try {
        const auth = yield takeAuth();

        yield put(Actions.projects.modelProcessStart());

        const model = yield fetchProjectModel(auth, payload.id);

        yield put(Actions.projects.modelSuccess(model));
    } catch (error) {
        yield put(
            Actions.projects.modelAlertShow(
                'Projects fetch failed',
                error.message
            )
        );

        yield put(Actions.projects.modelFailure(error));
    } finally {
        yield put(Actions.projects.modelProcessStop());
    }
}

function* deleteProject({ payload }) {
    try {
        const auth = yield takeAuth();

        yield call(Api.projects.deleteProject, auth, payload);

        yield put(Actions.projects.deleteProjectSuccess(payload));
        yield put(routerPush('/projects'));
    } catch (error) {
        yield put(Actions.projects.deleteProjectFailure(error));
    } finally {
    }
}

function* segmentsTableFetch({ payload }) {
    try {
        const auth = yield takeAuth();

        yield put(Actions.projects.segmentsTableProcessStart());
        const page = yield call(
            Api.projects.getProjectSegments,
            auth,
            payload.projectId,
            payload.offset,
            payload.limit
        );
        yield put(Actions.projects.segmentsTableSuccess(page.count, page.data));
    } catch (error) {
        yield put(
            Actions.projects.segmentsTableAlertShow(
                'Project segments fetch failed',
                error.message
            )
        );
        yield put(Actions.projects.segmentsTableFailure(error));
    } finally {
        yield put(Actions.projects.segmentsTableProcessStop());
    }
}

function* tableFetch({ payload }) {
    try {
        const auth = yield takeAuth();

        yield put(Actions.projects.tableProcessStart());

        if (auth.permissions.includes('ADMIN')) {
            const page = yield call(
                Api.projects.getProjects,
                auth,
                payload.offset,
                payload.limit
            );

            const projectsCreators = yield all(
                page.data.map((item) => {
                    return call(Api.users.getUser, auth, item.ownerId);
                })
            );
            page.data = page.data.map((item, i) => ({
                ...item,

                creator: projectsCreators[i],
            }));

            yield put(Actions.projects.tableSuccess(page.count, page.data));
        } else {
            const page = yield call(
                Api.projects.getOwnProjects,
                auth,
                payload.offset,
                payload.limit
            );
            yield put(Actions.projects.tableSuccess(page.count, page.data));
        }
    } catch (error) {
        yield put(
            Actions.projects.tableAlertShow(
                'Projects fetch failed',
                error.message
            )
        );
        yield put(Actions.projects.tableFailure(error));
    } finally {
        yield put(Actions.projects.tableProcessStop());
    }
}

function* ownListFetch() {
    try {
        const auth = yield takeAuth();

        const list = (yield loadAllPages(Api.projects.getOwnProjects, auth))
            .data;

        yield put(Actions.projects.ownListSuccess(list));
    } catch (error) {
        yield put(Actions.projects.ownListFailure(error));
    } finally {
    }
}

function* wizardImportStatus({ payload }) {
    try {
        let status = null;
        while (!status || 'IN_PROGRESS' === status.status) {
            const auth = yield takeAuth();

            status = yield call(
                Api.projects.getProjectImportStatus,
                auth,
                payload
            );

            yield put(Actions.projects.wizardImportStatus.success(status));

            if ('FAILED' === status.status) {
                yield put(push(`/projects/${payload}`));
                break;
            }

            yield delay(2000);
        }
    } catch (error) {
        yield put(Actions.projects.wizardImportStatus.failure(error));
    } finally {
    }
}

function* wizardRequestEdit({ payload }) {
    try {
        const auth = yield takeAuth();

        const project = yield fetchProjectModel(auth, payload);

        yield put(Actions.projects.wizardViewEdit(project));
    } finally {
    }
}

function* wizardRequestLayers({ payload }) {
    try {
        const auth = yield takeAuth();

        const project = yield fetchProjectInfo(auth, payload);

        yield put(Actions.projects.wizardViewLayers(project));
    } finally {
    }
}

function* wizardCreate({ payload }) {
    try {
        const auth = yield takeAuth();

        yield put(Actions.projects.wizardProcessStart());

        let countryName = null;
        let cityName = null;

        let projectData = payload.project;

        try {
            const geoPlace = yield call(
                Api.map.getGeoPlaceByLocation,
                payload.anchorPoint.longitude,
                payload.anchorPoint.latitude
            );

            if (geoPlace) {
                for (let feature of geoPlace) {
                    if (!feature.place_type) {
                        continue;
                    }

                    if (feature.place_type.includes('region')) {
                        cityName = feature.text || null;
                    } else if (feature.place_type.includes('country')) {
                        countryName = feature.text || null;
                    }
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            projectData.country = countryName;
            projectData.city = cityName;
        }

        const project = yield call(
            Api.projects.createProject,
            auth,
            projectData,
            payload.cadFile,
            payload.anchorPoint
        );

        project.segments = [];
        project.areas = [];

        yield put(
            Actions.projects.wizardCreateSuccess(
                { ...project, anchorPoint: payload.anchorPoint },
                payload.wizard
            )
        );
    } catch (error) {
        console.log(error);
        yield put(Actions.projects.wizardCreateFailure(error));
    } finally {
        yield put(Actions.projects.wizardProcessStop());
    }
}

function* wizardUpdateInfo({ payload }) {
    try {
        const auth = yield takeAuth();

        yield put(Actions.projects.wizardProcessStart());

        yield call(
            Api.projects.editProject,
            auth,
            payload.projectId,
            payload.data
        );

        for (let document of payload.changedDocuments) {
            if (document.isDeleted) {
                yield call(
                    Api.projects.deleteProjectDocument,
                    auth,
                    payload.projectId,
                    document.id
                );
            }
            if (document.isUpload) {
                yield call(
                    Api.projects.addProjectDocument,
                    auth,
                    payload.projectId,
                    document.file
                );
            }
        }

        for (let image of payload.changedImages) {
            if (image.isDeleted) {
                yield call(
                    Api.projects.deleteProjectImage,
                    auth,
                    payload.projectId,
                    image.id
                );
            }

            if (image.isUpload) {
                yield call(
                    Api.projects.addProjectImage,
                    auth,
                    payload.projectId,
                    image.file
                );
            }
        }

        yield put(Actions.projects.wizardUpdateInfoSuccess({}, payload.wizard));

        // yield put(routerPush(`/projects/${payload.projectId}`));
    } catch (error) {
        console.log(error);
        yield put(Actions.projects.wizardUpdateInfoFailure(error));
    } finally {
        yield put(Actions.projects.wizardProcessStop());
    }
}

function* uploadSegment(auth, projectId, segmentId, segment) {
    const data = {
        link: segment.link,
        title: segment.title,
        description: segment.description,
        typeId: segment.typeId,
        geoJson: {
            type: segment.geoJson.type,
            geometry: segment.geoJson.geometry,
            properties: {
                points: segment.geoJson.properties.segment.points,
            },
        },
    };

    if (segmentId) {
        yield call(
            Api.projects.editProjectSegment,
            auth,
            projectId,
            segmentId,
            data
        );
    } else {
        segmentId = (yield call(
            Api.projects.createProjectSegment,
            auth,
            projectId,
            data
        )).id;
    }

    if (segment.changedDocuments) {
        for (let document of segment.changedDocuments) {
            if (document.isDeleted) {
                yield call(
                    Api.projects.deleteProjectSegmentDocument,
                    auth,
                    projectId,
                    segmentId,
                    document.id
                );
            }
            if (document.isUpload) {
                yield call(
                    Api.projects.addProjectSegmentDocument,
                    auth,
                    projectId,
                    segmentId,
                    document.file
                );
            }
        }
    }

    if (segment.changedImages) {
        for (let image of segment.changedImages) {
            if (image.isDeleted) {
                yield call(
                    Api.projects.deleteProjectSegmentImage,
                    auth,
                    projectId,
                    segmentId,
                    image.id
                );
            }

            if (image.isUpload) {
                yield call(
                    Api.projects.addProjectSegmentImage,
                    auth,
                    projectId,
                    segmentId,
                    image.file
                );
            }
        }
    }

    return segmentId;
}

function* uploadArea(auth, projectId, areaId, area) {
    const data = {
        link: area.link,
        title: area.title,
        description: area.description,
        typeId: area.typeId,
        geoJson: {
            type: area.geoJson.type,
            geometry: area.geoJson.geometry,
            properties: {},
        },
    };

    console.log(area);

    if (areaId) {
        yield call(Api.projects.editProjectArea, auth, projectId, areaId, data);
    } else {
        areaId = (yield call(
            Api.projects.createProjectArea,
            auth,
            projectId,
            data
        )).id;
    }

    if (area.changedDocuments) {
        for (let document of area.changedDocuments) {
            if (document.isDeleted) {
                yield call(
                    Api.projects.deleteProjectAreaDocument,
                    auth,
                    projectId,
                    areaId,
                    document.id
                );
            }
            if (document.isUpload) {
                yield call(
                    Api.projects.addProjectAreaDocument,
                    auth,
                    projectId,
                    areaId,
                    document.file
                );
            }
        }
    }

    if (area.changedImages) {
        for (let image of area.changedImages) {
            if (image.isDeleted) {
                yield call(
                    Api.projects.deleteProjectAreaImage,
                    auth,
                    projectId,
                    areaId,
                    image.id
                );
            }

            if (image.isUpload) {
                yield call(
                    Api.projects.addProjectAreaImage,
                    auth,
                    projectId,
                    areaId,
                    image.file
                );
            }
        }
    }

    return areaId;
}

function* wizardEditorUploadSegments(auth, payload) {
    for (let segment of payload.segments) {
        if (!segment.isChanged) {
            continue;
        }

        if (segment.isDelete) {
            yield call(
                Api.projects.deleteProjectSegment,
                auth,
                payload.projectId,
                segment.id
            );

            continue;
        }

        const segmentData = {
            description: segment.feature.properties.segment.description,
            title: segment.feature.properties.segment.title,
            link: segment.feature.properties.segment.link,
            geoJson: {
                ...segment.feature,
                properties: {
                    ...segment.feature.properties,
                    points: segment.feature.properties.segment.points,
                },
            },
            typeId: segment.feature.properties.segment.segmentType.id,
            changedDocuments: [],
            changedImages: [],
        };

        yield uploadSegment(auth, payload.projectId, segment.id, segmentData);
    }
}

function* wizardEditorUploadAreas(auth, payload) {
    for (let area of payload.areas) {
        if (!area.isChanged) {
            continue;
        }

        if (area.isDelete) {
            yield call(
                Api.projects.deleteProjectArea,
                auth,
                payload.projectId,
                area.id
            );

            continue;
        }

        const areaData = {
            description: area.feature.properties.area.description,
            title: area.feature.properties.area.title,
            link: area.feature.properties.area.link,
            geoJson: {
                ...area.feature,
                properties: {
                    ...area.feature.properties,
                },
            },
            typeId: area.feature.properties.area.areaType.id,
            changedDocuments: [],
            changedImages: [],
        };

        yield uploadArea(auth, payload.projectId, area.id, areaData);
    }
}

function* wizardEditorUpload({ payload }) {
    try {
        const auth = yield takeAuth();

        yield put(Actions.projects.wizardProcessStart());

        yield wizardEditorUploadSegments(auth, payload);

        yield wizardEditorUploadAreas(auth, payload);

        yield delay(2000);

        yield put(
            Actions.projects.wizardEditorUploadSuccess({}, payload.wizard)
        );
        if (payload.startPoint) {
            yield put(
                routerPush(
                    `/map?long=${payload.startPoint[0]}&lat=${payload.startPoint[1]}`
                )
            );
        } else {
            yield put(routerPush(`/projects/${payload.projectId}`));
        }
    } catch (error) {
        yield put(Actions.projects.wizardEditorUploadFailure(error));
    } finally {
        yield put(Actions.projects.wizardProcessStop());
    }
}

function* wizardLayersUpload({ payload }) {
    try {
        yield put(Actions.projects.wizardViewLoading());

        if (0 < payload.layers.length) {
            yield put(Actions.projects.wizardUploadLayers.start());

            const auth = yield takeAuth();

            yield call(
                Api.projects.changeLayer,
                auth,
                payload.projectId,
                payload.layers.map((layer) => ({
                    projectLayerId: layer.id,
                    enabled: layer.enabled,
                    userProjectSegmentTypeId: layer.userProjectSegmentTypeId,
                }))
            );

            yield put(Actions.projects.wizardUploadLayers.success());
        }

        yield put(Actions.projects.wizardRequestViewEdit(payload.projectId));
    } catch (error) {
        yield put(Actions.projects.wizardUploadLayers.show(error));
        yield put(Actions.projects.wizardUploadLayers.failure(error));
        yield put(routerPush('/projects'));
    } finally {
        yield put(Actions.projects.wizardUploadLayers.stop());
    }
}

function* changeVisibility({ payload }) {
    try {
        const auth = yield takeAuth();

        yield call(
            Api.projects.changeVisibility,
            auth,
            payload.id,
            payload.visibility
        );

        yield put(
            Actions.projects.changeVisibilitySuccess(
                payload.id,
                payload.visibility,
                payload.name
            )
        );
    } catch (error) {
        yield put(Actions.projects.changeVisibilityFailure(error));
    } finally {
    }
}
