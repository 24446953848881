// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { useState } from 'react';
import { useSelector } from 'react-redux';

export const useForceUpdate = () => {
    const [, setValue] = useState(0); // integer state
    return () => setValue((value) => ++value); // update the state to force render
};

export const useListReducer = (selector) => {
    const list = useSelector(selector);
    return list.allIds.map((id) => list.byId[id]);
};
