// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { getJsonRequest, deleteRequest, API_HOST } from './common';


export const getFileInfo = (id, init) => getJsonRequest(
    `/files/${id}/info`,
    init,
    r => {
        r.downloadLink = makeFileDownloadLink(id);
        return r;
    }
);

export const makeFileDownloadLink = (id) => `${API_HOST}/files/${id}/download`;

export const deleteFile = (id, init) => deleteRequest(
    `/files/${id}`,
    init
);
