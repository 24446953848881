// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useState } from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { BasePage } from 'components/pages/BasePage';

import { SectionView } from 'components/section/SectionView';
import { UserMapView } from 'components/map/UserMapView';

import { useListReducer } from 'helpers/hooks';

import Actions from 'actions';
import { MapSideBar } from 'components/map/MapSideBar';
import { SegmentPreviewPanel } from 'components/panels/SegmentPreviewPanel';
import { AreaPreviewPanel } from 'components/panels/AreaPreviewPanel';
import { useEffect } from 'react';

const MAP_LOAD_ZOOM = 15.5;
const MAP_SECTION_ZOOM = 16.5;

const MapPage = ({ location }) => {
    const dispatch = useDispatch();

    const segmentTypes = useListReducer((state) => state.app.segmentTypes);

    const [selectedSegmentTypes, setSelectedSegmentTypes] = useState(
        segmentTypes.map((s) => s.id)
    );

    const userMap = useSelector(
        (state) => state.account.user.map,
        () => true // just for init position
    );

    const [mapViewport, setMapViewport] = useState({
        longitude: (userMap && userMap.center[0]) || 0.0,
        latitude: (userMap && userMap.center[1]) || 0.0,
        zoom: (userMap && userMap.zoom) || 0.0,
    });

    const [selectedSegmentId, setSelectedSegmentId] = useState(null);
    const [selectedAreaId, setSelectedAreaId] = useState(null);
    const [map, setMap] = useState(null);
    const [zoom, setZoom] = useState((userMap && userMap.zoom) || 10.0);
    const [center, setCenter] = useState((userMap && userMap.center) || [0, 0]);
    const [sectionVisible, setSectionVisible] = useState(false);
    const [filteredSegments, setFilteredSegments] = useState([]);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const queryCenter = [
            parseFloat(query.get('long')),
            parseFloat(query.get('lat')),
        ];
        if (queryCenter[0] && queryCenter[1]) {
            setMapViewport({
                longitude: queryCenter[0],
                latitude: queryCenter[1],
                zoom: MAP_LOAD_ZOOM,
            });
        }

        dispatch(Actions.projects.mapProjects.reset());
    }, [dispatch, location]);

    const onMapViewportChanged = (center, zoom) => {
        // add throttle here
        dispatch(
            Actions.account.saveMap({
                center,
                zoom,
            })
        );
    };

    const onProjectClicked = (project) => {
        if (!map) {
            return;
        }

        if (project.boundingRect) {
            setMapViewport({
                longitude:
                    (project.boundingRect.minLongitude +
                        project.boundingRect.maxLongitude) *
                    0.5,
                latitude:
                    (project.boundingRect.minLatitude +
                        project.boundingRect.maxLatitude) *
                    0.5,
                zoom: MAP_LOAD_ZOOM + 0.5,
            });
        } else if (project.anchorPoint) {
            setMapViewport({
                longitude: project.anchorPoint.longitude,
                latitude: project.anchorPoint.latitude,
                zoom: MAP_LOAD_ZOOM + 0.5,
            });
        }
    };

    const onSectionModeChanged = (selected) => {
        let segments = [];
        if (selected && map) {
            segments = map
                .queryRenderedFeatures({
                    // layers:[],
                })
                .map((f) => f.properties)
                .filter((p) => !!p.segment)
                .map((p) => JSON.parse(p.segment))
                .map((segment) => segment.id);
        }

        setFilteredSegments(segments);
        setSectionVisible(selected);
    };

    const onMapClicked = (e) => {
        if (!e.features || 0 >= e.features.length) {
            return;
        }

        let segmentId = null;
        let areaId = null;
        for (let feature of e.features) {
            if (feature.type !== 'Feature' || !feature.properties) {
                continue;
            }

            if (feature.properties.segment) {
                const segmentProps = JSON.parse(feature.properties.segment);
                segmentId = segmentProps.id;
                break;
            }

            if (feature.properties.area) {
                const areaProps = JSON.parse(feature.properties.area);
                areaId = areaProps.id;
                break;
            }
        }

        setSelectedAreaId(areaId);
        setSelectedSegmentId(segmentId);
    };

    return (
        <BasePage
            className={`flex-fill d-flex flex-column p-0`}
            style={{
                paddingLeft: '0',
                paddingRight: '0',
            }}
        >
            <UserMapView
                className={`position-fixed p-0 m-0`}
                width='100%'
                height='100%'
                style={{
                    position: 'fixed',
                    paddingLeft: '0',
                    paddingRight: '0',
                }}
                minLoadZoom={MAP_LOAD_ZOOM}
                onMount={setMap}
                onCenterChange={setCenter}
                onZoomChange={setZoom}
                selectedSegmentTypes={selectedSegmentTypes}
                startViewport={mapViewport}
                onUserViewportChange={onMapViewportChanged}
                onClick={onMapClicked}
                selectedAreas={selectedAreaId ? [selectedAreaId] : []}
                selectedSegments={selectedSegmentId ? [selectedSegmentId] : []}
            />

            <Section
                className={`position-fixed w-100 h-100`}
                segmentTypes={segmentTypes}
                center={center}
                zoom={zoom}
                map={map}
                filteredSegments={filteredSegments}
                visible={sectionVisible}
            />

            <Container fluid>
                <Row className='justify-content-between m-0'>
                    <Col xs={8} sm={8} md={6} lg={4} xl={3} className='p-1 m-4'>
                        <MapSideBar
                            mapViewAvailable={sectionVisible}
                            sectionViewAvailable={
                                !sectionVisible && MAP_SECTION_ZOOM < zoom
                            }
                            onMapViewRequest={() => onSectionModeChanged(false)}
                            onSectionViewRequest={() =>
                                onSectionModeChanged(true)
                            }
                            selectedSegmentTypes={selectedSegmentTypes}
                            onSelectedSegmentTypesChange={
                                setSelectedSegmentTypes
                            }
                            onResultClick={onProjectClicked}
                        />
                        <SegmentPreviewPanel
                            className={`mt-2`}
                            segmentId={selectedSegmentId}
                        />
                        <AreaPreviewPanel
                            className={`mt-2`}
                            areaId={selectedAreaId}
                        />
                    </Col>
                </Row>
            </Container>
        </BasePage>
    );
};

const Section = ({
    className,
    center,
    map,
    segmentTypes,
    filteredSegments,
    visible,
}) => {
    const segments = useListReducer(
        (state) => state.projects.mapProjects.model.segments
    );

    if (!visible) {
        return null;
    }

    const mapper = (point) => (map ? map.project(point) : { x: 0, y: 0, z: 0 });

    return (
        <SectionView
            className={className}
            segments={segments.filter((segment) =>
                filteredSegments.includes(segment.id)
            )}
            viewCenter={center}
            mapper={mapper}
            segmentTypes={segmentTypes}
            style={{ visibility: visible ? 'visible' : 'hidden' }}
        />
    );
};

export { MapPage };
