// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { createAction } from '@reduxjs/toolkit';

import { makePageActions } from './page.actions';
import { makeAlertActions } from './alert.actions';
import { makeProcessActions } from './process.actions';
import { makeSearchActions } from './search.actions';
import { makeListActions } from './list.actions';

import { makeRequestActions } from './common';
import { makeFetchActions } from './fetch.actions';

export const EDIT_ACTION = 'projects/edit';
export const TABLE_ACTION = 'projects/table';
export const SEGMENTS_TABLE_ACTION = 'projects/segments/table';
export const OWN_TABLE_ACTION = 'projects/own-table';
export const OWN_LIST_ACTION = 'projects/own-list';
export const MODEL_ACTION = 'projects/model';
export const INFO_MODEL_ACTION = 'projects/info-model';
export const WIZARD_ACTION = 'projects/wizard';
export const WIZARD_MAP_ACTION = 'projects/wizard/map';
export const WIZARD_EDIT_SEGMENT = 'projects/wizard/segment/edit';
export const WIZARD_EDIT_AREA = 'projects/wizard/area/edit';
export const WIZARD_VIEW_ACTION = 'projects/wizard/view';
export const WIZARD_IMPORT_STATUS_ACTION = 'projects/wizard/import/status';
export const WIZARD_LAYERS_UPLOAD_ACTION = 'projects/wizard/layers/upload';
export const SEGMENT_TYPES_ACTION = 'projects/segment-types';
export const DELETE_SEGMENT_TYPE_ACTION = 'projects/delete-segment-type';
export const DELETE_ACTION = 'projects/delete';
export const MAP_SEARCH_ACTION = 'projects/map-search';
export const PLACE_SEARCH_ACTION = 'projects/place-search';
export const VISIBILITY_ACTION = 'projects/visibility';
export const MAP_SEGMENTS_ACTION = 'projects/map-segments';
export const MAP_PROJECTS_ACTION = 'projects/map-projects';

export const edit = makeFetchActions(
    EDIT_ACTION,
    {
        fetch: {
            request: (id, project) => ({
                payload: { projectId: id, project: project },
            }),
            success: (project) => ({ payload: project }),
        },
    },
    makeRequestActions
);

// Settings

export const wizardEditSegment = makeFetchActions(WIZARD_EDIT_SEGMENT, {
    fetch: {
        request: (projectId, id, segment) => ({
            payload: {
                projectId,
                id,
                segment,
            },
        }),
        success: (projectId, id) => ({ payload: { projectId, id } }),
    },
});

export const wizardEditArea = makeFetchActions(WIZARD_EDIT_AREA, {
    fetch: {
        request: (projectId, id, area) => ({
            payload: {
                projectId,
                id,
                area,
            },
        }),
        success: (projectId, id) => ({ payload: { projectId, id } }),
    },
});

export const wizardImportStatus = makeFetchActions(
    WIZARD_IMPORT_STATUS_ACTION,
    {
        fetch: {
            request: (id) => ({ payload: id }),
            success: (model) => ({ payload: model }),
        },
    }
);

export const [
    mapSearch,
    mapSearchSuccess,
    mapSearchFailure,
] = makeRequestActions(MAP_SEARCH_ACTION, {
    request: (bounds, projects) => ({
        payload: { bounds: bounds, projects: projects },
    }),
    success: (projects) => ({ payload: projects }),
});

export const mapProjects = {
    ...makeFetchActions(MAP_PROJECTS_ACTION, {
        fetch: {
            request: (bounds, cache = {}) => ({
                payload: { bounds: bounds, cache: cache },
            }),
            success: () => ({
                payload: {},
            }),
        },
    }),
    reset: createAction(`${MAP_PROJECTS_ACTION}:reset`, () => ({})),
    projectsLoaded: createAction(
        `${MAP_PROJECTS_ACTION}/projects:loaded`,
        (projects) => ({
            payload: projects,
        })
    ),
    areasLoaded: createAction(
        `${MAP_PROJECTS_ACTION}/areas:loaded`,
        (areas) => ({
            payload: areas,
        })
    ),
    segmentsLoaded: createAction(
        `${MAP_PROJECTS_ACTION}/segments:loaded`,
        (segments) => ({
            payload: segments,
        })
    ),
};

export const [
    placeSearch,
    placeSearchSuccess,
    placeSearchFailure,
] = makeRequestActions(PLACE_SEARCH_ACTION, {
    request: (query, limit = 1) => ({ payload: { query, limit } }),
    success: (center, projects) => ({ payload: { center, projects } }),
});

export const [
    deleteProject,
    deleteProjectSuccess,
    deleteProjectFailure,
] = makeRequestActions(DELETE_ACTION, {
    request: (id) => ({ payload: id }),
    success: (id) => ({ payload: id }),
});

export const [
    segmentTypesFetch,
    segmentTypesSuccess,
    segmentTypesFailure,
] = makePageActions(SEGMENT_TYPES_ACTION);

export const [
    createSegmentType,
    createSegmentTypeSuccess,
    createSegmentTypeFailure,
] = makeRequestActions(SEGMENT_TYPES_ACTION, {
    request: (type) => ({ payload: type }),
});

export const [segmentTypesStart, segmentTypesStop] = makeProcessActions(
    SEGMENT_TYPES_ACTION
);

export const [
    deleteSegmentType,
    deleteSegmentTypeSuccess,
    deleteSegmentTypeFailure,
] = makeRequestActions(DELETE_SEGMENT_TYPE_ACTION, {
    request: (id) => ({ payload: id }),
});

// View project page

export const [modelFetch, modelSuccess, modelFailure] = makeRequestActions(
    MODEL_ACTION,
    {
        request: (id) => ({ payload: { id } }),
        success: (data) => ({ payload: data }),
    }
);

export const infoModel = makeFetchActions(INFO_MODEL_ACTION, {
    fetch: {
        request: (id) => ({ payload: { id } }),
        success: (data) => ({ payload: data }),
    },
});

export const [
    changeVisibility,
    changeVisibilitySuccess,
    changeVisibilityFailure,
] = makeRequestActions(VISIBILITY_ACTION, {
    request: (id, visibility, name) => ({ payload: { id, visibility, name } }),
    success: (id, visibility, name) => ({ payload: { id, visibility, name } }),
});

export const [modelAlertShow, modelAlertHide] = makeAlertActions(MODEL_ACTION);
export const [modelProcessStart, modelProcessStop] = makeProcessActions(
    MODEL_ACTION
);

// Project Page

export const [ownListFetch, ownListSuccess, ownListFailure] = makeListActions(
    OWN_LIST_ACTION
);

export const [tableFetch, tableSuccess, tableFailure] = makePageActions(
    TABLE_ACTION
);

export const [tableAlertShow, tableAlertHide] = makeAlertActions(TABLE_ACTION);
export const [tableProcessStart, tableProcessStop] = makeProcessActions(
    TABLE_ACTION
);

export const [
    segmentsTableFetch,
    segmentsTableSuccess,
    segmentsTableFailure,
] = makePageActions(SEGMENTS_TABLE_ACTION, {
    request: (offset, limit, projectId) => ({
        payload: {
            offset,
            limit,
            projectId,
        },
    }),
});

export const [
    segmentsTableAlertShow,
    segmentsTableAlertHide,
] = makeAlertActions(SEGMENTS_TABLE_ACTION);
export const [
    segmentsTableProcessStart,
    segmentsTableProcessStop,
] = makeProcessActions(SEGMENTS_TABLE_ACTION);

// Project Wizard

export const [
    wizardMapSearch,
    wizardMapSearchSuccess,
    wizardMapSearchFailure,
    wizardMapSearchReset,
] = makeSearchActions(WIZARD_MAP_ACTION);

export const wizardViewStart = createAction(`${WIZARD_VIEW_ACTION}:start`);
export const wizardViewLoading = createAction(`${WIZARD_VIEW_ACTION}:loading`);
export const wizardViewEdit = createAction(
    `${WIZARD_VIEW_ACTION}:edit`,
    (project) => ({ payload: project })
);

export const wizardViewLayers = createAction(
    `${WIZARD_VIEW_ACTION}:layers`,
    (project) => ({ payload: project })
);

export const wizardUploadLayers = makeFetchActions(
    `${WIZARD_LAYERS_UPLOAD_ACTION}`,
    {
        fetch: {
            request: (projectId, layers) => ({
                payload: { projectId, layers },
            }),
        },
    }
);

export const wizardRequestViewEdit = createAction(
    `${WIZARD_VIEW_ACTION}/request:edit`,
    (projectId) => ({ payload: projectId })
);

export const wizardRequestViewLayers = createAction(
    `${WIZARD_VIEW_ACTION}/request:layers`,
    (projectId) => ({ payload: projectId })
);

export const wizardViewNext = createAction(`${WIZARD_VIEW_ACTION}:next`);

const makeWizardStepRequestActions = (scope) =>
    makeRequestActions(`${scope}/step`, {
        request: (payload, wizard) => ({
            payload: {
                ...payload,
                wizard,
            },
        }),
        success: (data, wizard) => ({
            payload: {
                data,
                wizard,
            },
        }),
    });

export const [
    wizardCreate,
    wizardCreateSuccess,
    wizardCreateFailure,
] = makeWizardStepRequestActions(`${WIZARD_ACTION}/start`);

export const [
    wizardEditorUpload,
    wizardEditorUploadSuccess,
    wizardEditorUploadFailure,
] = makeWizardStepRequestActions(`${WIZARD_ACTION}/editor`);

export const [
    wizardUpdateInfo,
    wizardUpdateInfoSuccess,
    wizardUpdateInfoFailure,
] = makeWizardStepRequestActions(`${WIZARD_ACTION}/info`);

export const [wizardProcessStart, wizardProcessStop] = makeProcessActions(
    WIZARD_ACTION
);

export const clearSearch = createAction('clearSearch');
