// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { put, call, takeLatest, select, delay } from 'redux-saga/effects';

import { push as routerPush, push } from 'connected-react-router';

import { takeAuth, takeList, loadAllPages } from './common.saga';

import Api from 'api';
import Actions from 'actions';

export function* forumSaga() {
    yield takeLatest(Actions.forum.createTopic.execute, createTopic);
    yield takeLatest(Actions.forum.deleteTopic.execute, deleteTopic);
    yield takeLatest(Actions.forum.sendMessage.execute, sendMessage);
    yield takeLatest(Actions.forum.deleteMessage.execute, deleteMessage);
    yield takeLatest(Actions.forum.topicsList.fetch, getTopics);
    yield takeLatest(Actions.forum.messagesList.fetch, getMessages);
}

function* getTopics({ payload }) {
    try {
        yield put(Actions.forum.topicsList.start());

        const auth = yield takeAuth();

        const list = yield call(
            Api.forum.getForumTopics,
            auth,
            payload.projectId
        );

        yield put(Actions.forum.topicsList.success(list));
    } catch (error) {
        yield put(Actions.forum.topicsList.failure(error));
    } finally {
        yield put(Actions.forum.topicsList.stop());
    }
}

function* updateMessageInfo(auth, messages) {
    const users = {};

    for (let message of messages) {
        if (!users[message.senderId]) {
            users[message.senderId] = yield call(
                Api.users.getUserShort,
                auth,
                message.senderId
            );
        }

        message.user = users[message.senderId];
    }

    return messages;
}

function* getMessages({ payload }) {
    try {
        yield put(Actions.forum.messagesList.start());

        const auth = yield takeAuth();

        let list = yield call(
            Api.forum.getForumTopicMessages,
            auth,
            payload.projectId,
            payload.topicId
        );

        list = yield updateMessageInfo(auth, list.data);

        yield put(Actions.forum.messagesList.success(list));
    } catch (error) {
        yield put(Actions.forum.messagesList.failure(error));
    } finally {
        yield put(Actions.forum.messagesList.stop());
    }
}

function* createTopic({ payload }) {
    try {
        yield put(Actions.forum.createTopic.start());

        const auth = yield takeAuth();

        const model = yield call(
            Api.forum.createForumTopic,
            auth,
            payload.projectId,
            payload.topic
        );

        yield put(Actions.forum.createTopic.success(model));
    } catch (error) {
        yield put(Actions.forum.createTopic.failure(error));
    } finally {
        yield put(Actions.forum.createTopic.stop());
    }
}

function* deleteTopic({ payload }) {
    try {
        yield put(Actions.forum.deleteTopic.start());

        const auth = yield takeAuth();

        const model = yield call(
            Api.forum.deleteForumTopic,
            auth,
            payload.projectId,
            payload.topicId
        );

        yield put(Actions.forum.deleteTopic.success(payload.topicId));
    } catch (error) {
        yield put(Actions.forum.deleteTopic.failure(error));
    } finally {
        yield put(Actions.forum.deleteTopic.stop());
    }
}

function* sendMessage({ payload }) {
    try {
        yield put(Actions.forum.sendMessage.start());

        const auth = yield takeAuth();
        let model = null;
        if (payload.message) {
            model = yield call(
                Api.forum.createForumTopicMessage,
                auth,
                payload.projectId,
                payload.topicId,
                payload.message
            );
        }

        if (payload.file) {
            model = yield call(
                Api.forum.createForumTopicMessageFile,
                auth,
                payload.projectId,
                payload.topicId,
                payload.file
            );
        }

        yield put(Actions.forum.sendMessage.success(model));
    } catch (error) {
        console.log(error)
        yield put(Actions.forum.sendMessage.failure(error));
    } finally {
        yield put(Actions.forum.sendMessage.stop());
    }
}

function* deleteMessage({ payload }) {
    try {
        yield put(Actions.forum.deleteMessage.start());

        const auth = yield takeAuth();

        const model = yield call(
            Api.forum.deleteForumTopicMessage,
            auth,
            payload.projectId,
            payload.topicId,
            payload.messageId
        );

        yield put(Actions.forum.deleteMessage.success(payload.messageId));
    } catch (error) {
        yield put(Actions.forum.deleteMessage.failure(error));
    } finally {
        yield put(Actions.forum.deleteMessage.stop());
    }
}
