// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
    Container,
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    ButtonGroup,
    Button,
    Spinner,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';

import Actions from 'actions';
import { SearchField } from 'components/common/SearchField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useListReducer } from 'helpers/hooks';
import { DialogButton } from 'components/common/DialogButton';
import { MapLayerFilterDialog } from 'components/dialogs/MapLayerFilterDialog';
import { useTranslation } from 'react-i18next';
import { Pagination } from '../common/Pagination';

const MapSideBar = ({
    className,
    sectionViewAvailable,
    mapViewAvailable,
    selectedSegmentTypes = [],
    onMapViewRequest = () => {},
    onSectionViewRequest = () => {},
    onResultClick = () => {},
    onSelectedSegmentTypesChange = () => {},
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(Actions.projects.clearSearch());
        dispatch(Actions.projects.placeSearch(null));
    }, [dispatch]);

    const projectsInPlace = useListReducer(
        (state) => state.projects.map.projects
    );

    const [projectsPagination, setProjectPagination] = useState({
        start: 0,
        end: 5,
    });
    const projectsInPlaceSliced = projectsInPlace.slice(
        projectsPagination.start,
        projectsPagination.end
    );

    const [currentPage, setCurrentPage] = useState(1);

    const onPageChanged = (pageData) => {
        setCurrentPage(pageData.current);
        setProjectPagination({
            start: 5 * pageData.current - 5,
            end: pageData.current * 5,
        });
    };

    const isLoading = useSelector(
        (state) => state.projects.mapProjects.process.pending
    );

    const onSearch = (query) => {
        dispatch(Actions.projects.placeSearch(query));
    };
    return (
        <Container
            className={`${className || ''} shadow bg-light rounded p-2`}
            style={{ maxHeight: '50vh' }}
        >
            <Row className={'align-items-center mb-2'}>
                <Col>
                    <SearchField
                        onSearch={onSearch}
                        disabled={mapViewAvailable}
                    />
                </Col>
            </Row>
            <Row className={'mb-2'}>
                <Col className={'mr-4'} xs={4}>
                    <MenuButtons
                        sectionViewAvailable={sectionViewAvailable}
                        mapViewAvailable={mapViewAvailable}
                        onMapViewRequest={onMapViewRequest}
                        onSectionViewRequest={onSectionViewRequest}
                        onSelectedSegmentTypesChange={
                            onSelectedSegmentTypesChange
                        }
                        selectedSegmentTypes={selectedSegmentTypes}
                    />
                </Col>
                <Col></Col>
                <Col className={'align-self-center'} xs={2}>
                    {isLoading && <LoadingIndicator />}
                </Col>
            </Row>
            <Row
                className={`align-items-center ${
                    projectsInPlace && 0 < projectsInPlace.length ? 'mb-2' : ''
                }`}
            >
                <Col>
                    <SearchResults
                        results={projectsInPlaceSliced}
                        onResultClick={onResultClick}
                    />
                    <Row className='justify-content-center mt-4'>
                        {projectsInPlace.length > 5 && (
                            <Pagination
                                totalRecords={projectsInPlace.length}
                                currentPage={currentPage}
                                pageLimit={5}
                                onPageChange={onPageChanged}
                            />
                        )}
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

const SearchResultItem = ({ name, onClick }) => (
    <ListGroupItem action onClick={onClick}>
        {name}
    </ListGroupItem>
);

const SearchResults = ({ results = [], onResultClick }) => (
    <ListGroup
        style={{
            overflowX: 'hidden',
            maxHeight: '25vh',
            overflowY: 'auto',
        }}
    >
        {results.map((result) => (
            <SearchResultItem
                key={result.id}
                name={result.name}
                onClick={() => onResultClick(result)}
            />
        ))}
    </ListGroup>
);

const MenuButtons = ({
    sectionViewAvailable,
    mapViewAvailable,
    onMapViewRequest,
    onSectionViewRequest,
    onSelectedSegmentTypesChange,
    selectedSegmentTypes,
}) => {
    const { t } = useTranslation();
    return (
        <ButtonGroup>
            <OverlayTrigger
                placement='bottom'
                overlay={<Tooltip>{t('map-map-mode-btn')}</Tooltip>}
            >
                <Button disabled={!mapViewAvailable} onClick={onMapViewRequest}>
                    <FontAwesomeIcon icon='map' />
                </Button>
            </OverlayTrigger>
            <OverlayTrigger
                placement='bottom'
                overlay={<Tooltip>{t('map-section-mode-btn')}</Tooltip>}
            >
                <Button
                    disabled={!sectionViewAvailable}
                    onClick={onSectionViewRequest}
                >
                    <FontAwesomeIcon icon='cube' />
                </Button>
            </OverlayTrigger>
            <OverlayTrigger
                placement='bottom'
                overlay={<Tooltip>{t('map-filter-btn')}</Tooltip>}
            >
                <DialogButton
                    disabled={mapViewAvailable}
                    dialog={MapLayerFilterDialog}
                    dialogParams={{
                        selectedSegmentTypes: selectedSegmentTypes,
                        onSelectedSegmentTypesChange: onSelectedSegmentTypesChange,
                    }}
                    variant={'primary'}
                >
                    <FontAwesomeIcon icon='filter' />
                </DialogButton>
            </OverlayTrigger>
        </ButtonGroup>
    );
};

const LoadingIndicator = () => (
    <Row className={'align-items-center justify-content-center'}>
        <Col>
            <Spinner animation='border' variant={'secondary'} />
        </Col>
    </Row>
);
export { MapSideBar };
