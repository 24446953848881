// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LinkButton } from 'components/common/LinkButton';
import { DialogButton } from 'components/common/DialogButton';
import { DeleteDialog } from 'components/dialogs/DeleteDialog';
import { ApproveDialog } from 'components/dialogs/ApproveDialog';
import { StatusBadge } from 'components/common/StatusBadge';

import Actions from 'actions';
import { useSelector } from 'react-redux';
import { DeleteButton } from 'components/common/DeleteButton';
import { useTranslation } from 'react-i18next';

const AdminButtons = ({ request }) => {
    const approveRequest = useSelector(
        (state) => state.requests.approveRequest
    );

    return (
        <React.Fragment>
            <DialogButton
                className='mr-2'
                dialog={ApproveDialog}
                dialogParams={{
                    callAction: () =>
                        Actions.requests.approveRequest.execute(request.id),
                    resetAction: () => Actions.requests.approveRequest.reset(),
                    requestModel: approveRequest,
                }}
                variant='outline-success'
                size={'sm'}
            >
                <FontAwesomeIcon icon='check' />
            </DialogButton>
            <DeleteButton
                callAction={() =>
                    Actions.requests.declineRequest.execute(request.id)
                }
                icon={'times'}
                otherDialogParams={{
                    title: 'Decline',
                    message: 'Do you want to decline?',
                    acceptText: 'Decline',
                }}
            />
        </React.Fragment>
    );
};

const UserButtons = ({ request }) => (
    <React.Fragment>
        <DialogButton
            dialog={DeleteDialog}
            dialogParams={{
                callAction: () =>
                    Actions.requests.cancelRequest.execute(request.id),
            }}
            variant='outline-danger'
            size={'sm'}
        >
            <FontAwesomeIcon icon='trash' />
        </DialogButton>
    </React.Fragment>
);

const TableRow = ({ request, isAdmin = true }) => {
    const { t } = useTranslation();
    return (
        <tr>
            <td>{request.creator?.username}</td>
            <td>{request.project?.name}</td>
            <td>
                <StatusBadge
                    labelMap={{
                        CONFIRMED: t('requests-table-status-confirmed'),
                        DECLINED: t('requests-table-status-declined'),
                        IN_PROGRESS: t('requests-table-status-in-progress'),
                        CANCELED: t('requests-table-status-canceled'),
                    }}
                    variantMap={{
                        CONFIRMED: 'success',
                        DECLINED: 'danger',
                        IN_PROGRESS: 'secondary',
                        CANCELED: 'warning',
                    }}
                    value={request.status}
                />
            </td>
            <td>{new Date(request.createdAt).toLocaleString()}</td>
            <td>
                <LinkButton
                    className='mr-2'
                    variant='outline-secondary'
                    size={'sm'}
                    to={`/projects/${request.projectId}`}
                >
                    <FontAwesomeIcon icon='eye' />
                </LinkButton>
                {'IN_PROGRESS' === request.status &&
                    (isAdmin ? (
                        <AdminButtons request={request} />
                    ) : (
                        <UserButtons request={request} />
                    ))}
            </td>
        </tr>
    );
};

const TableHeader = () => {
    const { t } = useTranslation();
    return (
        <thead>
            <tr>
                <th>{t('requests-table-creator-col')}</th>
                <th>{t('requests-table-project-name-col')}</th>
                <th>{t('requests-table-status-col')}</th>
                <th>{t('requests-table-created-at-col')}</th>
                <th>{t('requests-table-actions-col')}</th>
            </tr>
        </thead>
    );
};

const RequestsTable = ({ model }) => {
    const user = useSelector((state) => state.account.user);
    return (
        <Table bordered hover>
            <TableHeader />
            <tbody>
                {model.map((request) => (
                    <TableRow
                        key={request.id}
                        request={request}
                        isAdmin={user && user.permissions.includes('ADMIN')}
                    />
                ))}
            </tbody>
        </Table>
    );
};

export { RequestsTable };
