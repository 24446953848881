// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { BasePage } from 'components/pages/BasePage';
import { PageLayout } from 'components/common/PageLayout';
import { PageHeader } from 'components/common/PageHeader';
import { PageSection } from 'components/common/PageSection';
import { SegmentTypesTable } from 'components/tables/SegmentTypesTable';
import { PageSectionHeader } from 'components/common/PageSectionHeader';
import { PageSectionPagination } from 'components/common/PageSectionPagination';

import Actions from 'actions';
import { Form, Col, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const SettingsPage = () => {
    const userPermissions = useSelector(
        (state) => state.account.user.permissions
    );

    const { t } = useTranslation();

    return (
        <BasePage>
            <PageLayout>
                <PageHeader title={t('settings-title')} />
                <AccountSection />
                <CoordinatesSection />
                {userPermissions.includes('ADMIN') && <SegmentTypesSection />}
                <LanguageSection />
            </PageLayout>
        </BasePage>
    );
};

const CoordinatesSection = () => {
    const { t } = useTranslation();

    const { register, handleSubmit } = useForm({
        defaultValues: {
            coordsFormat: 'UTM',
        },
    });
    const onSubmit = () => {};

    return (
        <PageSection>
            <PageSectionHeader title={t('settings-coordinates-format-title')} />
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Check
                            type='radio'
                            name='coordsFormat'
                            value='LAT_LNG'
                            label={t('settings-coordinates-format-lng-lat')}
                            ref={register({ required: false })}
                        />
                        <Form.Check
                            type='radio'
                            name='coordsFormat'
                            value='UTM'
                            label={t('settings-coordinates-format-utm')}
                            ref={register({ required: false })}
                        />
                        <Form.Check
                            type='radio'
                            name='coordsFormat'
                            value='DMS'
                            label={t('settings-coordinates-format-dms')}
                            ref={register({ required: false })}
                        />
                    </Form.Group>
                </Form.Row>
                <Button type='submit' variant='secondary'>
                    {t('settings-coordinates-format-save')}
                </Button>
            </Form>
        </PageSection>
    );
};

const AccountSection = () => {
    const { t } = useTranslation();

    const user = useSelector((state) => state.account.user.user);
    const dispatch = useDispatch();

    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
        },
    });

    const onSubmit = (data) => {
        dispatch(
            Actions.users.edit.execute({
                ...data,
                language: user.language,
                coordinateSystem: user.coordinateSystem,
            })
        );
    };

    return (
        <PageSection>
            <PageSectionHeader title={t('settings-account-title')} />
            <PageSection>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                {t('settings-account-first-name')}
                            </Form.Label>
                            <Form.Control
                                type='text'
                                name='firstName'
                                isInvalid={errors.firstName}
                                ref={register({ required: true })}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>
                                {t('settings-account-last-name')}
                            </Form.Label>
                            <Form.Control
                                type='text'
                                name='lastName'
                                isInvalid={errors.lastName}
                                ref={register({ required: true })}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                {t('settings-account-email')}
                            </Form.Label>
                            <Form.Control
                                type='email'
                                name='email'
                                isInvalid={errors.email}
                                ref={register({ required: true })}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                {t('settings-account-old-password')}
                            </Form.Label>
                            <Form.Control
                                type='password'
                                name='oldPassword'
                                isInvalid={errors.oldPassword}
                                ref={register({
                                    required: false,
                                })}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>
                                {t('settings-account-new-password')}
                            </Form.Label>
                            <Form.Control
                                type='password'
                                name='newPassword'
                                isInvalid={errors.newPassword}
                                ref={register({
                                    required: false,
                                    minLength: 8,
                                })}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Button type='submit' variant='secondary'>
                        {t('settings-account-update')}
                    </Button>
                </Form>
            </PageSection>
        </PageSection>
    );
};

const SegmentTypesSection = () => {
    const { t } = useTranslation();

    return (
        <PageSection>
            <PageSectionHeader title={t('settings-segment-types')} />

            <PageSectionPagination
                scope={['projects', 'segmentTypes']}
                fetchAction={Actions.projects.segmentTypesFetch}
                table={SegmentTypesTable}
            />
        </PageSection>
    );
};

const LanguageSection = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const user = useSelector((state) => state.account.user.user);
    const { register, handleSubmit } = useForm({
        defaultValues: {
            lang: i18n.language,
        },
    });

    const onSubmit = (data) => {
        i18n.changeLanguage(data.lang);

        dispatch(
            Actions.users.edit.execute({
                ...user,
                coordinateSystem: user.coordinateSystem,
                language: data.lang,
            })
        );
    };

    const systemLanguages = useSelector((state) => state.app.systemLanguages);

    return (
        <PageSection>
            <PageSectionHeader title={t('settings-lang-title')} />
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                    <Form.Group as={Col}>
                        {systemLanguages.map((lang, i) => (
                            <Form.Check
                                key={i}
                                type='radio'
                                name='lang'
                                value={lang.code}
                                label={lang.name}
                                ref={register({ required: false })}
                            />
                        ))}
                    </Form.Group>
                </Form.Row>
                <Button type='submit' variant='secondary'>
                    {t('settings-lang-change')}
                </Button>
            </Form>
        </PageSection>
    );
};

export { SettingsPage };
