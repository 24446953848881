// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Container } from 'react-bootstrap';

const BasePage = ({ className, children, ...rest }) => (
    <Container className={`${className || ''}`} fluid {...rest}>
        {children}
    </Container>
);

export { BasePage };
