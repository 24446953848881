// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { put, call, takeLatest, select, delay } from 'redux-saga/effects';

import { push as routerPush, push } from 'connected-react-router';

import { takeAuth, takeList, loadAllPages } from './common.saga';

import Api from 'api';
import Actions from 'actions';

export function* joinRequestsSaga() {
    yield takeLatest(Actions.joinRequests.create.execute, create);
    yield takeLatest(Actions.joinRequests.approve.execute, approve);
    yield takeLatest(Actions.joinRequests.decline.execute, decline);
    yield takeLatest(Actions.joinRequests.cancel.execute, cancel);
    yield takeLatest(Actions.joinRequests.list.fetch, getJoinRequests);
    yield takeLatest(
        Actions.joinRequests.listByProject.fetch,
        getJoinRequestsByProject
    );
}

function* updateJoinRequestInfo(auth, joinRequests) {
    const projects = {};
    const users = {};

    for (let joinRequest of joinRequests) {
        if (!projects[joinRequest.projectId]) {
            projects[joinRequest.projectId] = yield call(
                Api.projects.getProject,
                auth,
                joinRequest.projectId
            );
        }

        joinRequest.project = projects[joinRequest.projectId];

        if (!users[joinRequest.userId]) {
            users[joinRequest.userId] = yield call(
                Api.users.getUserShort,
                auth,
                joinRequest.userId
            );
        }

        joinRequest.user = users[joinRequest.userId];
    }

    return joinRequests;
}

function* getJoinRequests() {
    try {
        yield put(Actions.joinRequests.list.start());

        const auth = yield takeAuth();

        let list = yield call(Api.joinRequests.getJoinRequests, auth);
        list = yield call(updateJoinRequestInfo, auth, list);

        yield put(Actions.joinRequests.list.success(list));
    } catch (error) {
        yield put(Actions.joinRequests.list.failure(error));
    } finally {
        yield put(Actions.joinRequests.list.stop());
    }
}

function* getJoinRequestsByProject({ payload }) {
    try {
        yield put(Actions.joinRequests.listByProject.start());

        const auth = yield takeAuth();

        let list = yield call(
            Api.joinRequests.getJoinRequestsByProject,
            auth,
            payload.projectId
        );

        list = yield call(updateJoinRequestInfo, auth, list);

        yield put(Actions.joinRequests.listByProject.success(list));
    } catch (error) {
        yield put(Actions.joinRequests.listByProject.failure(error));
    } finally {
        yield put(Actions.joinRequests.listByProject.stop());
    }
}

function* create({ payload }) {
    try {
        yield put(Actions.joinRequests.create.start());

        const auth = yield takeAuth();

        yield call(
            Api.joinRequests.createJoinRequest,
            auth,
            payload.projectId,
            payload.joinRequest
        );

        yield put(Actions.joinRequests.create.success(payload.projectId));
    } catch (error) {
        yield put(Actions.joinRequests.create.failure(error));
    } finally {
        yield put(Actions.joinRequests.create.stop());
    }
}

function* approve({ payload }) {
    try {
        yield put(Actions.joinRequests.approve.start());

        const auth = yield takeAuth();

        yield call(
            Api.joinRequests.approveJoinRequest,
            auth,
            payload.projectId,
            payload.joinRequestId
        );

        yield put(
            Actions.joinRequests.approve.success(
                payload.projectId,
                payload.joinRequestId
            )
        );
    } catch (error) {
        yield put(Actions.joinRequests.approve.failure(error));
    } finally {
        yield put(Actions.joinRequests.approve.stop());
    }
}

function* decline({ payload }) {
    try {
        yield put(Actions.joinRequests.decline.start());

        const auth = yield takeAuth();

        yield call(
            Api.joinRequests.declineJoinRequest,
            auth,
            payload.projectId,
            payload.joinRequestId
        );

        yield put(
            Actions.joinRequests.decline.success(
                payload.projectId,
                payload.joinRequestId
            )
        );
    } catch (error) {
        yield put(Actions.joinRequests.decline.failure(error));
    } finally {
        yield put(Actions.joinRequests.decline.stop());
    }
}

function* cancel({ payload }) {
    try {
        yield put(Actions.joinRequests.cancel.start());

        const auth = yield takeAuth();

        yield call(
            Api.joinRequests.cancelJoinRequest,
            auth,
            payload.projectId,
            payload.joinRequestId
        );

        yield put(
            Actions.joinRequests.cancel.success(
                payload.projectId,
                payload.joinRequestId
            )
        );
    } catch (error) {
        yield put(Actions.joinRequests.cancel.failure(error));
    } finally {
        yield put(Actions.joinRequests.cancel.stop());
    }
}
