// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { createReducer } from '@reduxjs/toolkit';

const initial = {
    count: 0,
    offset: 0,
    limit: 0,
    byId: {},
    allIds: [],
};

export const addToPageReducer = (state, action) => ({
    ...state,
    count: 1 + state.count,
    byId: {
        ...state.byId,
        [action.payload.id]: action.payload,
    },
    allIds: [action.payload.id, ...state.allIds],
});

export const updateToPageReducer = (state, { payload }) => ({
    ...state,
    byId: {
        ...state.byId,
        [payload.id]: payload,
    },
});

export const removeFromPageReducer = (state, { payload }) => ({
    ...state,
    count: Math.max(0, state.count - 1),
    byId: state.allIds
        .filter((id) => id !== payload)
        .map((id) => state.byId[id])
        .reduce((byId, item) => ({ ...byId, [item.id]: item }), {}),
    allIds: state.allIds.filter((id) => id !== payload),
});

export const makePageReducer = (scope, reducer = {}) =>
    createReducer(initial, {
        [`${scope}/page:request`]: (state, action) => ({
            ...state,
            offset: action.payload.offset,
            limit: action.payload.limit,
        }),
        [`${scope}/page:success`]: (state, action) => ({
            ...state,
            count: action.payload.count,
            byId: action.payload.data.reduce((r, i) => {
                r[i.id] = i;
                return r;
            }, {}),
            allIds: action.payload.data.map((item) => item.id),
        }),
        [`${scope}:page:/failure`]: (state) => state,
        ...reducer,
    });
