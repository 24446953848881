// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { makeListActions } from './list.actions';

import { makeRequestActions } from './common';
import { makeFetchActions } from './fetch.actions';

export const CREATE_TOPIC_ACTION = 'project/forum/topic/create';
export const DELETE_TOPIC_ACTION = 'project/forum/topic/delete';
export const SEND_MESSAGE_ACTION = 'project/forum/topic/message/send';
export const DELETE_MESSAGE_ACTION = 'project/forum/topic/message/delete';
export const TOPICS_LIST_ACTION = 'project/forum/topic/list';
export const MESSAGES_LIST_ACTION = 'project/forum/topic/message/list';

export const deleteMessage = makeFetchActions(DELETE_MESSAGE_ACTION, {
    fetch: {
        request: (projectId, topicId, messageId) => ({
            payload: { projectId, topicId, messageId },
        }),
        success: (messageId) => ({
            payload: messageId,
        }),
    },
});

export const sendMessage = makeFetchActions(SEND_MESSAGE_ACTION, {
    fetch: {
        request: (projectId, topicId, message, file) => ({
            payload: { projectId, topicId, message, file },
        }),
        success: (message) => ({
            payload: message,
        }),
    },
});

export const deleteTopic = makeFetchActions(DELETE_TOPIC_ACTION, {
    fetch: {
        request: (projectId, topicId) => ({
            payload: { projectId, topicId },
        }),
        success: (topicId) => ({
            payload: topicId,
        }),
    },
});

export const createTopic = makeFetchActions(CREATE_TOPIC_ACTION, {
    fetch: {
        request: (projectId, topic) => ({
            payload: { projectId, topic },
        }),
        success: (model) => ({
            payload: model,
        }),
    },
});

export const topicsList = makeFetchActions(
    TOPICS_LIST_ACTION,
    {
        fetch: {
            request: (projectId) => ({
                payload: { projectId },
            }),
        },
    },
    makeListActions
);

export const messagesList = makeFetchActions(
    MESSAGES_LIST_ACTION,
    {
        fetch: {
            request: (projectId, topicId) => ({
                payload: { projectId, topicId },
            }),
        },
    },
    makeListActions
);
