// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Container } from 'react-bootstrap';

const PageSection = ({ title, children, className }) => (
    <Container  className={`${className || ''} p-0 mt-4 pt-1`}>
      
            {children}
     
    </Container>
);

export { PageSection };
