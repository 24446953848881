// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useEffect } from 'react';

import { BaseDialog } from 'components/dialogs/BaseDialog';
import { useDispatch } from 'react-redux';

const DeleteDialog = ({
    title = 'Delete',
    message = 'Do You want to delete?',
    acceptText = 'Delete',
    callAction,
    resetAction,
    pending,
    finished = true,
    show,
    onHide,
    onClose,
    onAccept = () => {},
}) => {
    const dispatch = useDispatch();

    const handleAccept = () => {
        dispatch(callAction());
        onAccept();
    };

    useEffect(() => {
        if (resetAction) {
            dispatch(resetAction());
        }
    }, [show]);


    return (
        <BaseDialog
            title={title}
            show={show}
            onHide={onHide}
            onClose={onClose}
            acceptVariant='danger'
            acceptText={acceptText}
            onAccept={handleAccept}
            finished={finished}
            pending={pending}
        >
            <p>{message}</p>
        </BaseDialog>
    );
};

export { DeleteDialog };
