// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import Utm, { LatLon } from 'geodesy/utm';

export const convertLatLngToUTM = (lat, lng) => {
    let p = new LatLon(lat, lng);

    const res = p.toUtm();

    return {
        easting: res.easting,
        northing: res.northing,
        zone: res.zone,
        hemisphere: res.hemisphere,
    };
};

export const convertUTMToLatLng = (zone, hemisphere, easting, northing) => {
    const p = new Utm(zone, hemisphere > 'M' ? 'N' : 'S', easting, northing);
    const res = p.toLatLon();
    return {
        lat: res.latitude,
        lng: res.longitude,
    };
};
