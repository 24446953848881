// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { combineReducers } from '@reduxjs/toolkit';

import { makeAlertReducer } from './alert.reducer';
import { makePageReducer } from './page.reducer';
import { makeProcessReducer } from './process.reducer';

export const makeTableReducer = (scope, reducer = {}) =>
    combineReducers({
        alert: makeAlertReducer(scope),
        process: makeProcessReducer(scope),
        page: makePageReducer(scope, reducer && reducer.page),
    });
