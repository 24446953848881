// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Form, Col } from 'react-bootstrap';

import { BaseDialog } from 'components/dialogs/BaseDialog';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Actions from 'actions';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const CreateUserDialog = ({ show, onHide, onClose, onAccept = () => {} }) => {
    const { register, handleSubmit, errors } = useForm({});
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const createState = useSelector((state) => state.users.create);
    const permissions = useSelector(
        (state) => state.app.userPermissions
    ).concat([{ code: 'ADMIN', name: 'Administrator' }]);

    useEffect(() => {
        dispatch(Actions.users.create.reset());
    });

    const onSubmit = (data) => {
        dispatch(
            Actions.users.create.execute({
                ...data,
                coordinateSystem: 'UTM',
                language: 'de',
            })
        );
    };

    if (!permissions || 0 === permissions.length) {
        return <></>;
    }

    return (
        <BaseDialog
            title={t('create-user-title')}
            onSubmit={handleSubmit(onSubmit)}
            show={show}
            onHide={onHide}
            onClose={onClose}
            onAccept={onAccept}
            acceptText={t('create-user-create-btn')}
            pending={createState.process.pending}
            finished={null != createState.model}
        >
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>{t('create-user-first-name')}</Form.Label>
                    <Form.Control
                        type='text'
                        name='firstName'
                        isInvalid={errors.name}
                        ref={register({ required: true })}
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{t('create-user-last-name')}</Form.Label>
                    <Form.Control
                        type='text'
                        name='lastName'
                        isInvalid={errors.name}
                        ref={register({ required: true })}
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>{t('create-user-email')}</Form.Label>
                    <Form.Control
                        type='email'
                        name='email'
                        isInvalid={errors.email}
                        ref={register({ required: true })}
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{t('create-user-username')}</Form.Label>
                    <Form.Control
                        type='text'
                        name='username'
                        isInvalid={errors.username}
                        ref={register({ required: true, minLength: 5 })}
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>{t('create-user-password')}</Form.Label>
                    <Form.Control
                        type='password'
                        name='password'
                        isInvalid={errors.password}
                        ref={register({ required: true, minLength: 8 })}
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>{t('create-user-role')}</Form.Label>
                    <Form.Control
                        as='select'
                        defaultValue={permissions[0]}
                        name='role'
                        ref={register({ required: true })}
                    >
                        {permissions.map((p) => (
                            <option key={p} value={p.code}>
                                {t(p.name)}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </Form.Row>
        </BaseDialog>
    );
};

export { CreateUserDialog };
