// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Row, Carousel, ListGroup, Col, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { push as routerPush } from 'connected-react-router';

import { PageHeader } from 'components/common/PageHeader';
import { PageLayout } from 'components/common/PageLayout';
import { PageLoader } from 'components/common/PageLoader';
import { PageSection } from 'components/common/PageSection';
import { LinkButton } from 'components/common/LinkButton';

import { BasePage } from 'components/pages/BasePage';

import { DialogButton } from 'components/common/DialogButton';
import { DeleteButton } from 'components/common/DeleteButton';

import Actions from 'actions';
import { StatusBadge } from 'components/common/StatusBadge';
import { PageSectionHeader } from 'components/common/PageSectionHeader';
import { PageSectionPagination } from 'components/common/PageSectionPagination';
import { ProjectSegmentsTable } from 'components/tables/ProjectSegmentsTable';
import { ProjectVisibilityDialog } from 'components/dialogs/ProjectVisibilityDialog';
import { useTranslation } from 'react-i18next';
import { UserMapView } from 'components/map/UserMapView';
import { ProjectInvestmentPanel } from 'components/panels/ProjectInvestmentPanel';
import { EditInvestmentPhaseDialog } from 'components/dialogs/EditInvestmentPhaseDialog';
import { ProjectPhasesPanel } from 'components/panels/ProjectPhasesPanel';
import { CreateJoinRequestDialog } from 'components/dialogs/CreateJoinRequestDialog';
import { Badge } from 'react-bootstrap';
import { ApproveDialog } from 'components/dialogs/ApproveDialog';

const Section = ({ title, children }) => (
    <PageSection>
        <PageSectionHeader title={title}></PageSectionHeader>
        {children}
    </PageSection>
);

const ImageSlider = ({ images = [] }) => (
    <Row>
        <Carousel className='col-12 p-0 my-4'>
            {images.map((image) => (
                <Carousel.Item key={image.id}>
                    <img
                        className='w-100'
                        style={{
                            backgroundImage: `url(${image.downloadLink})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            height: '300px',
                        }}
                        alt=''
                    />
                </Carousel.Item>
            ))}
        </Carousel>
    </Row>
);

function getProjectCenter(project) {
    if (project.boundingRect) {
        return {
            longitude:
                (project.boundingRect.minLongitude +
                    project.boundingRect.maxLongitude) *
                0.5,
            latitude:
                (project.boundingRect.minLatitude +
                    project.boundingRect.maxLatitude) *
                0.5,
        };
    } else if (project.anchorPoint) {
        return {
            longitude: project.anchorPoint.longitude,
            latitude: project.anchorPoint.latitude,
        };
    }

    return null;
}

const FINANCIAL_FIELDS = [
    {
        label: 'project-wizard-info-financial-price-of-land', // t('project-wizard-info-financial-price-of-land')
        name: 'landPrice',
        precision: 2,
        suffix: 'Euro',
    },
    {
        label: 'project-wizard-info-financial-development-costs', // t('project-wizard-info-financial-development-costs')
        name: 'developmentPrice',
        precision: 2,
        suffix: 'Euro',
    },
    {
        label: 'project-wizard-info-financial-construction-costs-per-sqm', // t('project-wizard-info-financial-construction-costs-per-sqm')
        name: 'constructionPricePerMeter',
        precision: 4,
        suffix: 'Euro',
    },
    {
        label: 'project-wizard-info-financial-construction-costs-in-total', // t('project-wizard-info-financial-construction-costs-in-total')
        name: 'totalConstructionPrice',
        precision: 2,
        suffix: 'Euro',
    },
    {
        label: 'project-wizard-info-financial-marketing-costs', // t('project-wizard-info-financial-marketing-costs')
        name: 'marketingPrice',
        precision: 2,
        suffix: 'Euro',
    },
    {
        label: 'project-wizard-info-financial-total-costs', // t('project-wizard-info-financial-total-costs')
        name: 'projectTotalPrice',
        precision: 2,
        suffix: 'Euro',
    },
    {
        label: 'project-wizard-info-financial-sales-price-per-sqm', // t('project-wizard-info-financial-sales-price-per-sqm')
        name: 'salesPricePerMeter',
        precision: 4,
        suffix: 'Euro',
    },
    {
        label: 'project-wizard-info-financial-total-sales-price', // t('project-wizard-info-financial-total-sales-price')
        name: 'totalSalesPrice',
        precision: 2,
        suffix: 'Euro',
    },
    // {
    //     label: 'project-wizard-info-financial-investor-margin', // t('project-wizard-info-financial-investor-margin')
    //     name: 'investorMargin',
    //     suffix: '%',
    // },
];

const ViewProjectPage = ({ match }) => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const user = useSelector((state) => state.account.user);

    const projectModelProcess = useSelector(
        (state) => state.projects.project.info.process.pending
    );

    const projectModel = useSelector(
        (state) => state.projects.project.info.model
    );
    const approveRequest = useSelector(
        (state) => state.requests.approveRequest
    );

    const projectId = match.params.id;

    const status = useSelector(
        (state) =>
            state.requests.table.page?.byId[projectModel?.requestId]?.status
    );

    useEffect(() => {
        dispatch(Actions.projects.infoModel.fetch(projectId));
    }, [projectId, dispatch]);

    if (!projectModel || projectModelProcess.pending) {
        return <PageLoader />;
    }

    const onMapMounted = (map) => {
        if (projectModel.boundingRect) {
            map.fitBounds([
                [
                    projectModel.boundingRect.minLongitude,
                    projectModel.boundingRect.minLatitude,
                ],
                [
                    projectModel.boundingRect.maxLongitude,
                    projectModel.boundingRect.maxLatitude,
                ],
            ]);
        }
    };

    const isAdmin = projectModel.userFeatures.isAdmin;
    const isJoined = projectModel.userFeatures.isJoined;
    const canEdit = projectModel.userFeatures.canEdit;
    const canJoin = projectModel.userFeatures.canJoin;
    const canForum = projectModel.userFeatures.canForum;

    const projectCenter = getProjectCenter(projectModel);

    return (
        <BasePage>
            <PageLayout>
                <Row className='justify-content-start'>
                    <Col md={'auto'}>
                        <StatusBadge
                            className='mb-2 ml-0'
                            labelMap={{
                                PUBLIC: t('project-view-status-public'),
                                PRIVATE: t('project-view-status-private'),
                                IMPORTING: t('project-view-status-importing'),
                                FAILED: t('project-view-status-failed'),
                            }}
                            variantMap={{
                                PUBLIC: 'success',
                                PRIVATE: 'warning',
                                IMPORTING: 'primary',
                                FAILED: 'danger',
                            }}
                            value={
                                (projectModel.isFailed && 'FAILED') ||
                                (projectModel.isImporting && 'IMPORTING') ||
                                projectModel.visibilityType ||
                                'PRIVATE'
                            }
                        />
                    </Col>
                    <Col md={'auto'}>
                        {isJoined && (
                            <Badge className='ml-2' size='sm' variant='info'>
                                {t('project-view-joined')}
                            </Badge>
                        )}
                    </Col>
                    <Col md={'auto'} />
                </Row>
                <PageHeader title={projectModel && projectModel.name}>
                    <div>
                        {isAdmin && status === 'IN_PROGRESS' && (
                            <DialogButton
                                className='mr-2'
                                dialog={ApproveDialog}
                                dialogParams={{
                                    callAction: () =>
                                        Actions.requests.approveRequest.execute(
                                            projectModel?.requestId
                                        ),
                                    resetAction: () =>
                                        Actions.requests.approveRequest.reset(),
                                    requestModel: approveRequest,
                                }}
                                variant='outline-success'
                                size='sm'
                            >
                                Accept
                            </DialogButton>
                        )}
                        {canForum && (
                            <LinkButton
                                className='mr-2'
                                variant='secondary'
                                size='sm'
                                to={`/projects/${projectModel.id}/forum`}
                            >
                                {t('project-view-forum')}
                            </LinkButton>
                        )}
                        {canJoin && (
                            <DialogButton
                                dialog={CreateJoinRequestDialog}
                                className='mr-2'
                                dialogParams={{
                                    project: projectModel,
                                }}
                                variant='secondary'
                                size='sm'
                            >
                                {t('project-view-join-btn')}
                            </DialogButton>
                        )}
                        {canEdit && (
                            <React.Fragment>
                                {!projectModel.isImporting && (
                                    <LinkButton
                                        variant='outline-dark'
                                        className='mr-2'
                                        to={`/projects/${projectModel.id}/edit`}
                                        disabled={projectModel.isImporting}
                                        size='sm'
                                    >
                                        {t('project-view-edit-btn')}
                                    </LinkButton>
                                )}
                                {isAdmin && (
                                    <DialogButton
                                        dialog={ProjectVisibilityDialog}
                                        className='mr-2'
                                        dialogParams={{
                                            project: projectModel,
                                            visibility:
                                                projectModel.visibilityType,
                                        }}
                                        variant='outline-dark'
                                        size='sm'
                                    >
                                        {t('project-view-visibility-btn')}
                                    </DialogButton>
                                )}

                                <DeleteButton
                                    icon=''
                                    text={t('project-view-delete-btn')}
                                    size='sm'
                                    callAction={() =>
                                        Actions.projects.deleteProject(
                                            projectId
                                        )
                                    }
                                    onAccept={() =>
                                        dispatch(routerPush('/projects'))
                                    }
                                />
                            </React.Fragment>
                        )}
                    </div>
                </PageHeader>
                <Row className='justify-content-end mt-2 align-items-center'>
                    <p className='text-sm-left'>
                        {t('project-view-views-count') +
                            ' ' +
                            projectModel.views}
                    </p>
                </Row>
                <hr />

                {projectModel.isFailed && (
                    <Row className='my-2'>
                        <Alert variant='danger'>
                            {t('project-view-failed-alert')}
                        </Alert>
                    </Row>
                )}

                {projectCenter &&
                    0 !== projectCenter.longitude &&
                    0 !== projectCenter.latitude && (
                        <Row>
                            <Col className='p-0 pl-0 pr-0'>
                                <LinkButton
                                    className='p-0 my-2'
                                    to={`/map?long=${projectCenter.longitude}&lat=${projectCenter.latitude}`}
                                >
                                    {t('project-view-go-to-map-btn')}
                                </LinkButton>

                                <UserMapView
                                    width='100%'
                                    height='300px'
                                    startViewport={{
                                        longitude: projectCenter.longitude,
                                        latitude: projectCenter.latitude,
                                        zoom: 10.0,
                                    }}
                                    onMount={onMapMounted}
                                    interactionEnabled={false}
                                />
                            </Col>
                        </Row>
                    )}

                <ImageSlider images={projectModel.images} />

                <Section title={t('project-view-description')}>
                    <p style={{ wordWrap: 'break-word' }}>
                        {projectModel && '' !== projectModel.description
                            ? projectModel.description
                            : t('project-view-description-empty')}
                    </p>
                </Section>

                {projectModel.projectFinancialData && (
                    <Section title={t('project-view-financial-data')}>
                        {FINANCIAL_FIELDS.filter(
                            (field) =>
                                !!projectModel.projectFinancialData[field.name]
                        )
                            .map((field) => ({
                                ...field,
                                price:
                                    projectModel.projectFinancialData[
                                        field.name
                                    ],
                            }))
                            .map((field) => (
                                <p key={field.name}>
                                    {t(field.label) +
                                        ' - ' +
                                        `${parseFloat(
                                            field.price.price
                                        ).toFixed(field.precision || 2)} ${
                                            field.price.currency
                                        }`}
                                </p>
                            ))}
                        {projectModel.projectFinancialData.investorMargin && (
                            <p>
                                {t(
                                    'project-wizard-info-financial-investor-margin'
                                ) +
                                    ` - ${projectModel.projectFinancialData.investorMargin.toFixed(
                                        2
                                    )} %`}
                            </p>
                        )}
                    </Section>
                )}

                <Section title={t('project-view-phases')}>
                    <ProjectPhasesPanel project={projectModel} />
                </Section>

                <Section title={t('project-view-investment')}>
                    <ProjectInvestmentPanel project={projectModel} />
                </Section>

                <Section
                    title={`${t('project-view-segments')} ${
                        projectModel.segments
                            ? '(' + projectModel.segments.length + ')'
                            : ''
                    }`}
                >
                    <PageSectionPagination
                        fetchAction={(offset, limit) =>
                            Actions.projects.segmentsTableFetch(
                                offset,
                                limit,
                                projectId
                            )
                        }
                        table={ProjectSegmentsTable}
                        scope={['projects', 'project', 'segments']}
                    />
                </Section>

                <Section
                    title={`${t('project-view-documents')}  ${
                        projectModel.documents
                            ? '(' + projectModel.documents.length + ')'
                            : ''
                    }`}
                >
                    <ListGroup className='ml-4'>
                        {projectModel.documents &&
                            projectModel.documents.map((doc) => (
                                <ListGroup.Item
                                    className='list-group-item'
                                    key={doc.id}
                                >
                                    <Row className='align-items-center justify-content-between px-2'>
                                        <p className='align-self-center m-0'>
                                            <FontAwesomeIcon
                                                className='mr-2'
                                                icon='file'
                                            />
                                            {doc.filename}
                                        </p>
                                        <a
                                            type='button'
                                            className='btn btn-link'
                                            download={doc.filename}
                                            href={doc.downloadLink}
                                        >
                                            {t(
                                                'project-view-documents-download-btn'
                                            )}
                                        </a>
                                    </Row>
                                </ListGroup.Item>
                            ))}
                    </ListGroup>
                </Section>
            </PageLayout>
        </BasePage>
    );
};

export { ViewProjectPage };
