import React, { useEffect, useState, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ReactMapGL, { Layer, Source, StaticMap } from 'react-map-gl';

import Actions from 'actions';

const collectItemsToFeatures = (items) =>
    items
        .filter((item) => !!item.typeId)
        .reduce((source, item) => {
            if (!source[item.typeId]) {
                source[item.typeId] = {
                    type: 'FeatureCollection',
                    features: [],
                };
            }

            source[item.typeId].features.push(item.geoJson);

            return source;
        }, {});

const updateItemsCache = (cached, loaded) => {
    // Remove old segments
    const existing = cached.filter(
        (item) => 0 <= loaded.findIndex((i) => i.id === item.id)
    );

    // Remove already existing segments
    const addItems = loaded.filter(
        (item) => 0 > existing.findIndex((s) => s.id === item.id)
    );

    if (0 === addItems.length && 0 < loaded.length) {
        return [false, existing];
    }

    return [true, existing.concat(addItems)];
};

const updateViewportBounds = (boundsList, current) => {
    let extendedCurrent = current;
    let resultBoundsList = boundsList;
    let hasIntersections = false;
    for (let i in boundsList) {
        const hasNW = boundsList[i].contains(current.getNorthWest());
        const hasSE = boundsList[i].contains(current.getSouthEast());

        if (hasNW && hasSE) {
            return [false, boundsList, current];
        } else if (hasNW || hasSE) {
            if (!hasIntersections) {
                hasIntersections = true;
                resultBoundsList = Array.from(boundsList);
            }

            resultBoundsList[i] = resultBoundsList[i].extend(current);
            extendedCurrent = extendedCurrent.extend(resultBoundsList[i]);
        }
    }

    if (!hasIntersections) {
        resultBoundsList.push(current);
    }

    return [true, resultBoundsList, extendedCurrent];
};

const InternalMapView = React.forwardRef(
    ({ interactive, children, ...rest }, ref) => {
        return interactive ? (
            <ReactMapGL ref={ref} {...rest}>
                {children}
            </ReactMapGL>
        ) : (
            <StaticMap ref={ref} {...rest}>
                {children}
            </StaticMap>
        );
    }
);

const UserMapView = ({
    className,
    width,
    height,
    style,
    selectedSegmentTypes,
    startViewport,
    interactionEnabled = true,
    minLoadZoom = 1,
    selectedSegments = [],
    selectedAreas = [],
    onCenterChange = () => {},
    onZoomChange = () => {},
    onUserViewportChange = () => {},
    onMount = () => {},
    onClick = () => {},
}) => {
    const dispatch = useDispatch();

    const segmentTypes = useSelector((state) => state.app.segmentTypes);
    const areaTypes = useSelector((state) => state.app.areaTypes);

    const loadedSegmentsList = useSelector(
        (state) => state.projects.mapProjects.model.segments
    );

    const loadedAreasList = useSelector(
        (state) => state.projects.mapProjects.model.areas
    );

    const loadedSegments = loadedSegmentsList.allIds.map(
        (id) => loadedSegmentsList.byId[id]
    );

    const loadedAreas = loadedAreasList.allIds.map(
        (id) => loadedAreasList.byId[id]
    );

    const [segments, setSegments] = useState([]);
    const [areas, setAreas] = useState([]);
    const [segmentSources, setSegmentSources] = useState({});
    const [areaSources, setAreaSources] = useState({});

    useEffect(() => {
        const [updated, cachedSegments] = updateItemsCache(
            segments,
            loadedSegments
        );

        if (updated) {
            setSegments(cachedSegments);
        }
    }, [loadedSegmentsList]);

    useEffect(() => {
        const [updated, cachedAreas] = updateItemsCache(areas, loadedAreas);

        if (updated) {
            setAreas(cachedAreas);
        }
    }, [loadedAreasList]);

    useEffect(() => {
        setSegmentSources(collectItemsToFeatures(segments));
    }, [segments]);

    useEffect(() => {
        setAreaSources(collectItemsToFeatures(areas));
    }, [areas]);

    const [map, setMap] = useState(null);
    const mapRef = useCallback(
        (map) => {
            if (map) {
                const instance = map.getMap();

                instance.getCanvas().style.cursor = 'default';

                setMap(instance);
                onMount(instance);
            }
        },
        [onMount]
    );

    const [boundsList, setBoundsList] = useState([]);

    const [viewport, setViewport] = useState(startViewport);

    const updateBounds = useCallback(
        (boundsList) => {
            if (!map || minLoadZoom > map.getZoom()) {
                return;
            }

            const [updated, bounds, current] = updateViewportBounds(
                boundsList,
                map.getBounds()
            );
            if (!updated) {
                return;
            }

            setBoundsList(bounds);

            const currentArray = current.toArray();

            dispatch(
                Actions.projects.mapProjects.fetch({
                    sw: {
                        latitude: currentArray[0][1],
                        longitude: currentArray[0][0],
                    },
                    ne: {
                        latitude: currentArray[1][1],
                        longitude: currentArray[1][0],
                    },
                })
            );
        },
        [map]
    );

    useEffect(() => {
        setViewport(startViewport);
        if (map) {
            map.once('moveend', () => {
                updateBounds(boundsList);
            });

            updateBounds(boundsList);
        }
    }, [startViewport, map, updateBounds]);

    const onMouseUp = () => {
        onCenterChange([viewport.longitude, viewport.latitude]);
        onUserViewportChange(
            [viewport.longitude, viewport.latitude],
            map.getZoom()
        );
        updateBounds(boundsList);
    };

    const onViewportChanged = (newViewport) => {
        if (newViewport.zoom !== viewport.zoom) {
            onZoomChange(newViewport.zoom);
            onUserViewportChange(
                [newViewport.longitude, newViewport.latitude],
                newViewport.zoom
            );
        }
        setViewport(newViewport);
    };
    return (
        <InternalMapView
            ref={mapRef}
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            className={className}
            width={width}
            height={height}
            style={{ ...style }}
            mapStyle='mapbox://styles/mapbox/dark-v10'
            {...viewport}
            onViewportChange={onViewportChanged}
            onMouseUp={onMouseUp}
            onWheel={() => interactionEnabled && updateBounds(boundsList)}
            onClick={onClick}
            getCursor={() => 'default'}
            clickRadius={10}
            interactive={interactionEnabled}
        >
            <Source
                id={'mapbox-roads'}
                type={'vector'}
                url={'mapbox://mapbox.mapbox-streets-v8'}
            />
            <Layer
                id={'mapbox-roads-layer'}
                source={'mapbox-roads'}
                source-layer={'road'}
                type={'line'}
                paint={{
                    'line-color': '#787878',
                }}
                minzoom={12}
                maxzoom={24}
            />

            {Object.keys(segmentSources).map((segmentTypeId) => (
                <Source
                    key={segmentTypeId}
                    id={`ss-${segmentTypeId}`}
                    type='geojson'
                    data={segmentSources[segmentTypeId]}
                />
            ))}

            {Object.keys(areaSources).map((areaTypeId) => (
                <Source
                    key={areaTypeId}
                    id={`ss-${areaTypeId}`}
                    type='geojson'
                    data={areaSources[areaTypeId]}
                />
            ))}

            {Object.keys(segmentSources)
                .filter(
                    (segmentTypeId) =>
                        !selectedSegmentTypes ||
                        selectedSegmentTypes.includes(segmentTypeId)
                )
                .map((segmentTypeId) => (
                    <Layer
                        key={segmentTypeId}
                        id={`sl-${segmentTypeId}`}
                        type='line'
                        source={`ss-${segmentTypeId}`}
                        paint={{
                            'line-color':
                                segmentTypes.byId[segmentTypeId].color ||
                                'white',
                            'line-width': 3,
                        }}
                        minzoom={12}
                        maxzoom={24}
                    />
                ))}

            {Object.keys(segmentSources)
                .filter(
                    (segmentTypeId) =>
                        !selectedSegmentTypes ||
                        selectedSegmentTypes.includes(segmentTypeId)
                )
                .map((segmentTypeId) => (
                    <Layer
                        key={segmentTypeId}
                        id={`fsl-${segmentTypeId}`}
                        type='line'
                        source={`ss-${segmentTypeId}`}
                        paint={{
                            'line-color': '#266773',
                            'line-width': 6,
                        }}
                        filter={[
                            'in',
                            ['get', 'id', ['get', 'segment']],
                            ['literal', selectedSegments],
                        ]}
                        minzoom={12}
                        maxzoom={24}
                    />
                ))}

            {Object.keys(areaSources).map((areaTypeId) => (
                <Layer
                    key={areaTypeId}
                    id={`sl-${areaTypeId}`}
                    type='fill'
                    source={`ss-${areaTypeId}`}
                    paint={{
                        'fill-color':
                            areaTypes.byId[areaTypeId].color || 'white',
                        'fill-opacity': 0.6,
                    }}
                    minzoom={12}
                    maxzoom={24}
                />
            ))}
            {Object.keys(areaSources).map((areaTypeId) => (
                <Layer
                    key={areaTypeId}
                    id={`fsl-${areaTypeId}`}
                    type='fill'
                    source={`ss-${areaTypeId}`}
                    paint={{
                        'fill-color': '#266773',
                        'fill-opacity': 1.0,
                    }}
                    filter={[
                        'in',
                        ['get', 'id', ['get', 'area']],
                        ['literal', selectedAreas],
                    ]}
                    minzoom={12}
                    maxzoom={24}
                />
            ))}
        </InternalMapView>
    );
};

export { UserMapView };
