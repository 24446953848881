// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Row, Col } from 'react-bootstrap';

import { BasePage } from 'components/pages/BasePage';
import { RegisterForm } from 'components/forms/RegisterForm';
import { useTranslation } from 'react-i18next';

import SinfraLogoIcon from 'assets/gendigitlogo.svg';

const RegisterPage = () => {
    const { t } = useTranslation();

    return (
        <BasePage>
            <Row className='p-4 pr-4 justify-content-center'>
                <Col className=' p-3' sm={4} xs={4} md={4} lg={4}>
                    <Row className='justify-content-center'>
                        <img
                            src={SinfraLogoIcon}
                            width='200px'
                            height='125px'
                            alt=''
                            className='d-inline-block align-center'
                        />
                    </Row>
                    <Row className='mt-2 mb-2 justify-content-center'>
                        <h4 className='text-center ml-4'>
                            {t('register-form-register-title')}
                        </h4>
                    </Row>
                    <RegisterForm />
                </Col>
            </Row>
        </BasePage>
    );
};

export { RegisterPage };
