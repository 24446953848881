// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { CommonAlert } from 'components/common/CommonAlert';
import { LinkButton } from 'components/common/LinkButton';
import { BaseForm } from 'components/forms/BaseForm';
import Actions from 'actions';
import { useTranslation } from 'react-i18next';

const LoginForm = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const loginProcess = useSelector((state) => state.account.login);
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = (data) => {
        dispatch(Actions.account.login(data.username, data.password));
    };

    return (
        <BaseForm onSubmit={handleSubmit(onSubmit)}>
            <CommonAlert scope={['account', 'login']} />
            <Form.Group>
                <Form.Label>{t('login-form-username')}</Form.Label>
                <Form.Control
                    type='text'
                    placeholder={t('login-form-enter-username-placeholder')}
                    name='username'
                    ref={register({ required: true })}
                    isInvalid={errors.username}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>{t('login-form-password')}</Form.Label>
                <Form.Control
                    type='password'
                    placeholder={t('login-form-enter-password-placeholder')}
                    name='password'
                    ref={register({ required: true })}
                    isInvalid={errors.password}
                />
            </Form.Group>

            <Row className={'align-items-center'}>
                <Col md='auto'>
                    <Button
                        variant='primary'
                        type='submit'
                        disabled={loginProcess.pending}
                    >
                        {t('login-form-login-btn')}
                    </Button>

                    <LinkButton to='/registration'>
                        {' '}
                        {t('login-form-register-btn')}
                    </LinkButton>
                </Col>
                <Col>
                    <LinkButton size={'sm'} to='/forgot-password'>
                        Forgot password?
                    </LinkButton>
                </Col>
            </Row>
        </BaseForm>
    );
};

export { LoginForm };
