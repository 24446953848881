// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { combineReducers } from '@reduxjs/toolkit';

import Actions from 'actions';

import { makeAlertReducer } from './alert.reducer';
import { makeFetchReducer } from './fetch.reducer';
import { makeModelReducer } from './model.reducer';
import { addToPageReducer, makePageReducer, removeFromPageReducer, updateToPageReducer } from './page.reducer';
import { makeProcessReducer } from './process.reducer';

export default combineReducers({
    table: combineReducers({
        alert: makeAlertReducer(Actions.requests.TABLE_ACTION),
        process: makeProcessReducer(Actions.requests.TABLE_ACTION),
        page: makePageReducer(Actions.requests.TABLE_ACTION, {
            [Actions.requests.createRequest.success]: addToPageReducer,
            [Actions.requests.approveRequest.success]: updateToPageReducer,
            [Actions.requests.declineRequest.success]: updateToPageReducer,
            [Actions.requests.cancelRequest.success]: removeFromPageReducer,
        }),
    }),
    createRequest: makeFetchReducer(Actions.requests.CREATE_ACTION, makeModelReducer),
    approveRequest: makeFetchReducer(Actions.requests.APPROVE_ACTION, makeModelReducer),
    declineRequest: makeFetchReducer(Actions.requests.DECLINE_ACTION, makeModelReducer),
    cancelRequest: makeFetchReducer(Actions.requests.CANCEL_ACTION, makeModelReducer),
});
