// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useEffect, useState, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Row, Carousel, ListGroup, Col, Alert, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { push as routerPush } from 'connected-react-router';

import { PageHeader } from 'components/common/PageHeader';
import { PageLayout } from 'components/common/PageLayout';
import { PageLoader } from 'components/common/PageLoader';
import { PageSection } from 'components/common/PageSection';
import { LinkButton } from 'components/common/LinkButton';

import { BasePage } from 'components/pages/BasePage';

import { useTranslation } from 'react-i18next';

import Actions from 'actions';
import { InvestmentsTable } from 'components/tables/InvestmentsTable';
import { PageSectionPagination } from 'components/common/PageSectionPagination';
import { useListReducer } from 'helpers/hooks';

import {
    Container,
    Form,
    Button,
    InputGroup,
    Toast,
    Breadcrumb,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FilePicker } from 'components/common/FilePicker';

const MessageInput = ({
    className,
    projectId,
    topicId,
    onMessageSend = () => {},
}) => {
    const dispatch = useDispatch();

    const sendMessage = useSelector((state) => state.forum.sendMessage);

    const { register, handleSubmit, reset } = useForm();
    const { t } = useTranslation();
    const [file, setFile] = useState(null);

    const onFileChanged = (file) => {
        setFile(file);
    };

    const onSubmit = (data) => {
        dispatch(
            Actions.forum.sendMessage.execute(
                projectId,
                topicId,
                data.message,
                file
            )
        );

        reset();
    };

    useEffect(() => {
        if (sendMessage.model) {
            onMessageSend();
            setFile(null);
        }
    }, [sendMessage.model]);

    return (
        <Container fluid className='w-100 p-4'>
            <Row className='justify-content-center'>
                <Form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{
                        width: '80vh',
                    }}
                >
                    <InputGroup>
                        <Form.Control
                            placeholder={t('topic-message-field-placeholder')}
                            as='textarea'
                            name='message'
                            ref={register({ required: !file })}
                        />
                        <InputGroup.Append>
                            <Button
                                variant='primary'
                                type='submit'
                                disabled={sendMessage.process.pending}
                            >
                                {t('topic-message-send-btn')}
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                    {/* <FilePicker className='mt-2' clearFile={!file} onFileChange={onFileChanged} /> */}
                </Form>
            </Row>
        </Container>
    );
};

const Message = ({ user, message, projectId, topicId }) => {
    const isUserMessage = user.id === message.senderId;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const onClose = () => {
        dispatch(
            Actions.forum.deleteMessage.execute(projectId, topicId, message.id)
        );
    };

    return (
        <Row className='w-100 p-1'>
            {isUserMessage && <Col xs={7}></Col>}
            <Col xs={5}>
                <Toast
                    bg={isUserMessage ? 'primary' : 'Light'}
                    onClose={onClose}
                >
                    <Toast.Header className='' closeButton={isUserMessage}>
                        <strong className='mr-auto'>
                            {isUserMessage
                                ? t('topic-message-you')
                                : `${message.user.firstName} ${message.user.lastName ?? ''}`}
                        </strong>
                        <small className=''>
                            {new Date(message.createdAt).toLocaleString()}
                        </small>
                    </Toast.Header>
                    <Toast.Body
                        className=''
                        style={{
                            display: 'inline-block',
                        }}
                    >
                        {message.message}
                    </Toast.Body>
                </Toast>
            </Col>
            {!isUserMessage && <Col xs={7}></Col>}
        </Row>
    );
};
const TopicPage = ({ match }) => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const user = useSelector((state) => state.account.user);

    const projectModelProcess = useSelector(
        (state) => state.projects.project.info.process.pending
    );

    const projectModel = useSelector(
        (state) => state.projects.project.info.model
    );
    const messagesEndRef = useRef();
    const [prevMessageCount, setPrevMessageCount] = useState(0);
    const messagesLists = useSelector((state) => state.forum.messagesList);
    const messages = useListReducer(
        (state) => state.forum.messagesList.model
    ).reverse();

    const projectId = match.params.id;
    const topicId = match.params.topicId;

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const onMessageSend = () => {
        scrollToBottom();
    };

    useEffect(() => {
        dispatch(Actions.forum.messagesList.reset());
        dispatch(Actions.projects.infoModel.fetch(projectId));
        dispatch(Actions.forum.messagesList.fetch(projectId, topicId));
    }, [projectId, topicId, dispatch]);

    useEffect(() => {
        if (prevMessageCount !== messages.length) {
            setPrevMessageCount(messages.length);
            scrollToBottom();
        }
    }, [messages.length]);

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(Actions.forum.messagesList.fetch(projectId, topicId));
        }, 5000);

        return () => clearInterval(interval);
    }, [dispatch]);

    if (!projectModel || projectModelProcess.pending) {
        return <PageLoader />;
    }

    return (
        <BasePage className='d-flex flex-column flex-fill  h-100'>
            <div className='d-flex flex-column flex-fill h-100 p-4 '>
                <PageHeader
                    className='d-flex p-2 mb-4'
                    title={t('topic-title')}
                >
                    <LinkButton
                        variant='secondary'
                        to={`/projects/${projectId}/forum`}
                        size='sm'
                    >
                        {t('topics-back-to-forum')}
                    </LinkButton>
                </PageHeader>
                <div
                    className='d-flex flex-column h-85 '
                    style={{
                        overflowY: 'scroll',
                        flex: '1 1 85%',
                        maxHeight: '70vh',
                    }}
                >
                    {messages.map((message) => (
                        <Message
                            key={message.id}
                            message={message}
                            user={user}
                            projectId={projectId}
                            topicId={topicId}
                        />
                    ))}
                    <div ref={messagesEndRef} />
                </div>
                <div
                    className='d-flex h-100 border'
                    style={{
                        overflowY: 'auto',
                        flex: '1 1 15%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <MessageInput
                        projectId={projectId}
                        topicId={topicId}
                        onMessageSend={onMessageSend}
                    />
                </div>
            </div>
        </BasePage>
    );
};

export { TopicPage };
