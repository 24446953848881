// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { select, take, call, put, takeLatest } from 'redux-saga/effects';

import Actions from 'actions';

export function* selectAuth() {
    const user = yield select((state) => state.account.user);
    if (!user || !user.isLogged) {
        return null;
    }
    return {
        token: user && user.accessToken,
        refreshToken: user && user.refreshToken,
        id: user && user.id,
        permissions: user && user.permissions,
        email: user && user.email,
        username: user && user.username,
        isRefresh: user && user.isRefresh,
        expiredAt: (user && user.expiredAt) || 0,
    };
}

export function* takeAuth() {
    let user = null;
    while (!user) {
        user = yield selectAuth();

        if (!user) {
            yield take([
                Actions.account.loginSuccces.toString(),
                Actions.account.refreshSuccess.toString(),
            ]);
        } else {
            if (Date.now() > user.expiredAt) {
                if (!user.isRefresh) {
                    yield put(Actions.account.refresh(user.refreshToken));
                }
                yield take([Actions.account.refreshSuccess.toString()]);
                user = null;
            }
        }
    }

    return user;
}

export function* loadAllPages(api, ...params) {
    let offset = 0;
    let limit = 512;

    let count = 0;
    let data = [];

    while (true) {
        const page = yield call(api, ...params, offset, limit);

        if (Array.isArray(page)) {
            count = page.length;
            data = page;
            break;
        }

        offset += limit;
        count = page.count;

        if (0 === count) {
            break;
        }

        data = data.concat(page.data);

        if (count <= offset) {
            break;
        }
    }

    return { count, data };
}

export function takeTable(action, api) {
    return takeFetch(action, api, function* (api, auth, payload) {
        const result = yield call(api, auth, payload.offset, payload.limit);
        return [result.count, result.data];
    });
}

export function takeList(action, api, fetch) {
    return takeFetch(action, api, fetch);
}

export function takeFetch(
    action,
    api,
    fetchCall = function* (api, auth, payload) {
        return [...(yield call(api, auth, ...payload))];
    },
    getArgs = (payload) => payload
) {
    return takeLatest(action.fetch, function* ({ payload }) {
        try {
            const auth = yield takeAuth();

            yield put(action.start());

            const result = yield fetchCall(api, auth, getArgs(payload));

            yield put(action.success(...result));
        } catch (error) {
            console.log(error);
            yield put(action.show('Fetch failed', error.message));
            yield put(action.failure(error));
        } finally {
            yield put(action.stop());
        }
    });
}
