// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { makeListActions } from './list.actions';

import { makeRequestActions } from './common';
import { makeFetchActions } from './fetch.actions';

export const EDIT_PHASE_ACTION = 'investment/phase/edit';
export const DELETE_PHASE_ACTION = 'investment/phase/delete';
export const INVEST_NOW_ACTION = 'investment/invest/now';
export const APPROVE_INVEST_ACTION = 'investment/invest/approve';
export const DECLINE_INVEST_ACTION = 'investment/invest/decline';
export const CANCEL_INVEST_ACTION = 'investment/invest/cancel';
export const INVESTMENTS_LIST_ACTION = 'investment/list';
export const INVESTMENTS_OVERVIEW_ACTION = 'investment/overview';

export const list = makeFetchActions(
    INVESTMENTS_LIST_ACTION,
    {
        fetch: {
            request: () => ({
                payload: {},
            }),
        },
    },
    makeListActions
);

export const overview = makeFetchActions(
    INVESTMENTS_OVERVIEW_ACTION,
    {
        fetch: {
            request: (projectId) => ({
                payload: { projectId },
            }),
            success: (model) => ({ payload: model }),
        },
    },
    makeRequestActions
);

export const investNow = makeFetchActions(
    INVEST_NOW_ACTION,
    {
        fetch: {
            request: (projectId, investment) => ({
                payload: {
                    projectId,
                    investment,
                },
            }),
            success: (projectId) => ({
                payload: {
                    projectId,
                },
            }),
        },
    },
    makeRequestActions
);

export const approveInvest = makeFetchActions(
    APPROVE_INVEST_ACTION,
    {
        fetch: {
            request: (projectId, investmentId) => ({
                payload: {
                    projectId,
                    investmentId,
                },
            }),
            success: (projectId, investmentId) => ({
                payload: {
                    projectId,
                    investmentId,
                },
            }),
        },
    },
    makeRequestActions
);

export const declineInvest = makeFetchActions(
    DECLINE_INVEST_ACTION,
    {
        fetch: {
            request: (projectId, investmentId) => ({
                payload: {
                    projectId,
                    investmentId,
                },
            }),
            success: (projectId, investmentId) => ({
                payload: {
                    projectId,
                    investmentId,
                },
            }),
        },
    },
    makeRequestActions
);

export const cancelInvest = makeFetchActions(
    CANCEL_INVEST_ACTION,
    {
        fetch: {
            request: (projectId, investmentId) => ({
                payload: {
                    projectId,
                    investmentId,
                },
            }),
            success: (projectId, investmentId) => ({
                payload: {
                    projectId,
                    investmentId,
                },
            }),
        },
    },
    makeRequestActions
);

export const editPhase = makeFetchActions(
    EDIT_PHASE_ACTION,
    {
        fetch: {
            request: (projectId, phaseId, phase) => ({
                payload: {
                    projectId,
                    phaseId,
                    phase,
                },
            }),
            success: (projectId, phaseId, phase, wasCreated) => ({
                payload: {
                    projectId,
                    phaseId,
                    phase,
                    wasCreated,
                },
            }),
        },
    },
    makeRequestActions
);

export const deletePhase = makeFetchActions(
    DELETE_PHASE_ACTION,
    {
        fetch: {
            request: (projectId, phaseId) => ({
                payload: {
                    projectId,
                    phaseId,
                },
            }),
            success: (projectId, phaseId) => ({
                payload: {
                    projectId,
                    phaseId,
                },
            }),
        },
    },
    makeRequestActions
);
