// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useEffect, useState } from 'react';

import { Button } from 'react-bootstrap';

const DialogButton = ({
    children,
    dialog,
    className,
    variant = 'link',
    onClose = () => {},
    onClick = () => {},
    onAccept = () => {},
    disabled,
    dialogParams = {},
    ...rest
}) => {
    const [state, setState] = useState({ visible: false });

    const handleClose = () => {
        setState({ visible: false });
        onClose();
    };

    const handleHide = () => {
        setState({ visible: false });
    };

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setState({ visible: true });
        onClick();
    };

    return (
        <React.Fragment>
            {dialog({
                ...dialogParams,
                backdrop: 'static',
                show: state.visible,
                onClose: handleClose,
                onHide: handleHide,
                onAccept: () => {
                    onAccept();
                },
            })}
            <Button
                className={`${className || ''}`}
                variant={variant}
                onClick={handleClick}
                disabled={disabled}
                {...rest}
            >
                {children}
            </Button>
        </React.Fragment>
    );
};

export { DialogButton };
