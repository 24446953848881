// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import {
    Container,
    Row,
    Col,
    Button,
    ListGroup,
    Badge,
    Card,
} from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { LinkButton } from 'components/common/LinkButton';
import { DialogButton } from 'components/common/DialogButton';

import { EditInvestmentPhaseDialog } from 'components/dialogs/EditInvestmentPhaseDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeleteButton } from 'components/common/DeleteButton';

import Actions from 'actions';

const PhaseCard = ({ phase, project, className }) => {
    const canEditPhases = project.userFeatures.canEditPhases;
    return (
        <Card className={`${className || ''} mt-2`}>
            <Card.Body>
                <Card.Title>
                    <Row className='justify-content-between px-2'>
                        <Col xs={6}>
                            <p>{phase.name}</p>
                        </Col>
                        <Col xs={6}>
                            <Row className='justify-content-end'>
                                <Badge variant='info'>
                                    {`${new Date(
                                        phase.dateFrom
                                    ).toDateString()} -> ${new Date(
                                        phase.dateTo
                                    ).toDateString()}`}
                                </Badge>
                                <Badge className='ml-2' variant='info'>
                                    {`${phase.priceData.price.toFixed(2)} ${
                                        phase.priceData.currency
                                    }`}
                                </Badge>
                            </Row>
                        </Col>
                    </Row>
                </Card.Title>
                <Card.Text style={{ whiteSpace: 'pre-wrap' }}>
                    {phase.description}
                </Card.Text>
            </Card.Body>
            {canEditPhases && (
                <Card.Footer>
                    <Row className='justify-content-end px-2'>
                        <DialogButton
                            className='mr-2'
                            dialog={EditInvestmentPhaseDialog}
                            variant='outline-dark'
                            size='sm'
                            dialogParams={{
                                project: project,
                                phase: phase,
                            }}
                        >
                            <FontAwesomeIcon icon='edit' />
                        </DialogButton>

                        <DeleteButton
                            callAction={() =>
                                Actions.investments.deletePhase.execute(
                                    project.id,
                                    phase.id
                                )
                            }
                        />
                    </Row>
                </Card.Footer>
            )}
        </Card>
    );
};

const ProjectPhasesPanel = ({ project }) => {
    const { t } = useTranslation();

    const user = useSelector((state) => state.account.user);
    const canEditPhases = project.userFeatures.canEditPhases;

    return (
        <Row>
            <Container>
                <Row className='justify-content-end mb-2 px-2'>
                    {canEditPhases && (
                        <DialogButton
                            dialog={EditInvestmentPhaseDialog}
                            variant='outline-dark'
                            dialogParams={{
                                project: project,
                                phase: null,
                            }}
                        >
                            {t('project-phase-panel-add-phase')}
                        </DialogButton>
                    )}
                </Row>
                <Row>
                    <Col>
                        {project.phases.map((phase) => (
                            <PhaseCard
                                key={phase.id}
                                phase={phase}
                                project={project}
                            />
                        ))}
                    </Col>
                </Row>
            </Container>
        </Row>
    );
};

export { ProjectPhasesPanel };
