// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { persistStorage } from 'services/persiststorage';

const USER_STORAGE_KEY = 'sinfra/user';
const USER_MAP_STORAGE_KEY = 'sinfra/user-map';

export const load = () => persistStorage.load(USER_STORAGE_KEY);

export const save = (user) => persistStorage.store(USER_STORAGE_KEY, user);
export const update = (user) => {
    const oldUser = load();
    console.log(user);
    if (oldUser) {
        save({ ...oldUser, ...user });
    } else {
        save(user);
    }
};

export const clear = () => persistStorage.remove(USER_STORAGE_KEY);

export const loadMap = () => persistStorage.load(USER_MAP_STORAGE_KEY);
export const saveMap = (map) => persistStorage.store(USER_MAP_STORAGE_KEY, map);
export const clearMap = () => persistStorage.remove(USER_MAP_STORAGE_KEY);
