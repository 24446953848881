// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import {
    getJsonRequest,
    postJsonRequest,
    putJsonRequest,
    withAuth,
    withOffsetLimit,
} from './common';

export const getUsers = (auth, offset, limit) =>
    getJsonRequest(
        withOffsetLimit(`/accounts/users`, offset, limit),
        withAuth(auth)
    );

export const getUser = (auth, id) =>
    getJsonRequest(`/accounts/users/${id}`, withAuth(auth));

export const getUserShort = (auth, id) =>
    getJsonRequest(`/accounts/users/${id}/short`, withAuth(auth));

export const editUser = (auth, id, user) =>
    putJsonRequest(
        `/accounts/users/${id}`,
        withAuth(auth, {
            body: JSON.stringify(user),
        })
    );

export const getUserPermissions = (auth) =>
    getJsonRequest(`/accounts/users/permissions`, withAuth(auth));

export const changePassword = (auth, userId, oldPassword, newPassword) =>
    putJsonRequest(
        `/accounts/users/${userId}/change-password`,
        withAuth(auth, {
            body: JSON.stringify({
                oldPassword: oldPassword,
                newPassword: newPassword,
            }),
        })
    );

export const createUser = (auth, user) =>
    postJsonRequest(
        `/accounts/users`,
        withAuth(auth, {
            body: JSON.stringify(user),
        })
    );

export const blockUser = (auth, userId) =>
    putJsonRequest(`/accounts/users/${userId}/block`, withAuth(auth));

export const changePermissions = (auth, userId, permissions) =>
    putJsonRequest(
        `/accounts/users/${userId}/change-permissions`,
        withAuth(auth, {
            body: JSON.stringify({
                permissions: permissions,
            }),
        })
    );
