// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Container, Row } from 'react-bootstrap';

import { NavigationBar } from 'components/navigation/NavigationBar';
import { PublicNavigationBar } from 'components/navigation/PublicNavigationBar';

import TokenManager from './TokenManager';
import AppRouter from './AppRouter';

const App = () => {
    const appState = useSelector((state) => state.app);
    const appUser = useSelector((state) => state.account.user);

    return (
        <Container
            fluid
            className={`app d-flex min-vh-100 p-0 m-0 flex-${
                appUser.isLogged ? 'row' : 'column' 
            } h-100`}
        >
            <TokenManager />

            <AppRouter
                navigationBar={
                    appUser.isLogged ? NavigationBar : PublicNavigationBar
                }
            />
        </Container>
    );
};

export default App;
