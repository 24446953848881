// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import {
    putJsonRequest,
    getJsonRequest,
    postJsonRequest,
    deleteRequest,
    postRequest,
    putRequest,
    withAuth,
    withOffsetLimit,
    withOwn,
} from './common';

export const createInvestmentPhase = (auth, projectId, phase) =>
    postJsonRequest(
        `/projects/${projectId}/phases`,
        withAuth(auth, {
            body: JSON.stringify({
                name: phase.name,
                orderNumber: phase.orderNumber,
                description: phase.description,
                priceData: {
                    price: phase.priceData.price,
                    currency: phase.priceData.currency,
                },
                dateFrom: phase.dateFrom,
                dateTo: phase.dateTo,
            }),
        }),
        (r) => r.json()
    );

export const editInvestmentPhase = (auth, projectId, phaseId, phase) =>
    putJsonRequest(
        `/projects/${projectId}/phases/${phaseId}`,
        withAuth(auth, {
            body: JSON.stringify({
                name: phase.name,
                orderNumber: phase.orderNumber,
                description: phase.description,
                priceData: {
                    price: phase.priceData.price,
                    currency: phase.priceData.currency,
                },
                dateFrom: phase.dateFrom,
                dateTo: phase.dateTo,
            }),
        }),
        (r) => r.json()
    );

export const deleteInvestmentPhase = (auth, projectId, phaseId) =>
    deleteRequest(`/projects/${projectId}/phases/${phaseId}`, withAuth(auth));

export const createInvestment = (auth, projectId, investment) =>
    postJsonRequest(
        `/projects/${projectId}/investments`,
        withAuth(auth, {
            body: JSON.stringify({
                description: investment.description,
                priceData: {
                    price: investment.priceData.price,
                    currency: investment.priceData.currency,
                },
            }),
        })
    );

export const approveInvestment = (auth, projectId, investmentId) =>
    putRequest(
        `/projects/${projectId}/investments/${investmentId}/approve`,
        withAuth(auth, {})
    );

export const declineInvestment = (auth, projectId, investmentId) =>
    deleteRequest(
        `/projects/${projectId}/investments/${investmentId}/decline`,
        withAuth(auth, {})
    );

export const cancelInvestment = (auth, projectId, investmentId) =>
    deleteRequest(
        `/projects/${projectId}/investments/${investmentId}/cancel`,
        withAuth(auth, {})
    );

export const getInvestments = (auth) =>
    getJsonRequest(`/projects/investments`, withAuth(auth));

export const getInvestmentOverview = (auth, projectId) =>
    getJsonRequest(
        `/projects/${projectId}/investments/overview`,
        withAuth(auth)
    );
