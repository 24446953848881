// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { createAction } from '@reduxjs/toolkit';

export const SYSTEM_INIT_ACTION = 'app/init/system';
export const USER_INIT_ACTION = 'app/init/user';

export const userInit = createAction(USER_INIT_ACTION, (init) => ({ payload: init }));
export const systemInit = createAction(SYSTEM_INIT_ACTION, (init) => ({ payload: init }));
