// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { createMapboxDraw } from './editor.draw';
import { createEditorController } from './editor.controller';
import {
    EDITOR_TOOL_MODE_CURSOR,
    EDITOR_TOOL_MODE_DRAW_SEGMENT,
    EDITOR_TOOL_MODE_DRAW_AREA,
    EDITOR_TOOL_ACTION_GROUP,
    EDITOR_TOOL_ACTION_UNGROUP,
    EDITOR_TOOL_ACTION_COMMENT,
    EDITOR_TOOL_ACTION_CREATE_SEGMENT,
    EDITOR_TOOL_ACTION_TRASH,
} from './editor.tools';

const createEditorInstance = ({
    startPoint = [0, 0],
    onMapLoaded = () => {},
    project = {},
    segmentTypes = { byId: {}, allIds: [] },
    areaTypes = { byId: {}, allIds: [] },
    t,
}) => {
    const instance = {
        project: project,
        map: null,
        draw: createMapboxDraw(),
        toolMenu: null,
        entitiesMenu: null,
        propertiesDialog: null,
        segmentTypes: segmentTypes,
        areaTypes: areaTypes,
        statusBar: null,
        createSegmentDialog: null,
        openPropertiesDialog: () => controller.openPropertiesDialog(),
    };

    const controller = createEditorController(instance);

    const paramsHelper = {
        startPoint,
    };

    const propertiesDialogHelper = {
        onPropertiesDialogInitialized: (propertiesDialog) => {
            instance.propertiesDialog = propertiesDialog;
        },
        onPropertiesDialogSaved: (data) => {
            controller.onPropertiesDialogSaved(data);
        },
    };

    const createSegmentDialogHelper = {
        onCreateSegmentDialogInitialized: (createSegmentDialog) => {
            instance.createSegmentDialog = createSegmentDialog;
        },
        onCreateSegmentDialogSaved: (points) => {
            controller.createSegmentFromPoints(points);
        },
    };

    const entitiesMenuHelper = {
        onEntitiesMenuInitialized: (entitiesMenu) => {
            instance.entitiesMenu = entitiesMenu;

            controller.onEntitiesMenuInitialized(entitiesMenu);
        },

        onEntitiesMenuClicked: (drawId) => {
            instance.draw.changeMode('simple_select', {
                featureIds: [drawId],
            });
            instance.entitiesMenu.select(drawId);
        },
    };

    const statusBarHelper = {
        onStatusBarInitialized: (statusBar) => {
            instance.statusBar = statusBar;
        },
    };

    const toolMenuHelper = {
        toolModes: [
            {
                name: EDITOR_TOOL_MODE_CURSOR,
                icon: 'mouse-pointer',
                tooltip: t('editor-tool-cursor-tip'),
            },
            {
                name: EDITOR_TOOL_MODE_DRAW_SEGMENT,
                icon: 'grip-lines',
                tooltip: t('editor-tool-draw-segment-tip'),
            },
            {
                name: EDITOR_TOOL_MODE_DRAW_AREA,
                icon: 'draw-polygon',
                tooltip: t('editor-tool-draw-area-tip'),
            },
        ],
        toolActions: [
            {
                name: EDITOR_TOOL_ACTION_GROUP,
                icon: 'object-group',
                tooltip: t('editor-tool-group-tip'),
            },
            {
                name: EDITOR_TOOL_ACTION_UNGROUP,
                icon: 'object-ungroup',
                tooltip: t('editor-tool-uncursor-tip'),
            },
            {
                name: EDITOR_TOOL_ACTION_COMMENT,
                icon: 'comment-alt',
                tooltip: t('editor-tool-properties-tip'),
            },
            {
                name: EDITOR_TOOL_ACTION_TRASH,
                icon: 'trash',
                tooltip: t('editor-tool-delete-tip'),
            },
            {
                name: EDITOR_TOOL_ACTION_CREATE_SEGMENT,
                icon: 'map-marker-alt',
                tooltip: t('editor-tool-create-by-points-tip'),
            },
        ],
        onToolMenuInitialized: (toolMenu) => {
            instance.toolMenu = toolMenu;
            instance.toolMenu.changeMode(EDITOR_TOOL_MODE_CURSOR);
        },
        onToolModeSelected: (mode) => {
            switch (mode) {
                case EDITOR_TOOL_MODE_CURSOR:
                    instance.draw.changeMode('simple_select');
                    break;
                case EDITOR_TOOL_MODE_DRAW_SEGMENT:
                    instance.draw.changeMode('draw_line_string');
                    break;
                case EDITOR_TOOL_MODE_DRAW_AREA:
                    instance.draw.changeMode('draw_polygon');
                    break;
                default:
                    throw new Error('Unknown mode');
            }
        },
        onToolActionCalled: (mode) => {
            switch (mode) {
                case EDITOR_TOOL_ACTION_TRASH:
                    controller.trashFeatures();
                    break;
                case EDITOR_TOOL_ACTION_COMMENT:
                    controller.openPropertiesDialog();
                    break;
                case EDITOR_TOOL_ACTION_CREATE_SEGMENT:
                    instance.createSegmentDialog.open({
                        lng: startPoint[0],
                        lat: startPoint[1],
                    });
                    break;
                case EDITOR_TOOL_ACTION_GROUP:
                    controller.groupFeatures();
                    break;
                case EDITOR_TOOL_ACTION_UNGROUP:
                    controller.ungroupFeatures();
                    break;
                default:
                    throw new Error('Unknown action');
            }
        },
    };

    const mapHelper = {
        draw: instance.draw,
        onLoad: (map) => {
            instance.map = map;

            onMapLoaded(instance.map, instance.draw);
            controller.onMapLoaded(instance.map, instance.draw);
            instance.draw.changeMode('simple_select');
        },
    };

    return {
        getInstance: () => instance,
        getSegments: () => controller.getSegments(),
        getAreas: () => controller.getAreas(),
        ...mapHelper,
        ...propertiesDialogHelper,
        ...createSegmentDialogHelper,
        ...statusBarHelper,
        ...toolMenuHelper,
        ...entitiesMenuHelper,
        ...paramsHelper,
    };
};

export { createEditorInstance };
