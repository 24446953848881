// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Row, Col } from 'react-bootstrap';

import { BasePage } from 'components/pages/BasePage';
import { ForgotPasswordForm } from 'components/forms/ForgotPasswordForm';

import SinfraLogoIcon from 'assets/gendigitlogo.svg';

const ForgotPasswordPage = () => (
    <BasePage>
        <Row className='justify-content-center'>
            <Col className='p-3 align-self-center' xs={5} md={5} lg={3}>
                <Row className='justify-content-center'>
                    <img
                        src={SinfraLogoIcon}
                        width='200px'
                        height='125px'
                        alt=''
                        className='d-inline-block align-center'
                    />
                </Row>
                <Row className='justify-content-center mb-2'>
                    <h2 className='text-center mt-2'>Forgot your password?</h2>
                    <h6 className='text-center mt-2'>
                        Just fill in your email and we will send you a new
                        password
                    </h6>
                </Row>
                <Row className='justify-content-center'>
                    <ForgotPasswordForm />
                </Row>
            </Col>
        </Row>
    </BasePage>
);

export { ForgotPasswordPage };
