// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { ProjectWizardStep } from 'components/wizard/ProjectWizardStep';

import { PageLayout } from 'components/common/PageLayout';
import { PageSection } from 'components/common/PageSection';
import { PageLoader } from 'components/common/PageLoader';
import { useTranslation } from 'react-i18next';

const LoadingProjectWizardStep = ({ className }) => {
    const { t } = useTranslation();
    return (
        <ProjectWizardStep className={`${className || ''} container p-4 `}>
            <PageLayout>
                <PageSection>
                    <p className='text-center mt-4 mb-4'>
                        {t('project-wizard-loading-please-wait')}
                    </p>
                    <PageLoader />
                </PageSection>
            </PageLayout>
        </ProjectWizardStep>
    );
};

export { LoadingProjectWizardStep };
