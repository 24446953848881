// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Row, Alert, Col } from 'react-bootstrap';

import { Pagination } from 'components/common/Pagination';
import { PageLoader } from 'components/common/PageLoader';
import { CommonAlert } from 'components/common/CommonAlert';
import { useTranslation } from 'react-i18next';

const PageSectionPagination = ({
    scope,
    table: Table,
    className,
    pageLimit = 15,
    startPage = 1,
    fetchAction,
}) => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [currentPage, setCurrentPage] = useState(startPage);

    useEffect(() => {
        dispatch(fetchAction((currentPage - 1) * pageLimit, pageLimit));
    }, [currentPage, dispatch, fetchAction, pageLimit]);

    const onPageChanged = (pageData) => {
        setCurrentPage(pageData.current);
    };

    const scopeModel = useSelector((state) =>
        scope.reduce((s, n) => s[n], state)
    );

    const modelPage = scopeModel.page;
    const modelProcess = scopeModel.process;
    const modelList = modelPage.allIds.map((id) => modelPage.byId[id]);

    return (
        <Row className={`${className || ''} p-0`}>
            <CommonAlert scope={scope} />
            {0 === modelPage.count ? (
                <Alert variant='light'>{t('no-items')}</Alert>
            ) : (
                <React.Fragment>
                    <Table model={modelList} />

                    <Col>
                        <Row className='justify-content-center'>
                            <Pagination
                                totalRecords={modelPage.count}
                                currentPage={currentPage}
                                pageLimit={pageLimit}
                                onPageChange={onPageChanged}
                            />
                        </Row>
                    </Col>
                </React.Fragment>
            )}
            {modelProcess && modelProcess.pending && <PageLoader />}
        </Row>
    );
};
export { PageSectionPagination };
