// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Row } from 'react-bootstrap';

const PageSectionHeader = ({ title, children, className }) => (
    <Row
        className={`${
            className || ''
        } justify-content-between align-items-center`}
    >
        <h5>{title}</h5>
        {children}
    </Row>
);

export { PageSectionHeader };
