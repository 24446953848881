import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseForm } from 'components/forms/BaseForm';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { CommonAlert } from 'components/common/CommonAlert';
import { Redirect } from 'react-router';

import Actions from 'actions';

function ForgotPasswordForm() {
    const dispatch = useDispatch();
    const { register, handleSubmit, errors } = useForm();

    const restorePasswordProcess = useSelector(
        (state) => state.account.restorePassword.process
    );

    if (restorePasswordProcess.process) {
        return <Redirect to='/login' />;
    }

    const onSubmit = (data) => {
        dispatch(Actions.account.restorePassword(data.email));
    };
    return (
        <BaseForm onSubmit={handleSubmit(onSubmit)}>
            <CommonAlert scope={['account', 'restorePassword']} />

            <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type='email'
                    placeholder='Enter email'
                    name='email'
                    ref={register({ required: true })}
                    isInvalid={errors.email}
                />
            </Form.Group>

            <Row className='justify-content-center'>
                <Button
                    variant='primary'
                    type='submit'
                    disabled={restorePasswordProcess.pending}
                >
                    Send confirmation
                </Button>
            </Row>
        </BaseForm>
    );
}

export { ForgotPasswordForm };
