// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { createReducer } from '@reduxjs/toolkit';

const initial = {
    count: 0,
    limit: 0,
    query: '',
    invalid: false,
    pending: false,
    data: [],
};

export const makeSearchReducer = scope =>
    createReducer(initial, {
        [`${scope}/search:request`]: (state, action) => ({
            ...state,
            invalid: false,
            pending: true,
            query: action.payload.query,
            limit: action.payload.limit,
        }),
        [`${scope}/search:success`]: (state, action) => ({
            ...state,
            count: action.payload.count,
            data: action.payload.data,
            pending: false,
        }),
        [`${scope}/search:failure`]: state => ({
            ...state,
            invalid: true,
            pending: false,
        }),
        [`${scope}/search:reset`]: () => initial,
    });
