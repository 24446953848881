// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { combineReducers, createReducer } from '@reduxjs/toolkit';

import Actions from 'actions';

import { makeAlertReducer } from './alert.reducer';
import { makePageReducer, removeFromPageReducer } from './page.reducer';
import { makeProcessReducer } from './process.reducer';
import { makeSearchReducer } from './search.reducer';
import {
    makeListReducer,
    addToListReducer,
    clearListReducer,
    initListReducer,
    updateToListReducer,
} from './list.reducer';
import { makeFetchReducer } from './fetch.reducer';
import { makeModelReducer } from './model.reducer';

const joinRequestListReducer = {
    [`${Actions.joinRequests.APPROVE_JOIN_REQUEST_ACTION}:success`]: (
        state,
        { payload }
    ) =>
        updateToListReducer(state, payload.joinRequestId, {
            status: 'APPROVED',
        }),
    [`${Actions.joinRequests.DECLINE_JOIN_REQUEST_ACTION}:success`]: (
        state,
        { payload }
    ) =>
        updateToListReducer(state, payload.joinRequestId, {
            status: 'DECLINED',
        }),
    [`${Actions.joinRequests.CANCEL_JOIN_REQUEST_ACTION}:success`]: (
        state,
        { payload }
    ) =>
        updateToListReducer(state, payload.joinRequestId, {
            status: 'CANCELED',
        }),
};

export default combineReducers({
    create: makeFetchReducer(
        Actions.joinRequests.CREATE_JOIN_REQUEST_ACTION,
        (scope) => makeModelReducer(scope, null)
    ),
    approve: makeFetchReducer(
        Actions.joinRequests.APPROVE_JOIN_REQUEST_ACTION,
        (scope) => makeModelReducer(scope, null)
    ),
    decline: makeFetchReducer(
        Actions.joinRequests.DECLINE_JOIN_REQUEST_ACTION,
        (scope) => makeModelReducer(scope, null)
    ),
    cancel: makeFetchReducer(
        Actions.joinRequests.CANCEL_JOIN_REQUEST_ACTION,
        (scope) => makeModelReducer(scope, null)
    ),
    list: makeFetchReducer(
        Actions.joinRequests.JOIN_REQUEST_LIST_ACTION,
        (scope) => makeListReducer(scope, joinRequestListReducer)
    ),
    listByProject: makeFetchReducer(
        Actions.joinRequests.PROJECT_JOIN_REQUEST_LIST_ACTION,
        (scope) => makeListReducer(scope, joinRequestListReducer)
    ),
});
