// Copyright © 2020 Embrox Solutions
// Copyright © 2020 Genconstrux GmbH

import React, { useEffect, useRef } from 'react';

import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const createMapPopup = (map, point, content, lngLat) => {
    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    if (lngLat) {
        while (Math.abs(lngLat.lng - point[0]) > 180) {
            point[0] += lngLat.lng > point[0] ? 360 : -360;
        }
    }

    return new mapboxgl.Popup().setLngLat(point).setHTML(content).addTo(map);
};

const MapView = ({
    className,
    width,
    height,
    draw = null,
    onLoad = () => {},
    style = {},
    initialCenter = [-122.486052, 37.830348],
    initialZoom = 15.0,
    mapStyle = 'mapbox://styles/mapbox/dark-v10',
}) => {
    const mapRef = useRef(null);

    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapRef.current,
            style: mapStyle,
            center: initialCenter,
            zoom: initialZoom,
        });

        draw && map.addControl(draw);

        map.on('load', () => {
            map.getCanvas().style.cursor = 'default';
            onLoad(map);

            map.addSource('test', {
                type: 'vector',
                url: 'mapbox://mapbox.mapbox-streets-v8',
            });

            map.addLayer({
                id: 'road',
                source: 'test',
                'source-layer': 'road',
                type: 'line',
                paint: {
                    'line-color': '#787878',
                },
            });
        });
    }, [draw, onLoad, initialCenter, initialZoom, mapStyle]);

    return (
        <div className={`${className || ''}`} style={{ ...style }} width={width} height={height}>
            <div ref={mapRef} style={{ width: '100%', height: '100%' }}></div>
        </div>
    );
};

export { MapView };
export { createMapPopup };
