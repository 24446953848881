import React from 'react';

import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Navbar, Nav, NavLink, Row, Col } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListGroup } from 'react-bootstrap';

import SinfraLogoIcon from 'assets/gendigitlogo.svg';
import Actions from 'actions';

import './navbar.scss';

const NavButton = ({ title, link, icon }) => (
    <h5 className='ml-2 mt-3'>
        <Link to={link} variant='secondary'>
            <FontAwesomeIcon
                icon={icon}
                size='sm'
                className='mr-2'
                fixedWidth
            />
            {`\t` + title}
        </Link>
    </h5>
);

const NavButtonList = ({ buttons, permissions }) => {
    const { t } = useTranslation();

    return (
        <ListGroup>
            {buttons.map((button) => {
                if (
                    0 >=
                    permissions.filter((p) => button.permissions.includes(p))
                        .length
                ) {
                    return null;
                }

                return (
                    <NavButton
                        title={t(button.title)}
                        link={button.link}
                        key={button.link}
                        icon={button.icon}
                    />
                );
            })}
        </ListGroup>
    );
};

function mainButtons(t, permissions = []) {
    return [
        {
            title: t('nav-home'),
            link: '/home',
            permissions: [
                'REAL_ESTATE_AGENCY',
                'CONSTRUCTION_COMPANY',
                'INVESTOR',
                'DEVELOPER',
                'ADMIN',
            ],
            icon: 'home',
        },
        {
            title: t('nav-map'),
            link: '/map',
            permissions: [
                'REAL_ESTATE_AGENCY',
                'CONSTRUCTION_COMPANY',
                'INVESTOR',
                'DEVELOPER',
                'ADMIN',
            ],
            icon: 'map',
        },
        {
            title: t('nav-requests'),
            link: '/requests',
            permissions: ['DEVELOPER', 'ADMIN'],
            icon: 'question-circle',
        },
        {
            title: permissions.includes('ADMIN')
                ? t('nav-projects')
                : t('nav-my-projects'),
            link: '/projects',
            permissions: [
                'REAL_ESTATE_AGENCY',
                'CONSTRUCTION_COMPANY',
                'INVESTOR',
                'DEVELOPER',
                'ADMIN',
            ],
            icon: 'project-diagram',
        },
        {
            title: t('nav-users'),
            link: '/users',
            permissions: ['ADMIN'],
            icon: 'user',
        },
        {
            title: t('nav-investments'),
            link: '/investments',
            permissions: ['INVESTOR', 'DEVELOPER', 'ADMIN'],
            icon: 'money-check',
        },
        {
            title: t('nav-join-requests'),
            link: '/join-requests',
            permissions: [
                'REAL_ESTATE_AGENCY',
                'CONSTRUCTION_COMPANY',
                'INVESTOR',
                'DEVELOPER',
                'USER',
                'ADMIN',
            ],
            icon: 'user',
        },
        {
            title: t('nav-settings'),
            link: '/settings',
            permissions: [
                'REAL_ESTATE_AGENCY',
                'CONSTRUCTION_COMPANY',
                'INVESTOR',
                'DEVELOPER',
                'ADMIN',
            ],
            icon: 'cog',
        },
    ];
}

const LogoHeader = () => (
    <Row className='align-items-center my-4 pl-4'>
        <img
            src={SinfraLogoIcon}
            width='70'
            height='auto'
            alt=''
            className='d-inline-block align-center mr-2'
        />
        {'GENDIGIT'}
    </Row>
);

const NavigationBar = () => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.account.user);
    const { t } = useTranslation();

    return (
        <Col className='p-0' xs={2}>
            <Col className='position-fixed h-100 pr-4 w-25' xs={2}>
                <LogoHeader />

                <NavButtonList
                    buttons={mainButtons(t)}
                    permissions={user.permissions}
                />
                <Col className='fixed-bottom' xs={2}>
                    <ListGroup className='ml-2'>
                        <h5>
                            <FontAwesomeIcon
                                icon='user'
                                size='sm'
                                className='mr-2'
                            />
                            {`${user.username} `}
                        </h5>
                        <h5 className='mt-2'>
                            <Link
                                to={'/logout'}
                                onClick={() =>
                                    dispatch(Actions.account.logout())
                                }
                            >
                                <FontAwesomeIcon
                                    icon='sign-out-alt'
                                    size='sm'
                                    className='mr-2'
                                />
                                Logout
                            </Link>
                        </h5>
                    </ListGroup>
                    <Row className='pl-3 mt-4'>
                        <Link className='mr-1' to='/data-policy'>
                            {'Privacy'}
                        </Link>
                        <p>{`|`}</p>
                        <Link className='ml-1' to='/terms-and-conditions'>
                            {'Terms'}
                        </Link>
                    </Row>
                    <Row className='pl-3'>
                        <p>{'© 2021, Genconstrux GmbH'}</p>
                    </Row>
                </Col>
            </Col>
        </Col>
    );
};

export { NavigationBar };
