// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
    Container,
    Row,
    Col,
    Button,
    ListGroup,
    Badge,
    Alert,
} from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { LinkButton } from 'components/common/LinkButton';
import { DialogButton } from 'components/common/DialogButton';
import { EditProjectInvestmentDialog } from 'components/dialogs/EditProjectInvestmentDialog';
import { EditInvestmentPhaseDialog } from 'components/dialogs/EditInvestmentPhaseDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeleteButton } from 'components/common/DeleteButton';

import Actions from 'actions';
import { InvestNowDialog } from 'components/dialogs/InvestNowDialog';
import { ProgressBar } from 'react-bootstrap';

function calcInvesmentProgress(overviewModel) {
    if (
        !overviewModel ||
        !overviewModel.projectFinancialData ||
        !overviewModel.projectFinancialData.totalProjectPrice
    ) {
        return null;
    }

    let investmentPriceSum = 0.0;
    for (let investment of overviewModel.projectInvestments) {
        investmentPriceSum += investment.priceData.price;
    }

    return {
        min: 0,
        max: overviewModel.projectFinancialData.totalProjectPrice.price,
        current: investmentPriceSum,
        currency: overviewModel.projectFinancialData.totalProjectPrice.currency,
    };
}

const ProjectInvestmentPanel = ({ project }) => {
    const user = useSelector((state) => state.account.user);
    const overviewModel = useSelector(
        (state) => state.investments.overview.model
    );
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const invesmentProgress = calcInvesmentProgress(overviewModel);

    const investmentEnabled = !!overviewModel;

    const isFinanced =
        investmentEnabled &&
        invesmentProgress &&
        invesmentProgress.current >= invesmentProgress.max;

    const canInvest = !isFinanced && project.userFeatures.canInvest;
    useEffect(() => {
        dispatch(Actions.investments.overview.fetch(project.id));
    }, [project]);

    if (!investmentEnabled) {
        return <Row></Row>;
    }

    return (
        <Row>
            <Container>
                <Row className='p-2'>
                    <Col md={12}>
                        {invesmentProgress && (
                            <p>
                                {t('invesment-panel-total-price') +
                                    `  ${invesmentProgress.max} ${invesmentProgress.currency}`}
                            </p>
                        )}
                        {invesmentProgress && (
                            <ProgressBar
                                variant='info'
                                min={invesmentProgress.min}
                                now={invesmentProgress.current}
                                max={invesmentProgress.max}
                                label={`${invesmentProgress.current} ${invesmentProgress.currency}`}
                            />
                        )}
                        {isFinanced && (
                            <Alert className='my-2' variant='success'>
                                {t('invesment-panel-financed')}
                            </Alert>
                        )}
                        <Row className='mt-4 justify-content-end'>
                            {!isFinanced && canInvest && (
                                <DialogButton
                                    dialog={InvestNowDialog}
                                    dialogParams={{
                                        project: project,
                                    }}
                                    variant={'primary'}
                                >
                                    {t('invest-now')}
                                </DialogButton>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Row>
    );
};

export { ProjectInvestmentPanel };
