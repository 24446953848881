// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useEffect } from 'react';

import { PageHeader } from 'components/common/PageHeader';
import { PageLayout } from 'components/common/PageLayout';

import { BasePage } from 'components/pages/BasePage';

import { ProjectWizard } from 'components/wizard/ProjectWizard';
import { LayersProjectWizardStep } from 'components/wizard/steps/LayersProjectWizardStep';
import { StartProjectWizardStep } from 'components/wizard/steps/StartProjectWizardStep';
import { EditorProjectWizardStep } from 'components/wizard/steps/EditorProjectWizardStep';
import { ImportProjectWizardStep } from 'components/wizard/steps/ImportProjectWizardStep';
import { InfoProjectWizardStep } from 'components/wizard/steps/InfoProjectWizardStep';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Actions from "actions"

const MemoEditorProjectWizardStep = React.memo(EditorProjectWizardStep);

const WIZARD_STEPS = [
    {
        id: 'start',
        title: 'Start',
        component: () => <StartProjectWizardStep />,
    },
    {
        id: 'info',
        title: 'Information',
        component: () => <InfoProjectWizardStep />,
    },
    {
        id: 'import',
        title: 'Importing',
        component: () => <ImportProjectWizardStep />,
    },
    {
        id: 'editor',
        title: 'Editor',
        component: () => <MemoEditorProjectWizardStep />,
    },
    {
        id: 'layers',
        title: 'Layers',
        component: () => <LayersProjectWizardStep />,
    },
];

const CreateProjectPage = ({ className }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(()=>{

        dispatch(Actions.projects.wizardViewStart());
        dispatch(Actions.projects.wizardMapSearchReset());

    }, [])
    return (
        <BasePage className={`${className || ''} flex-fill d-flex flex-column`}>
            <PageLayout>
                <PageHeader title={t('create-project-title')} />
            </PageLayout>
            <ProjectWizard className='flex-fill' steps={WIZARD_STEPS} />
        </BasePage>
    );
};

export { CreateProjectPage };
