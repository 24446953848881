// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useState, useEffect } from 'react';

import { ProgressBar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { ProjectWizardStep } from 'components/wizard/ProjectWizardStep';

import Actions from 'actions';
import { PageHeader } from 'components/common/PageHeader';
import { PageLayout } from 'components/common/PageLayout';
import { PageSection } from 'components/common/PageSection';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';

const ImportProjectWizardStep = ({ className }) => {
    return (
        <ProjectWizardStep className={`${className || ''} container p-4 `}>
            <ImportStatus />
        </ProjectWizardStep>
    );
};

const ImportStatus = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [pending, setPending] = useState(true);
    const [progress, setProgress] = useState(0);
    const [estimation, setEstimation] = useState(0);

    const projectId = useSelector(
        (state) => state.projects.wizard.view.projectId
    );

    const status = useSelector((state) => state.projects.wizard.status);

    useEffect(() => {
        if (projectId && '' !== projectId) {
            dispatch(Actions.projects.wizardImportStatus.reset());
            dispatch(Actions.projects.wizardImportStatus.execute(projectId));
        }
    }, [projectId, dispatch]);

    const completed = status.model && 'COMPLETED' === status.model.status;
    const inProgress = status.model && 'IN_PROGRESS' === status.model.status;

    if (pending) {
        if (completed) {
            setPending(false);

            dispatch(push(`/projects/${projectId}/edit`));
        }

        if (
            pending &&
            inProgress &&
            status.model.progress !== progress &&
            status.model.progress > progress
        ) {
            setProgress(status.model.progress);
        }

        if (
            pending &&
            inProgress &&
            status.model.estimation !== estimation &&
            (0 === estimation || status.model.estimation < estimation)
        ) {
            setEstimation(status.model.estimation);
        }
    }

    return (
        <PageLayout>
            <PageHeader
                title={t('project-wizard-importing-title')}
            ></PageHeader>
            <PageSection>
                {!completed && (
                    <ImportingState
                        estimation={estimation}
                        progress={progress}
                    />
                )}
            </PageSection>
        </PageLayout>
    );
};

const ImportingState = ({ estimation, progress }) => {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <p className='text-center mt-4 mb-4'>
                {t('project-wizard-importing-working-message')}
                {1 < estimation &&
                    ` ${t(
                        'project-wizard-importing-please-wait'
                    )}. ${estimation} ${t('project-wizard-importing-seconds')}.`}
                {1 === estimation &&
                    ` ${t('project-wizard-importing-almost-message')}.`}
            </p>
            <ProgressBar now={progress * 100.0} />
        </React.Fragment>
    );
};

export { ImportProjectWizardStep };
