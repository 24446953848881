// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { put, call, takeLatest, select, delay } from 'redux-saga/effects';

import { push as routerPush, push } from 'connected-react-router';

import { takeAuth, takeList, loadAllPages } from './common.saga';

import Api from 'api';
import Actions from 'actions';

export function* investmentsSaga() {
    yield takeLatest(Actions.investments.editPhase.execute, editPhase);
    yield takeLatest(Actions.investments.deletePhase.execute, deletePhase);
    yield takeLatest(Actions.investments.investNow.execute, investNow);
    yield takeLatest(Actions.investments.list.fetch, getInvestments);
    yield takeLatest(Actions.investments.overview.fetch, getOverview);
    yield takeLatest(Actions.investments.approveInvest.fetch, approveInvest);
    yield takeLatest(Actions.investments.declineInvest.fetch, declineInvest);
    yield takeLatest(Actions.investments.cancelInvest.fetch, cancelInvest);
}

function* getOverview({ payload }) {
    try {
        yield put(Actions.investments.overview.start());

        const auth = yield takeAuth();

        const model = yield call(
            Api.investments.getInvestmentOverview,
            auth,
            payload.projectId
        );

        yield put(Actions.investments.overview.success(model));
    } catch (error) {
        yield put(Actions.investments.overview.failure(error));
    } finally {
        yield put(Actions.investments.overview.stop());
    }
}

function* approveInvest({ payload }) {
    try {
        yield put(Actions.investments.approveInvest.start());

        const auth = yield takeAuth();

        yield call(
            Api.investments.approveInvestment,
            auth,
            payload.projectId,
            payload.investmentId
        );

        yield put(
            Actions.investments.approveInvest.success(
                payload.projectId,
                payload.investmentId
            )
        );
    } catch (error) {
        yield put(Actions.investments.approveInvest.failure(error));
    } finally {
        yield put(Actions.investments.approveInvest.stop());
    }
}

function* declineInvest({ payload }) {
    try {
        yield put(Actions.investments.declineInvest.start());

        const auth = yield takeAuth();

        yield call(
            Api.investments.declineInvestment,
            auth,
            payload.projectId,
            payload.investmentId
        );

        yield put(
            Actions.investments.declineInvest.success(
                payload.projectId,
                payload.investmentId
            )
        );
    } catch (error) {
        yield put(Actions.investments.declineInvest.failure(error));
    } finally {
        yield put(Actions.investments.declineInvest.stop());
    }
}

function* cancelInvest({ payload }) {
    try {
        yield put(Actions.investments.cancelInvest.start());

        const auth = yield takeAuth();

        yield call(
            Api.investments.cancelInvestment,
            auth,
            payload.projectId,
            payload.investmentId
        );

        yield put(
            Actions.investments.cancelInvest.success(
                payload.projectId,
                payload.investmentId
            )
        );
    } catch (error) {
        yield put(Actions.investments.cancelInvest.failure(error));
    } finally {
        yield put(Actions.investments.cancelInvest.stop());
    }
}

function* editPhase({ payload }) {
    try {
        yield put(Actions.investments.editPhase.start());

        const auth = yield takeAuth();

        let phase = null;
        let wasCreated = false;

        if (payload.phaseId) {
            phase = yield call(
                Api.investments.editInvestmentPhase,
                auth,
                payload.projectId,
                payload.phaseId,
                payload.phase
            );
        } else {
            phase = yield call(
                Api.investments.createInvestmentPhase,
                auth,
                payload.projectId,
                payload.phase
            );
            wasCreated = true;

            payload.phaseId = phase.id;
        }

        yield put(
            Actions.investments.editPhase.success(
                payload.projectId,
                payload.phaseId,
                phase,
                wasCreated
            )
        );
    } catch (error) {
        yield put(Actions.investments.editPhase.failure(error));
    } finally {
        yield put(Actions.investments.editPhase.stop());
    }
}

function* deletePhase({ payload }) {
    try {
        yield put(Actions.investments.deletePhase.start());

        const auth = yield takeAuth();

        yield call(
            Api.investments.deleteInvestmentPhase,
            auth,
            payload.projectId,
            payload.phaseId
        );

        yield put(
            Actions.investments.deletePhase.success(
                payload.projectId,
                payload.phaseId
            )
        );
    } catch (error) {
        yield put(Actions.investments.deletePhase.failure(error));
    } finally {
        yield put(Actions.investments.deletePhase.stop());
    }
}

function* investNow({ payload }) {
    try {
        yield put(Actions.investments.investNow.start());

        const auth = yield takeAuth();

        yield call(
            Api.investments.createInvestment,
            auth,
            payload.projectId,
            payload.investment
        );

        yield put(Actions.investments.investNow.success(payload.projectId));
    } catch (error) {
        yield put(Actions.investments.investNow.failure(error));
    } finally {
        yield put(Actions.investments.investNow.stop());
    }
}

function* updateInvestmentInfo(auth, investments) {
    const projects = {};
    const users = {};

    for (let investment of investments) {
        if (!projects[investment.projectId]) {
            projects[investment.projectId] = yield call(
                Api.projects.getProject,
                auth,
                investment.projectId
            );
        }

        investment.project = projects[investment.projectId];

        if (!users[investment.investorId]) {
            users[investment.investorId] = yield call(
                Api.users.getUserShort,
                auth,
                investment.investorId
            );
        }

        investment.user = users[investment.investorId];
    }

    return investments;
}

function* getInvestments() {
    try {
        yield put(Actions.investments.list.start());

        const auth = yield takeAuth();

        let list = yield call(Api.investments.getInvestments, auth);
        list = yield call(updateInvestmentInfo, auth, list);

        yield put(Actions.investments.list.success(list));
    } catch (error) {
        yield put(Actions.investments.list.failure(error));
    } finally {
        yield put(Actions.investments.list.stop());
    }
}
