// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useState, useEffect } from 'react';

import { Col, Row, Container } from 'react-bootstrap';
import { convertLatLngToUTM } from 'helpers/utm';

const StatusBar = ({ className, style, onInitialize = () => {} }) => {
    const [state, setState] = useState({
        lat: 0.0,
        lng: 0.0,
    });

    useEffect(() => {
        onInitialize({
            setCoordinates: (lng, lat) => setState({ ...state, lat, lng }),
        });
    });

    const utm = convertLatLngToUTM(state.lat, state.lng);

    return (
        <div className={`${className || ''} d-flex bg-light pr-4`} style={style}>
            <p>{`[${utm.easting.toFixed(3)};${utm.northing.toFixed(3)};${
                utm.zone
            }]`}</p>
        </div>
    );
};

export { StatusBar };
