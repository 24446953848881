// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Canvas } from 'react-three-fiber';

import * as THREE from 'three';

import { SegmentMesh } from 'components/section/SegmentMesh';
import { SkyMesh } from 'components/section/SkyMesh';
import { GroundMesh } from 'components/section/GroundMesh';
import { SectionControls } from 'components/section/SectionControls';

const mapViewCenter = (center, mapper) => {
    const r = mapper(center);
    return new THREE.Vector3(-r.x, 0.0, -r.y);
};

const SectionView = ({
    className,
    viewCenter,
    segments = [],
    style,
    segmentTypes,
    mapper = () => ({ x: 0, y: 0, z: 0 }),
}) => {

    return (
        <Canvas
            className={`${className || ''}`}
            style={{ ...style, backgroundColor: 'white' }}
            camera={{
                position: [0, 150, 0],
            }}
        >
            <ambientLight intensity={0.5} />
            <pointLight position={[0, 100, 0]} />
            <GroundMesh />
            <gridHelper args={[1000, 250]} />
            <SkyMesh scale={[10000.0, 10000.0, 10000.0]} />
            <SectionControls />

            <mesh scale={[0.35, 0.35, 0.35]}>
                {segments.map((segment) => (
                    <SegmentMesh
                        key={segment.id}
                        viewCenter={viewCenter}
                        model={segment}
                        mapper={mapper}
                        position={mapViewCenter(viewCenter, mapper)}
                        segmentTypes={segmentTypes}
                    />
                ))}
            </mesh>
        </Canvas>
    );
};

export { SectionView };
