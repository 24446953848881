// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { call } from 'redux-saga/effects';

import { takeTable, takeList, takeFetch } from './common.saga';

import Services from 'services';

import Api from 'api';
import Actions from 'actions';

export function* usersSage() {
    yield takeTable(Actions.users.table, Api.users.getUsers);
    yield takeList(Actions.users.permissions, Api.users.getUserPermissions);
    yield takeFetch(Actions.users.create, Api.users.createUser, createUser);
    yield takeFetch(Actions.users.block, Api.users.blockUser, blockUser);
    yield takeFetch(Actions.users.edit, Api.users.editUser, editUser);
}

function* blockUser(api, auth, payload) {
    const user = yield call(api, auth, payload);
    return [user];
}

function* editUser(api, auth, payload) {
    const user = yield call(api, auth, auth.id, {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email || auth.email,
        username: auth.username,
        coordinateSystem: payload.coordinateSystem || 'UTM',
        language: payload.language || 'de',
    });

    yield call(Services.user.update, payload);

    if (payload.newPassword && '' !== payload.newPassword) {
        yield call(
            Api.users.changePassword,
            auth,
            auth.id,
            payload.oldPassword,
            payload.newPassword
        );
    }

    return [user];
}

function* createUser(api, auth, payload) {
    const user = yield call(api, auth, payload);

    yield call(Api.users.changePermissions, auth, user.id, [payload.role]);

    return [user];
}
