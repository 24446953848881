// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const TableRow = ({ type }) => (
    <tr>
        <td>{type.name}</td>
        <td>
            <div
                style={{
                    backgroundColor: `${type.color}`,
                    width: '64px',
                    height: '24px',
                }}
            ></div>
        </td>
    </tr>
);

const TableHeader = () => {
    const { t } = useTranslation();
    return (
        <thead>
            <tr>
                <th>{t('segment-types-table-name-col')}</th>
                <th>{t('segment-types-table-color-col')}</th>
            </tr>
        </thead>
    );
};

const SegmentTypesTable = ({ model = [], forceUpdate }) => {
    return (
        <Table bordered hover>
            <TableHeader />
            <tbody>
                {model.map((type) => (
                    <TableRow
                        key={type.id}
                        type={type}
                        forceUpdate={forceUpdate}
                    />
                ))}
            </tbody>
        </Table>
    );
};

export { SegmentTypesTable };
