// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { useSelector } from 'react-redux';

import { Container, Row, Col, Carousel, Button } from 'react-bootstrap';
import { LinkButton } from 'components/common/LinkButton';

const AreaPreviewPanel = ({ className, areaId }) => {
    const areas = useSelector(
        (state) => state.projects.mapProjects.model.areas
    );

    const areaTypes = useSelector((state) => state.app.areaTypes);

    const projects = useSelector(
        (state) => state.projects.mapProjects.model.projects
    );

    if (!areaId || !areas) {
        return <></>;
    }
    const area = areas.byId[areaId];
    
    if (!area) {
        return <></>;
    }
    const areaType = areaTypes.byId[area.typeId] || null;
    const projectId = area.geoJson.properties.area.projectId;
    const projectName = projects.byId[projectId].name;

    return (
        <Container
            className={`${className || ''} shadow bg-light rounded p-2`}
            style={{
                maxHeight: '50vh',
                overflowY: 'auto',
                overflowX: 'hidden',
            }}
        >
            <Row className={''}>
                <Col>
                    {area.coverIds && 0 < area.coverIds.length && (
                        <ImageSlider images={area.coverIds} />
                    )}
                    <Row className='justify-content-between'>
                        <Col xs={7}>
                            <p className='font-weight-bold'>
                                {projectName || 'Project'}
                            </p>
                        </Col>
                        <Col xs={7}>
                            <p
                                className='font-italic'
                                style={{ whiteSpace: 'pre-wrap' }}
                            >
                                {area.title || 'No title'}
                            </p>
                        </Col>

                        <Col xs={5}>
                            {area.link && (
                                <Button
                                    href={area.link}
                                    variant='link'
                                    size='sm'
                                >
                                    More details
                                </Button>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {areaType && (
                                <p className='text-uppercase'>
                                    {areaType.name}
                                </p>
                            )}
                        </Col>
                    </Row>
                    <p
                        className='font-italic'
                        style={{ whiteSpace: 'pre-wrap' }}
                    >
                        {area.description || 'No description'}
                    </p>
                    <Row className='justify-content-between'>
                        <Col xs={7}>
                            {projectId && (
                                <LinkButton to={`/projects/${projectId}`}>
                                    Open project
                                </LinkButton>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

const ImageSlider = ({ images = [] }) => (
    <Carousel className='col-12 p-0 my-2'>
        {images.map((image) => (
            <Carousel.Item key={image}>
                <img
                    className='w-100'
                    style={{
                        backgroundImage: `url(/api/images/${image}/download)`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        height: '210px',
                    }}
                    alt=''
                />
            </Carousel.Item>
        ))}
    </Carousel>
);

export { AreaPreviewPanel };
