// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useState, useEffect } from 'react';

import { Modal, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const BaseDialog = ({
    show,
    pending = false,
    finished = false,
    onHide,
    title,
    children,
    footer,
    onSubmit,
    onClose,
    backdrop,
    onAccept = () => {},
    acceptVariant = 'primary',
    acceptText,
}) => {
    const [accepted, setAccepted] = useState(false);
    const { t } = useTranslation();

    const handleAccept = (e) => {
        if (!onSubmit) {
            e.preventDefault();
        }
        setAccepted(true);
        onAccept();
    };

    const handleHide = () => {
        setAccepted(false);
        onHide();
    };

    const handleClose = () => {
        setAccepted(false);
        onClose();
    };

    useEffect(() => {
        if (finished && accepted) {
            setAccepted(false);
            onClose();
        }
    }, [finished, accepted, onClose]);

    return (
        <Modal show={show} onHide={handleHide} backdrop={backdrop}>
            <Form onSubmit={onSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{children}</Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handleClose}>
                        {t('dialog-cancel-btn')}
                    </Button>
                    <Button
                        type='submit'
                        variant={acceptVariant}
                        onClick={handleAccept}
                        disabled={pending}
                    >
                        {acceptText || t('dialog-accept-btn')}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export { BaseDialog };
