// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { Fragment, useState } from 'react';

import { Pagination as BoostrapPagination } from 'react-bootstrap';

const LEFT_PAGE = 'LEFT_PAGE';
const RIGHT_PAGE = 'RIGHT_PAGE';

const createRange = (from, to, step = 1) => {
    let i = from;
    const result = [];

    while (i <= to) {
        result.push(i);
        i += step;
    }

    return result;
};

const fetchPageNumbers = (totalPages, currentPage, pageNeighbours) => {
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
        const startPage = Math.max(2, currentPage - pageNeighbours);
        const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

        let pages = createRange(startPage, endPage);

        const hasLeftSpill = startPage > 2;
        const hasRightSpill = totalPages - endPage > 1;
        const spillOffset = totalNumbers - (pages.length + 1);

        switch (true) {
            case hasLeftSpill && !hasRightSpill: {
                const extraPages = createRange(
                    startPage - spillOffset,
                    startPage - 1
                );
                pages = [LEFT_PAGE, ...extraPages, ...pages];
                break;
            }
            case !hasLeftSpill && hasRightSpill: {
                const extraPages = createRange(
                    endPage + 1,
                    endPage + spillOffset
                );
                pages = [...pages, ...extraPages, RIGHT_PAGE];
                break;
            }

            case hasLeftSpill && hasRightSpill:
            default: {
                pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                break;
            }
        }

        return [1, ...pages, totalPages];
    }

    return createRange(1, totalPages);
};

const Pagination = props => {
    const totalRecords = props.totalRecords || 20;
    const pageLimit = props.pageLimit || 30;
    const pageNeighbours = Math.max(0, Math.min(2, props.pageNeighbours || 3));

    const totalPages = Math.ceil(totalRecords / pageLimit);

    const [currentPage, setCurrentPage] = useState(1);
    const pages = fetchPageNumbers(totalPages, currentPage, pageNeighbours);

    const gotoPage = page => {
        const currentPage = Math.max(0, Math.min(page, totalPages));

        setCurrentPage(currentPage);
        props &&
            props.onPageChange &&
            props.onPageChange({
                current: currentPage,
                limit: pageLimit,
                offset: (currentPage - 1) * pageLimit,
                totalPages: totalPages,
                totalRecords: totalRecords,
            });
    };

    const handleClick = page => e => {
        e.preventDefault();
        gotoPage(page);
    };

    const handleMoveLeft = e => {
        e.preventDefault();
        gotoPage(currentPage - pageNeighbours * 2 - 1);
    };

    const handleMoveRight = e => {
        e.preventDefault();
        gotoPage(currentPage + pageNeighbours * 2 + 1);
    };

    return (
        <Fragment>
            <BoostrapPagination>
                {pages.map((page, index) => {
                    if (page === LEFT_PAGE)
                        return (
                            <BoostrapPagination.Prev
                                key={index}
                                onClick={handleMoveLeft}
                            />
                        );

                    if (page === RIGHT_PAGE)
                        return (
                            <BoostrapPagination.Next
                                key={index}
                                onClick={handleMoveRight}
                            />
                        );

                    return (
                        <BoostrapPagination.Item
                            key={index}
                            active={currentPage === page}
                            onClick={handleClick(page)}
                        >
                            {page}
                        </BoostrapPagination.Item>
                    );
                })}
            </BoostrapPagination>
        </Fragment>
    );
};

export { Pagination };
