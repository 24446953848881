// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';
import { Provider } from 'react-redux';

import App from 'components/App';

import { createStore } from 'helpers/store';

const store = createStore();

const Root = () => (
    <Provider store={store}>
        <App />
    </Provider>
);

export { Root };
