// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { BasePage } from 'components/pages/BasePage';
import { Alert, Col, Row } from 'react-bootstrap';

const MobileViewPage = () => {
    return (
        <BasePage>
            <Row className='p-4 justify-content-center'>
                <Col xs={8}>
                    <Alert variant='warning'>
                        Mobile version is not supported yet.
                    </Alert>
                </Col>
            </Row>
        </BasePage>
    );
};

export { MobileViewPage };
