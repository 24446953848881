// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { put, call, takeLatest, all } from 'redux-saga/effects';

import { takeAuth } from './common.saga';

import Api from 'api';
import Actions from 'actions';

export function* requestsSaga() {
    yield takeLatest(Actions.requests.tableFetch, tableFetch);
    yield takeLatest(Actions.requests.createRequest.execute, createRequest);
    yield takeLatest(Actions.requests.approveRequest.execute, approveRequest);
    yield takeLatest(Actions.requests.declineRequest.execute, declineRequest);
    yield takeLatest(Actions.requests.cancelRequest.execute, cancelRequest);
}

function* tableFetch({ payload }) {
    try {
        const auth = yield takeAuth();

        yield put(Actions.requests.tableProcessStart());

        const api = auth.permissions.includes('ADMIN')
            ? Api.requests.getRequests
            : Api.requests.getOwnRequests;
        const page = yield call(api, auth, payload.offset, payload.limit);

        const projects = yield all(
            page.data.map((item) => {
                return call(Api.projects.getProject, auth, item.projectId);
            })
        );
        const projectsCreators = yield all(
            projects.map((item) => {
                return call(Api.users.getUser, auth, item.ownerId);
            })
        );

        page.data = page.data.map((item, i) => ({
            ...item,
            project: projects[i],
            creator: projectsCreators[i],
        }));

        yield put(Actions.requests.tableSuccess(page.count, page.data));
    } catch (error) {
        yield put(
            Actions.requests.tableAlertShow(
                'Requests fetch failed',
                error.message
            )
        );
        yield put(Actions.requests.tableFailure());
    } finally {
        yield put(Actions.requests.tableProcessStop());
    }
}

function* createRequest({ payload }) {
    try {
        yield put(Actions.requests.createRequest.start());
        const auth = yield takeAuth();
        const request = yield call(Api.requests.createRequest, auth, payload);
        yield put(Actions.requests.createRequest.success(request));
        yield put(Actions.requests.tableFetch(0, 15));
    } catch (error) {
        yield put(Actions.requests.createRequest.failure(error));
    } finally {
        yield put(Actions.requests.createRequest.stop());
    }
}

function* approveRequest({ payload }) {
    try {
        yield put(Actions.requests.approveRequest.start());
        const auth = yield takeAuth();

        const request = yield call(Api.requests.confirmRequest, auth, payload);
        yield put(Actions.requests.approveRequest.success(request));
        yield put(Actions.requests.tableFetch(0, 15));
    } catch (error) {
        yield put(Actions.requests.approveRequest.failure(error));
    } finally {
        yield put(Actions.requests.approveRequest.stop());
    }
}

function* declineRequest({ payload }) {
    try {
        yield put(Actions.requests.declineRequest.start());
        const auth = yield takeAuth();

        const request = yield call(Api.requests.declineRequest, auth, payload);
        yield put(Actions.requests.declineRequest.success(request));
    } catch (error) {
        yield put(Actions.requests.declineRequest.failure(error));
    } finally {
        yield put(Actions.requests.declineRequest.stop());
    }
}

function* cancelRequest({ payload }) {
    try {
        yield put(Actions.requests.cancelRequest.start());
        const auth = yield takeAuth();

        yield call(Api.requests.cancelRequest, auth, payload);
        yield put(Actions.requests.cancelRequest.success(payload));
    } catch (error) {
        yield put(Actions.requests.cancelRequest.failure(error));
    } finally {
        yield put(Actions.requests.cancelRequest.stop());
    }
}
