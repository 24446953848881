// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { createReducer, combineReducers } from '@reduxjs/toolkit';

import Actions from 'actions';
import Services from 'services';

import { makeAlertReducer } from './alert.reducer';
import { makeProcessReducer } from './process.reducer';

const loadUser = () => {
    const user = Services.user.load();
    return user;
};

const loadMap = () => {
    const map = Services.user.loadMap();
    return map;
};

const emptyMap = {
    zoom: 6.155561554861458,
    center: [13.392380403963443, 52.516328142434475],
};
const initialUser = loadUser();
const initialMap = loadMap() || emptyMap;

const mapUserToState = (state, action) => ({
    ...state,
    isLogged: true,
    isRefresh: false,
    id: action.payload.id,
    username: action.payload.username,
    email: action.payload.email,
    language: action.payload.language,
    features: action.payload.features,
    coordinateSystem: action.payload.coordinateSystem,
    permissions: action.payload.permissions,
    accessToken: action.payload.accessToken,
    refreshToken: action.payload.refreshToken,
    expiredAt: action.payload.expiredAt,
});

const userReducer = createReducer(
    {
        isLogged: !!initialUser,
        isRefresh: false,
        ...initialUser,
        map: initialMap,
    },
    {
        [Actions.app.userInit]: (state, action) => ({
            ...state,
            user: action.payload.user,
        }),
        [Actions.account.saveMapSuccess]: (state, action) => ({
            ...state,
            map: action.payload,
        }),
        [Actions.account.loginSuccess]: mapUserToState,
        [Actions.account.refresh]: (state) => ({
            ...state,
            isRefresh: true,
        }),
        [Actions.account.refreshSuccess]: mapUserToState,
        [Actions.account.refreshFailure]: () => ({
            isLogged: false,
            isRefresh: false,
        }),
        [Actions.account.logout]: () => ({
            isLogged: false,
            map: emptyMap,
        }),
    }
);

export default combineReducers({
    login: combineReducers({
        alert: makeAlertReducer(Actions.account.LOGIN_ACTION),
        process: makeProcessReducer(Actions.account.LOGIN_ACTION),
    }),
    register: combineReducers({
        alert: makeAlertReducer(Actions.account.REGISTER_ACTION),
        process: makeProcessReducer(Actions.account.REGISTER_ACTION),
    }),
    restorePassword: combineReducers({
        alert: makeAlertReducer(Actions.account.RESTORE_PASSWORD_ACTION),
        process: makeProcessReducer(Actions.account.RESTORE_PASSWORD_ACTION),
    }),
    user: userReducer,
});
