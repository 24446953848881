// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faCheckSquare,
    faCoffee,
    faCube,
    faMap,
    faSearch,
    faTrash,
    faEdit,
    faEye,
    faUserCircle,
    faFile,
    faTrashRestore,
    faFileUpload,
    faMousePointer,
    faArrowsAlt,
    faDrawPolygon,
    faCommentAlt,
    faObjectUngroup,
    faObjectGroup,
    faGripLines,
    faCheck,
    faFilter,
    faExclamationTriangle,
    faMapMarkerAlt,
    faHome,
    faQuestionCircle,
    faProjectDiagram,
    faUser,
    faCog,
    faSignOutAlt,
    faMoneyCheck,
    faTimes
} from '@fortawesome/free-solid-svg-icons';

export const intializeFonts = () => {
    const iconPack = [
        faCheckSquare,
        faCoffee,
        faCube,
        faMap,
        faSearch,
        faTrash,
        faEdit,
        faEye,
        faUserCircle,
        faFilter,
        faExclamationTriangle,
        faFile,
        faTrashRestore,
        faFileUpload,
        faMousePointer,
        faArrowsAlt,
        faDrawPolygon,
        faCommentAlt,
        faObjectUngroup,
        faObjectGroup,
        faGripLines,
        faCheck,
        faMapMarkerAlt,
        faHome,
        faQuestionCircle,
        faProjectDiagram,
        faUser,
        faCog,
        faSignOutAlt,
        faMoneyCheck,
        faTimes
    ];
    library.add(iconPack);
};
