// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import * as THREE from 'three';

const GroundMesh = () => {
    return (
        <mesh
            geometry={new THREE.PlaneGeometry(1000, 1000)}
            rotation={[-Math.PI / 2, 0.0, 0.0]}
        >
            <meshStandardMaterial attach={'material'} transparent opacity={0.65} color={'#6b4e00'}/>
        </mesh>
    );
};

export { GroundMesh };
