// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { take, put, call } from 'redux-saga/effects';

import { push as routerPush } from 'connected-react-router';

import Api from 'api';
import Actions from 'actions';

import { selectAuth, loadAllPages } from './common.saga';

import i18n from 'i18next';

// translate trigger
function t(text) {
    return text;
}

function mapCodesToValues(codes, mapper) {
    return codes.map((code) => ({
        code: code,
        name: mapper[code] || code,
    }));
}

const LANGUAGE_NAME_MAP = {
    en: 'English',
    de: 'Deutsch',
};

const PERMISSION_NAME_MAP = {
    USER: t('role-name-user'),
    INVESTOR: t('role-name-investor'),
    DEVELOPER: t('role-name-developer'),
    CONSTRUCTION_COMPANY: t('role-name-construction-company'),
    REAL_ESTATE_AGENCY: t('role-name-real-estate-agency'),
};

const PROJECT_CONSTRUCTION_STATUS_NAME_MAP = {
    NO_BUILDING_YET: t('project-construction-status-no-building-yet'),
    DEVELOPMENT_PLAN: t('project-construction-status-development-plan'),
    CONSTRUCTION_SUBMITTED: t('project-construction-status-submitted'),
    CONSTRUCTION_APPROVED: t('project-construction-status-approved'),
};

const PROJECT_DEVELOPMENT_STATUS_NAME_MAP = {
    PLANNED_EARLY_PHASE: t('project-development-status-planned-early-phase'),
    PLANNED_LATE_PHASE: t('project-development-status-planned-late-phase'),
    CONSTRUCTION: t('project-development-status-construction'),
};

const PROJECT_INVESTMENT_STATUS_NAME_MAP = {
    INVESTED: t('project-investment-status-invested'),
    NOT_INVESTED: t('project-investment-status-not-invested'),
};

export function* appSage() {
    const systemLanguages = mapCodesToValues(
        yield call(Api.system.getSystemLanguages),
        LANGUAGE_NAME_MAP
    );

    const coordinateFormats = yield call(Api.system.getCoordinateFormats);
    const currencies = yield call(Api.system.getCurrencies);

    const userPermissions = mapCodesToValues(
        yield call(Api.system.getUserPermissions),
        PERMISSION_NAME_MAP
    ).filter((i) => i.code != 'USER');

    const projectConstructionStatuses = mapCodesToValues(
        Object.keys(PROJECT_CONSTRUCTION_STATUS_NAME_MAP),
        PROJECT_CONSTRUCTION_STATUS_NAME_MAP
    );

    const projectDevelopmentStatuses = mapCodesToValues(
        Object.keys(PROJECT_DEVELOPMENT_STATUS_NAME_MAP),
        PROJECT_DEVELOPMENT_STATUS_NAME_MAP
    );

    const projectInvestmentStatuses = mapCodesToValues(
        Object.keys(PROJECT_INVESTMENT_STATUS_NAME_MAP),
        PROJECT_INVESTMENT_STATUS_NAME_MAP
    );

    yield put(
        Actions.app.systemInit({
            systemLanguages,
            coordinateFormats,
            currencies,
            userPermissions,
            projectConstructionStatuses,
            projectDevelopmentStatuses,
            projectInvestmentStatuses,
        })
    );

    while (true) {
        try {
            let auth = null;
            let refreshRequested = process.env.NODE_ENV === 'production';
            while (!auth) {
                auth = yield selectAuth();

                if (auth) {
                    if (!refreshRequested) {
                        break;
                    }

                    yield put(Actions.account.refresh(auth.refreshToken));
                    const refreshAction = yield take([
                        Actions.account.refreshSuccess,
                        Actions.account.refreshFailure,
                    ]);
                    if (
                        refreshAction.type ===
                        Actions.account.refreshFailure.toString()
                    ) {
                        yield put(Actions.account.logout());
                        auth = null;
                        yield put(routerPush('/login'));
                    } else {
                        auth = null;
                        refreshRequested = false;
                        continue;
                    }
                }

                yield take(Actions.account.loginSuccess);
                refreshRequested = false;
            }

            const segmentTypes = yield call(loadSegmentTypes, auth);
            const areaTypes = yield call(loadAreaTypes, auth);

            const user = yield call(loadUser, auth, auth.id);

            yield put(
                Actions.app.userInit({
                    segmentTypes,
                    areaTypes,
                    user,
                })
            );

            i18n.changeLanguage(user.language);

            yield take(Actions.account.logout);
        } catch (e) {
            yield put(Actions.account.logout());
        }
    }
}

function* loadUser(auth, id) {
    const user = yield call(Api.users.getUser, auth, id);
    return user;
}

const COLOR_MAP = {
    PINK: '#f5aee3',
    YELLOW: '#f0f00a',
    GREEN: '#3bd12a',
    RED: '#eb1328',
    PURPLE: '#9f36eb',
    BLUE: '#347bd1',
    WHITE: '#f2f2f2',
    ORANGE: '#fcba03',
};

function* loadSegmentTypes(auth) {
    const segmentTypes = yield loadAllPages(
        Api.projects.getProjectSegmentTypes,
        auth
    );

    return segmentTypes.data.map((type) => ({
        ...type,
        color: COLOR_MAP[type.color.toUpperCase()] || type.color,
    }));
}

function* loadAreaTypes(auth) {
    const areaTypes = yield loadAllPages(
        Api.projects.getProjectAreaTypes,
        auth
    );

    return areaTypes.data.map((type) => ({
        ...type,
        color: COLOR_MAP[type.color.toUpperCase()] || type.color,
    }));
}
