// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { useSelector } from 'react-redux';

import { Container } from 'react-bootstrap';

const ProjectWizard = ({ className, steps }) => {
    const wizard = useSelector(state => state.projects.wizard);

    const step = steps.find(step => step.id === wizard.view.stepId);

    return (
        <Container
            fluid
            className={`${className || ''} flex-fill d-flex flex-column p-2`}
        >
            {step && step.component({})}
        </Container>
    );
};

export { ProjectWizard };
