// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactMapGL from 'react-map-gl';
import {
    Tab,
    Row,
    Col,
    Nav,
    Button,
    InputGroup,
    Form,
    Spinner,
} from 'react-bootstrap';

import { FilePicker } from 'components/common/FilePicker';
import { SearchField } from 'components/common/SearchField';
import { PageLayout } from 'components/common/PageLayout';

import { ProjectWizardStep } from 'components/wizard/ProjectWizardStep';

import Actions from 'actions';
import { convertLatLngToUTM } from 'helpers/utm';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const START_NEW = 'new';
const START_CAD = 'cad';

const SearchPlace = ({
    className,
    initialPlace = '',
    disabled = false,
    onCoordsChanged = () => {},
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const searchState = useSelector(
        (state) => state.projects.wizard.map.search
    );

    const pending = searchState.pending;
    const placeFound = 0 < searchState.data.length && '' !== searchState.query;
    const placeData = placeFound && searchState.data[0];
    const placeCoords = placeFound && placeData.center;

    const onSearch = (query) => {
        dispatch(Actions.projects.wizardMapSearch(query));
    };

    const [mapViewport, setMapViewport] = useState({
        latitude: 0,
        longitude: 0,
        zoom: 8,
    });

    useEffect(() => {
        if (placeCoords) {
            setMapViewport({
                latitude: placeCoords[1],
                longitude: placeCoords[0],
                zoom: 13,
            });

            onCoordsChanged({
                latitude: placeCoords[1],
                longitude: placeCoords[0],
            });
        }
    }, [placeCoords]);

    const onViewportChanged = (viewport) => {
        setMapViewport(viewport);
        onCoordsChanged({
            latitude: viewport.latitude,
            longitude: viewport.longitude,
        });
    };

    const isCorrectZoom = !placeFound || mapViewport.zoom >= 13.0;

    const utm =
        placeFound &&
        convertLatLngToUTM(mapViewport.latitude, mapViewport.longitude);

    return (
        <Col className={`${className || ''}`}>
            <h5>{t('project-wizard-target-place')}</h5>
            <SearchField
                initialValue={initialPlace}
                className='mt-2'
                onSearch={onSearch}
                isValid={placeFound}
                disabled={disabled}
                isInvalid={
                    !searchState.pending &&
                    '' !== searchState.query &&
                    !placeFound
                }
            />
            <Row className='justify-content-center'>
                {!placeFound && pending && (
                    <Row className='mt-3 align-self-center'>
                        <Spinner
                            animation='border'
                            className='align-self-center'
                        />
                    </Row>
                )}
                {placeFound && (
                    <Col className='mt-3'>
                        <InputGroup className='mb-3'>
                            <InputGroup.Prepend>
                                <InputGroup.Text id='basic-addon3'>
                                    {t('project-wizard-utm-zone')}
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type='text'
                                readOnly
                                value={utm && utm.zone}
                            />
                        </InputGroup>
                    </Col>
                )}
            </Row>

            {!isCorrectZoom && (
                <Row className='ml-2'>
                    <FontAwesomeIcon
                        className='text-warning mr-2'
                        icon='exclamation-triangle'
                    ></FontAwesomeIcon>
                    <p className='text-warning'>
                        {t('project-wizard-start-incorrect-zoom')}
                    </p>
                </Row>
            )}
            {placeFound && (
                <ReactMapGL
                    width={'100%'}
                    height={'350px'}
                    {...mapViewport}
                    onViewportChange={onViewportChanged}
                />
            )}
        </Col>
    );
};

const StartProjectWizardStep = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [projectType, setProjectType] = useState(START_NEW);
    const [cadFile, setCadFile] = useState(null);

    const wizardProcess = useSelector((state) => state.projects.wizard.process);

    const projectTypeReady = START_NEW === projectType || !!cadFile;

    const [projectCoords, setProjectCoords] = useState(null);

    const nextEnabled = projectTypeReady && projectCoords;

    const onCadFileChanged = (file) => {
        setCadFile(file);
    };

    const onNextClicked = () => {
        dispatch(
            Actions.projects.wizardCreate(
                {
                    project: { name: t('project-wizard-new-project-name') },
                    cadFile: START_CAD === projectType ? cadFile : null,
                    anchorPoint: {
                        longitude: projectCoords.longitude,
                        latitude: projectCoords.latitude,
                    },
                },
                {
                    next: START_CAD === projectType ? 'import' : 'info',
                }
            )
        );
    };

    return (
        <ProjectWizardStep>
            <PageLayout>
                <Tab.Container defaultActiveKey={projectType}>
                    <Row>
                        <Col sm={3}>
                            <Nav variant='pills' className='flex-row'>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey='new'
                                        onClick={() =>
                                            setProjectType(START_NEW)
                                        }
                                    >
                                        {t('project-wizard-new-mode')}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey='cad'
                                        onClick={() =>
                                            setProjectType(START_CAD)
                                        }
                                    >
                                        {t('project-wizard-cad-file-mode')}
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Row>
                    <Row className='mt-3 p-3'>
                        <Col>
                            <Tab.Content>
                                <Tab.Pane eventKey='new'>
                                    <h5>
                                        {t('project-wizard-new-mode-title')}
                                    </h5>
                                    <p>
                                        {t('project-wizard-new-mode-message')}
                                    </p>
                                </Tab.Pane>
                                <Tab.Pane eventKey='cad'>
                                    <h5>
                                        {t(
                                            'project-wizard-cad-file-mode-title'
                                        )}
                                    </h5>
                                    <p>
                                        {t(
                                            'project-wizard-cad-file-mode-message'
                                        )}
                                    </p>
                                    <FilePicker
                                        label='Excel, DWG'
                                        accept='application/vnd.ms-excel, .dwg, .xls'
                                        onFileChange={onCadFileChanged}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>

                <SearchPlace
                    className='mt-4'
                    disabled={wizardProcess.pending}
                    onCoordsChanged={(e) => setProjectCoords(e)}
                />

                <Row className='justify-content-end mt-4 mr-3'>
                    <Button
                        className='p-2 m-0'
                        disabled={!nextEnabled || wizardProcess.pending}
                        onClick={onNextClicked}
                    >
                        {wizardProcess.pending && START_CAD === projectType ? (
                            <Row className='p-0 m-0 align-items-center justify-content-center'>
                                <Col className='p-0 m-0'>
                                    <Spinner animation='border' />
                                </Col>
                                <Col className='pr-4'>
                                    <span>
                                        {t('project-wizard-uploading-btn')}
                                    </span>
                                </Col>
                            </Row>
                        ) : (
                            t('project-wizard-create-btn')
                        )}
                    </Button>
                </Row>
            </PageLayout>
        </ProjectWizardStep>
    );
};

export { StartProjectWizardStep };
