// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { createAction } from '@reduxjs/toolkit';

export const makeProcessActions = (scope) => [
    createAction(`${scope}/process:start`, (message) => ({
        payload: { message },
    })),
    createAction(`${scope}/process:stop`, () => ({
        payload: '',
    })),
    createAction(`${scope}/process:progress`, (c, t) => ({
        payload: { current: c, total: t },
    })),
];
