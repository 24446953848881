// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { take, put, call, fork, cancel, takeLatest } from 'redux-saga/effects';

import { push as routerPush } from 'connected-react-router';

import Api from 'api';
import Actions from 'actions';
import Services from 'services';

export function* logoutSaga() {
    while (true) {
        yield take(Actions.account.logout);
        yield call(Services.user.clear);
        yield call(Services.user.clearMap);
    }
}

export function* loginSaga() {
    while (true) {
        const { payload } = yield take(Actions.account.login);

        yield put(Actions.account.loginProcessStart());

        const task = yield fork(authorize, payload.username, payload.password);

        const action = yield take([
            Actions.account.logout,
            Actions.account.loginFailure,
        ]);

        if (action.type === Actions.account.logout.toString()) {
            yield cancel(task);
        }

        yield call(Services.user.clear);
    }
}

export function* refreshSaga() {
    yield takeLatest(Actions.account.refresh, refresh);
}

function* refresh({ payload }) {
    try {
        const user = yield call(Api.account.refresh, payload);
        const mappedUser = mapUser(user);

        yield put(Actions.account.refreshSuccess(mappedUser));
        yield call(Services.user.save, mappedUser);
    } catch (error) {
        console.log(error);
        yield put(Actions.account.refreshFailure(error));
    }
}

export function* registerSaga() {
    yield takeLatest(Actions.account.register, register);
}

function getUserFeatures(user) {
    return {
        canCreateProject:
            user.permissions.includes('ADMIN') ||
            user.permissions.includes('DEVELOPER'),
        canRequestProjectPublish:
            user.permissions.includes('ADMIN') ||
            user.permissions.includes('DEVELOPER'),
        canInvest: user.permissions.includes('INVESTOR'),
    };
}

const mapUser = (user) => ({
    id: user.id,
    username: user.username,
    email: user.email,
    language: user.language || 'de',
    features: getUserFeatures(user),
    coordinateSystem: user.coordinateSystem || 'UTM',
    permissions: user.permissions,
    accessToken: user.accessToken,
    refreshToken: user.refreshToken,
    expiredAt: Date.now() + Math.max(2, user.expiresIn) * 1000 - 1500,
});

export function* saveMapSaga() {
    yield takeLatest(Actions.account.saveMap, saveMap);
}

function* saveMap({ payload }) {
    yield call(Services.user.saveMap, payload);
    yield put(Actions.account.saveMapSuccess(payload));
}

function* register({ payload }) {
    try {
        yield put(Actions.account.registerProcessStart());
        yield call(Api.account.register, payload);
        yield put(Actions.account.registerSuccess());
        yield put(routerPush('/login'));
    } catch (error) {
        yield put(
            Actions.account.registerAlertShow(
                'Registration failed',
                error.message
            )
        );
        yield put(Actions.account.registerFailure());
    } finally {
        yield put(Actions.account.registerProcessStop());
    }
}

function* authorize(username, password) {
    try {
        const user = yield call(Api.account.login, username, password);

        const mappedUser = mapUser(user);

        yield put(Actions.account.loginSuccess(mappedUser));

        yield call(Services.user.save, mappedUser);
    } catch (error) {
        switch (error.status) {
            case 401:
                yield put(
                    Actions.account.loginAlertShow(
                        'Invalid credentials',
                        'Incorrect username or password'
                    )
                );
                break;
            default:
                yield put(
                    Actions.account.loginAlertShow(
                        'Connection to server failed',
                        error.message
                    )
                );
                break;
        }

        yield put(Actions.account.loginFailure());
    } finally {
        yield put(Actions.account.loginProcessStop());
    }
}

export function* restorePasswordSaga() {
    while (true) {
        const { payload } = yield take(Actions.account.restorePassword);
        try {
            const data = yield call(Api.account.forgotPassword, payload.email);
            //TODO if data
            yield put(Actions.account.restorePasswordSuccess(data));
        } catch (error) {
            switch (error.status) {
                case 404:
                    yield put(
                        Actions.account.restorePasswordAlertShow(
                            'Not found',
                            error.message
                        )
                    );
                    break;
                default:
                    yield put(
                        Actions.account.restorePasswordAlertShow(
                            'Connection to server failed',
                            error.message
                        )
                    );
                    break;
            }
            yield put(Actions.account.restorePasswordFailure());
        }
    }
}
