// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Button } from 'react-bootstrap';
import { push } from 'react-router-redux';
import { useDispatch } from 'react-redux';

const LinkButton = ({
    children,
    className,
    to,
    onClick = () => {},
    variant = 'link',
    size,
    disabled,
}) => {
    const dispatch = useDispatch();

    const onClicked = (e) => {
        e.stopPropagation();
        dispatch(push(to));
        onClick();
    };

    return (
        <Button
            className={`${className || ''}`}
            onClick={onClicked}
            variant={variant}
            size={size}
            disabled={disabled}
        >
            {children}
        </Button>
    );
};

export { LinkButton };
