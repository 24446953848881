// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Controller } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const DatePicker = ({
    control,
    name,
    dateFormat = 'd MMM yyyy',
    minDate,
    register,
    required,
    startDate,
    endDate,
    selectsEnd,
    selectsStart,
}) => {
    const { t } = useTranslation();

    return (
        <Controller
            as={
                <ReactDatePicker
                    id={name}
                    minDate={minDate}
                    todayButton={<p>{t('date-picker-today')}</p>}
                    selectsStart={selectsStart}
                    selectsEnd={selectsEnd}
                    dateFormat={dateFormat}
                    startDate={startDate}
                    endDate={endDate}
                    ref={register}
                />
            }
            name={name}
            control={control}
            valueName='selected'
            register={register}
            required={required}
        />
    );
};

export { DatePicker };
