// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useEffect, useState } from 'react';

import { Col, Form, Button, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { MultiFilePicker } from 'components/common/MultiFilePicker';
import { ProjectWizardStep } from 'components/wizard/ProjectWizardStep';

import Actions from 'actions';
import { useTranslation } from 'react-i18next';

const InfoProjectWizardStep = ({ className }) => {
    return (
        <ProjectWizardStep className={`${className || ''} container p-4 `}>
            <ProjectForm />
        </ProjectWizardStep>
    );
};

export { InfoProjectWizardStep };

const FINANCIAL_PRICE_FIELDS = [
    {
        label: 'project-wizard-info-financial-price-of-land', // t('project-wizard-info-financial-price-of-land')
        name: 'landPrice',
        suffix: 'Euro',
    },
    {
        label: 'project-wizard-info-financial-development-costs', // t('project-wizard-info-financial-development-costs')
        name: 'developmentPrice',
        suffix: 'Euro',
    },
    {
        label: 'project-wizard-info-financial-construction-costs-per-sqm', // t('project-wizard-info-financial-construction-costs-per-sqm')
        name: 'constructionPricePerMeter',
        suffix: 'Euro',
        calculated: true,
    },
    {
        label: 'project-wizard-info-financial-construction-costs-in-total', // t('project-wizard-info-financial-construction-costs-in-total')
        name: 'totalConstructionPrice',
        suffix: 'Euro',
    },
    {
        label: 'project-wizard-info-financial-marketing-costs', // t('project-wizard-info-financial-marketing-costs')
        name: 'marketingPrice',
        suffix: 'Euro',
    },
    {
        label: 'project-wizard-info-financial-total-costs', // t('project-wizard-info-financial-total-costs')
        name: 'totalProjectPrice',
        suffix: 'Euro',
    },
    {
        label: 'project-wizard-info-financial-sales-price-per-sqm', // t('project-wizard-info-financial-sales-price-per-sqm')
        name: 'salesPricePerMeter',
        suffix: 'Euro',
        calculated: true,
    },
    {
        label: 'project-wizard-info-financial-total-sales-price', // t('project-wizard-info-financial-total-sales-price')
        name: 'totalSalesPrice',
        suffix: 'Euro',
    },
    {
        label: 'project-wizard-info-financial-investor-margin', // t('project-wizard-info-financial-investor-margin')
        name: 'investorMargin',
        suffix: '%',
    },
];

function createValuesFromModel(project) {
    const values = {
        totalArea: 0,
        totalNetArea: 0,
    };

    if (!project) {
        return values;
    }

    values['name'] = project.name;
    values['description'] = project.description;
    values['totalArea'] = project.totalArea;
    values['totalNetArea'] = project.totalNetArea;

    values['projectConstructionStatus'] = project.constructionStatus;
    values['projectDevelopmentStatus'] = project.developmentStatus;
    values['projectInvestmentStatus'] = project.investmentStatus === 'INVESTED';
    // values['publishToCompanies'] = project.publishConstructionCompanies;
    // values['publishToInvestors'] = project.publishInvestors;
    // values['publishToAgencies'] = project.publishRealEstate;

    values[
        'publishToCompanies'
    ] = project.projectUserPermissionVisibilities.includes(
        'CONSTRUCTION_COMPANY'
    );
    values[
        'publishToInvestors'
    ] = project.projectUserPermissionVisibilities.includes('INVESTOR');
    values[
        'publishToAgencies'
    ] = project.projectUserPermissionVisibilities.includes(
        'REAL_ESTATE_AGENCY'
    );

    if (project.projectFinancialData) {
        for (let field of FINANCIAL_PRICE_FIELDS) {
            if (!project.projectFinancialData[field.name]) {
                continue;
            }

            values[field.name] = project.projectFinancialData[field.name].price;
        }

        values['investorMargin'] =
            project.projectFinancialData['investorMargin'];
    }

    return values;
}

const ProjectForm = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const model = useSelector((state) => state.projects.wizard.view.data.start);

    const [projectFinancedEnabled, setProjectFinancedEnabled] = useState(true);
    const [publishToInvestorsEnabled, setPublishToInvestorsEnabled] = useState(
        true
    );
    const [publishToCompaniesEnabled, setPublishToCompaniesEnabled] = useState(
        false
    );
    const [publishToAgenciesEnabled, setPublishToAgenciesEnabled] = useState(
        false
    );

    const projectConstructionStatuses = useSelector(
        (state) => state.app.projectConstructionStatuses
    );
    const projectDevelopmentStatuses = useSelector(
        (state) => state.app.projectDevelopmentStatuses
    );

    useEffect(() => {
        if (!model?.investmentStatus) {
            return;
        }
        setPublishToInvestorsEnabled(model.investmentStatus === 'INVESTED');
        setPublishToCompaniesEnabled(model.investmentStatus !== 'INVESTED');
    }, [model.investmentStatus]);

    useEffect(() => {
        if (!model?.developmentStatus) return;

        setProjectFinancedEnabled(
            model.developmentStatus.startsWith('PLANNED')
        );
        setPublishToInvestorsEnabled(
            model.developmentStatus.startsWith('PLANNED')
        );
        setPublishToCompaniesEnabled(
            !model.developmentStatus.startsWith('PLANNED')
        );

        setPublishToAgenciesEnabled(
            !model.developmentStatus.startsWith('PLANNED')
        );
    }, [model.developmentStatus, model.investmentStatus]);

    const { register, handleSubmit, errors } = useForm({
        defaultValues: createValuesFromModel(model),
    });

    const initialDocuments =
        model &&
        model.documents &&
        model.documents.map((doc) => ({ id: doc.id, name: doc.filename }));

    const initialImages =
        model &&
        model.images &&
        model.images.map((image) => ({ id: image.id, name: image.filename }));

    const [documents, setDocuments] = useState(initialDocuments);
    const [images, setImages] = useState(initialImages);

    const onDocumentsChanged = (files) => {
        setDocuments(files);
    };

    const onImagesChanged = (files) => {
        setImages(files);
    };

    const onSubmit = (data) => {
        const changedDocuments = documents ? documents.map((doc) => doc) : [];
        const changedImages = images ? images.map((doc) => doc) : [];

        let financialDetails = FINANCIAL_PRICE_FIELDS.map((i) => i.name).reduce(
            (r, i) => {
                if (data[i] !== '') {
                    if (!r) {
                        r = {};
                    }

                    r[i] = {
                        price: parseFloat(data[i]),
                        currency: 'EUR',
                    };
                }

                return r;
            },
            null
        );
        if (!financialDetails) {
            financialDetails = {};
        }
        financialDetails['investorMargin'] = parseFloat(data['investorMargin']);
        dispatch(
            Actions.projects.wizardUpdateInfo(
                {
                    projectId: model && model.id,
                    data: {
                        ...data,
                        country: model.country,
                        city: model.city,
                        name: data.name,
                        totalArea: parseFloat(data.totalArea),
                        totalNetArea: parseFloat(data.totalNetArea),
                        description: data.description,
                        anchorPoint: model.anchorPoint,
                        constructionStatus: data.projectConstructionStatus,
                        developmentStatus: data.projectDevelopmentStatus,
                        investmentStatus: data.projectInvestmentStatus
                            ? 'INVESTED'
                            : 'NOT_INVESTED',
                        financialDetails: financialDetails,
                        publishConstructionCompanies:
                            publishToCompaniesEnabled &&
                            data.publishToCompanies,
                        publishInvestors:
                            publishToInvestorsEnabled &&
                            data.publishToInvestors,
                        publishRealEstate:
                            publishToAgenciesEnabled && data.publishToAgencies,
                    },
                    changedDocuments,
                    changedImages,
                },
                {
                    next: 'editor',
                }
            )
        );
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <h3>{t('project-wizard-info-general')}</h3>

            <Form.Group as={Col} xs={8}>
                <Form.Label>{t('project-wizard-info-project-name')}</Form.Label>
                <Form.Control
                    type='text'
                    isInvalid={errors.name}
                    name='name'
                    id='nameInput'
                    ref={register({ required: true, maxLength: 64 })}
                />
            </Form.Group>

            <Form.Group as={Col} xs={8}>
                <Form.Label htmlFor='descriptionInput'>
                    {t('project-wizard-info-project-description')}
                </Form.Label>
                <Form.Control
                    as='textarea'
                    id='descriptionInput'
                    name='description'
                    ref={register({ maxLength: 2048 })}
                />
            </Form.Group>

            <Col xs={6}>
                <Form.Row>
                    <Form.Group as={Col} xs={6}>
                        <Form.Label>
                            {t('project-wizard-info-total-area')}
                        </Form.Label>
                        <Form.Control
                            type='number'
                            step='any'
                            isInvalid={errors.totalArea}
                            name='totalArea'
                            ref={register({ required: true, min: 0 })}
                        />
                    </Form.Group>
                    <Form.Group as={Col} xs={6}>
                        <Form.Label>
                            {t('project-wizard-info-total-net-area')}
                        </Form.Label>
                        <Form.Control
                            type='number'
                            step='any'
                            isInvalid={errors.totalNetArea}
                            name='totalNetArea'
                            ref={register({ required: true, min: 0 })}
                        />
                    </Form.Group>
                </Form.Row>
            </Col>

            <Form.Group as={Col} xs={6}>
                <Form.Label htmlFor='projectConstructionStatus'>
                    {t('project-wizard-info-project-construction-status')}
                </Form.Label>
                <Form.Control
                    as='select'
                    name='projectConstructionStatus'
                    ref={register({ required: true })}
                >
                    {projectConstructionStatuses.map((i) => (
                        <option key={i.code} value={i.code}>
                            {t(i.name)}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>

            <Form.Group as={Col} xs={6}>
                <Form.Label htmlFor='projectDevelopmentStatus'>
                    {t('project-wizard-info-project-development-status')}
                </Form.Label>
                <Form.Control
                    as='select'
                    name='projectDevelopmentStatus'
                    defaultValue={projectDevelopmentStatuses[0].code}
                    onChange={(e) => {
                        setProjectFinancedEnabled(
                            e.target.value.startsWith('PLANNED')
                        );
                        setPublishToInvestorsEnabled(
                            e.target.value.startsWith('PLANNED')
                        );
                        setPublishToCompaniesEnabled(
                            !e.target.value.startsWith('PLANNED')
                        );
                        setPublishToAgenciesEnabled(
                            !e.target.value.startsWith('PLANNED')
                        );
                    }}
                    ref={register({ required: true })}
                >
                    {projectDevelopmentStatuses.map((i) => (
                        <option key={i.code} value={i.code}>
                            {t(i.name)}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>

            {projectFinancedEnabled && (
                <Form.Group as={Col}>
                    <Form.Check
                        type='checkbox'
                        label={t('project-wizard-info-is-project-financed')}
                        name='projectInvestmentStatus'
                        ref={register({ required: false })}
                        onChange={(e) => {
                            setPublishToInvestorsEnabled(!e.target.checked);
                            setPublishToCompaniesEnabled(e.target.checked);
                        }}
                    />
                </Form.Group>
            )}

            {publishToInvestorsEnabled && (
                <Form.Group as={Col}>
                    <Form.Check
                        type='checkbox'
                        label={t(
                            'project-wizard-info-publish-project-investors'
                        )}
                        name='publishToInvestors'
                        ref={register({ required: false })}
                    />
                </Form.Group>
            )}

            {publishToCompaniesEnabled && (
                <Form.Group as={Col}>
                    <Form.Check
                        type='checkbox'
                        label={t(
                            'project-wizard-info-publish-project-companies'
                        )}
                        name='publishToCompanies'
                        ref={register({ required: false })}
                    />
                </Form.Group>
            )}

            {publishToAgenciesEnabled && (
                <Form.Group as={Col}>
                    <Form.Check
                        type='checkbox'
                        label={t(
                            'project-wizard-info-publish-project-agencies'
                        )}
                        name='publishToAgencies'
                        ref={register({ required: false })}
                    />
                </Form.Group>
            )}

            <h3>{t('project-wizard-info-financial-details')}</h3>

            {FINANCIAL_PRICE_FIELDS.map((field) => (
                <Form.Group as={Col} xs={4} key={field.name}>
                    <Form.Label>{t(field.label)}</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type='number'
                            name={field.name}
                            disabled={field.calculated}
                            step='any'
                            isInvalid={errors[field.name]}
                            ref={register({ required: false, min: 0 })}
                        />

                        <InputGroup.Append>
                            <InputGroup.Text>{field.suffix}</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Form.Group>
            ))}

            <h3>{t('project-wizard-info-shared-files')}</h3>

            <Col xs={6}>
                <p> {t('project-wizard-info-project-documents')}</p>
                <MultiFilePicker
                    initialFiles={initialDocuments}
                    onFilesChanged={onDocumentsChanged}
                />
            </Col>
            <Col xs={6}>
                <p>{t('project-wizard-info-project-images')}</p>
                <MultiFilePicker
                    acceptFiles='image/*'
                    initialFiles={initialImages}
                    onFilesChanged={onImagesChanged}
                />
            </Col>

            <Button type='submit'>{t('project-wizard-info-next-btn')}</Button>
        </Form>
    );
};
