// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useState, useEffect } from 'react';

import { ButtonGroup, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ToolMenu = ({
    className,
    modes = [],
    actions = [],
    initialMode,
    onInitialize = () => {},
    onModeSelect = () => {},
    onActionCall = () => {},
}) => {
    const [currentMode, setCurrentMode] = useState(initialMode);

    useEffect(() => {
        onInitialize({ changeMode: (mode) => setCurrentMode(mode) });
    }, [onInitialize]);

    const selectMode = (e, mode) => {
        e.preventDefault();
        setCurrentMode(mode);
        onModeSelect(mode);
    };

    const callAction = (e, action) => {
        e.preventDefault();
        onActionCall(action, currentMode);
    };

    return (
        <div className={`${className || ''} d-flex flex-column`}>
            <ButtonGroup vertical>
                {modes.map((mode) => (
                    <OverlayTrigger
                        key={mode.name}
                        placement='right'
                        overlay={<Tooltip>{mode.tooltip}</Tooltip>}
                    >
                        <Button
                            key={mode.name}
                            active={currentMode === mode.name}
                            onClick={(e) => selectMode(e, mode.name)}
                        >
                            <FontAwesomeIcon icon={mode.icon} />
                        </Button>
                    </OverlayTrigger>
                ))}
            </ButtonGroup>

            <ButtonGroup vertical className='mt-2'>
                {actions.map((action) => (
                    <OverlayTrigger
                        key={action.name}
                        placement='right'
                        overlay={<Tooltip>{action.tooltip}</Tooltip>}
                    >
                        <Button
                            variant='light'
                            key={action.name}
                            onClick={(e) => callAction(e, action.name)}
                        >
                            <FontAwesomeIcon icon={action.icon} />
                        </Button>
                    </OverlayTrigger>
                ))}
            </ButtonGroup>
        </div>
    );
};

export { ToolMenu };
