// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { createReducer } from '@reduxjs/toolkit';

export const makeModelReducer = (scope, initial = null, reducer = {}) =>
    createReducer(initial, {
        [`${scope}:reset`]: () => initial,
        [`${scope}:request`]: () => initial,
        [`${scope}:success`]: (state, action) => ({
            ...state,
            ...action.payload,
        }),
        [`${scope}:failure`]: (state) => state,
        ...reducer,
    });
