// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import {
    putJsonRequest,
    getJsonRequest,
    postJsonRequest,
    deleteRequest,
    postRequest,
    withAuth,
    withOffsetLimit,
    withOwn,
} from './common';

import { getFileInfo } from './file.api';
import { getImageInfo } from './image.api';

export const getProjectsInBounds = (auth, bounds) =>
    postJsonRequest(
        `/projects/map/search`,
        withAuth(auth, { body: JSON.stringify(bounds) })
    );

export const getProjectInfosInBounds = (auth, bounds, offset, limit) =>
    postJsonRequest(
        withOffsetLimit(`/projects/search/info`, offset, limit),
        withAuth(auth, { body: JSON.stringify({ rectangleData: bounds }) })
    );

export const getProjectIdsInBounds = (auth, bounds, offset, limit) =>
    postJsonRequest(
        withOffsetLimit(`/projects/search/ids`, offset, limit),
        withAuth(auth, { body: JSON.stringify({ rectangleData: bounds }) })
    );

export const getProjects = (auth, offset, limit) =>
    getJsonRequest(withOffsetLimit(`/projects`, offset, limit), withAuth(auth));

export const getProjectImportStatus = (auth, id) =>
    getJsonRequest(`/projects/${id}/cad-files/status`, withAuth(auth));

export const getOwnProjects = (auth, offset, limit) =>
    getJsonRequest(
        withOwn(withOffsetLimit(`/projects`, offset, limit), auth.id),
        withAuth(auth)
    );

export const getProject = (auth, projectId) =>
    getJsonRequest(`/projects/${projectId}`, withAuth(auth));

export const editProject = (auth, projectId, project) =>
    putJsonRequest(
        `/projects/${projectId}`,
        withAuth(auth, {
            body: JSON.stringify({
                name: project.name,
                description: project.description,
                country: project.country,
                city: project.city,
                groupId: project.groupId,
                projectFinancialData: project.financialDetails,
                constructionStatus: project.constructionStatus,
                developmentStatus: project.developmentStatus,
                investmentStatus: project.investmentStatus,
                publishConstructionCompanies: project.publishConstructionCompanies,
                publishInvestors: project.publishInvestors,
                publishRealEstate: project.publishRealEstate,
                totalArea: project.totalArea,
                totalNetArea: project.totalNetArea,
                anchorPoint: project.anchorPoint,
                releaseDate: project.releaseDate,
            }),
        })
    );

export const changeVisibility = (auth, projectId, visibility) =>
    putJsonRequest(
        `/projects/${projectId}/visibility`,
        withAuth(auth, {
            body: JSON.stringify({
                visibilityType: visibility,
            }),
        })
    );

export const changeLayer = (auth, projectId, layers) =>
    putJsonRequest(
        `/projects/${projectId}/layers`,
        withAuth(auth, {
            body: JSON.stringify(layers),
        })
    );

// Project areas

export const getProjectArea = (auth, projectId, id) =>
    getJsonRequest(`/projects/${projectId}/areas/${id}`, withAuth(auth));

export const getProjectAreas = (auth, projectId, offset, limit) =>
    getJsonRequest(
        withOffsetLimit(`/projects/${projectId}/areas`, offset, limit),
        withAuth(auth)
    );

export const getProjectAreasInBounds = (auth, projectId, bounds, filter = []) =>
    postJsonRequest(
        `/projects/${projectId}/areas/search`,
        withAuth(auth, {
            body: JSON.stringify({
                filterProjectAreaIds: filter,
                rectangleData: bounds,
            }),
        })
    );

export const createProjectArea = (auth, projectId, area) =>
    postJsonRequest(
        `/projects/${projectId}/areas`,
        withAuth(auth, {
            body: JSON.stringify({
                polygon: area.geoJson,
                title: area.title,
                link: area.link,
                typeId: area.typeId,
                description: area.description,
            }),
        }),
        (r) => r.json()
    );

export const editProjectArea = (auth, projectId, areaId, area) =>
    putJsonRequest(
        `/projects/${projectId}/areas/${areaId}`,
        withAuth(auth, {
            body: JSON.stringify({
                polygon: area.geoJson,
                title: area.title,
                link: area.link,
                typeId: area.typeId,
                description: area.description,
            }),
        })
    );

export const deleteProjectArea = (auth, projectId, id) =>
    deleteRequest(`/projects/${projectId}/areas/${id}`, withAuth(auth));

// Project segments

export const getProjectSegment = (auth, projectId, segmentId) =>
    getJsonRequest(`/projects/segments/${segmentId}`, withAuth(auth));

export const getProjectSegments = (auth, projectId, offset, limit) =>
    getJsonRequest(
        withOffsetLimit(`/projects/${projectId}/segments`, offset, limit),
        withAuth(auth)
    );

export const getProjectSegmentsInBounds = (
    auth,
    projectId,
    bounds,
    filter = []
) =>
    postJsonRequest(
        `/projects/${projectId}/segments/search`,
        withAuth(auth, {
            body: JSON.stringify({
                filterProjectSegmentIds: filter,
                rectangleData: bounds,
            }),
        })
    );

export const getProjectSegmentInfo = (auth, projectId, segmentId) =>
    getJsonRequest(
        `/projects/${projectId}/segments/${segmentId}/info`,
        withAuth(auth)
    );

export const getProjectSegmentGeo = (auth, projectId, segmentId) =>
    getJsonRequest(
        `/projects/${projectId}/segments/${segmentId}/geo-json`,
        withAuth(auth)
    );

export const createProjectSegment = (auth, projectId, segment) =>
    postJsonRequest(
        `/projects/${projectId}/segments`,
        withAuth(auth, {
            body: JSON.stringify(segment),
        }),
        (r) => r.json()
    );

export const editProjectSegment = (auth, projectId, segmentId, segment) =>
    putJsonRequest(
        `/projects/${projectId}/segments/${segmentId}`,
        withAuth(auth, {
            body: JSON.stringify(segment),
        }),
        (r) => r.json()
    );

export const deleteProjectSegment = (auth, projectId, id) =>
    deleteRequest(`/projects/segments/${id}`, withAuth(auth));

// Project segment types
export const getProjectSegmentTypes = (auth, offset, limit) =>
    getJsonRequest(
        withOffsetLimit(`/projects/project-segment-types`, offset, limit),
        withAuth(auth)
    );

export const createProjectSegmentType = (auth, segmentType) =>
    postJsonRequest(
        `/projects/project-segment-types`,
        withAuth(auth, { body: JSON.stringify(segmentType) })
    );

export const deleteProject = (auth, id) =>
    deleteRequest(`/projects/${id}`, withAuth(auth));

export const deleteProjectSegmentType = (auth, id) =>
    deleteRequest(`/projects/project-segment-types/${id}`, withAuth(auth));

// Project area types
export const getProjectAreaTypes = (auth, offset, limit) =>
    getJsonRequest(
        withOffsetLimit(`/projects/project-area-types`, offset, limit),
        withAuth(auth)
    );

export const createProject = async (auth, project, cadFile, anchor) => {
    const formData = new FormData();

    formData.append(
        'projectData',
        JSON.stringify({
            name: project.name,
            country: project.country,
            city: project.city,
            anchorPoint: anchor,
            totalArea: 0.0,
            totalNetArea: 0.0,
            projectFinancialData: null,
            constructionStatus: null,
            developmentStatus: null,
            investmentStatus: null,
        })
    );

    if (cadFile) {
        formData.append('file', cadFile);
    }

    const projectModel = await postRequest(
        `/projects`,
        withAuth(auth, {
            headers: {
                Connection: 'keep-alive',
            },
            body: formData,
        }),
        (r) => r.json()
    );

    return projectModel;
};

export const getProjectImage = (auth, projectId, imageId) =>
    getImageInfo(imageId, withAuth(auth));

export const addProjectImage = (auth, projectId, file) => {
    const formData = new FormData();
    formData.append('file', file);
    return postRequest(
        `/projects/${projectId}/cover-images`,
        withAuth(auth, { body: formData })
    );
};
export const deleteProjectImage = (auth, projectId, imageId) =>
    deleteRequest(
        `/projects/${projectId}/cover-images/${imageId}`,
        withAuth(auth)
    );

export const getProjectDocument = (auth, projectId, documentId) =>
    getFileInfo(documentId, withAuth(auth));

export const addProjectDocument = (auth, projectId, file) => {
    const formData = new FormData();
    formData.append('file', file);
    return postRequest(
        `/projects/${projectId}/documents`,
        withAuth(auth, { body: formData })
    );
};

export const deleteProjectDocument = (auth, projectId, documentId) =>
    deleteRequest(
        `/projects/${projectId}/documents/${documentId}`,
        withAuth(auth)
    );

export const getProjectSegmentImage = (auth, projectId, segmentId, imageId) =>
    getImageInfo(imageId, withAuth(auth));

export const addProjectSegmentImage = (auth, projectId, segmentId, file) => {
    const formData = new FormData();
    formData.append('file', file);
    return postRequest(
        `/projects/${projectId}/segments/${segmentId}/cover-images`,
        withAuth(auth, { body: formData })
    );
};
export const deleteProjectSegmentImage = (
    auth,
    projectId,
    segmentId,
    imageId
) =>
    deleteRequest(
        `/projects/${projectId}/segments/${segmentId}/cover-images/${imageId}`,
        withAuth(auth)
    );

export const getProjectSegmentDocument = (
    auth,
    projectId,
    segmentId,
    documentId
) => getFileInfo(documentId, withAuth(auth));

export const addProjectSegmentDocument = (auth, projectId, segmentId, file) => {
    const formData = new FormData();
    formData.append('file', file);
    return postRequest(
        `/projects/${projectId}/segments/${segmentId}/documents`,
        withAuth(auth, { body: formData })
    );
};

export const deleteProjectSegmentDocument = (
    auth,
    projectId,
    segmentId,
    documentId
) =>
    deleteRequest(
        `/projects/${projectId}/segments/${segmentId}/documents/${documentId}`,
        withAuth(auth)
    );

export const getProjectAreaImage = (auth, projectId, areaId, imageId) =>
    getImageInfo(imageId, withAuth(auth));

export const addProjectAreaImage = (auth, projectId, areaId, file) => {
    const formData = new FormData();
    formData.append('file', file);
    return postRequest(
        `/projects/${projectId}/areas/${areaId}/cover-images`,
        withAuth(auth, { body: formData })
    );
};
export const deleteProjectAreaImage = (auth, projectId, areaId, imageId) =>
    deleteRequest(
        `/projects/${projectId}/areas/${areaId}/cover-images/${imageId}`,
        withAuth(auth)
    );

export const getProjectAreaDocument = (auth, projectId, areaId, documentId) =>
    getFileInfo(documentId, withAuth(auth));

export const addProjectAreaDocument = (auth, projectId, areaId, file) => {
    const formData = new FormData();
    formData.append('file', file);
    return postRequest(
        `/projects/${projectId}/areas/${areaId}/documents`,
        withAuth(auth, { body: formData })
    );
};

export const deleteProjectAreaDocument = (
    auth,
    projectId,
    areaId,
    documentId
) =>
    deleteRequest(
        `/projects/${projectId}/areas/${areaId}/documents/${documentId}`,
        withAuth(auth)
    );
