// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Form } from 'react-bootstrap';

import { BaseDialog } from 'components/dialogs/BaseDialog';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Actions from 'actions';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const CreateRequestDialog = ({
    show,
    onHide,
    onClose,
    onAccept = () => {},
}) => {
    const { register, handleSubmit } = useForm({});
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(Actions.requests.createRequest.reset());
        dispatch(Actions.projects.ownListFetch());
    }, [dispatch]);

    const projectList = useSelector((state) => state.projects.ownList.list);
    const createRequest = useSelector((state) => state.requests.createRequest);

    const onSubmit = (data) => {
        dispatch(Actions.requests.createRequest.execute(data.project));
        onAccept();
    };

    const projects = projectList.allIds;

    if (0 === projects.length) {
        return <></>;
    }
    return (
        <BaseDialog
            title={t('create-request-title')}
            onSubmit={handleSubmit(onSubmit)}
            show={show}
            onHide={onHide}
            onClose={onClose}
            onAccept={onAccept}
            pending={createRequest.process.pending}
            finished={null != createRequest.model}
        >
            <Form.Group>
                <Form.Label>{t('create-request-project')}</Form.Label>
                <Form.Control
                    as='select'
                    defaultValue={projectList.allIds[0]}
                    name='project'
                    ref={register}
                >
                    {projects.map((id) => (
                        <option key={id} value={id}>
                            {projectList.byId[id].name}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>
        </BaseDialog>
    );
};

export { CreateRequestDialog };
