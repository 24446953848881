// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { all } from 'redux-saga/effects';

import { appSage } from './app.saga';
import {
    loginSaga,
    logoutSaga,
    registerSaga,
    refreshSaga,
    saveMapSaga,
    restorePasswordSaga,
} from './account.saga';
import { projectsSaga } from './projects.saga';
import { requestsSaga } from './requests.saga';
import { mapSaga } from './map.saga';
import { settingsSaga } from './settings.saga';
import { usersSage } from './users.saga';
import { investmentsSaga } from './investments.saga';
import { joinRequestsSaga } from './joinrequests.saga';
import { forumSaga } from './forum.saga';

function* rootSaga() {
    yield all([
        appSage(),
        loginSaga(),
        logoutSaga(),
        registerSaga(),
        refreshSaga(),
        requestsSaga(),
        projectsSaga(),
        mapSaga(),
        settingsSaga(),
        saveMapSaga(),
        usersSage(),
        investmentsSaga(),
        joinRequestsSaga(),
        forumSaga(),
        restorePasswordSaga(),
    ]);
}

const runSagas = (middleware) => {
    middleware.run(rootSaga);
};

export { runSagas };
