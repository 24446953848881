// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Container, Row } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

const PageLoader = () => (
    <Container fluid className='p-4'>
        <Row className='justify-content-center'>
            <Spinner animation='border' />
        </Row>
    </Container>
);

export { PageLoader };
