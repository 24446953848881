// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

const PageLayout = ({ children, className }) => (
    <Container fluid className={`${className || ''} p-4`}>
        <Row className='justify-content-left ml-4'>
            <Col xs={10} sm={10} md={10} lg={10} xl={8}>
                {children}
            </Col>
        </Row>
    </Container>
);

export { PageLayout };
