// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { BasePage } from 'components/pages/BasePage';
import { Col, Row } from 'react-bootstrap';

const TermsAndConditions = () => {
    return (
        <BasePage>
            <Row className='p-4 justify-content-center'>
                <Col xs={8}>
                    <p class='p1'>
                        <b>Terms and Conditions for Genconstrux GmbH</b>
                    </p>
                    <p class='p2'>
                        <b>Introduction</b>
                    </p>
                    <p class='p3'>
                        These Website Standard Terms and Conditions written on
                        this webpage shall manage your use of our website, 
                        <span class='s1'>Website Name</span> accessible at 
                        <span class='s1'>www.genconstrux.com</span>.
                    </p>
                    <p class='p3'>
                        These Terms will be applied fully and affect to your use
                        of this Website. By using this Website, you agreed to
                        accept all terms and conditions written in here. You
                        must not use this Website if you disagree with any of
                        these Website Standard Terms and Conditions.
                    </p>
                    <p class='p3'>
                        Minors or people below 18 years old are not allowed to
                        use this Website.
                    </p>
                    <p class='p2'>
                        <b>Intellectual Property Rights</b>
                    </p>
                    <p class='p3'>
                        Other than the content you own, under these Terms, 
                        <span class='s1'>Genconstrux</span> and/or its licensors
                        own all the intellectual property rights and materials
                        contained in this Website.
                    </p>
                    <p class='p3'>
                        You are granted limited license only for purposes of
                        viewing the material contained on this Website.
                    </p>
                    <p class='p2'>
                        <b>Restrictions</b>
                    </p>
                    <p class='p3'>
                        You are specifically restricted from all of the
                        following:
                    </p>
                    <ul class='ul1'>
                        <li class='li4'>
                            <span class='s2'></span>
                            <span class='s3'>
                                publishing any Website material in any other
                                media;
                            </span>
                        </li>
                        <li class='li4'>
                            <span class='s2'></span>
                            <span class='s3'>
                                selling, sublicensing and/or otherwise
                                commercializing any Website material;
                            </span>
                        </li>
                        <li class='li4'>
                            <span class='s2'></span>
                            <span class='s3'>
                                publicly performing and/or showing any Website
                                material;
                            </span>
                        </li>
                        <li class='li4'>
                            <span class='s2'></span>
                            <span class='s3'>
                                using this Website in any way that is or may be
                                damaging to this Website;
                            </span>
                        </li>
                        <li class='li4'>
                            <span class='s2'></span>
                            <span class='s3'>
                                using this Website in any way that impacts user
                                access to this Website;
                            </span>
                        </li>
                        <li class='li4'>
                            <span class='s2'></span>
                            <span class='s3'>
                                using this Website contrary to applicable laws
                                and regulations, or in any way may cause harm to
                                the Website, or to any person or business
                                entity;
                            </span>
                        </li>
                        <li class='li4'>
                            <span class='s2'></span>
                            <span class='s3'>
                                engaging in any data mining, data harvesting,
                                data extracting or any other similar activity in
                                relation to this Website;
                            </span>
                        </li>
                        <li class='li5'>
                            <span class='s2'></span>
                            <span class='s3'>
                                using this Website to engage in any advertising
                                or marketing.
                            </span>
                        </li>
                    </ul>
                    <p class='p3'>
                        Certain areas of this Website are restricted from being
                        access by you and <span class='s1'>Genconstrux</span>
                         may further restrict access by you to any areas of this
                        Website, at any time, in absolute discretion. Any user
                        ID and password you may have for this Website are
                        confidential and you must maintain confidentiality as
                        well.
                    </p>
                    <p class='p6'>
                        <br />
                    </p>
                    <p class='p2'>
                        <b>Your Content</b>
                    </p>
                    <p class='p3'>
                        In these Website Standard Terms and Conditions, “Your
                        Content” shall mean any audio, video text, images or
                        other material you choose to display on this Website. By
                        displaying Your Content, you grant 
                        <span class='s1'>Genconstrux</span> a non-exclusive,
                        worldwide irrevocable, sub licensable license to use,
                        reproduce, adapt, publish, translate and distribute it
                        in any and all media.
                    </p>
                    <p class='p3'>
                        Your Content must be your own and must not be invading
                        any third-party's rights. 
                        <span class='s1'>Genconstrux</span> reserves the right
                        to remove any of Your Content from this Website at any
                        time without notice.
                    </p>
                    <p class='p2'>
                        <b>No warranties</b>
                    </p>
                    <p class='p3'>
                        This Website is provided “as is,” with all faults, and 
                        <span class='s1'>Genconstrux</span> express no
                        representations or warranties, of any kind related to
                        this Website or the materials contained on this Website.
                        Also, nothing contained on this Website shall be
                        interpreted as advising you.
                    </p>
                    <p class='p2'>
                        <b>Limitation of liability</b>
                    </p>
                    <p class='p3'>
                        In no event shall <span class='s1'>Genconstrux</span>,
                        nor any of its officers, directors and employees, shall
                        be held liable for anything arising out of or in any way
                        connected with your use of this Website whether such
                        liability is under contract.  
                        <span class='s1'>Genconstrux</span>, including its
                        officers, directors and employees shall not be held
                        liable for any indirect, consequential or special
                        liability arising out of or in any way related to your
                        use of this Website.
                    </p>
                    <p class='p2'>
                        <b>Indemnification</b>
                    </p>
                    <p class='p2'>
                        <b>You hereby indemnify to the fullest extent </b>
                        <span class='s1'>
                            <b>Genconstrux</b>
                        </span>
                        <b>
                             from and against any and/or all liabilities, costs,
                            demands, causes of action, damages and expenses
                            arising in any way related to your breach of any of
                            the provisions of these Terms.
                        </b>
                    </p>
                    <p class='p2'>
                        <b>Severability</b>
                    </p>
                    <p class='p3'>
                        If any provision of these Terms is found to be invalid
                        under any applicable law, such provisions shall be
                        deleted without affecting the remaining provisions
                        herein.
                    </p>
                    <p class='p2'>
                        <b>Variation of Terms</b>
                    </p>
                    <p class='p3'>
                        <span class='s1'>Genconstrux</span> is permitted to
                        revise these Terms at any time as it sees fit, and by
                        using this Website you are expected to review these
                        Terms on a regular basis.
                    </p>
                    <p class='p2'>
                        <b>Assignment</b>
                    </p>
                    <p class='p3'>
                        The <span class='s1'>Genconstrux</span> is allowed to
                        assign, transfer, and subcontract its rights and/or
                        obligations under these Terms without any notification.
                        However, you are not allowed to assign, transfer, or
                        subcontract any of your rights and/or obligations under
                        these Terms.
                    </p>
                    <p class='p2'>
                        <b>Entire Agreement</b>
                    </p>
                    <p class='p3'>
                        These Terms constitute the entire agreement between 
                        <span class='s1'>Genconstrux</span> and you in relation
                        to your use of this Website, and supersede all prior
                        agreements and understandings.
                    </p>
                    <p class='p2'>
                        <b>Governing Law &amp; Jurisdiction</b>
                    </p>
                    <p class='p7'>
                        These Terms will be governed by and interpreted in
                        accordance with the laws of the State of 
                        <span class='s1'>Cologne - Germany</span>, and you
                        submit to the non-exclusive jurisdiction of the state
                        and federal courts located in 
                        <span class='s1'>Germany </span>for the resolution of
                        any disputes.
                    </p>
                    <p class='p2'>
                        <b>
                            Using our Platforms agreement
                            <span class='Apple-converted-space'> </span>
                        </b>
                    </p>
                    <p class='p5'>
                        You agree that the data you will contribute and added in
                        our Maps for GENDIGIT Infrastructure Platform can be
                        used and given to other side of interest.
                    </p>
                    <p class='p7'>
                        You agree that the data you will contribute and added in
                        our Maps for GENDIGIT Real Estate and Construction
                        Development Platform can be used and given to other side
                        of interest.
                    </p>
                    <p class='p8'>Imprint</p>
                    <p class='p8'>Managing director: Mustafa Sindi</p>
                    <p class='p8'>
                        Content responsibility (TMG): Mustafa Sindi (For more
                        information, visit the german website version)
                    </p>
                    <p class='p8'> </p>
                    <p class='p8'>Registration court: Amtgericht köln</p>
                    <p class='p8'>Registration number: HRB 100428</p>
                    <p class='p8'>
                        USt-ID number (paragraph 27 a): DE328856063
                    </p>
                    <p class='p8'> </p>
                    <p class='p8'>Phone: +492272 8089 066</p>
                    <p class='p9'>
                        <span class='s4'>
                            Mail: 
                            <a href='mailto:info@genconstrux.com'>
                                <span class='s5'>info@genconstrux.com</span>
                            </a>
                        </span>
                    </p>
                    <p class='p8'> </p>
                    <p class='p8'>Adress:</p>
                    <p class='p8'>Genconstrux GmbH</p>
                    <p class='p8'>Finkenweg 11</p>
                    <p class='p8'>50181 Bedburg</p>
                    <p class='p8'>Content responsibility: </p>
                    <p class='p8'>GENCONSTRUX GmbH</p>
                </Col>
            </Row>
        </BasePage>
    );
};

export { TermsAndConditions };
