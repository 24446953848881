// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Row } from 'react-bootstrap';

const PageHeader = ({ title, children, className }) => (
    <Row
        className={`${
            className || ''
        } justify-content-between align-items-center`}
    >
        <h4>{title}</h4>
        {children}
    </Row>
);

export { PageHeader };
