// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
    Button,
    ButtonGroup,
    OverlayTrigger,
    Table,
    Tooltip,
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LinkButton } from 'components/common/LinkButton';
import { StatusBadge } from 'components/common/StatusBadge';
import { DeleteButton } from 'components/common/DeleteButton';

import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';

import Actions from 'actions';

const TableRow = ({
    project,
    canDelete = false,
    canRequest = false,
    isAdmin,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const goToProject = () => {
        dispatch(push(`/projects/${project.id}`));
    };

    return (
        <tr>
            {isAdmin && <td>{project.creator?.username}</td>}
            <td onClick={goToProject}>{project.name}</td>
            <td onClick={goToProject}>
                {`${project.country ? project.country : ''} ${
                    project.city ? project.city : ''
                }`}
            </td>
            <td onClick={goToProject}>
                <StatusBadge
                    labelMap={{
                        PUBLIC: t('projects-table-status-public'),
                        PRIVATE: t('projects-table-status-private'),
                        FAILED: t('projects-table-status-failed'),
                        IMPORTING: t('projects-table-status-importing'),
                    }}
                    variantMap={{
                        PUBLIC: 'success',
                        PRIVATE: 'warning',
                        FAILED: 'danger',
                        IMPORTING: 'primary',
                    }}
                    value={
                        (project.isFailed && 'FAILED') ||
                        (project.isImporting && 'IMPORTING') ||
                        project.visibilityType ||
                        'PRIVATE'
                    }
                />
            </td>
            <td onClick={goToProject}>
                {new Date(project.createdAt).toLocaleString()}
            </td>
            <td>
                <ButtonGroup>
                    <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>{t('project-table-view')}</Tooltip>}
                    >
                        <LinkButton
                            variant='outline-secondary'
                            to={`/projects/${project.id}`}
                            size='sm'
                        >
                            <FontAwesomeIcon icon='eye' />
                        </LinkButton>
                    </OverlayTrigger>

                    <OverlayTrigger
                        placement='top'
                        overlay={
                            (canRequest && (
                                <Tooltip>{t('project-table-request')}</Tooltip>
                            )) || <div />
                        }
                    >
                        <Button
                            variant='outline-secondary'
                            size='sm'
                            disabled={!canRequest}
                            onClick={() => {
                                dispatch(
                                    Actions.requests.createRequest.execute(
                                        project.id
                                    )
                                );
                                goToProject();
                            }}
                        >
                            <FontAwesomeIcon icon='check-square' />
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                        placement='top'
                        overlay={
                            (canDelete && (
                                <Tooltip>{t('project-table-delete')}</Tooltip>
                            )) || <div />
                        }
                    >
                        <DeleteButton
                            disabled={!canDelete}
                            callAction={() =>
                                Actions.projects.deleteProject(project.id)
                            }
                        />
                    </OverlayTrigger>
                </ButtonGroup>
            </td>
        </tr>
    );
};

const TableHeader = ({ isAdmin }) => {
    const { t } = useTranslation();

    return (
        <thead>
            <tr>
                {isAdmin && <th>Creator</th>}
                <th>{t('projects-table-name-col')}</th>
                <th>{t('projects-table-location-col')}</th>
                <th>{t('projects-table-visibility-col')}</th>
                <th>{t('projects-table-created-at-col')}</th>
                <th>{t('projects-table-actions-col')}</th>
            </tr>
        </thead>
    );
};
const ProjectsTable = ({ model = [] }) => {
    const user = useSelector((state) => state.account.user);
    const isAdmin = user && user.permissions.includes('ADMIN');
    return (
        <Table bordered hover>
            <TableHeader isAdmin={isAdmin} />
            <tbody>
                {model.map((project) => (
                    <TableRow
                        key={project.id}
                        project={project}
                        canDelete={
                            user &&
                            (user.id === project.ownerId ||
                                user.permissions.includes('ADMIN'))
                        }
                        isAdmin={isAdmin}
                        canRequest={
                            user &&
                            user.id === project.ownerId &&
                            user.features.canRequestProjectPublish
                        }
                    />
                ))}
            </tbody>
        </Table>
    );
};

export { ProjectsTable };
