// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DialogButton } from 'components/common/DialogButton';
import { StatusBadge } from 'components/common/StatusBadge';

import Actions from 'actions';
import { useSelector } from 'react-redux';
import { DeleteDialog } from 'components/dialogs/DeleteDialog';
import { useTranslation } from 'react-i18next';
import { ButtonGroup } from 'react-bootstrap';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from 'react-bootstrap';
import { ApproveDialog } from 'components/dialogs/ApproveDialog';
import { LinkButton } from 'components/common/LinkButton';

const TableRow = ({ user, investment }) => {
    const { t } = useTranslation();

    const approveInvest = useSelector(
        (state) => state.investments.approveInvest
    );

    const declineInvest = useSelector(
        (state) => state.investments.declineInvest
    );

    const cancelInvest = useSelector((state) => state.investments.cancelInvest);

    const isUserInvest = user.id === investment.investorId;
    const isUserApprover =
        user.permissions.includes('ADMIN') ||
        user.permissions.includes('DEVELOPER');
    const canApprove = 'PENDING' === investment.status;
    const canCancel = 'PENDING' === investment.status;

    return (
        <tr>
            <td style={{ height: '40px', verticalAlign: 'middle' }}>
                {new Date(investment.createdAt).toLocaleString()}
            </td>
            <td style={{ height: '40px', verticalAlign: 'middle' }}>
                <LinkButton to={`/projects/${investment.projectId}`}>
                    {investment.project.name}
                </LinkButton>
            </td>
            <td style={{ height: '40px', verticalAlign: 'middle' }}>
                {`${investment.user.firstName} ${investment.user.lastName}`}
            </td>
            <td style={{ height: '40px', verticalAlign: 'middle' }}>
                {`${investment.priceData.price} ${investment.priceData.currency}`}
            </td>
            <td style={{ height: '40px', verticalAlign: 'middle' }}>
                {investment.description}
            </td>
            <td style={{ height: '40px', verticalAlign: 'middle' }}>
                <StatusBadge
                    labelMap={{
                        PENDING: t('investment-table-status-pending'),
                        DECLINED: t('investment-table-status-declined'),
                        APPROVED: t('investment-table-status-approved'),
                        CANCELED: t('investment-table-status-canceled'),
                    }}
                    variantMap={{
                        PEDNDING: 'warning',
                        DECLINED: 'danger',
                        APPROVED: 'success',
                        CANCELED: 'danger',
                    }}
                    value={investment.status}
                />
            </td>
            <td style={{ height: '40px', verticalAlign: 'middle' }}>
                <ButtonGroup>
                    {isUserApprover && canApprove && (
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                <Tooltip>
                                    {t('investment-table-approve')}
                                </Tooltip>
                            }
                        >
                            <DialogButton
                                variant='outline-success'
                                size='sm'
                                dialog={ApproveDialog}
                                dialogParams={{
                                    requestModel: approveInvest,
                                    callAction: () =>
                                        Actions.investments.approveInvest.execute(
                                            investment.projectId,
                                            investment.id
                                        ),
                                    resetAction: () =>
                                        Actions.investments.approveInvest.reset(),
                                    message: t(
                                        'investment-table-approve-message'
                                    ),
                                }}
                            >
                                <FontAwesomeIcon icon='check' />
                            </DialogButton>
                        </OverlayTrigger>
                    )}
                    {isUserApprover && canApprove && (
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                <Tooltip>
                                    {t('investment-table-decline')}
                                </Tooltip>
                            }
                        >
                            <DialogButton
                                variant='outline-danger'
                                size='sm'
                                dialog={DeleteDialog}
                                dialogParams={{
                                    requestModel: declineInvest,
                                    callAction: () =>
                                        Actions.investments.declineInvest.execute(
                                            investment.projectId,
                                            investment.id
                                        ),
                                    resetAction: () =>
                                        Actions.investments.declineInvest.reset(),
                                    acceptText: t(
                                        'investment-table-decline-btn'
                                    ),
                                    message: t(
                                        'investment-table-decline-message'
                                    ),
                                }}
                            >
                                <FontAwesomeIcon icon='trash' />
                            </DialogButton>
                        </OverlayTrigger>
                    )}
                    {isUserInvest && canCancel && (
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                <Tooltip>
                                    {t('investment-table-cancel')}
                                </Tooltip>
                            }
                        >
                            <DialogButton
                                variant='outline-danger'
                                size='sm'
                                dialog={DeleteDialog}
                                dialogParams={{
                                    requestModel: cancelInvest,
                                    callAction: () =>
                                        Actions.investments.cancelInvest.execute(
                                            investment.projectId,
                                            investment.id
                                        ),
                                    resetAction: () =>
                                        Actions.investments.cancelInvest.reset(),
                                    acceptText: t(
                                        'investment-table-cancel-btn'
                                    ),
                                    message: t(
                                        'investment-table-cancel-message'
                                    ),
                                }}
                            >
                                <FontAwesomeIcon icon='trash' />
                            </DialogButton>
                        </OverlayTrigger>
                    )}
                </ButtonGroup>
            </td>
        </tr>
    );
};

const TableHeader = () => {
    const { t } = useTranslation();
    return (
        <thead>
            <tr>
                <th>{t('investments-table-created-at-col')}</th>
                <th>{t('investments-table-project-col')}</th>
                <th>{t('investments-table-user-col')}</th>
                <th>{t('investments-table-price-col')}</th>
                <th>{t('investments-table-message-col')}</th>
                <th>{t('investments-table-status-col')}</th>
                <th>{t('investments-table-actions-col')}</th>
            </tr>
        </thead>
    );
};
const InvestmentsTable = ({ model }) => {
    const user = useSelector((state) => state.account.user);

    return (
        <Table bordered hover>
            <TableHeader />
            <tbody>
                {model.map((investment) => (
                    <TableRow
                        key={investment.id}
                        investment={investment}
                        user={user}
                    />
                ))}
            </tbody>
        </Table>
    );
};

export { InvestmentsTable };
