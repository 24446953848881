// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';

const CommonAlert = ({ scope, variant = 'danger', ...rest }) => {
    const dispatch = useDispatch();

    const scopePath = scope.join('/');

    const alert = useSelector(
        (state) => scope.reduce((s, n) => s[n], state).alert
    );

    const onClose = () => {
        dispatch({ type: `${scopePath}/alert:hide` });
    };
    if (!alert) {
        return <div></div>;
    }

    return (
        <Alert
            dismissible
            variant={variant}
            show={alert.visible}
            className='mt-4'
            onClose={onClose}
            {...rest}
        >
            {'' !== alert.title && <Alert.Heading>{alert.title}</Alert.Heading>}
            <p>{alert.message}</p>
        </Alert>
    );
};

export { CommonAlert };
