// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { createReducer } from '@reduxjs/toolkit';

import Actions from 'actions';

const initial = {
    isLoaded: false,
    systemLanguages: ['de', 'en'],
    currencies: [],
    userPermissions:[],
};

const appReducer = createReducer(initial, {
    [Actions.app.userInit]: (state, action) => ({
        ...state,
        segmentTypes: {
            byId: action.payload.segmentTypes.reduce((r, i) => {
                r[i.id] = i;
                return r;
            }, {}),
            allIds: action.payload.segmentTypes.map((i) => i.id),
        },
        areaTypes: {
            byId: action.payload.areaTypes.reduce((r, i) => {
                r[i.id] = i;
                return r;
            }, {}),
            allIds: action.payload.areaTypes.map((i) => i.id),
        },
        isLoaded: true,
    }),
    [Actions.app.systemInit]: (state, action) => ({
        ...state,
        systemLanguages: action.payload.systemLanguages,
        currencies: action.payload.currencies,
        userPermissions: action.payload.userPermissions,
        projectConstructionStatuses: action.payload.projectConstructionStatuses,
        projectDevelopmentStatuses: action.payload.projectDevelopmentStatuses,
        projectInvestmentStatuses: action.payload.projectInvestmentStatuses,
    }),
    [Actions.account.logout]: (state) => ({
        ...state,
        isLoaded: false,
        segmentTypes: null,
        areaTypes: null,
    }),
});

export default appReducer;
