// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { postJsonRequest, addQuery } from './common';

export const login = (username, password) =>
    postJsonRequest(`/accounts/user/login`, {
        body: JSON.stringify({ username: username, password: password }),
    });

export const refresh = (token) =>
    postJsonRequest(`/accounts/user/refresh`, {
        body: JSON.stringify({
            refreshToken: token,
        }),
    });

export const register = (user) =>
    postJsonRequest(`/accounts/users/register`, {
        body: JSON.stringify({
            username: user.username,
            email: user.email,
            password: user.password,
            firstName: user.firstName,
            lastName: user.lastName,
            userPermission: [user.role],
            language: user.language || 'de',
            coordinateSystem: user.coordinateSystem || 'UTM',
        }),
    });

export const forgotPassword = (email) =>
    postJsonRequest(
        addQuery(`/accounts/users/forgot-password`, `email=${email}`)
    );
