// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { BaseDialog } from 'components/dialogs/BaseDialog';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import Actions from 'actions';

const ApproveDialog = ({
    title = 'Approve',
    message,
    callAction,
    resetAction,
    show,
    onHide,
    onClose,
    requestModel,
    acceptText,
    acceptVariant = 'primary',
    onAccept = () => {},
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const handleAccept = () => {
        dispatch(callAction());
        onAccept();
    };

    useEffect(() => {
        if (resetAction) {
            dispatch(resetAction());
        }
    }, [show]);

    return (
        <BaseDialog
            title={title || t('approve-request-title')}
            show={show}
            onHide={onHide}
            onClose={onClose}
            acceptVariant={acceptVariant}
            acceptText={acceptText || t('approve-request-approve-btn')}
            onAccept={handleAccept}
            finished={!requestModel || null != requestModel.model}
            pending={requestModel && requestModel.process.pending}
        >
            <p>{message || t('approve-request-message')}</p>
        </BaseDialog>
    );
};

export { ApproveDialog };
