// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { DialogButton } from 'components/common/DialogButton'

const PageSectionAddItem = ({
    ...rest
}) => <DialogButton {...rest} />;

export { PageSectionAddItem };
