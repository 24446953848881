// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useEffect } from 'react';

import { Form, Row, Col } from 'react-bootstrap';

import { BaseDialog } from 'components/dialogs/BaseDialog';
import { DatePicker } from 'components/common/DatePicker';
import { useForm } from 'react-hook-form';

import { useDispatch, useSelector } from 'react-redux';

import Actions from 'actions';
import { useTranslation } from 'react-i18next';

const EditInvestmentPhaseDialog = ({
    project,
    phase,
    show,
    onHide,
    onClose,
    onAccept,
}) => {
    const currencies = useSelector((state) => state.app.currencies);
    const editPhase = useSelector((state) => state.investments.phase.edit);

    const { register, control, watch, handleSubmit } = useForm({
        defaultValues: phase
            ? {
                  name: phase.name,
                  description: phase.description,
                  dateFrom: new Date(phase.dateFrom),
                  dateTo: new Date(phase.dateTo),
                  priceValue: phase.priceData.price,
                  priceCurrency: phase.priceData.currency,
              }
            : {},
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const onSubmit = (data) => {
        dispatch(
            Actions.investments.editPhase.execute(
                project.id,
                phase ? phase.id : null,
                {
                    ...phase,
                    priceData: {
                        price: parseFloat(data.priceValue),
                        currency: data.priceCurrency,
                    },
                    name: data.name,
                    description: data.description,
                    dateTo: data.dateTo.toISOString(),
                    dateFrom: data.dateFrom.toISOString(),
                }
            )
        );
    };

    const { dateFrom, dateTo } = watch(['dateFrom', 'dateTo']);

    return (
        <BaseDialog
            title={t('edit-investment-phase-title')}
            onSubmit={handleSubmit(onSubmit)}
            show={show}
            onHide={onHide}
            onClose={onClose}
            onAccept={onAccept}
            pending={editPhase.process.pending}
            finished={null !== editPhase.model}
        >
            <Form.Group>
                <Form.Label>{t('edit-investment-phase-name')}</Form.Label>
                <Form.Control
                    type='text'
                    name='name'
                    ref={register({ required: true })}
                />
                <Form.Label>
                    {t('edit-investment-phase-description')}
                </Form.Label>
                <Form.Control as='textarea' name='description' ref={register} />
                <Form.Label>{t('edit-investment-phase-date-range')}</Form.Label>
                <Form.Group as={Row}>
                    <Col>
                        <DatePicker
                            control={control}
                            startDate={dateFrom}
                            endDate={dateTo}
                            selectsStart
                            name='dateFrom'
                            placeholderText={t(
                                'edit-investment-phase-date-from'
                            )}
                            required
                        />
                    </Col>
                    <Col>
                        <DatePicker
                            control={control}
                            startDate={dateFrom}
                            endDate={dateTo}
                            minDate={dateFrom}
                            selectsEnd
                            name='dateTo'
                            placeholderText={t('edit-investment-phase-date-to')}
                            required
                        />
                    </Col>
                </Form.Group>
                <Form.Label>{t('edit-investment-phase-price')}</Form.Label>
                <Form.Group as={Row}>
                    <Col>
                        <Form.Control
                            type='number'
                            step='any'
                            name='priceValue'
                            ref={register({ required: true, min: 0.0 })}
                        />
                    </Col>
                    <Col>
                        <Form.Control
                            as='select'
                            defaultValue={currencies[0].toUpperCase()}
                            name='priceCurrency'
                            ref={register({ required: true })}
                        >
                            {currencies.map((currency) => (
                                <option
                                    key={currency}
                                    value={currency.toUpperCase()}
                                >
                                    {currency.toUpperCase()}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                </Form.Group>
            </Form.Group>
        </BaseDialog>
    );
};

export { EditInvestmentPhaseDialog };
