// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { createReducer } from '@reduxjs/toolkit';

const initial = {
    pending: false,
    message: '',
    progress: 100.0,
};

export const makeProcessReducer = (scope) =>
    createReducer(initial, {
        [`${scope}/process:start`]: (state, action) => ({
            ...state,
            pending: true,
            progress: 0.0,
            message: action.payload.message || '',
        }),
        [`${scope}/process:stop`]: () => initial,
        [`${scope}/process:progress`]: (state, { payload }) => ({
            ...state,
            progress:
                0.0 === payload.total ? 0.0 : payload.current / payload.total,
        }),
    });
