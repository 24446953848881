// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useState } from 'react';

import { Row, Form, ListGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MultiFilePicker = ({ initialFiles, onFilesChanged, acceptFiles }) => {
    const [files, setFiles] = useState(initialFiles || []);

    const onSelected = (e) => {
        const newFiles = files.concat(
            ...[...e.target.files].flatMap((file) => [
                {
                    file,
                    name: file.name,
                    filename: file.name,
                    isTemp: true,
                    isUpload: true,
                    isDeleted: false,
                },
            ])
        );

        setFiles(newFiles);

        e.target.value = null;

        onFilesChanged && onFilesChanged(newFiles);
    };

    const onRemove = (e, index) => {
        e.preventDefault();

        const file = files[index];

        file.isDeleted = true;

        const newFiles = files.slice();
        if (file.isUpload) {
            newFiles.splice(index, 1);
            setFiles(newFiles);
        }
        setFiles(newFiles);

        onFilesChanged && onFilesChanged(newFiles);
    };

    const onRestore = (e, index) => {
        e.preventDefault();

        const file = files[index];

        file.isDeleted = false;

        const newFiles = files.slice();
        setFiles(newFiles);

        onFilesChanged && onFilesChanged(newFiles);
    };

    return (
        <Form.Group>
            <ListGroup>
                {files.map((file, index) => (
                    <ListGroup.Item key={index}>
                        <Row className='justify-content-between align-items-center px-2'>
                            <p className='align-self-center m-0'>
                                <FontAwesomeIcon
                                    icon={
                                        file.isUpload ? 'file-upload' : 'file'
                                    }
                                    className='mr-2'
                                />{' '}
                                {file.name}
                            </p>
                            <Button
                                disabled={!file.id && !file.isUpload}
                                variant={
                                    file.isDeleted
                                        ? 'secondary'
                                        : 'outline-danger'
                                }
                                size='sm'
                                onClick={(e) =>
                                    file.isDeleted
                                        ? onRestore(e, index)
                                        : onRemove(e, index)
                                }
                            >
                                <FontAwesomeIcon
                                    icon={
                                        file.isDeleted
                                            ? 'trash-restore'
                                            : 'trash'
                                    }
                                />
                            </Button>
                        </Row>
                    </ListGroup.Item>
                ))}
            </ListGroup>

            <div className={`custom-file ${0 < files.length && 'mt-2'}`}>
                <input
                    type='file'
                    className='custom-file-input'
                    onChange={onSelected}
                    accept={acceptFiles}
                />
                <label className='custom-file-label'>Choose file...</label>
            </div>
        </Form.Group>
    );
};

export { MultiFilePicker };
