// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DialogButton } from 'components/common/DialogButton';
import { StatusBadge } from 'components/common/StatusBadge';

import Actions from 'actions';
import { useSelector } from 'react-redux';
import { DeleteDialog } from 'components/dialogs/DeleteDialog';
import { useTranslation } from 'react-i18next';

const TableRow = ({ user, current }) => {
    const { t } = useTranslation();
    return (
        <tr>
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>
            <td>
                <StatusBadge
                    labelMap={{
                        ADMIN: t('role-name-admin'),
                        USER: t('role-name-user'),
                        INVESTOR: t('role-name-investor'),
                        DEVELOPER: t('role-name-developer'),
                        CONSTRUCTION_COMPANY: t(
                            'role-name-construction-company'
                        ),
                        REAL_ESTATE_AGENCY: t('role-name-real-estate-agency'),
                        BLOCKED_USER: t('users-table-status-blocked'),
                    }}
                    variantMap={{
                        USER: 'success',
                        INVESTOR: 'success',
                        DEVELOPER: 'success',
                        CONSTRUCTION_COMPANY: 'success',
                        REAL_ESTATE_AGENCY: 'success',
                        ADMIN: 'secondary',
                        BLOCKED_USER: 'danger',
                    }}
                    value={user.permissions[0]}
                />
            </td>
            <td>
                {'BLOCKED_USER' !== user.permissions[0] &&
                    user.id !== current.id && (
                        <DialogButton
                            dialog={DeleteDialog}
                            dialogParams={{
                                callAction: () =>
                                    Actions.users.block.execute(user.id),
                                title: t('users-table-block-user-title'),
                                message: t('users-table-block-user-message'),
                                acceptText: t('users-table-block-btn'),
                            }}
                            variant='outline-danger'
                            size={'sm'}
                        >
                            <FontAwesomeIcon icon='trash' />
                        </DialogButton>
                    )}
            </td>
        </tr>
    );
};

const TableHeader = () => {
    const { t } = useTranslation();
    return (
        <thead>
            <tr>
                <th>{t('users-table-first-name-col')}</th>
                <th>{t('users-table-last-name-col')}</th>
                <th>{t('users-table-role-col')}</th>
                <th>{t('users-table-actions-col')}</th>
            </tr>
        </thead>
    );
};
const UsersTable = ({ model }) => {
    const current = useSelector((state) => state.account.user);

    return (
        <Table bordered hover>
            <TableHeader />
            <tbody>
                {model.map((user) => (
                    <TableRow key={user.id} current={current} user={user} />
                ))}
            </tbody>
        </Table>
    );
};

export { UsersTable };
