// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { createReducer } from '@reduxjs/toolkit';

const initial = {
    byId: {},
    allIds: [],
};

export const initListReducer = () => initial;

export const setToListReducer = (state, { payload }) => ({
    ...state,
    byId: payload.reduce((r, i) => {
        r[i.id] = i;
        return r;
    }, {}),
    allIds: payload.map((item) => item.id),
});

export const updateToListReducer = (state, id, model) => ({
    ...state,
    byId: {
        ...state.byId,
        [id]: {
            ...state.byId[id],
            ...model,
        },
    },
});

export const appendItemToListReducer = (state, model) => ({
    ...state,
    byId: {
        ...state.byId,
        [model.id]: {
            ...model,
        },
    },
    allIds: state.allIds.concat([model.id]),
});

export const prependItemToListReducer = (state, model) => ({
    ...state,
    byId: {
        ...state.byId,
        [model.id]: {
            ...model,
        },
    },
    allIds: [model.id].concat(state.allIds),
});

export const removeItemFromListReducer = (state, id) => ({
    ...state,
    allIds: state.allIds.filter((i) => i !== id),
});

export const addToListReducer = (state, { payload }) => ({
    ...state,
    byId: payload.reduce(
        (r, i) => {
            r[i.id] = i;
            return r;
        },
        { ...state.byId }
    ),
    allIds: state.allIds.concat(
        payload
            .filter((i) => !state.allIds.includes(i.id))
            .map((item) => item.id)
    ),
});

export const clearListReducer = (state, { payload }) => ({
    ...state,
    byId: {},
    allIds: [],
});

export const makeListReducer = (scope, reducer = {}, initOnRequest = true) =>
    createReducer(initial, {
        [`${scope}/list:request`]: (state) => (initOnRequest ? initial : state),
        [`${scope}/list:success`]: setToListReducer,
        [`${scope}/list:failure`]: (state) => state,
        ...reducer,
    });
