// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { BasePage } from 'components/pages/BasePage';
import { Col, Row } from 'react-bootstrap';

const DataPolicyPage = () => {
    return (
        <BasePage>
            <Row className='p-4 justify-content-center'>
                <Col xs={8}>
                    <p>
                        <b>Data Protection Policy</b>
                    </p>
                    <p>
                        The protection of your personal data is important to us
                        ("Genconstrux"). The processing of your personal data
                        such as your name, address, email address or your
                        telephone number is always carried out in compliance
                        with the General Data Protection Regulation (GDPR)
                        [Datenschutz-Grundverordnung (DSGVO)] and the
                        country-specific data protection provisions that apply
                        for GENCONSTRUX.
                    </p>
                    <p>
                        The aim of this Data Protection Declaration is to inform
                        you of the type, scope and purpose of the personal data
                        that we collect. In addition, we should like to inform
                        you of your rights. If we want to offer you special
                        services via our website or elsewhere, and if there
                        should be no legal basis for the necessary processing of
                        the data in this connection, we shall obtain your
                        consent.
                    </p>
                    <p>
                        <br />
                    </p>
                    <p>
                        <b>1. Office responsible and data protection officer</b>
                    </p>
                    <p>
                        The office responsible within the meaning of the data
                        protection law is:
                    </p>
                    <p>
                        Genconstrux GmbH
                        <span>
                            <br />
                        </span>
                        Finkenweg 11
                        <span>
                            <br />
                        </span>
                        50181 Bedburg
                    </p>
                    <p>Managing director: Mustafa Sindi</p>
                    <p>
                        Content responsibility (TMG): Mustafa Sindi (For more
                        information, visit the german website version)
                    </p>
                    <p>Gesellschafter und Geschäftsführer: Mustafa Sindi</p>
                    <p>
                        Register Gericht: Amtgericht Köln
                        <span>
                            <br />
                        </span>
                        Registriernummer: HRB 100428
                        <span>
                            <br />
                        </span>
                        USt-ID Nummer (paragraph 27 a): DE328856063
                    </p>
                    <p>
                        Telefonnummer: 02272 8089 066
                        <span>
                            <br />
                        </span>
                        E-Mail:{' '}
                        <a href='mailto:info@genconstrux.de'>
                            <span>info@genconstrux.de</span>
                        </a>
                    </p>
                    <p>
                        <b>Content responsibility: </b>
                    </p>
                    <p>GENCONSTRUX GmbH</p>
                    <p>
                        <b>2. Recording of general data and information</b>
                    </p>
                    <p>
                        Every time visited, this website records various general
                        data and information These general data and information
                        are stored in the server’s log files. It is possible
                        that the following are recorded: (1) the type and
                        version of browser used, (2) the operating system used
                        by the accessing system, (3) the internet site from
                        which the accessing system accesses our internet site
                        (called referrer), (4) the sub-websites that link to our
                        internet site via an accessing system, (5) the date and
                        time the internet site was accessed, (6) the internet
                        protocol address (IP address), (7) the internet service
                        provider of the accessing system and (8) other similar
                        data and information that act as an emergency response
                        in the case of attacks on our IT systems.
                    </p>
                    <p>
                        In using these general data and information, it is
                        impossible for GENCONSTRUX to determine your identity.
                        No profiling is carried out. The reason we need this
                        information is rather to ensure that we keep the
                        contents of our website up to date, that we optimize the
                        contents of our website and the advertising for this,
                        that we guarantee the permanent functionality of our IT
                        systems and the technology of our website as well as to
                        ensure that we are able to provide law enforcement
                        authorities with the necessary information for
                        prosecution in the event of a cyber attack.
                    </p>
                    <p>
                        GENCONSTRUX analyzes these anonymously recorded data and
                        information on a statistical basis with the aim of
                        increasing data protection and data security in our
                        company. We save the anonymous data in the server log
                        files separately from all the personal data that you
                        have provided us. The legal basis for the temporary
                        storage of the data and the log files is Art. 6 Para. 1
                        f) of the GDPR.
                    </p>
                    <p>
                        <b>3. Data processing upon first contact</b>
                    </p>
                    <p>
                        You can contact us using the contact form provided on
                        our website or via the email address that you will find
                        in our imprint. If you use one of these channels to
                        contact GENCONSTRUX, we automatically save the personal
                        data that you have given us. Such personal details given
                        to GENCONSTRUX on a voluntary basis are saved to process
                        your inquiry and/or for the purpose of getting in touch
                        with you.
                    </p>
                    <p>
                        GENCONSTRUX SE allocates the inquiries and related
                        personal data to the technically responsible office
                        within the GENCONSTRUX group, usually the national
                        branch of the subsidiary of the inquirer, for processing
                        and use, and exchanges the data with this branch. For
                        example, inquiries and the corresponding data from the
                        website for Switzerland (www.Genconstrux.com/ch) are
                        exchanged with the GENCONSTRUX national branch in
                        Switzerland (GENCONSTRUX Spedition AG). It is possible
                        that data can be exchanged with third countries such as
                        the USA in this connection.
                    </p>
                    <p>
                        The legal basis for the processing of the data for
                        contract initiation or execution is Art. 6 Para. 1 b) of
                        the GDPR and in all other cases Art. 6 Para. 1 f) of the
                        GDPR. The legal basis for data transfer to a third
                        country is Art. 49 Para. 1 b) of the GDPR.
                    </p>
                    <p>
                        <b>4. Subscription to our newsletter</b>
                    </p>
                    <p>
                        Users are given the option of subscribing to the
                        GENCONSTRUX newsletter (e.g., "eLetter") on the
                        GENCONSTRUX website. The personal data that are recorded
                        when subscribing to the GENCONSTRUX newsletter can be
                        seen on the input screen.
                    </p>
                    <p>
                        GENCONSTRUX uses the newsletter to provide information
                        on a regular basis to customers and business partners
                        about innovations of our services and production,
                        current press releases, information about our brand or
                        the current contents of our website. The newsletter can
                        fundamentally only be received by the person affected if
                        (1) the person affected has a valid email address and
                        (2) the person affected registers for receipt of the
                        newsletter. For legal reasons, a confirmation email is
                        initially sent to the registered email address of the
                        affected person in a double opt-in procedure for receipt
                        of the newsletter. This confirmation email serves as a
                        check to ensure that the owner of the email address as
                        the person affected has authorized the receipt of the
                        newsletter. The legal basis for sending the newsletter
                        is Art. 6 Para. 1 a) of the GDPR.
                    </p>
                    <p>
                        Upon registration for the newsletter, we store the IP
                        address of the internet service provider (ISP) of the
                        computer system of the person affected at the time of
                        registration as well as the date and time of
                        registration. The recording of this data is required to
                        be able to identify the (possible) misuse of the email
                        address of the person affected at a later point in time
                        and therefore also to guarantee legal protection of
                        GENCONSTRUX. Within the context of sending the
                        newsletter, GENCONSTRUX commissions the company Atrivio
                        GmbH, Albert-Einstein-Str. 6, 87437 Kempten, with the
                        recording, processing, use and/or storage of the data as
                        part of order processing.
                    </p>
                    <p>
                        The personal data collected as part of the registration
                        process for the newsletter are used exclusively for the
                        following purposes:
                    </p>
                    <ul class='ul1'>
                        <li class='li2'>
                            <span class='s3'></span>Dispatch of the newsletter
                        </li>
                        <li class='li2'>
                            <span class='s3'></span>Advice and advertising
                        </li>
                        <li class='li2'>
                            <span class='s3'></span>Needs-related structure of
                            the newsletter
                        </li>
                        <li class='li2'>
                            <span class='s3'></span>Compilation of the topics in
                            the newsletter in line with the interests of
                            subscribers
                        </li>
                    </ul>
                    <p class='p2'>
                        In addition, subscribers to the newsletter could be
                        informed by email insofar as this is necessary for the
                        operation of the newsletter services or a registration
                        with regard to this, such as for changes to the offer or
                        a modification to the technical circumstances.
                    </p>
                    <p class='p2'>
                        Consent to the storage of personal data that the person
                        affected has given us for the subscription to the
                        newsletter can be revoked at any time. There is a
                        corresponding link in every newsletter for the
                        revocation of consent. In addition, it is also possible
                        to deregister at any time directly with GENCONSTRUX by
                        sending a notification to the contact details given
                        under number 1.
                    </p>
                    <p class='p10'>
                        <b>5. Newsletter-Tracking</b>
                    </p>
                    <p class='p2'>
                        Our newsletters contain tracking pixels. A tracking
                        pixel is a miniature graphic that is embedded in such
                        emails that are sent in HTML format to facilitate the
                        recording and analyzing a log file. This means that a
                        statistical analysis of the success or failure of online
                        marketing campaigns can be carried out. By means of the
                        embedded tracking pixels, GENCONSTRUX can identify
                        whether and when an email was opened by a person
                        affected and which of the links in the email were
                        accessed by the person affected.
                    </p>
                    <p class='p2'>
                        Such personal data recorded via the tracking pixels
                        contained in the newsletter are saved and evaluated by
                        GENCONSTRUX to optimize the dispatch of newsletters and
                        to align the contents of future newsletters better to
                        the interests of the person affected. Persons affected
                        are entitled to revoke the separate declaration of
                        consent given via the double opt-in procedure at any
                        time. Following a revocation, these personal data are
                        deleted by GENCONSTRUX. Deregistration of the
                        subscription to the newsletter is automatically deemed
                        by GENCONSTRUX to be a revocation.
                    </p>
                    <p class='p10'>
                        <b>6. Cookies</b>
                    </p>
                    <p class='p2'>
                        This website uses cookies. Cookies are text files that
                        are placed on a computer system and stored in that
                        system. By using cookies, GENCONSTRUX is able to provide
                        you with user-friendly services that would not be
                        possible if the cookies had not been placed. Cookies
                        enable us to recognize the users of our website. For
                        example, the user of a website that uses cookies does
                        not have to enter his/her login data every time he/she
                        visits the website, because these are taken from the
                        website and the cookie placed by the website on the
                        computer system.
                    </p>
                    <p class='p2'>
                        Our website uses the technology of etracker GmbH, Erste
                        Brunnenstrasse 1, 20459 Hamburg (www.etracker.com). The
                        data are collected and stored for optimization and
                        marketing purposes. These data are used to create user
                        profiles under a pseudonym. Cookies can be used for
                        this. The cookies enable recognition of the internet
                        browser. The data collected with etracker technologies
                        are not used to identify the visitor of this website
                        personally and are not combined with personal data of
                        the carrier of the pseudonym without the individually
                        granted consent of the person affected. No profiling is
                        carried out. The collection and storage of data can be
                        objected to at any time with future effect.
                    </p>
                    <p class='p2'>
                        You can prevent the placing of cookies by our website at
                        any time by means of a corresponding setting of your
                        internet browser and consequently prevent the setting of
                        cookies on a permanent basis. In addition, cookies that
                        have already been placed can be deleted at any time via
                        an internet browser or another software program. This is
                        possible in all standard internet browsers. However, if
                        you deactivate the placing of cookies in your internet
                        browser, not all the functions of our website will be
                        fully available to you in certain circumstances. The
                        legal basis for the processing of personal data using
                        cookies is Art. 6 Para. 1 f) of the GDPR.
                    </p>
                    <p class='p4'>
                        <b>7. Google Maps</b>
                    </p>
                    <p class='p2'>
                        We use the offer from Google Maps on this website. This
                        means that we can show you interactive maps directly on
                        the website and enable you to use the map features
                        simply and easily.
                    </p>
                    <p class='p2'>
                        By accessing its website, Google obtains information
                        that you have visited the corresponding sub-site on our
                        website. In addition, the data mentioned under number 2
                        are transmitted. This occurs irrespective of whether
                        Google makes a user account available, via which you are
                        logged in, or if there is no user account. If you are
                        logged in to Google, your data are directly allocated to
                        your account. If you do not wish this allocation to your
                        profile with Google, you must log out before activating
                        the button. Google stores your data as a usage profile
                        and uses this for the purposes of advertising, market
                        research and/or the needs-related structuring of its
                        website. Such an evaluation is carried out in particular
                        (even for users who do not log in) to provide
                        needs-related advertising and to inform other users in
                        your social network of your activities on our website.
                        You have a right to object to the creation of this user
                        profile, but you have to contact Google directly to
                        enforce this. Further information on the purpose and
                        scope of the data collection and the processing thereof
                        by the plugin service provider can be found in the data
                        protection declaration of the service provider.
                    </p>
                    <p class='p11'>
                        The legal basis for the processing of your personal data
                        when using Google Maps is Art. 6 Para. 1 f) of the GDPR.
                        The transmission of personal data to the USA is carried
                        out in compliance with the EU-US Privacy Shield pursuant
                        to Art. 45 Para. 1 of the GDPR, of which Google is a
                        signatory.
                    </p>
                    <p class='p12'>
                        <b>8. Social Plugins</b>
                    </p>
                    <p class='p2'>
                        We use the social plugins (“Plugins”) of Facebook,
                        Twitter, YouTube, Xing and LinkedIn on our website. We
                        use plugins in particular so that you can share the
                        contents of our website with other users of social
                        networks or you can refer them to these contents. You
                        can identity the provider of the relevant plugin from
                        its logo or initial letter.
                    </p>
                    <p class='p2'>
                        When you visit our website, we do not initially transmit
                        any personal data to the plugin provider. However, if
                        you click the relevant field, your personal data are
                        sent directly to the provider of the relevant plugin and
                        processed by said provider in third countries such as
                        the USA under certain circumstances. After you click a
                        plugin field, a new window opens in your browser and
                        calls up the website of the provider of the relevant
                        social network. The data transfer to the provider of the
                        relevant plugin is carried out irrespective of whether
                        you have an account with the social network of said
                        plugin provider. Once you have logged on to the plugin
                        provider, your data that have been registered with us
                        are directly transmitted to the account you have with
                        the plugin provider.
                    </p>
                    <p class='p2'>
                        We have no influence on the type and scope of the data
                        that are collected and processed by using the plugin,
                        nor do we know the full scope of the data collection,
                        the purposes of the processing or the storage periods.
                        According to the information of the plugin provider, the
                        data transmitted include information about your browser,
                        the websites that you have visited as well as the date
                        and time of your visit. The plugin providers process
                        this information to create a user profile and to create
                        tailor-made advertising for you, for example. You have a
                        right to object to the creation of this user profile,
                        but you have to contact the respective plugin provider
                        directly to enforce this revocation right. You will find
                        further information on this on the internet sites and in
                        the data protection declarations of the relevant service
                        providers.
                    </p>
                    <p class='p11'>
                        The legal basis for the processing of your personal data
                        when using social plugins is Art. 6 Para. 1 f) of the
                        GDPR. The transmission of personal data to the USA is
                        carried out in compliance with the EU-US Privacy Shield
                        pursuant to Art. 45 Para. 1 of the GDPR, to which
                        Facebook, Twitter, LinkedIn and Google are signatories.
                    </p>
                    <p class='p12'>
                        <b>9. Data transfer to third parties</b>
                    </p>
                    <p class='p2'>
                        Access to personal data is technically possible for
                        service providers and contracting parties that we employ
                        for the operation of our website. These external service
                        providers are obliged to use your personal data only for
                        the purpose of providing the services that we require or
                        for other purposes in compliance with out instructions.
                    </p>
                    <p class='p2'>
                        GENCONSTRUX will transmit your personal data to
                        companies within the GENCONSTRUX Group for the purposes
                        stated in this data protection declaration and forward
                        them to the relevant national branches. GENCONSTRUX
                        companies are also based outside of the European Union
                        or the European Economic Area. GENCONSTRUX is
                        responsible for ensuring that you are informed about
                        your rights as a person affected within the context of
                        the valid data protection laws. You can address any
                        inquiries or complaints regarding your personal data to
                        GENCONSTRUX. The other GENCONSTRUX companies within the
                        GENCONSTRUX Group, which also process your personal
                        data, collaborate with us and support us in dealing with
                        such inquiries or complaints.
                    </p>
                    <p class='p11'>
                        Apart from the aforementioned data transfer, we do not
                        transmit, sell or market your personal data to third
                        parties such as other companies or organizations unless
                        you have given us your express permission to do so, or
                        the transmission is required for us to be able to
                        fulfill our contractual duties to you, the user of our
                        website.
                    </p>
                    <p class='p12'>
                        <b>10. Storage period of data</b>
                    </p>
                    <p class='p2'>
                        The criterion for the duration of the storage of
                        personal data is the relevant statutory storage period.
                        Once this deadline has expired, we routinely delete all
                        the corresponding data insofar as they are no longer
                        required to fulfill or initiate a contract.
                    </p>
                    <p class='p2'>
                        If the reason for storage no longer applies or if the
                        storage deadline set by the European regulator or
                        another relevant legislator should expire, the personal
                        data are routinely blocked or deleted in accordance with
                        statutory provisions.
                    </p>
                    <p class='p13'>
                        <b>11. Your rights</b>
                    </p>
                    <p class='p14'>
                        As a person affected, you are entitled to the rights as
                        set out in Articles 15-21 of the GDPR vis-à-vis
                        GENCONSTRUX if the prerequisites set out therein have
                        been fulfilled. These are the rights to information
                        (Art. 15 of the GDPR), correction (Art. 16 of the GDPR),
                        deletion (Art. 17 of the GDPR), restriction in
                        processing (Art. 18 of the GDPR), data transfer (Art. 20
                        of the GDPR) and the right to object (Art. 21 and 22 of
                        the GDPR). In addition, you have a right to complain to
                        the supervisory authority pursuant to Art. 77 of the
                        GDPR.
                    </p>
                    <p class='p15'>
                        <br />
                    </p>
                    <p class='p16'>
                        We use cookies to improve user experience, and analyze
                        website traffic. For these reasons, we may share your
                        site usage data with our analytics partners. By clicking
                        “Accept Cookies,” you consent to store on your device
                        all the technologies described in our 
                        <span class='s4'>Cookie Policy</span>. You can change
                        your cookie settings at any time by clicking “
                        <span class='s4'>Cookie Preferences.</span>”
                    </p>
                    <p class='p17'>
                        <br />
                    </p>
                    <p class='p17'>
                        <br />
                    </p>
                    <p class='p17'>
                        <br />
                    </p>
                    <p class='p18'>
                        <br />
                    </p>
                    <p class='p19'>
                        <br />
                    </p>
                    <p class='p19'>
                        <br />
                    </p>
                    <p class='p19'>
                        <br />
                    </p>
                    <p class='p19'>
                        <br />
                    </p>
                    <p class='p19'>
                        <br />
                    </p>
                    <p class='p19'>
                        <br />
                    </p>
                    <p class='p19'>
                        <br />
                    </p>
                </Col>
            </Row>
        </BasePage>
    );
};

export { DataPolicyPage };
