// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { makeRequestActions } from './common';
import { makeAlertActions } from './alert.actions';
import { makeProcessActions } from './process.actions';
import { createAction } from '@reduxjs/toolkit';

export const makeFetchActions = (
    scope,
    prepare,
    action = makeRequestActions
) => {
    
    const fetch = action(scope, prepare && prepare.fetch);
    const alert = makeAlertActions(scope, prepare && prepare.alert);
    const process = makeProcessActions(scope, prepare && prepare.process);

    return {
        reset: createAction(`${scope}:reset`),
        fetch: fetch[0],
        execute: fetch[0],
        success: fetch[1],
        failure: fetch[2],

        show: alert[0],
        hide: alert[1],

        start: process[0],
        stop: process[1],
        progress: process[2],
    };
};
