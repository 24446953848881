// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Button, Row, Alert } from 'react-bootstrap';

import { ProjectWizardStep } from 'components/wizard/ProjectWizardStep';
import { Editor } from 'components/editor/Editor';
import { createEditorInstance } from 'components/editor/editor.instance';

import Actions from 'actions';
import { useTranslation } from 'react-i18next';

const Header = ({ editor, projectModel, startPoint }) => {
    const wizard = useSelector((state) => state.projects.wizard);
    const wizardProcess = wizard.process;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onFinished = () => {
        dispatch(
            Actions.projects.wizardEditorUpload(
                {
                    segments: editor.getSegments(),
                    areas: editor.getAreas(),
                    projectId: projectModel && projectModel.id,
                    startPoint: startPoint,
                },
                {
                    next: 'done',
                }
            )
        );
    };

    return (
        <Row className='px-4 mb-2 justify-content-between'>
            <h5>{t('project-editor-create-edit-segments')}</h5>
            <Alert
                show={wizardProcess.pending && wizard.view.mode === 'create'}
                variant={'success'}
                className='p-3 align-self-center'
                xs={5}
                md={5}
                lg={5}
            >
                Project created
            </Alert>

            <Button onClick={onFinished} disabled={wizardProcess.pending}>
                Finish
            </Button>
        </Row>
    );
};

const EditorProjectWizardStep = ({ className }) => {
    const wizardData = useSelector((state) => state.projects.wizard.view.data);
    const appState = useSelector((state) => state.app);
    const { t } = useTranslation();
    const startData = wizardData.start;

    const startGeoJson =
        (startData &&
            0 < startData.segments.length &&
            startData.segments[0].geoJson) ||
        null;

    let startPoint = startData.anchorPoint
        ? [startData.anchorPoint.longitude, startData.anchorPoint.latitude]
        : [0, 0];
    if (startData && startGeoJson) {
        if ('LineString' === startGeoJson.geometry.type) {
            startPoint = startGeoJson.geometry.coordinates[0];
        } else if ('MultiLineString' === startGeoJson.geometry.type) {
            startPoint = startGeoJson.geometry.coordinates[0][0];
        }
    }

    const editorInstance = createEditorInstance({
        startPoint: startPoint,
        project: startData,
        segmentTypes: appState.segmentTypes,
        areaTypes: appState.areaTypes,
        t: t,
    });

    const projectModel = useSelector(
        (state) => state.projects.wizard.view.data.start
    );

    return (
        <ProjectWizardStep
            className={`${
                className || ''
            } container-fluid p-0 d-flex flex-column`}
        >
            <Header
                editor={editorInstance}
                projectModel={projectModel}
                startPoint={startPoint}
            />
            <Editor className='' editor={editorInstance} />
        </ProjectWizardStep>
    );
};

export { EditorProjectWizardStep };
