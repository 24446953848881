// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { BasePage } from 'components/pages/BasePage';
import { LoginForm } from 'components/forms/LoginForm';
import SinfraLogoIcon from 'assets/gendigitlogo.svg';

const LoginPage = () => (
    <BasePage>
        <Row className='justify-content-center'>
            <Col className='p-3 align-self-center' xs={5} md={5} lg={3}>
                <Row className='justify-content-center'>
                    <img
                        src={SinfraLogoIcon}
                        width='200px'
                        height='125px'
                        alt=''
                        className='d-inline-block align-center'
                    />
                </Row>
                <Row className='mt-2 mb-2'>
                    <h4 className='text-center ml-4'>
                        Construction and Building Sector Digitized
                    </h4>
                </Row>

                <LoginForm />
            </Col>
        </Row>
    </BasePage>
);

export { LoginPage };
