// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useState, useEffect } from 'react';

import { Col, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const SegmentItem = ({ segment, active, onClick = () => {} }) => (
    <ListGroup.Item as='li' active={active} action onClick={onClick}>
        <b>Segment</b>
    </ListGroup.Item>
);

const AreaItem = ({ area, active, onClick = () => {} }) => (
    <ListGroup.Item as='li' active={active} action onClick={onClick}>
        <b>Area</b>
    </ListGroup.Item>
);

const EntitiesMenu = ({
    className,
    styles,
    onInitialize = () => {},
    onClick = () => {},
}) => {
    const [selectedId, setSelectedId] = useState('');
    const { t } = useTranslation();
    const [segmentsWrapper, setSegmentsWrapper] = useState({
        segments: [],
    });

    const [areasWrapper, setAreasWrapper] = useState({
        areas: [],
    });

    useEffect(() => {
        onInitialize({
            setAreas: (areas) => setAreasWrapper({ areas: areas }),
            setSegments: (segments) =>
                setSegmentsWrapper({ segments: segments }),
            select: (drawId) => setSelectedId(drawId),
        });
    }, [onInitialize]);

    return (
        <Col
            xs={3}
            sm={4}
            md={4}
            lg={3}
            xl={3}
            className={`${className || ''} p-1 shadow rounded bg-light`}
            style={{
                minHeight: '25vh',
                maxHeight: '45vh',
                overflowX: 'hidden',
                overflowY: 'auto',
                ...styles,
            }}
        >
            <div className='p-1'>
                <h5>{t('editor-menu-segments')+ ` (${segmentsWrapper.segments.length})`}</h5>
            </div>

            <ListGroup as='ul' className='p-1'>
                {segmentsWrapper.segments.map((segment) => {
                    return (
                        <SegmentItem
                            key={segment.drawId}
                            segment={segment}
                            active={selectedId === segment.drawId}
                            onClick={() => onClick(segment.drawId)}
                        />
                    );
                })}
            </ListGroup>

            <div className='p-1'>
                <h5>{t('editor-menu-areas')}</h5>
            </div>

            <ListGroup as='ul' className='p-1'>
                {areasWrapper.areas.map((area) => {
                    return (
                        <AreaItem
                            key={area.drawId}
                            segment={area}
                            active={selectedId === area.drawId}
                            onClick={() => onClick(area.drawId)}
                        />
                    );
                })}
            </ListGroup>
        </Col>
    );
};

export { EntitiesMenu };
