// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { makePageActions } from './page.actions';
import { makeAlertActions } from './alert.actions';
import { makeProcessActions } from './process.actions';

export const makeTableActions = (scope, prepare) => {
    const page = makePageActions(scope, prepare && prepare.page);
    const alert = makeAlertActions(scope, prepare && prepare.alert);
    const process = makeProcessActions(scope, prepare && prepare.process);

    return {
        fetch: page[0],
        success: page[1],
        failure: page[2],

        show: alert[0],
        hide: alert[1],

        start: process[0],
        stop: process[1],
    };
};
