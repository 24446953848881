// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Badge } from 'react-bootstrap';

const StatusBadge = ({
    children,
    className,
    labelMap = {},
    value = '',
    variantMap = {},
}) => (
    <Badge
        className={`${className || ''}`}
        variant={variantMap[value] || 'primary'}
    >
        {children}
        {labelMap[value]}
    </Badge>
);

export { StatusBadge };
