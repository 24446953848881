// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useEffect } from 'react';

import { PageHeader } from 'components/common/PageHeader';
import { PageLayout } from 'components/common/PageLayout';

import { BasePage } from 'components/pages/BasePage';

import { ProjectWizard } from 'components/wizard/ProjectWizard';
import { LayersProjectWizardStep } from 'components/wizard/steps/LayersProjectWizardStep';
import { EditorProjectWizardStep } from 'components/wizard/steps/EditorProjectWizardStep';
import { InfoProjectWizardStep } from 'components/wizard/steps/InfoProjectWizardStep';
import { LoadingProjectWizardStep } from 'components/wizard/steps/LoadingProjectWizardStep';
import { useDispatch } from 'react-redux';

import Actions from 'actions';
import { useTranslation } from 'react-i18next';

const MemoEditorProjectWizardStep = React.memo(EditorProjectWizardStep);

const WIZARD_STEPS = [
    {
        id: 'editor',
        title: 'Editor',
        component: () => <MemoEditorProjectWizardStep />,
    },
    {
        id: 'info',
        title: 'Information',
        component: () => <InfoProjectWizardStep />,
    },
    {
        id: 'layers',
        title: 'Layers',
        component: () => <LayersProjectWizardStep />,
    },
    {
        id: 'loading',
        title: 'Loading',
        component: () => <LoadingProjectWizardStep />,
    },
];

const EditProjectPage = ({ className, match }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const projectId = match.params.id;

    useEffect(() => {
        dispatch(Actions.projects.wizardRequestViewLayers(projectId));
    }, [projectId, dispatch]);

    return (
        <BasePage className={`${className || ''} flex-fill d-flex flex-column`}>
            <PageLayout>
                <PageHeader title={t('edit-project-title')} />
            </PageLayout>
            <ProjectWizard className='flex-fill' steps={WIZARD_STEPS} />
        </BasePage>
    );
};

export { EditProjectPage };
