// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { configureStore, combineReducers } from '@reduxjs/toolkit';

import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import accountReducer from 'reducers/account.reducer';
import projectsReducer from 'reducers/projects.reducer';
import appReducer from 'reducers/app.reducer';
import requestsReducer from 'reducers/requests.reducer';
import usersReducer from 'reducers/users.reducer';
import investmentsReducer from 'reducers/invsetments.reducer';
import joinRequestsReducer from 'reducers/joinrequests.reducer';
import forumReducer from 'reducers/forum.reducer';

import { runSagas } from 'sagas';

const history = createBrowserHistory();
export { history };

const createStore = () => {
    const sagaMiddleware = createSagaMiddleware();

    const store = configureStore({
        reducer: combineReducers({
            account: accountReducer,
            projects: projectsReducer,
            requests: requestsReducer,
            users: usersReducer,
            investments: investmentsReducer,
            app: appReducer,
            joinRequests: joinRequestsReducer,
            forum: forumReducer,
            router: connectRouter(history),
        }),
        middleware: [sagaMiddleware, routerMiddleware(history)],
    });

    runSagas(sagaMiddleware);

    return store;
};

export { createStore };
