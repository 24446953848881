// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { makeListActions } from './list.actions';

import { makeRequestActions } from './common';
import { makeFetchActions } from './fetch.actions';

export const CREATE_JOIN_REQUEST_ACTION = 'project/join-request/create';
export const APPROVE_JOIN_REQUEST_ACTION = 'project/join-request/approve';
export const DECLINE_JOIN_REQUEST_ACTION = 'project/join-request/decline';
export const CANCEL_JOIN_REQUEST_ACTION = 'project/join-request/cancel';
export const JOIN_REQUEST_LIST_ACTION = 'project/join-request/list';
export const PROJECT_JOIN_REQUEST_LIST_ACTION =
    'project/join-request/project/list';

export const list = makeFetchActions(
    JOIN_REQUEST_LIST_ACTION,
    {
        fetch: {
            request: () => ({
                payload: {},
            }),
        },
    },
    makeListActions
);

export const listByProject = makeFetchActions(
    PROJECT_JOIN_REQUEST_LIST_ACTION,
    {
        fetch: {
            request: (projectId) => ({
                payload: { projectId },
            }),
        },
    },
    makeListActions
);

export const create = makeFetchActions(
    CREATE_JOIN_REQUEST_ACTION,
    {
        fetch: {
            request: (projectId, joinRequest) => ({
                payload: {
                    projectId,
                    joinRequest,
                },
            }),
            success: (projectId) => ({
                payload: {
                    projectId,
                },
            }),
        },
    },
    makeRequestActions
);

export const approve = makeFetchActions(
    APPROVE_JOIN_REQUEST_ACTION,
    {
        fetch: {
            request: (projectId, joinRequestId) => ({
                payload: {
                    projectId,
                    joinRequestId,
                },
            }),
            success: (projectId, joinRequestId) => ({
                payload: {
                    projectId,
                    joinRequestId,
                },
            }),
        },
    },
    makeRequestActions
);

export const decline = makeFetchActions(
    DECLINE_JOIN_REQUEST_ACTION,
    {
        fetch: {
            request: (projectId, joinRequestId) => ({
                payload: {
                    projectId,
                    joinRequestId,
                },
            }),
            success: (projectId, joinRequestId) => ({
                payload: {
                    projectId,
                    joinRequestId,
                },
            }),
        },
    },
    makeRequestActions
);

export const cancel = makeFetchActions(
    CANCEL_JOIN_REQUEST_ACTION,
    {
        fetch: {
            request: (projectId, joinRequestId) => ({
                payload: {
                    projectId,
                    joinRequestId,
                },
            }),
            success: (projectId, joinRequestId) => ({
                payload: {
                    projectId,
                    joinRequestId,
                },
            }),
        },
    },
    makeRequestActions
);
