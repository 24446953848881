// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Row, Carousel, ListGroup, Col, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { push as routerPush } from 'connected-react-router';

import { PageHeader } from 'components/common/PageHeader';
import { PageLayout } from 'components/common/PageLayout';
import { PageLoader } from 'components/common/PageLoader';
import { PageSection } from 'components/common/PageSection';
import { LinkButton } from 'components/common/LinkButton';

import { BasePage } from 'components/pages/BasePage';

import { useTranslation } from 'react-i18next';

import Actions from 'actions';
import { InvestmentsTable } from 'components/tables/InvestmentsTable';
import { PageSectionPagination } from 'components/common/PageSectionPagination';
import { useListReducer } from 'helpers/hooks';

const InvestmentsPage = ({ match }) => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const user = useSelector((state) => state.account.user);
    const model = useListReducer((state) => state.investments.list.model);
    useEffect(()=> {
        dispatch(Actions.investments.list.fetch());
    }, []);

    return (
        <BasePage>
            <PageLayout>
                <PageHeader title={t('investments-title')}>
                </PageHeader>
              
                <InvestmentsTable model={model} />
            </PageLayout>
        </BasePage>
    );
};

export { InvestmentsPage };
