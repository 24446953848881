// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Row, Carousel, ListGroup, Col, Alert, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { push as routerPush } from 'connected-react-router';

import { PageHeader } from 'components/common/PageHeader';
import { PageLayout } from 'components/common/PageLayout';
import { PageLoader } from 'components/common/PageLoader';
import { PageSection } from 'components/common/PageSection';
import { LinkButton } from 'components/common/LinkButton';

import { BasePage } from 'components/pages/BasePage';

import { useTranslation } from 'react-i18next';

import Actions from 'actions';
import { InvestmentsTable } from 'components/tables/InvestmentsTable';
import { PageSectionPagination } from 'components/common/PageSectionPagination';
import { useListReducer } from 'helpers/hooks';
import { PageSectionAddItem } from 'components/common/PageSectionAddItem';
import { CreateTopicDialog } from 'components/dialogs/CreateTopicDialog';
import { DeleteButton } from 'components/common/DeleteButton';

const UserCard = ({}) => {
    return (
        <Card>
            <Card.Body>
                <Card.Title>Card Title</Card.Title>
            </Card.Body>
        </Card>
    );
};

const TopicCard = ({ projectId, topic, user, project }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    return (
        <Card className='mb-2'>
            <Card.Body className='py-2'>
                <Row className='d-flex px-2 py-0 justify-content-between align-items-center'>
                    <h5>{topic.name}</h5>
                    <LinkButton
                        to={`/projects/${projectId}/forum/${topic.id}`}
                        variant='primary'
                    >
                        {t('forum-topic-open')}
                    </LinkButton>
                </Row>
            </Card.Body>
            <Card.Footer>
                <Row className='px-2 justify-content-between align-items-center'>
                    <small className='text-muted'>
                        {!topic.lastMessageInfo
                            ? t('forum-topic-no-messages')
                            : t('forum-topic-last-message') +
                              ` ${new Date(
                                  topic.lastMessageInfo.date
                              ).toLocaleString()}`}
                    </small>
                    {project.ownerId === user.id && (
                        <DeleteButton
                            size='sm'
                            callAction={() =>
                                dispatch(
                                    Actions.forum.deleteTopic.execute(
                                        projectId,
                                        topic.id
                                    )
                                )
                            }
                        />
                    )}
                </Row>
            </Card.Footer>
        </Card>
    );
};

const ForumPage = ({ match }) => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const user = useSelector((state) => state.account.user);
    const topicsList = useListReducer((state) => state.forum.topicsList.model);

    const projectModelProcess = useSelector(
        (state) => state.projects.project.info.process.pending
    );

    const projectModel = useSelector(
        (state) => state.projects.project.info.model
    );

    const projectId = match.params.id;

    useEffect(() => {
        dispatch(Actions.projects.infoModel.fetch(projectId));
        dispatch(Actions.forum.topicsList.fetch(projectId));
    }, [projectId, dispatch]);

    if (!projectModel || projectModelProcess.pending) {
        return <PageLoader />;
    }

    return (
        <BasePage>
            <PageLayout>
                <PageHeader title={t('forum-title')}>
                    <LinkButton
                        variant='secondary'
                        to={`/projects/${projectId}`}
                        size='sm'
                    >
                        {t('forum-back-to-project')}
                    </LinkButton>
                </PageHeader>
                <PageSection>
                    <Row>
                        <p>
                            {t('forum-participants-count') +
                                ` ${projectModel.participants.length + 1}`}
                        </p>
                    </Row>
                    <Row className='mb-2 justify-content-between'>
                        <p>{t('forum-topics-title')}</p>

                        <PageSectionAddItem
                            dialog={CreateTopicDialog}
                            dialogParams={{
                                project: projectModel,
                            }}
                        >
                            {t('forum-topic-create-btn')}
                        </PageSectionAddItem>
                    </Row>
                    <Row>
                        <Col>
                            {topicsList.map((topic) => (
                                <TopicCard
                                    key={topic.id}
                                    topic={topic}
                                    projectId={projectId}
                                    project={projectModel}
                                    user={user}
                                />
                            ))}
                        </Col>
                    </Row>
                </PageSection>
            </PageLayout>
        </BasePage>
    );
};

export { ForumPage };
