// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { makeRequestActions } from './common';

export const makeListActions = (scope, prepare) =>
    makeRequestActions(`${scope}/list`, {
        request:
            (prepare && prepare.request) ||
            ((data) => ({
                payload: { data },
            })),
        success: (data) => ({
            payload: data,
        }),
    });
