// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogButton } from 'components/common/DialogButton';
import { DeleteDialog } from 'components/dialogs/DeleteDialog';

const DeleteButton = ({
    className,
    callAction,
    onAccept,
    variant = 'outline-danger',
    size = 'sm',
    icon = 'trash',
    text = '',
    disabled,
    otherDialogParams,
}) => {
    return (
        <DialogButton
            className={className}
            dialog={DeleteDialog}
            disabled={disabled}
            dialogParams={{
                callAction: callAction,
                ...otherDialogParams,
            }}
            onAccept={onAccept}
            variant={variant}
            size={size}
        >
            {'' !== icon && <FontAwesomeIcon icon={icon} />}
            {text}
        </DialogButton>
    );
};

export { DeleteButton };
