// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { BasePage } from 'components/pages/BasePage';
import { PageLayout } from 'components/common/PageLayout';
import { PageSectionAddItem } from 'components/common/PageSectionAddItem';
import { PageHeader } from 'components/common/PageHeader';
import { PageSectionPagination } from 'components/common/PageSectionPagination';
import { UsersTable } from 'components/tables/UsersTable';

import Actions from 'actions';
import { CreateUserDialog } from 'components/dialogs/CreateUserDialog';
import { useTranslation } from 'react-i18next';

const UsersPage = () => {
    const { t } = useTranslation();
    return (
        <BasePage>
            <PageLayout>
                <PageHeader title={t('users-title')}>
                    <PageSectionAddItem dialog={CreateUserDialog}>
                        {t('users-create-btn')}
                    </PageSectionAddItem>
                </PageHeader>

                <PageSectionPagination
                    fetchAction={Actions.users.table.fetch}
                    table={UsersTable}
                    scope={['users', 'table']}
                />
            </PageLayout>
        </BasePage>
    );
};

export { UsersPage };
