// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DialogButton } from 'components/common/DialogButton';
import { StatusBadge } from 'components/common/StatusBadge';

import { useSelector } from 'react-redux';
import { DeleteDialog } from 'components/dialogs/DeleteDialog';
import { useTranslation } from 'react-i18next';
import { ButtonGroup } from 'react-bootstrap';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from 'react-bootstrap';
import { ApproveDialog } from 'components/dialogs/ApproveDialog';
import { LinkButton } from 'components/common/LinkButton';

import Actions from 'actions';

const TableRow = ({ user, joinRequest }) => {
    const { t } = useTranslation();

    const approve = useSelector((state) => state.joinRequests.approve);

    const decline = useSelector((state) => state.joinRequests.decline);

    const cancel = useSelector((state) => state.joinRequests.cancel);

    const isUserJoinRequest = joinRequest.userId === user.id;
    const isUserApprover =
        user.permissions.includes('ADMIN') ||
        user.permissions.includes('DEVELOPER');
    const canApprove = 'PENDING' === joinRequest.status;
    const canCancel = 'PENDING' === joinRequest.status;

    return (
        <tr>
            <td style={{ height: '40px', verticalAlign: 'middle' }}>
                {new Date(joinRequest.createdAt).toLocaleString()}
            </td>
            <td style={{ height: '40px', verticalAlign: 'middle' }}>
                <LinkButton to={`/projects/${joinRequest.projectId}`}>
                    {joinRequest.project.name}
                </LinkButton>
            </td>
            <td style={{ height: '40px', verticalAlign: 'middle' }}>
                {`${joinRequest.user.firstName} ${joinRequest.user.lastName}`}
            </td>
            <td style={{ height: '40px', verticalAlign: 'middle' }}>{joinRequest.description}</td>
            <td style={{ height: '40px', verticalAlign: 'middle' }}>
                <StatusBadge
                    labelMap={{
                        PENDING: t('join-requests-table-status-pending'),
                        DECLINED: t('join-requests-table-status-declined'),
                        APPROVED: t('join-requests-table-status-approved'),
                        CANCELED: t('join-requests-table-status-canceled'),
                    }}
                    variantMap={{
                        PEDNDING: 'warning',
                        DECLINED: 'danger',
                        APPROVED: 'success',
                        CANCELED: 'danger',
                    }}
                    value={joinRequest.status}
                />
            </td>
            <td style={{ height: '40px', verticalAlign: 'middle' }}>
                <ButtonGroup>
                    {isUserApprover && canApprove && (
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                <Tooltip>
                                    {t('join-requests-table-approve')}
                                </Tooltip>
                            }
                        >
                            <DialogButton
                                variant='outline-success'
                                size='sm'
                                dialog={ApproveDialog}
                                dialogParams={{
                                    requestModel: approve,
                                    callAction: () =>
                                        Actions.joinRequests.approve.execute(
                                            joinRequest.projectId,
                                            joinRequest.id
                                        ),
                                    resetAction: () =>
                                        Actions.joinRequests.approve.reset(),
                                    message: t(
                                        'join-requests-table-approve-message'
                                    ),
                                }}
                            >
                                <FontAwesomeIcon icon='check' />
                            </DialogButton>
                        </OverlayTrigger>
                    )}
                    {isUserApprover && (
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                <Tooltip>
                                    {t('join-requests-table-decline')}
                                </Tooltip>
                            }
                        >
                            <DialogButton
                                variant='outline-danger'
                                size='sm'
                                dialog={DeleteDialog}
                                dialogParams={{
                                    requestModel: decline,
                                    callAction: () =>
                                        Actions.joinRequests.decline.execute(
                                            joinRequest.projectId,
                                            joinRequest.id
                                        ),
                                    resetAction: () =>
                                        Actions.joinRequests.decline.reset(),
                                    acceptText: t(
                                        'join-requests-table-decline-btn'
                                    ),
                                    message: t(
                                        'join-requests-table-decline-message'
                                    ),
                                }}
                            >
                                <FontAwesomeIcon icon='trash' />
                            </DialogButton>
                        </OverlayTrigger>
                    )}
                    {isUserJoinRequest && canCancel && (
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                <Tooltip>
                                    {t('join-requests-table-cancel')}
                                </Tooltip>
                            }
                        >
                            <DialogButton
                                variant='outline-danger'
                                size='sm'
                                dialog={DeleteDialog}
                                dialogParams={{
                                    requestModel: cancel,
                                    callAction: () =>
                                        Actions.joinRequests.cancel.execute(
                                            joinRequest.projectId,
                                            joinRequest.id
                                        ),
                                    resetAction: () =>
                                        Actions.joinRequests.cancel.reset(),
                                    acceptText: t(
                                        'join-requests-table-cancel-btn'
                                    ),
                                    message: t(
                                        'join-requests-table-cancel-message'
                                    ),
                                }}
                            >
                                <FontAwesomeIcon icon='trash' />
                            </DialogButton>
                        </OverlayTrigger>
                    )}
                </ButtonGroup>
            </td>
        </tr>
    );
};

const TableHeader = () => {
    const { t } = useTranslation();
    return (
        <thead>
            <tr>
                <th>{t('join-requests-table-created-at-col')}</th>
                <th>{t('join-requests-table-project-col')}</th>
                <th>{t('join-requests-table-user-col')}</th>
                <th>{t('join-requests-table-message-col')}</th>
                <th>{t('join-requests-table-status-col')}</th>
                <th>{t('join-requests-table-actions-col')}</th>
            </tr>
        </thead>
    );
};
const JoinRequestsTable = ({ model }) => {
    const user = useSelector((state) => state.account.user);

    return (
        <Table bordered hover>
            <TableHeader />
            <tbody>
                {model.map((joinRequest) => (
                    <TableRow
                        key={joinRequest.id}
                        joinRequest={joinRequest}
                        user={user}
                    />
                ))}
            </tbody>
        </Table>
    );
};

export { JoinRequestsTable };
