// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import {
    getJsonRequest,
    postJsonRequest,
    postRequest,
    withAuth,
    withOffsetLimit,
} from './common';

export const getRequests = (auth, offset, limit) =>
    getJsonRequest(
        withOffsetLimit(`/projects/requests`, offset, limit),
        withAuth(auth)
    );

export const getOwnRequests = (auth, offset, limit) =>
    getJsonRequest(
        withOffsetLimit(`/projects/users/${auth.id}/requests`, offset, limit),
        withAuth(auth)
    );

export const createRequest = (auth, projectId) =>
    postJsonRequest(
        '/projects/requests',
        withAuth(auth, { body: JSON.stringify({ projectId: projectId }) })
    );

export const cancelRequest = (auth, id) =>
    postRequest(`/projects/requests/${id}/cancel`, withAuth(auth));

export const confirmRequest = (auth, id) =>
    postJsonRequest(
        `/projects/requests/${id}/confirm`,
        withAuth(auth, {
            body: JSON.stringify({
                confirmed: true,
            }),
        })
    );

export const declineRequest = (auth, id) =>
    postJsonRequest(
        `/projects/requests/${id}/decline`,
        withAuth(auth, {
            body: JSON.stringify({
                confirmed: false,
            }),
        })
    );
