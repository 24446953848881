// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

const ProjectWizardStep = ({ className, children }) => {
    return (
        <div className={`${className || ''} `}>
            {children}
        </div>
    );
};

export { ProjectWizardStep };
