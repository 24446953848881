// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const CreateSegmentDialog = ({
    onInitialize = () => {},
    onSave = () => {},
}) => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);
    const [points, setPoints] = useState([]);
    const [startPoint, setStartPoint] = useState({ lng: 0.0, lat: 0.0 });

    const { register, handleSubmit, getValues, errors } = useForm({});

    useEffect(() => {
        onInitialize({
            open: (startPoint) => {
                setPoints([]);
                setVisible(true);
                setStartPoint(startPoint);
            },
        });
    }, [onInitialize]);

    const createPointsArray = (points, data) => [
        ...points,
        [parseFloat(data.lng), parseFloat(data.lat)],
    ];
    const onSubmit = (data) => {
        setPoints(createPointsArray(points, data));
    };

    const onSaveClicked = () => {
        onSave(createPointsArray(points, getValues()));
        setVisible(false);
    };

    return (
        <Modal show={visible} centered onHide={() => setVisible(false)}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t('create-segment-enter-point')} [{1 + points.length}]
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{t('create-segment-longitude')}</Form.Label>
                        <Form.Control
                            type='number'
                            name='lng'
                            step='0.00001'
                            min='-180'
                            max='80'
                            isInvalid={errors.lng}
                            defaultValue={startPoint.lng}
                            ref={register({
                                required: true,
                            })}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{t('create-segment-latitude')}</Form.Label>
                        <Form.Control
                            type='number'
                            name='lat'
                            step='0.00001'
                            min='-90'
                            max='90'
                            defaultValue={startPoint.lat}
                            isInvalid={errors.lat}
                            ref={register({
                                required: true,
                            })}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='secondary'
                        onClick={() => setVisible(false)}
                    >
                        {t('create-segment-cancel-btn')}
                    </Button>
                    <Button type='submit' variant='secondary'>
                        {t('create-segment-add-btn')}
                    </Button>
                    <Button
                        variant='primary'
                        disabled={0 === points.length}
                        onClick={onSaveClicked}
                    >
                        {t('create-segment-finish')}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export { CreateSegmentDialog };
