// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { BasePage } from 'components/pages/BasePage';
import { PageLayout } from 'components/common/PageLayout';
import { PageHeader } from 'components/common/PageHeader';
import { LinkButton } from 'components/common/LinkButton';
import { PageSectionPagination } from 'components/common/PageSectionPagination';

import { ProjectsTable } from 'components/tables/ProjectsTable';

import Actions from 'actions';
import { useTranslation } from 'react-i18next';

const ProjectsPage = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.account.user);
    const { t } = useTranslation();
    const onCreateClicked = () => {
        dispatch(Actions.projects.wizardMapSearchReset());
        dispatch(Actions.projects.wizardViewStart());
    };

    return (
        <BasePage>
            <PageLayout>
                <PageHeader title={t('projects-title')}>
                    {user.features.canCreateProject && (
                        <LinkButton
                            variant='outline-primary'
                            size={'lg'}
                            to='/projects/create'
                            onClick={onCreateClicked}
                        >
                            {t('projects-create-btn')}
                        </LinkButton>
                    )}
                </PageHeader>

                <PageSectionPagination
                    fetchAction={Actions.projects.tableFetch}
                    table={ProjectsTable}
                    scope={['projects', 'table']}
                />
            </PageLayout>
        </BasePage>
    );
};

export { ProjectsPage };
