// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import * as account from './account.api';
import * as projects from './projects.api';
import * as requests from './requests.api';
import * as users from './users.api';
import * as map from './map.api';
import * as system from './system.api';
import * as investments from './investments.api';
import * as joinRequests from './joinrequests.api';
import * as forum from './forum.api';

export default {
    account,
    projects,
    requests,
    users,
    map,
    system,
    investments,
    joinRequests,
    forum,
};
