// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { makeRequestActions } from './common';
import { createAction } from '@reduxjs/toolkit';

export const makeSearchActions = scope =>
    makeRequestActions(`${scope}/search`, {
        request: (query, limit = 1) => ({
            payload: { query, limit },
        }),
        success: (count, data) => ({
            payload: { count, data },
        }),
    }).concat(createAction(`${scope}/search:reset`));
