// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { createReducer } from '@reduxjs/toolkit';

const initial = {
    visible: false,
    title: '',
    message: '',
};

export const makeAlertReducer = scope =>
    createReducer(initial, {
        [`${scope}/alert:show`]: (state, action) => ({
            ...state,
            visible: true,
            title: action.payload.title || '',
            message: action.payload.message || '',
        }),
        [`${scope}/alert:hide`]: () => initial,
    });
