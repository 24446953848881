// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Form, Button, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { CommonAlert } from 'components/common/CommonAlert';
import { BaseForm } from 'components/forms/BaseForm';

import Actions from 'actions';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PageLoader } from 'components/common/PageLoader';

const RegisterForm = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const registerProcess = useSelector(
        (state) => state.account.register.process
    );

    const userPermissions = useSelector((state) => state.app.userPermissions);

    const { register, handleSubmit, errors } = useForm();

    const onSubmit = (data) => {
        dispatch(Actions.account.register(data));
    };

    return (
        <BaseForm onSubmit={handleSubmit(onSubmit)}>
            <CommonAlert scope={['account', 'register']} />
            {registerProcess.pending ? (
                <PageLoader />
            ) : (
                <>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                {t('register-form-first-name')}
                            </Form.Label>
                            <Form.Control
                                type='text'
                                placeholder={t(
                                    'register-form-enter-first-name-placeholder'
                                )}
                                name='firstName'
                                ref={register({ required: true })}
                                isInvalid={errors.firstName}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>
                                {t('register-form-last-name')}
                            </Form.Label>
                            <Form.Control
                                type='text'
                                placeholder={t(
                                    'register-form-enter-last-name-placeholder'
                                )}
                                name='lastName'
                                ref={register({ required: true })}
                                isInvalid={errors.lastName}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                {t('register-form-organisation')}
                            </Form.Label>
                            <Form.Control
                                type='text'
                                placeholder={t(
                                    'register-form-enter-organisation-placeholder'
                                )}
                                name='organisation'
                                ref={register({ required: false })}
                                isInvalid={errors.organisation}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>{t('register-form-phone')}</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder={t(
                                    'register-form-enter-phone-placeholder'
                                )}
                                name='phoneNumber'
                                ref={register({
                                    required: false,
                                    pattern: /[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}/,
                                })}
                                isInvalid={errors.phoneNumber}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Group>
                        <Form.Label>{t('register-form-email')}</Form.Label>
                        <Form.Control
                            type='email'
                            placeholder={t(
                                'register-form-enter-email-placeholder'
                            )}
                            name='email'
                            ref={register({ required: true })}
                            isInvalid={errors.email}
                        />
                    </Form.Group>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                {t('register-form-username')}
                            </Form.Label>
                            <Form.Control
                                type='text'
                                placeholder={t(
                                    'register-form-enter-username-placeholder'
                                )}
                                name='username'
                                ref={register({ required: true, minLength: 5 })}
                                isInvalid={errors.username}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>
                                {t('register-form-password')}
                            </Form.Label>
                            <Form.Control
                                type='password'
                                placeholder={t(
                                    'register-form-enter-password-placeholder'
                                )}
                                name='password'
                                ref={register({ required: true, minLength: 8 })}
                                isInvalid={errors.password}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Group>
                        <Form.Label>
                            {t('register-form-register-as')}
                        </Form.Label>
                        <Form.Control
                            as='select'
                            name='role'
                            ref={register({ required: true })}
                        >
                            {userPermissions.map((p) => (
                                <option key={p.code} value={p.code}>
                                    {t(p.name)}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            {t('register-form-data-policy')}
                            <Link
                                style={{ color: 'blue' }}
                                to='/registration-data-policy'
                                target='_blank'
                            >
                                {' '}
                                {t('register-form-read-data-policy')}
                            </Link>
                        </Form.Label>

                        <Form.Check
                            type='checkbox'
                            name='agreeDataPolicy'
                            label={t('register-form-agree-with-data-policy')}
                            ref={register({ required: true })}
                            isInvalid={errors.agreeDataPolicy}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            {t('register-form-terms-and-conditions')}
                            <Link
                                style={{ color: 'blue' }}
                                variant='primary'
                                to='/registration-terms-and-conditions'
                                target='_blank'
                            >
                                {' '}
                                {t('register-form-read-terms-and-conditions')}
                            </Link>
                        </Form.Label>

                        <Form.Check
                            type='checkbox'
                            name='agreeTermsConditions'
                            label={t(
                                'register-form-agree-with-terms-conditions'
                            )}
                            ref={register({ required: true })}
                            isInvalid={errors.agreeTermsConditions}
                        />
                    </Form.Group>
                </>
            )}

            <Button
                variant='primary'
                type='submit'
                disabled={registerProcess.pending}
            >
                {t('register-form-register-btn')}
            </Button>
        </BaseForm>
    );
};

export { RegisterForm };
