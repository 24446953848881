// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useState } from 'react';

import { Form, InputGroup, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const SearchField = ({
    className,
    isInvalid,
    isValid,
    initialValue = '',
    disabled = false,
    onSearch = () => {},
}) => {
    const [value, setValue] = useState(initialValue);
    const { t } = useTranslation();

    const onChanged = (e) => {
        setValue(e.target.value);
        if (!e.target.value) {
            onSearch(value);
        }
    };

    const onKeyDown = (e) => {
        if (e.key === 'Enter' && !disabled) {
            onSearch(value);
        }
    };

    return (
        <InputGroup className={`${className || ''}`}>
            <Form.Control
                placeholder={t('search-field-placeholder')}
                type='text'
                onChange={onChanged}
                value={value}
                isInvalid={isInvalid}
                isValid={isValid}
                onKeyDown={onKeyDown}
                disabled={disabled}
            />
            <InputGroup.Append>
                <Button
                    variant='outline-secondary'
                    disabled={disabled}
                    onClick={() => onSearch(value)}
                >
                    {t('search-field-search-btn')}
                </Button>
            </InputGroup.Append>
        </InputGroup>
    );
};

export { SearchField };
