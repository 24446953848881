// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Form, Col } from 'react-bootstrap';

import { BaseDialog } from 'components/dialogs/BaseDialog';
import { useForm } from 'react-hook-form';
import { useListReducer } from 'helpers/hooks';
import { useTranslation } from 'react-i18next';

const MapLayerFilterDialog = ({
    selectedSegmentTypes = [],
    show,
    onHide,
    onClose,
    onAccept,
    onSelectedSegmentTypesChange = () => {},
}) => {
    const segmentTypes = useListReducer((state) => state.app.segmentTypes);
    const { t } = useTranslation();
    const { register, handleSubmit } = useForm({});

    const onSubmit = (data) => {
        const selected = [];
        for (let id of Object.keys(data)) {
            if (data[id]) {
                selected.push(id);
            }
        }

        onSelectedSegmentTypesChange(selected);
    };

    return (
        <BaseDialog
            title={t('layer-filter-title')}
            onSubmit={handleSubmit(onSubmit)}
            show={show}
            onHide={onHide}
            onClose={onClose}
            onAccept={onAccept}
            finished={true}
        >
            <Col style={{ overflowY: 'auto', maxHeight: '35vh' }}>
                {segmentTypes.map((segmentType) => (
                    <Form.Group key={segmentType.id}>
                        <Form.Check
                            type='checkbox'
                            label={segmentType.name}
                            name={segmentType.id}
                            defaultChecked={selectedSegmentTypes.includes(
                                segmentType.id
                            )}
                            ref={register({ required: false })}
                        />
                    </Form.Group>
                ))}
            </Col>
        </BaseDialog>
    );
};

export { MapLayerFilterDialog };
