// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { getJsonRequest, withAuth } from './common';

export const getSystemLanguages = () =>
    getJsonRequest(`/system/languages`);

export const getCoordinateFormats = () =>
    getJsonRequest(`/system/coordinate-systems`);

export const getCurrencies = () =>
    getJsonRequest(`/system/currencies`);

export const getUserPermissions = () =>
    getJsonRequest(`/system/user-permissions`);
