// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { Table } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const TableRow = ({ segment, types }) => {
    const { t } = useTranslation();
    return (
        <tr>
            <td>{t('segments-table-segment-row')}</td>
            <td>
                {segment.typeId &&
                    types.byId[segment.typeId] &&
                    types.byId[segment.typeId].name}
            </td>
        </tr>
    );
};

const TableHeader = () => {
    const { t } = useTranslation();
    return (
        <thead>
            <tr>
                <th>{t('segments-table-name-col')}</th>
                <th>{t('segments-table-type-col')}</th>
            </tr>
        </thead>
    );
};

const ProjectSegmentsTable = ({ model = [] }) => {
    const segmentTypesList = useSelector((state) => state.app.segmentTypes);

    return (
        <Table bordered hover>
            <TableHeader />
            <tbody>
                {model.map((segment, i) => (
                    <TableRow
                        key={segment.id}
                        segment={segment}
                        index={i}
                        types={segmentTypesList}
                    />
                ))}
            </tbody>
        </Table>
    );
};

export { ProjectSegmentsTable };
