// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { combineReducers, createReducer } from '@reduxjs/toolkit';

import Actions from 'actions';

import { makeAlertReducer } from './alert.reducer';
import { makePageReducer, removeFromPageReducer } from './page.reducer';
import { makeProcessReducer } from './process.reducer';
import { makeSearchReducer } from './search.reducer';
import {
    makeListReducer,
    addToListReducer,
    clearListReducer,
    initListReducer,
    updateToListReducer,
    appendItemToListReducer,
    prependItemToListReducer,
    removeItemFromListReducer,
} from './list.reducer';
import { makeFetchReducer } from './fetch.reducer';
import { makeModelReducer } from './model.reducer';

const topicsListReducer = {
    [`${Actions.forum.CREATE_TOPIC_ACTION}:success`]: (state, action) =>
        appendItemToListReducer(state, action.payload),
    [`${Actions.forum.DELETE_TOPIC_ACTION}:success`]: (state, action) =>
        removeItemFromListReducer(state, action.payload),
};

const messagesListReducer = {
    [`${Actions.forum.SEND_MESSAGE_ACTION}:success`]: (state, action) =>
        prependItemToListReducer(state, action.payload),
    [`${Actions.forum.DELETE_MESSAGE_ACTION}:success`]: (state, action) =>
        removeItemFromListReducer(state, action.payload),
};

export default combineReducers({
    sendMessage: makeFetchReducer(Actions.forum.SEND_MESSAGE_ACTION, (scope) =>
        makeModelReducer(scope, null)
    ),
    deleteMessage: makeFetchReducer(
        Actions.forum.DELETE_MESSAGE_ACTION,
        (scope) => makeModelReducer(scope, null)
    ),
    deleteTopic: makeFetchReducer(Actions.forum.DELETE_TOPIC_ACTION, (scope) =>
        makeModelReducer(scope, null)
    ),
    createTopic: makeFetchReducer(Actions.forum.CREATE_TOPIC_ACTION, (scope) =>
        makeModelReducer(scope, null)
    ),
    topicsList: makeFetchReducer(Actions.forum.TOPICS_LIST_ACTION, (scope) =>
        makeListReducer(scope, topicsListReducer)
    ),
    messagesList: makeFetchReducer(
        Actions.forum.MESSAGES_LIST_ACTION,
        (scope) => makeListReducer(scope, messagesListReducer, false)
    ),
});
