// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import * as serviceWorker from './serviceWorker';

import React from 'react';
import ReactDOM from 'react-dom';

import './custom.scss';

import './i18n';

import { Root } from 'components/Root';
import { intializeFonts } from 'fonts';

intializeFonts();

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
