// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { createAction } from '@reduxjs/toolkit';

export const makeRequestActions = (scope, prepare) => [
    createAction(
        `${scope}:request`,
        (prepare && prepare.request) || (() => ({}))
    ),
    createAction(
        `${scope}:success`,
        (prepare && prepare.success) || (() => ({}))
    ),
    createAction(
        `${scope}:failure`,
        (prepare && prepare.failure) || ((err) => ({ payload: err }))
    ),
];
