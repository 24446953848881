// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { MultiFilePicker } from 'components/common/MultiFilePicker';
import { convertLatLngToUTM, convertUTMToLatLng } from 'helpers/utm';

import Actions from 'actions';
import { useTranslation } from 'react-i18next';
import { PageLoader } from 'components/common/PageLoader';

const DIALOG_FOR_POINTS = 'points';
const DIALOG_FOR_SEGMENTS = 'segments';
const DIALOG_FOR_AREAS = 'areas';

const PropertiesDialog = ({ onInitialize = () => {}, onSave = () => {} }) => {
    const [mode, setMode] = useState('');
    const [model, setModel] = useState({});

    useEffect(() => {
        onInitialize({
            openForSegments: (model) => {
                setModel(model);
                setMode(DIALOG_FOR_SEGMENTS);
            },
            openForPoints: (model) => {
                console.log(model);
                setModel(model);
                setMode(DIALOG_FOR_POINTS);
            },
            openForAreas: (model) => {
                setModel(model);
                setMode(DIALOG_FOR_AREAS);
            },
        });
    }, [onInitialize]);

    const onSubmit = (data) => {
        switch (mode) {
            case DIALOG_FOR_POINTS:
                data.type = 'points';
                data.elevation = parseFloat(data.elevation);
                break;
            case DIALOG_FOR_SEGMENTS:
                data.type = 'segments';
                break;
            case DIALOG_FOR_AREAS:
                data.type = 'areas';
                break;
            default:
                break;
        }
        onSave(data);
    };

    return (
        <Modal
            show={'' !== mode}
            centered
            onHide={() => setMode('')}
            backdrop={'static'}
        >
            {DIALOG_FOR_POINTS === mode && (
                <PropertiesDialogPointsForm
                    model={model}
                    onSubmit={onSubmit}
                    onClose={() => setMode('')}
                />
            )}

            {DIALOG_FOR_SEGMENTS === mode && (
                <PropertiesDialogSegmentsForm
                    model={model}
                    onSubmit={onSubmit}
                    onClose={() => setMode('')}
                />
            )}

            {DIALOG_FOR_AREAS === mode && (
                <PropertiesDialogAreasForm
                    model={model}
                    onSubmit={onSubmit}
                    onClose={() => setMode('')}
                />
            )}
        </Modal>
    );
};

const PropertiesDialogPointsForm = ({ model, onClose, onSubmit }) => {
    const { t } = useTranslation();
    const utm = model ? convertLatLngToUTM(model.lat, model.lng) : null;

    const { register, handleSubmit } = useForm({
        defaultValues: {
            elevation: model ? model.elevation : 0.0,
            northing: utm ? utm.northing.toFixed(3) : 0.0,
            easting: utm ? utm.easting.toFixed(3) : 0.0,
        },
    });

    const wrapOnSubmit = (data) => {
        const lnglat = convertUTMToLatLng(
            utm.zone,
            utm.hemisphere,
            data.easting,
            data.northing
        );

        data.longitude = lnglat.lng;
        data.latitude = lnglat.lat;

        onSubmit(data);
        onClose();
    };
    return (
        <Form onSubmit={handleSubmit(wrapOnSubmit)}>
            <Modal.Header closeButton>
                <Modal.Title>{t('editor-props')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!model.elevationDisabled && (
                    <Form.Group>
                        <Form.Label>{t('editor-props-elevation')}</Form.Label>
                        <Form.Control
                            type='number'
                            name='elevation'
                            ref={register}
                        />
                    </Form.Group>
                )}
                <Form.Group>
                    <Form.Label>{t('editor-props-northing')}</Form.Label>

                    <Form.Control
                        type='number'
                        name='northing'
                        step='any'
                        ref={register({
                            max: 9334080.0,
                            min: 1110400.0,
                        })}
                    />

                    <Form.Label>{t('editor-props-easting')}</Form.Label>

                    <Form.Control
                        type='number'
                        name='easting'
                        step='any'
                        ref={register({
                            max: 833360.0,
                            min: 166640.0,
                        })}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={onClose}>
                    {t('editor-props-cancel-btn')}
                </Button>
                <Button type='submit' variant='primary'>
                    {t('editor-props-save-btn')}
                </Button>
            </Modal.Footer>
        </Form>
    );
};

const PropertiesDialogSegmentsForm = ({ model, onClose, onSubmit }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const segmentTypes = useSelector((state) => state.app.segmentTypes);

    const { register, handleSubmit } = useForm({
        defaultValues: {
            description: model ? model.description : '',
            title: model ? model.title : '',
            link: model ? model.link : '',
            cadLayer: model ? model.cadLayer : '',
            segmentTypeId:
                (model && model.segmentType && model.segmentType.id) ||
                segmentTypes.allIds[0],
        },
    });

    const initialDocuments =
        model &&
        model.documents &&
        model.documents.map((doc) => ({ id: doc.id, name: doc.filename }));

    const initialImages =
        model &&
        model.images &&
        model.images.map((image) => ({ id: image.id, name: image.filename }));

    const [documents, setDocuments] = useState([]);
    const [images, setImages] = useState([]);
    const [sending, setSending] = useState(null);

    const editSegment = useSelector(
        (state) => state.projects.wizard.editSegment
    );

    const editSegmentProcess = editSegment.process;

    useEffect(() => {
        if (sending && editSegment.model && !editSegmentProcess.pending) {
            onSubmit({ ...sending, id: editSegment.model.id });
            onClose();
        }
    }, [editSegmentProcess, sending, editSegment, onClose, onSubmit]);

    const onDocumentsChanged = (files) => {
        setDocuments(files);
    };

    const onImagesChanged = (files) => {
        setImages(files);
    };

    const onSave = (data) => {
        const changedImages = images ? images.map((image) => image) : [];
        const changedDocuments = documents ? documents.map((doc) => doc) : [];
        dispatch(
            Actions.projects.wizardEditSegment.execute(
                model.projectId,
                model.id,
                {
                    link: data.link,
                    title: data.title,
                    description: data.description,
                    geoJson: model.geoJson,
                    typeId: data.segmentTypeId,
                    changedImages: changedImages,
                    changedDocuments: changedDocuments,
                }
            )
        );

        setSending({ ...data, changedImages, changedDocuments });
    };

    return (
        <Form onSubmit={handleSubmit(onSave)}>
            <Modal.Header>
                <Modal.Title>{t('editor-props')}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflowY: 'auto', maxHeight: '70vh' }}>
                {editSegmentProcess.pending ? (
                    <PageLoader />
                ) : (
                    <>
                        <Form.Group>
                            <Form.Label>{t('editor-props-type')}</Form.Label>
                            <Form.Control
                                as='select'
                                name='segmentTypeId'
                                ref={register}
                            >
                                {segmentTypes.allIds.map((id) => (
                                    <option key={id} value={id}>
                                        {segmentTypes.byId[id].name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                {t('editor-props-cad-layer')}
                            </Form.Label>
                            <Form.Control
                                type='text'
                                readOnly={true}
                                name='cadLayer'
                                ref={register}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t('editor-props-title')}</Form.Label>
                            <Form.Control
                                type='text'
                                name='title'
                                ref={register}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t('editor-props-link')}</Form.Label>
                            <Form.Control
                                type='url'
                                name='link'
                                placeholder='https://example.com'
                                ref={register}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                {t('editor-props-description')}
                            </Form.Label>
                            <Form.Control
                                as='textarea'
                                name='description'
                                ref={register}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                {t('editor-props-documents')}
                            </Form.Label>
                            <MultiFilePicker
                                initialFiles={initialDocuments}
                                onFilesChanged={onDocumentsChanged}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t('editor-props-images')}</Form.Label>
                            <MultiFilePicker
                                acceptFiles='image/*'
                                initialFiles={initialImages}
                                onFilesChanged={onImagesChanged}
                            />
                        </Form.Group>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={onClose}
                    disabled={editSegmentProcess.pending || sending}
                >
                    {t('editor-props-cancel-btn')}
                </Button>
                <Button
                    type='submit'
                    variant='primary'
                    disabled={editSegmentProcess.pending || sending}
                >
                    {t('editor-props-save-btn')}
                </Button>
            </Modal.Footer>
        </Form>
    );
};

const PropertiesDialogAreasForm = ({ model, onClose, onSubmit }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const areaTypes = useSelector((state) => state.app.areaTypes);

    const { register, handleSubmit } = useForm({
        defaultValues: {
            description: model ? model.description : '',
            title: model ? model.title : '',
            link: model ? model.link : '',
            areaTypeId:
                (model && model.areaType && model.areaType.id) ||
                areaTypes.allIds[0],
        },
    });

    const initialDocuments =
        model &&
        model.documents &&
        model.documents.map((doc) => ({ id: doc.id, name: doc.filename }));

    const initialImages =
        model &&
        model.images &&
        model.images.map((image) => ({ id: image.id, name: image.filename }));

    const [documents, setDocuments] = useState([]);
    const [images, setImages] = useState([]);
    const [sending, setSending] = useState(null);

    const editArea = useSelector((state) => state.projects.wizard.editArea);

    const editAreaProcess = editArea.process;

    useEffect(() => {
        if (sending && editArea.model && !editAreaProcess.pending) {
            onSubmit({ ...sending, id: editArea.model.id });
            onClose();
        }
    }, [editAreaProcess, sending, editArea, onSubmit, onClose]);

    const onDocumentsChanged = (files) => {
        setDocuments(files);
    };

    const onImagesChanged = (files) => {
        setImages(files);
    };

    const onSave = (data) => {
        const changedImages = images ? images.map((image) => image) : [];
        const changedDocuments = documents ? documents.map((doc) => doc) : [];
        dispatch(
            Actions.projects.wizardEditArea.execute(model.projectId, model.id, {
                link: data.link,
                title: data.title,
                description: data.description,
                geoJson: model.geoJson,
                typeId: data.areaTypeId,
                changedImages: changedImages,
                changedDocuments: changedDocuments,
            })
        );

        setSending({ ...data, changedImages, changedDocuments });
    };

    return (
        <Form onSubmit={handleSubmit(onSave)}>
            <Modal.Header>
                <Modal.Title>{t('editor-props')}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflowY: 'auto', maxHeight: '70vh' }}>
                {editAreaProcess.pending ? (
                    <PageLoader />
                ) : (
                    <>
                        <Form.Group>
                            <Form.Label>{t('editor-props-type')}</Form.Label>
                            <Form.Control
                                as='select'
                                name='areaTypeId'
                                ref={register}
                            >
                                {areaTypes.allIds.map((id) => (
                                    <option key={id} value={id}>
                                        {areaTypes.byId[id].name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t('editor-props-title')}</Form.Label>
                            <Form.Control
                                type='text'
                                name='title'
                                ref={register}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{t('editor-props-link')}</Form.Label>
                            <Form.Control
                                type='url'
                                name='link'
                                placeholder='https://example.com'
                                ref={register}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                {t('editor-props-description')}
                            </Form.Label>
                            <Form.Control
                                as='textarea'
                                name='description'
                                ref={register}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                {t('editor-props-documents')}
                            </Form.Label>
                            <MultiFilePicker
                                initialFiles={initialDocuments}
                                onFilesChanged={onDocumentsChanged}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label> {t('editor-props-images')}</Form.Label>
                            <MultiFilePicker
                                acceptFiles='image/*'
                                initialFiles={initialImages}
                                onFilesChanged={onImagesChanged}
                            />
                        </Form.Group>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={onClose}
                    disabled={editAreaProcess.pending || sending}
                >
                    {t('editor-props-cancel-btn')}
                </Button>
                <Button
                    type='submit'
                    variant='primary'
                    disabled={editAreaProcess.pending || sending}
                >
                    {t('editor-props-save-btn')}
                </Button>
            </Modal.Footer>
        </Form>
    );
};

export { PropertiesDialog };
