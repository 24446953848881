// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

export const EDITOR_TOOL_MODE_CURSOR = 'cursor';
export const EDITOR_TOOL_MODE_DRAW_SEGMENT = 'draw-segment';
export const EDITOR_TOOL_MODE_DRAW_AREA = 'draw-area';

export const EDITOR_TOOL_ACTION_GROUP = 'group';
export const EDITOR_TOOL_ACTION_UNGROUP = 'ungroup';
export const EDITOR_TOOL_ACTION_TRASH = 'trash';
export const EDITOR_TOOL_ACTION_COMMENT = 'comment';
export const EDITOR_TOOL_ACTION_CREATE_SEGMENT = 'create-segment';