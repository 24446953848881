// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { PageHeader } from 'components/common/PageHeader';
import { PageLayout } from 'components/common/PageLayout';

import { BasePage } from 'components/pages/BasePage';

import { useTranslation } from 'react-i18next';

import { useListReducer } from 'helpers/hooks';
import { JoinRequestsTable } from 'components/tables/JoinRequestsTable';

import Actions from 'actions';

const JoinRequestsPage = ({ match }) => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const model = useListReducer((state) => state.joinRequests.list.model);
    useEffect(() => {
        dispatch(Actions.joinRequests.list.fetch());
    }, []);

    return (
        <BasePage>
            <PageLayout>
                <PageHeader title={t('joint-requests-title')}></PageHeader>
                <JoinRequestsTable model={model} />
            </PageLayout>
        </BasePage>
    );
};

export { JoinRequestsPage };
