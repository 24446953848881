// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { createAction } from '@reduxjs/toolkit';

export const makeAlertActions = scope => [
    createAction(`${scope}/alert:show`, (title, message) => ({
        payload: { title, message },
    })),
    createAction(`${scope}/alert:hide`),
];
