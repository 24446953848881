// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import * as THREE from 'three';

const pointMapper = (point, mapper) => {
    const r = mapper(point);
    return [r.x, 0.0, r.y];
};

const retrieveSegmentProperties = (model) => {
    if (!model || !model.geoJson || !model.geoJson.properties) {
        return null;
    }

    const geoProps = model.geoJson.properties;
    if (geoProps.segment && geoProps.segment.points) {
        return geoProps.segment;
    }

    if (!geoProps.points) {
        return null;
    }

    return geoProps;
};

const calculateGeometry = (
    coordinates,
    pointDetails,
    mapper,
    radius,
    count
) => {
    const stepAngle = (2.0 * Math.PI) / count;
    const radiusVector = new THREE.Vector3(0, 1, 0); // Immutable vector
    const normalVector = new THREE.Vector3(0, 0, 1);
    const vertexStartVector = new THREE.Vector3(0, 0, 0); // Temporary vector
    const vertexEndVector = new THREE.Vector3(0, 0, 0); // Temporary vector
    const vertexVector = new THREE.Vector3(0, 0, 0); // Temporary vector

    let points = coordinates
        .map((point) => pointMapper(point, mapper))
        .map((point, i) => {
            point[1] =
                ((i < pointDetails.length && pointDetails[i].elevation) ||
                    0.0) * 5.0;
            return point;
        });

    const pointsCount = points.length + points.length - 2;
    const positions = new Array(pointsCount * 3 * count + 2 * 3).fill(0);
    const indices = new Array(pointsCount * count + 2 * (count + 1)).fill(0);

    const rotate = (vertex, radius, normal, angle) =>
        vertexVector.addVectors(
            vertex,
            radiusVector
                .set(0, 1, 0)
                .applyAxisAngle(normal, angle)
                .multiplyScalar(radius)
        );

    let vertexIndex = 0;
    for (let i = 1; i < points.length; ++i) {
        vertexStartVector.fromArray(points[i - 1]);
        vertexEndVector.fromArray(points[i - 0]);

        normalVector.subVectors(vertexEndVector, vertexStartVector).normalize();

        let angle = 0.0;

        for (let j = 0; j < count; ++j) {
            rotate(vertexStartVector, radius, normalVector, angle).toArray(
                positions,
                vertexIndex * count * 3 + j * 3
            );

            rotate(vertexEndVector, radius, normalVector, angle).toArray(
                positions,
                (vertexIndex + 1) * count * 3 + j * 3
            );

            angle += stepAngle;
        }
        vertexIndex += 2;
    }

    // Create begin of segment
    positions[pointsCount * count * 3 + 0] = points[0][0];
    positions[pointsCount * count * 3 + 1] = points[0][1];
    positions[pointsCount * count * 3 + 2] = points[0][2];

    // Create end of segment
    positions[pointsCount * count * 3 + 3] = points[points.length - 1][0];
    positions[pointsCount * count * 3 + 4] = points[points.length - 1][1];
    positions[pointsCount * count * 3 + 5] = points[points.length - 1][2];

    let indexOffset = 0;
    for (let i = 1; i < pointsCount; ++i) {
        const startIndex = (i - 1) * count;
        const endIndex = (i - 0) * count;
        for (let j = 0; j < count; ++j) {
            indices[indexOffset++] = startIndex + ((j + 0) % count);
            indices[indexOffset++] = startIndex + ((j + 1) % count);
            indices[indexOffset++] = endIndex + ((j + 0) % count);

            indices[indexOffset++] = startIndex + ((j + 1) % count);
            indices[indexOffset++] = endIndex + ((j + 1) % count);
            indices[indexOffset++] = endIndex + ((j + 0) % count);
        }
    }

    for (let j = 0; j < count; ++j) {
        indices[indexOffset++] = pointsCount * count;
        indices[indexOffset++] = (j + 1) % count;
        indices[indexOffset++] = (j + 0) % count;

        indices[indexOffset++] = pointsCount * count + 1;
        indices[indexOffset++] =
            pointsCount * count - count + ((j + 0) % count);
        indices[indexOffset++] =
            pointsCount * count - count + ((j + 1) % count);
    }

    const bufferGeometry = new THREE.BufferGeometry();
    bufferGeometry.setIndex(indices);
    bufferGeometry.setAttribute(
        'position',
        new THREE.BufferAttribute(new Float32Array(positions), 3)
    );

    return bufferGeometry;
};

const SegmentMesh = ({
    scale,
    position,
    model,
    mapper,
    segmentRadius = 1.0,
    segmentEdgeCount = 10,
    segmentTypes,
}) => {
    const geoJson = model.geoJson;
    const geoProps = retrieveSegmentProperties(model);

    const segmentPointDetails = (geoProps && geoProps.points) || [];

    const geometries = [];

    switch (geoJson.geometry.type) {
        case 'LineString':
            geometries.push(
                calculateGeometry(
                    geoJson.geometry.coordinates,
                    segmentPointDetails,
                    mapper,
                    segmentRadius,
                    segmentEdgeCount
                )
            );
            break;
        case 'MultiLineString':
            for (let i in geoJson.geometry.coordinates) {
                geometries.push(
                    calculateGeometry(
                        geoJson.geometry.coordinates[i],
                        segmentPointDetails[i],
                        mapper,
                        segmentRadius,
                        segmentEdgeCount
                    )
                );
            }
            break;
        default:
            break;
    }

    let segmentColor = 'black';

    if (segmentTypes) {
        const segmentType = segmentTypes.find(
            (type) => type.id === model.typeId
        );
        if (segmentType) {
            segmentColor = segmentType.color;
        }
    }

    return (
        <mesh position={position} scale={scale}>
            {geometries.map((geo, i) => (
                <mesh
                    key={i}
                    visible
                    geometry={geo}
                    onUpdate={(m) => {
                        m.geometry.computeVertexNormals();
                    }}
                >
                    <meshStandardMaterial
                        roughness={0.3}
                        metalness={0.1}
                        color={segmentColor}
                        attach='material'
                    />
                </mesh>
            ))}
        </mesh>
    );
};

export { SegmentMesh };
