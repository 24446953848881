import React from 'react';

import { Link } from 'react-router-dom';
import { Navbar, Nav} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import SinfraLogoIcon from 'assets/gendigitlogo.svg';

const PublicNavigationBar = () => {
    
    const { t } = useTranslation();

    return (
        <Navbar expand='sm'>
            <Navbar.Brand>
                <img
                    src={SinfraLogoIcon}
                    width='60'
                    height='auto'
                    alt=''
                    className='d-inline-block align-top'
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='app-navbar-nav' />
            <Navbar.Collapse id='app-navbar-nav'>
                <Nav className='mr-auto'></Nav>
                <Nav.Link as={Link} to='/login'>
                    {t('nav-login')}
                </Nav.Link>
                <Nav.Link as={Link} to='/registration'>
                    {t('nav-register')}
                </Nav.Link>
            </Navbar.Collapse>
        </Navbar>
    );
};

export { PublicNavigationBar };
