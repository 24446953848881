// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { makeRequest, JSON_HTTP_HEADERS } from './common';

const MAP_API_GEO_PLACE_BASE_URL =
    'https://api.mapbox.com/geocoding/v5/mapbox.places';

const MAP_API_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export const getGeoPlace = (query, limit = 5) =>
    makeRequest(
        `${MAP_API_GEO_PLACE_BASE_URL}/${encodeURI(
            query
        )}.json?limit=${limit}&access_token=${MAP_API_TOKEN}`,
        {
            method: 'GET',
            headers: {
                ...JSON_HTTP_HEADERS,
            },
        },
        (r) => r.json()
    ).then((r) => r.features);

export const getGeoPlaceByLocation = (longitude, latitude) =>
    makeRequest(
        `${MAP_API_GEO_PLACE_BASE_URL}/${encodeURI(
            `${longitude},${latitude}`
        )}.json?access_token=${MAP_API_TOKEN}`,
        {
            method: 'GET',
            headers: {
                ...JSON_HTTP_HEADERS,
            },
        },
        (r) => r.json()
    ).then((r) => r.features);
