// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { makePageActions } from './page.actions';
import { makeAlertActions } from './alert.actions';
import { makeProcessActions } from './process.actions';

import { makeFetchActions } from './fetch.actions';

export const TABLE_ACTION = 'requests/table';
export const CREATE_ACTION = 'requests/create';
export const APPROVE_ACTION = 'requests/approve';
export const DECLINE_ACTION = 'requests/decline';
export const CANCEL_ACTION = 'requests/cancel';

export const [tableFetch, tableSuccess, tableFailure] = makePageActions(
    TABLE_ACTION
);

export const [tableAlertShow, tableAlertHide] = makeAlertActions(TABLE_ACTION);
export const [tableProcessStart, tableProcessStop] = makeProcessActions(
    TABLE_ACTION
);

export const createRequest = makeFetchActions(CREATE_ACTION, {
    fetch: {
        request: (projectId) => ({ payload: projectId }),
        success: (request) => ({ payload: request }),
    },
});

export const approveRequest = makeFetchActions(APPROVE_ACTION, {
    fetch: {
        request: (id) => ({ payload: id }),
        success: (request) => ({ payload: request }),
    },
});

export const declineRequest = makeFetchActions(DECLINE_ACTION, {
    fetch: {
        request: (id) => ({ payload: id }),
        success: (request) => ({ payload: request }),
    },
});

export const cancelRequest = makeFetchActions(CANCEL_ACTION, {
    fetch: {
        request: (id) => ({ payload: id }),
        success: (id) => ({ payload: id }),
    },
});
