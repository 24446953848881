// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { makeTableActions } from './table.actions';
import { makeFetchActions } from './fetch.actions';
import { makeListActions } from './list.actions';
import { makeRequestActions } from './common';

export const TABLE_ACTION = 'users/table';
export const CREATE_ACTION = 'users/create';
export const EDIT_ACTION = 'users/edit';
export const BLOCK_ACTION = 'users/block';
export const PERMISSIONS_ACTION = 'users/permissions';

export const table = makeTableActions(TABLE_ACTION);
export const permissions = makeFetchActions(
    PERMISSIONS_ACTION,
    makeListActions
);

export const create = makeFetchActions(
    CREATE_ACTION,
    {
        fetch: {
            request: (user) => ({ payload: user }),
            success: (user) => ({ payload: user }),
        },
    },
    makeRequestActions
);

export const edit = makeFetchActions(
    EDIT_ACTION,
    {
        fetch: {
            request: (user) => ({ payload: user }),
            success: (user) => ({ payload: user }),
        },
    },
    makeRequestActions
);

export const block = makeFetchActions(
    BLOCK_ACTION,
    {
        fetch: {
            request: (userId) => ({ payload: userId }),
        },
    },
    makeRequestActions
);
