// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React from 'react';

import { useSelector } from 'react-redux';

import { Switch, Redirect } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { ConnectedRouter } from 'connected-react-router';

import { ProtectedRoute } from 'components/navigation/ProtectedRoute';
import { NoAuthRoute } from 'components/navigation/NoAuthRoute';

import { HomePage } from 'components/pages/HomePage';
import { RegisterPage } from 'components/pages/RegisterPage';
import { MapPage } from 'components/pages/MapPage';
import { LoginPage } from 'components/pages/LoginPage';
import { ProjectsPage } from 'components/pages/ProjectsPage';
import { CreateProjectPage } from 'components/pages/CreateProjectPage';
import { ViewProjectPage } from 'components/pages/ViewProjectPage';
import { EditProjectPage } from 'components/pages/EditProjectPage';
import { RequestsPage } from 'components/pages/RequestsPage';
import { SettingsPage } from 'components/pages/SettingsPage';
import { UsersPage } from 'components/pages/UsersPage';
import { DataPolicyPage } from 'components/pages/DataPolicyPage';
import { TermsAndConditions } from 'components/pages/TermsAndConditions';
import { InvestmentsPage } from 'components/pages/InvestmentsPage';
import { ViewInvestmentPage } from 'components/pages/ViewInvestmentPage';
import { InvestmentPhaseChatPage } from 'components/pages/InvestmentPhaseChatPage';
import { ProjectInvestmentsPage } from 'components/pages/ProjectInvestmentsPage';
import { MobileViewPage } from 'components/pages/MobileViewPage';
import { JoinRequestsPage } from 'components/pages/JoinRequestsPage';
import { Splash } from 'components/Splash';

import { history } from 'helpers/store';

import { isMobile } from 'react-device-detect';
import { ForumPage } from 'components/pages/ForumPage';
import { TopicPage } from 'components/pages/TopicPage';
import { ForgotPasswordPage } from 'components/pages/ForgotPasswordPage';

const AppRouter = ({ navigationBar: NavigationBar }) => {
    const appState = useSelector((state) => state.app);
    const appUser = useSelector((state) => state.account.user);
    return (
        <ConnectedRouter history={history}>
            <NavigationBar />
            <Row className={`container-fluid flex-fill flex-column p-0 m-0`}>
                <Switch>
                    {isMobile && (
                        <NoAuthRoute path='*' component={MobileViewPage} />
                    )}

                    <NoAuthRoute
                        path='/registration'
                        component={RegisterPage}
                    />
                    <NoAuthRoute
                        path='/forgot-password'
                        component={ForgotPasswordPage}
                    />

                    <NoAuthRoute path='/login' component={LoginPage} />

                    <NoAuthRoute
                        path='/registration-data-policy'
                        component={DataPolicyPage}
                    />
                    <NoAuthRoute
                        path='/registration-terms-and-conditions'
                        component={TermsAndConditions}
                    />

                    <ProtectedRoute
                        path='/data-policy'
                        component={DataPolicyPage}
                    />
                    <ProtectedRoute
                        path='/terms-and-conditions'
                        component={TermsAndConditions}
                    />

                    {!appState.isLoaded && (
                        <ProtectedRoute path='/' component={Splash} />
                    )}

                    <ProtectedRoute exact path='/' component={HomePage} />
                    <ProtectedRoute exact path='/map' component={MapPage} />
                    <ProtectedRoute
                        exact
                        path='/requests'
                        component={RequestsPage}
                    />
                    <ProtectedRoute exact path='/users' component={UsersPage} />
                    <ProtectedRoute
                        exact
                        path='/settings'
                        component={SettingsPage}
                    />

                    <ProtectedRoute
                        exact
                        path='/projects'
                        component={ProjectsPage}
                    />

                    <ProtectedRoute
                        exact
                        path='/projects/create'
                        component={CreateProjectPage}
                    />

                    <ProtectedRoute
                        exact
                        path='/projects/:id'
                        component={ViewProjectPage}
                    />
                    <ProtectedRoute
                        exact
                        path='/projects/:id/edit'
                        component={EditProjectPage}
                    />
                    <ProtectedRoute
                        exact
                        path='/investments'
                        component={InvestmentsPage}
                    />
                    <ProtectedRoute
                        exact
                        path='/join-requests'
                        component={JoinRequestsPage}
                    />
                    <ProtectedRoute
                        exact
                        path='/projects/:id/forum'
                        component={ForumPage}
                    />
                    <ProtectedRoute
                        exact
                        path='/projects/:id/forum/:topicId'
                        component={TopicPage}
                    />

                    <Redirect from='*' to='/' />
                </Switch>
            </Row>
        </ConnectedRouter>
    );
};

export default AppRouter;
