// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

export const API_HOST = `${process.env.REACT_APP_SINFRA_HOST}/api`;

export const JSON_HTTP_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

export const withJsonResponse = (r) => r.json();

export const withAuth = (auth, init = {}) => ({
    ...init,
    headers: {
        ...init.headers,
        Authorization: `Bearer ${auth.token}`,
    },
});

export const addQuery = (url, query) => {
    const index = url.indexOf('?');
    if (0 > index) {
        url += '?';
    } else if (index + 1 < url.length) {
        url += '&';
    }

    return `${url}${query}`;
};

export const withOwn = (url, ownerId) => addQuery(url, `ownerId=${ownerId}`);

export const withOffsetLimit = (url, offset, limit) =>
    addQuery(addQuery(url, `offset=${offset}`), `limit=${limit}`);

export const getJsonRequest = (url, init = {}, onResponse = (j) => j) =>
    getRequest(
        url,
        {
            ...init,
            headers: {
                ...init.headers,
                ...JSON_HTTP_HEADERS,
            },
        },
        (r) => r.json()
    ).then(onResponse);

export const postJsonRequest = (url, init = {}) =>
    postRequest(
        url,
        {
            ...init,
            headers: {
                ...init.headers,
                ...JSON_HTTP_HEADERS,
            },
        },
        (r) => r.json()
    );

export const putJsonRequest = (url, init = {}, onResponse) =>
    putRequest(
        url,
        {
            ...init,
            headers: {
                ...init.headers,
                ...JSON_HTTP_HEADERS,
            },
        },
        onResponse
    );

export const postRequest = (url, init = {}, onResponse) =>
    makeRequest(
        `${API_HOST}${url}`,
        {
            ...init,
            method: 'POST',
        },
        onResponse
    );

export const putRequest = (url, init = {}, onResponse) =>
    makeRequest(
        `${API_HOST}${url}`,
        {
            ...init,
            method: 'PUT',
        },
        onResponse
    );

export const getRequest = (url, init = {}, onResponse) =>
    makeRequest(
        `${API_HOST}${url}`,
        {
            ...init,
            method: 'GET',
        },
        onResponse
    );

export const deleteRequest = (url, init = {}, onResponse) =>
    makeRequest(
        `${API_HOST}${url}`,
        {
            ...init,
            method: 'DELETE',
        },
        onResponse
    );

export const makeRequest = (url, init = {}, onResponse = (R) => R) =>
    fetch(url, init)
        .then(async (response) => {
            if (!response.ok) {
                throw makeError(
                    response.status,
                    `API error`,
                    await response.json()
                );
            }

            return onResponse(response);
        })
        .catch((e) => {
            console.log(`Error in request ${url}`, e);
            throw makeError(
                e.status,
                (e.response && e.response.error && e.response.error.message) ||
                    e.message ||
                    'Network issue',
                e.response
            );
        });

export const makeError = (status, message, response) => ({
    status,
    message,
    response,
});
