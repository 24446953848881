// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import * as account from './account.actions';
import * as app from './app.actions';
import * as projects from './projects.actions';
import * as requests from './requests.actions';
import * as users from './users.actions';
import * as investments from './investments.actions';
import * as joinRequests from './joinrequests.actions';
import * as forum from './forum.actions';

export default {
    account,
    projects,
    requests,
    users,
    app,
    investments,
    joinRequests,
    forum,
};
