// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Actions from 'actions';

const TokenManager = () => {
    
    const dispatch = useDispatch();
    const appUser = useSelector((state) => state.account.user);

    useEffect(() => {
        if (!appUser || !appUser.isLogged || appUser.isRefresh) {
            return;
        }

        const now = Date.now();

        if (now >= appUser.expiredAt) {
            return;
        }

        const refreshTimeout = appUser.expiredAt - now;

        const timer = setTimeout(() => {
            dispatch(Actions.account.refresh(appUser.refreshToken));
        }, refreshTimeout);

        return () => clearTimeout(timer);
    }, [appUser, dispatch]);

    return <div></div>;
};

export default TokenManager;
