// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import { combineReducers, createReducer } from '@reduxjs/toolkit';

import Actions from 'actions';

import { makeAlertReducer } from './alert.reducer';
import { makePageReducer, removeFromPageReducer } from './page.reducer';
import { makeProcessReducer } from './process.reducer';
import { makeSearchReducer } from './search.reducer';
import {
    makeListReducer,
    addToListReducer,
    clearListReducer,
    initListReducer,
} from './list.reducer';
import { makeFetchReducer } from './fetch.reducer';
import { makeModelReducer } from './model.reducer';

const initial = {
    stepId: 'start',
    mode: 'create',
    projectId: null,
    data: {},
};

const wizardViewReducer = createReducer(initial, {
    [Actions.projects.wizardViewStart]: () => initial,
    [Actions.projects.wizardViewEdit]: (state, action) => ({
        ...state,
        stepId: 'info',
        mode: 'edit',
        data: {
            start: action.payload,
        },
    }),
    [Actions.projects.wizardViewLayers]: (state, action) => ({
        ...state,
        stepId: 'layers',
        mode: 'edit',
        data: {
            start: action.payload,
        },
    }),
    [Actions.projects.wizardViewLoading]: (state) => ({
        ...state,
        stepId: 'loading',
        mode: 'edit',
    }),
    [Actions.projects.wizardCreateSuccess]: (state, action) => ({
        ...state,
        stepId: action.payload.wizard.next || state.stepId,
        projectId: action.payload.data && action.payload.data.id,
        data: {
            ...state.data,
            [state.stepId]: action.payload.data || {},
        },
    }),
    [Actions.projects.wizardEditorUploadSuccess]: (state, action) => ({
        ...state,
        stepId: action.payload.wizard.next || state.stepId,
        data: {
            ...state.data,
            [state.stepId]: action.payload.segments || {},
        },
    }),
    [Actions.projects.wizardUpdateInfoSuccess]: (state, action) => ({
        ...state,
        stepId: action.payload.wizard.next || state.stepId,
    }),
});

const modelReducer = createReducer(
    {
        data: null,
    },
    {
        [Actions.projects.modelSuccess]: (state, action) => ({
            ...state,
            data: action.payload,
        }),
    }
);

const infoReducer = {
    [Actions.projects.changeVisibilitySuccess]: (state, { payload }) => ({
        ...state,
        visibilityType:
            state.id === payload.id ? payload.visibility : state.visibilityType,
    }),
    [Actions.projects.edit.success]: (state, { payload }) => ({
        ...state,
        ...payload,
    }),
    [Actions.investments.editPhase.success]: (state, { payload }) => ({
        ...state,
        phases: payload.wasCreated
            ? state.phases.concat([payload.phase])
            : state.phases.map((phase) =>
                  payload.phaseId === phase.id ? payload.phase : phase
              ),
    }),
    [Actions.investments.deletePhase.success]: (state, action) => ({
        ...state,
        phases: state.phases.filter(
            (phase) => action.payload.phaseId !== phase.id
        ),
    }),
};

const mapReducer = createReducer(
    {
        data: [],
        center: [0, 0],
        projects: {
            byId: {},
            allIds: [],
        },
        segments: {
            byId: {},
            allIds: [],
        },
        areas: {
            byId: {},
            allIds: [],
        },
    },
    {
        [Actions.projects.mapSearchSuccess]: (state, action) => ({
            ...state,
            data: action.payload,
        }),
        [Actions.projects.clearSearch]: (state) => ({
            ...state,
            projects: {
                byId: {},
                allIds: [],
            },
        }),
        [Actions.projects.placeSearchSuccess]: (state, action) => ({
            ...state,
            projects: {
                byId: action.payload.projects.reduce((r, i) => {
                    r[i.id] = i;
                    return r;
                }, {}),
                allIds: action.payload.projects.map((item) => item.id),
            },
        }),
        [Actions.account.logout]: (state) => ({
            ...state,
            projects: {
                byId: {},
                allIds: [],
            },
        }),
    }
);

const makeMapProjectsReducer = (scope) =>
    makeFetchReducer(scope, (scope) =>
        combineReducers({
            projects: createReducer(initListReducer(), {
                [`${scope}/projects:loaded`]: addToListReducer,
                [`${scope}:reset`]: clearListReducer,
            }),
            segments: createReducer(initListReducer(), {
                [`${scope}/segments:loaded`]: addToListReducer,
                [`${scope}:reset`]: clearListReducer,
            }),
            areas: createReducer(initListReducer(), {
                [`${scope}/areas:loaded`]: addToListReducer,
                [`${scope}:reset`]: clearListReducer,
            }),
        })
    );

export default combineReducers({
    table: combineReducers({
        alert: makeAlertReducer(Actions.projects.TABLE_ACTION),
        process: makeProcessReducer(Actions.projects.TABLE_ACTION),
        page: makePageReducer(Actions.projects.TABLE_ACTION, {
            [Actions.projects.deleteProjectSuccess]: removeFromPageReducer,
        }),
    }),
    ownList: combineReducers({
        alert: makeAlertReducer(Actions.projects.OWN_LIST_ACTION),
        process: makeProcessReducer(Actions.projects.OWN_LIST_ACTION),
        list: makeListReducer(Actions.projects.OWN_LIST_ACTION),
    }),
    wizard: combineReducers({
        view: wizardViewReducer,
        editSegment: makeFetchReducer(
            Actions.projects.WIZARD_EDIT_SEGMENT,
            (scope) => makeModelReducer(scope, null)
        ),
        editArea: makeFetchReducer(Actions.projects.WIZARD_EDIT_AREA, (scope) =>
            makeModelReducer(scope, null)
        ),
        process: makeProcessReducer(Actions.projects.WIZARD_ACTION),
        map: combineReducers({
            search: makeSearchReducer(Actions.projects.WIZARD_MAP_ACTION),
        }),
        status: makeFetchReducer(
            Actions.projects.WIZARD_IMPORT_STATUS_ACTION,
            makeModelReducer
        ),
    }),
    project: combineReducers({
        alert: makeAlertReducer(Actions.projects.MODEL_ACTION),
        process: makeProcessReducer(Actions.projects.MODEL_ACTION),
        model: modelReducer,
        info: makeFetchReducer(Actions.projects.INFO_MODEL_ACTION, (scope) =>
            makeModelReducer(scope, null, infoReducer)
        ),
        edit: makeFetchReducer(Actions.projects.EDIT_ACTION, (scope) =>
            makeModelReducer(scope, null)
        ),
        segments: combineReducers({
            alert: makeAlertReducer(Actions.projects.SEGMENTS_TABLE_ACTION),
            process: makeProcessReducer(Actions.projects.SEGMENTS_TABLE_ACTION),
            page: makePageReducer(Actions.projects.SEGMENTS_TABLE_ACTION),
        }),
    }),
    segmentTypes: combineReducers({
        process: makeProcessReducer(Actions.projects.SEGMENT_TYPES_ACTION),
        page: makePageReducer(Actions.projects.SEGMENT_TYPES_ACTION),
    }),

    map: mapReducer,
    mapProjects: makeMapProjectsReducer(Actions.projects.MAP_PROJECTS_ACTION),
});
