// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

const groupLineToLine = (segmentData, lineA, lineB) => {
    return {
        type: 'Feature',
        properties: {
            segment: {
                ...segmentData,
                points: [
                    lineA.properties.segment.points,
                    lineB.properties.segment.points,
                ],
            },
        },
        geometry: {
            type: 'MultiLineString',
            coordinates: [
                lineA.geometry.coordinates,
                lineB.geometry.coordinates,
            ],
        },
    };
};

const groupMultiLineToLine = (segmentData, lineA, lineB) => {
    return {
        type: 'Feature',
        properties: {
            segment: {
                ...segmentData,
                points: lineA.properties.segment.points.concat([
                    lineB.properties.segment.points,
                ]),
            },
        },
        geometry: {
            type: 'MultiLineString',
            coordinates: lineA.geometry.coordinates.concat([
                lineB.geometry.coordinates,
            ]),
        },
    };
};

const groupMultiLineToMultiLine = (segmentData, lineA, lineB) => {
    return {
        type: 'Feature',
        properties: {
            segment: {
                ...segmentData,
                points: lineA.properties.segment.points.concat(
                    lineB.properties.segment.points
                ),
            },
        },
        geometry: {
            type: 'MultiLineString',
            coordinates: lineA.geometry.coordinates.concat(
                lineB.geometry.coordinates
            ),
        },
    };
};

const ungroupMultiLineToLines = (feature) =>
    feature.geometry.coordinates.map((coords, i) => ({
        type: 'Feature',
        properties: {
            ...feature.properties,
            segment: {
                ...feature.properties.segment,
                points: feature.properties.segment.points[i],
                id: 0 === i ? feature.properties.segment.id : null,
            },
        },
        geometry: {
            type: 'LineString',
            coordinates: coords,
        },
    }));

const GROUP_SEGMENTS_SUPPORTED_TYPES = new Set([
    'LineString',
    'MultiLineString',
]);

const groupSegmentFeatures = (segmentFeatures) => {
    const featuresToRemove = new Set();

    segmentFeatures = segmentFeatures.filter((f) =>
        GROUP_SEGMENTS_SUPPORTED_TYPES.has(f.geometry.type)
    );

    if (0 === segmentFeatures.length) {
        return [null, featuresToRemove];
    }

    if (1 === segmentFeatures.length) {
        return [segmentFeatures[0], featuresToRemove];
    }

    let segmentData = null;
    for (let feature of segmentFeatures) {
        if (feature.properties.segment) {
            if (!segmentData) {
                segmentData = feature.properties.segment;
            }

            if (feature.properties.segment.id) {
                segmentData = feature.properties.segment;
                break;
            }
        }
    }

    let resultFeature = segmentFeatures[0];
    featuresToRemove.add(resultFeature.id);

    for (let i = 1; i < segmentFeatures.length; ++i) {
        const leftFeature = segmentFeatures[i];

        featuresToRemove.add(leftFeature.id);

        switch (resultFeature.geometry.type) {
            case 'LineString':
                switch (leftFeature.geometry.type) {
                    case 'LineString':
                        resultFeature = groupLineToLine(
                            segmentData,
                            resultFeature,
                            leftFeature
                        );
                        break;
                    case 'MultiLineString':
                        resultFeature = groupMultiLineToLine(
                            segmentData,
                            resultFeature,
                            leftFeature
                        );
                        break;
                    default:
                        break;
                }
                break;
            case 'MultiLineString':
                switch (leftFeature.geometry.type) {
                    case 'LineString':
                        resultFeature = groupMultiLineToLine(
                            segmentData,
                            resultFeature,
                            leftFeature
                        );
                        break;
                    case 'MultiLineString':
                        resultFeature = groupMultiLineToMultiLine(
                            segmentData,
                            resultFeature,
                            leftFeature
                        );

                        break;
                    default:
                        break;
                }
                break;
            default:
                throw Error('Unexpected type of segment');
        }
    }

    return [resultFeature, featuresToRemove];
};

const ungroupSegmentFeatures = (segmentFeatures) => {
    segmentFeatures = segmentFeatures.filter((f) =>
        GROUP_SEGMENTS_SUPPORTED_TYPES.has(f.geometry.type)
    );

    const featuresToRemove = new Set();

    if (0 === segmentFeatures.length) {
        return [[], featuresToRemove];
    }

    let resultFeatures = [];

    for (let segmentFeature of segmentFeatures) {
        switch (segmentFeature.geometry.type) {
            case 'LineString':
                // Nothing to do
                break;
            case 'MultiLineString':
                resultFeatures = resultFeatures.concat(
                    ungroupMultiLineToLines(segmentFeature)
                );
                featuresToRemove.add(segmentFeature.id);
                break;
            default:
                throw Error('Unexpected type of segment');
        }
    }

    return [resultFeatures, featuresToRemove];
};

export { ungroupSegmentFeatures };
export { groupSegmentFeatures };
