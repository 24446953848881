// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

const POLYGON_STYLES = [
    {
        id: 'gl-draw-polygon-fill-inactive',
        type: 'fill',
        filter: [
            'all',
            ['==', 'active', 'false'],
            ['==', '$type', 'Polygon'],
            ['!=', 'mode', 'static'],
        ],
        paint: {
            'fill-color': [
                'get',
                'color',
                ['get', 'areaType', ['get', 'user_area']],
            ],
            'fill-outline-color': '#3bb2d0',
            'fill-opacity': 0.5,
        },
    },
    {
        id: 'gl-draw-polygon-fill-active',
        type: 'fill',
        filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
        paint: {
            'fill-color': '#fbb03b',
            'fill-outline-color': '#fbb03b',
            'fill-opacity': 0.6,
        },
    },
    {
        id: 'gl-draw-polygon-stroke-inactive',
        type: 'line',
        filter: [
            'all',
            ['==', 'active', 'false'],
            ['==', '$type', 'Polygon'],
            ['!=', 'mode', 'static'],
        ],
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#3bb2d0',
            'line-width': 2,
        },
    },
    {
        id: 'gl-draw-polygon-stroke-active',
        type: 'line',
        filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#fbb03b',
            'line-dasharray': [0.2, 2],
            'line-width': 2,
        },
    },
    {
        id: 'gl-draw-polygon-fill-static',
        type: 'fill',
        filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
        paint: {
            'fill-color': '#404040',
            'fill-outline-color': '#404040',
            'fill-opacity': 0.1,
        },
    },
    {
        id: 'gl-draw-polygon-stroke-static',
        type: 'line',
        filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#404040',
            'line-width': 2,
        },
    },
];

const LINE_POINT_STYLES = [
    {
        id: 'gl-draw-polygon-midpoint',
        type: 'circle',
        filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
        paint: {
            'circle-radius': 2,
            'circle-color': '#096373',
        },
    },
    {
        id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
        type: 'circle',
        filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static'],
        ],
        paint: {
            'circle-radius': 4,
            'circle-color': '#29adc4',
        },
    },
    {
        id: 'gl-draw-polygon-and-line-vertex-inactive',
        type: 'circle',
        filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static'],
        ],
        paint: {
            'circle-radius': 4,
            'circle-color': '#1a99b0',
        },
    },
];

const LINE_STYLES = [
    {
        id: 'gl-draw-line-inactive',
        type: 'line',
        filter: [
            'all',
            ['==', 'active', 'false'],
            ['==', '$type', 'LineString'],
            ['!=', 'mode', 'static'],
        ],
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': [
                'get',
                'color',
                ['get', 'segmentType', ['get', 'user_segment']],
            ],
            'line-width': 2,
        },
        minzoom: 12,
        maxzoom: 23,
    },
    {
        id: 'gl-draw-line-active',
        type: 'line',
        filter: [
            'all',
            ['==', '$type', 'LineString'],
            ['==', 'active', 'true'],
        ],
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#266773',
            'line-width': 4,
        },
    },
    {
        id: 'gl-draw-line-static',
        type: 'line',
        filter: [
            'all',
            ['==', 'mode', 'static'],
            ['==', '$type', 'LineString'],
        ],
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#404040',
            'line-width': 20.0,
        },
    },
];

const POINT_STYLES = [
    {
        id: 'gl-draw-point-point-stroke-inactive',
        type: 'circle',
        filter: [
            'all',
            ['==', 'active', 'false'],
            ['==', '$type', 'Point'],
            ['==', 'meta', 'feature'],
            ['!=', 'mode', 'static'],
        ],
        paint: {
            'circle-radius': 6,
            'circle-opacity': 1,
            'circle-color': '#fff',
        },
    },
    {
        id: 'gl-draw-point-inactive',
        type: 'circle',
        filter: [
            'all',
            ['==', 'active', 'false'],
            ['==', '$type', 'Point'],
            ['==', 'meta', 'feature'],
            ['!=', 'mode', 'static'],
        ],
        paint: {
            'circle-radius': 6,
            'circle-color': '#3bb2d0',
        },
    },
    {
        id: 'gl-draw-point-stroke-active',
        type: 'circle',
        filter: [
            'all',
            ['==', '$type', 'Point'],
            ['==', 'active', 'true'],
            ['!=', 'meta', 'midpoint'],
        ],
        paint: {
            'circle-radius': 6,
            'circle-color': '#fff',
        },
    },
    {
        id: 'gl-draw-point-active',
        type: 'circle',
        filter: [
            'all',
            ['==', '$type', 'Point'],
            ['!=', 'meta', 'midpoint'],
            ['==', 'active', 'true'],
        ],
        paint: {
            'circle-radius': 6,
            'circle-color': '#29adc4',
        },
    },

    {
        id: 'gl-draw-point-static',
        type: 'circle',
        filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
        paint: {
            'circle-radius': 4,
            'circle-color': '#404040',
        },
    },
];

export function createMapboxDrawStyles() {
    return [
        ...POLYGON_STYLES,
        ...LINE_STYLES,
        ...POINT_STYLES,
        ...LINE_POINT_STYLES,
    ];
}
