// Copyright © 2020 Embrox Solutions LLC
// Copyright © 2020 Genconstrux GmbH

import MapboxDraw from '@mapbox/mapbox-gl-draw';

import { createMapboxDrawStyles } from './styles';

const createMapboxDraw = () =>
    new MapboxDraw({
        displayControlsDefault: false,
        userProperties: true,
        controls: {},
        clickBuffer: 25,
        styles: createMapboxDrawStyles(),
    });

export { createMapboxDraw };
